import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';

const createImage = (
	imgSource: string,
	cssProperties: CSSProperties,
	onClick: () => void,
	dataTestId: string,
	altText: string
) => (
	<Link
		onClick={onClick}
		onKeyDown={onClick}
		style={{
			display: 'flex',
			maxWidth: '100%',
			maxHeight: '100%',
		}}
	>
		<img
			data-testid={dataTestId}
			src={'data:image/jpg;base64,' + imgSource}
			style={{
				...cssProperties,
				objectFit: 'contain',
				overflow: 'hidden',
			}}
			alt={altText}
		/>
	</Link>
);

export type ScreenshotViewerProps = {
	screenShot: string;
};

export const ScreenshotViewer = ({ screenShot }: ScreenshotViewerProps) => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation(['result']);
	const theme = useTheme();

	document.addEventListener('keydown', (keyDownEvent) => {
		if (keyDownEvent.key === 'Escape') {
			setOpen(false);
		}
	});

	return (
		<>
			{createImage(
				screenShot,
				{
					cursor: 'zoom-in',
				},
				() => setOpen(true),
				'screenShot',
				t('events.details.screenshotAltTxt')
			)}
			<Backdrop open={open} sx={{ position: 'fixed', zIndex: 10_000 }}>
				<IconButton
					style={{
						color: 'white',
						top: theme.spacing(1),
						right: theme.spacing(1),
						position: 'absolute',
					}}
					onClick={() => setOpen(false)}
				>
					<CloseOutlined style={{ color: 'white', opacity: '60%' }} />
				</IconButton>
				{createImage(
					screenShot,
					{
						padding: '40px',
						cursor: 'zoom-out',
					},
					() => setOpen(false),
					'screenShotViewer',
					t('events.details.screenshotAltTxt')
				)}
			</Backdrop>
		</>
	);
};
