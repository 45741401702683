import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const size = 80;
const margin = -size / 2;

const ApplicationLoader = () => (
	<Box
		sx={{
			height: '100vh',
			width: '100vw',
		}}
	>
		<CircularProgress
			size={`${size}px`}
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				marginTop: `${margin}px`,
				marginLeft: `${margin}px`,
			}}
		/>
	</Box>
);

export { ApplicationLoader };
