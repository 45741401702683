import { MouseEventHandler, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import ChipStatus from '@tricentis/aura/components/ChipStatus.js';
import { RunChip } from './run-chip';
import { ResultStatusData } from './result-status-data';
import { TestResultInput } from '@neoload/api';

type QualityStatus = NonNullable<TestResultInput['qualityStatus']>;

type EditableRunChipProps = {
	resultStatusData: ResultStatusData;
	onRightClick?: MouseEventHandler<HTMLDivElement>;
	withLabel?: boolean;
	onStatusChange: (nextStatus: QualityStatus) => void;
};

const EditableRunChip = (props: EditableRunChipProps) => {
	const { resultStatusData: status, onStatusChange, withLabel, onRightClick } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation(['result']);
	const open = Boolean(anchorEl);

	const onClick: MouseEventHandler<HTMLDivElement> = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const changeStatus = (nextStatus: QualityStatus): void => {
		handleClose();
		onStatusChange(nextStatus);
	};

	return (
		<>
			<RunChip resultStatusData={status} onRightClick={onRightClick} withLabel={withLabel} onClick={onClick} />
			<Menu
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				data-testid='editable-run-icon-menu'
				sx={{ marginTop: 1 }}
			>
				<MenuItem
					selected={status.qualityStatus === 'PASSED'}
					data-testid='editable-run-icon-menu-item'
					onClick={() => changeStatus('PASSED')}
				>
					<ChipStatus status='Passed' label={t('resultStatus.passed')} />
				</MenuItem>
				<MenuItem
					selected={status.qualityStatus === 'FAILED'}
					data-testid='editable-run-icon-menu-item'
					onClick={() => changeStatus('FAILED')}
				>
					<ChipStatus status='Failed' label={t('resultStatus.failed')} />
				</MenuItem>
				<MenuItem
					selected={status.qualityStatus === 'UNKNOWN'}
					data-testid='editable-run-icon-menu-item'
					onClick={() => changeStatus('UNKNOWN')}
				>
					<ChipStatus status='NotApplicable' label={t('resultStatus.unknown')} />
				</MenuItem>
			</Menu>
		</>
	);
};

export { EditableRunChip };
