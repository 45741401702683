import { ReactNode, isValidElement } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export type EventTabDetailItemProps = {
	displayLoading?: boolean;
	title: string;
	value?: string | ReactNode | number | object;
};

const EventTabDetailItem = ({ displayLoading = false, title, value }: EventTabDetailItemProps) => {
	if (value === undefined || value === null) {
		return null;
	}
	return (
		<>
			<Typography variant='body2' color='text.secondary' component={'p'} marginBottom={0.5}>
				{displayLoading ? <Skeleton sx={{ maxWidth: '10%' }} /> : title}
			</Typography>
			{isValidElement(value) ? (
				<Box marginBottom={2} data-testid={`${title}-value`}>
					{value}
				</Box>
			) : (
				<Typography
					variant='body2'
					marginBottom={2}
					style={{ overflowWrap: 'anywhere' }}
					data-testid={`${title}-value`}
				>
					{displayLoading ? <Skeleton sx={{ maxWidth: '60%' }} /> : value.toString()}
				</Typography>
			)}
		</>
	);
};

export { EventTabDetailItem };
