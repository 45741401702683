import { ChipProps } from '@mui/material/Chip';
import CachedOutlined from '@mui/icons-material/CachedOutlined';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import ChipStatus from '@tricentis/aura/components/ChipStatus.js';

export type RunningChipProps = {
	withLabel?: boolean;
	percentage?: number;
	onClick?: MouseEventHandler<HTMLDivElement>;
	size?: ChipProps['size'];
};

const i18nLabelKey = (percentage?: number) =>
	percentage === undefined ? 'resultStatus.running' : 'resultStatus.runningPercent';

const RunningChip = ({ withLabel = false, percentage, onClick, size = 'medium' }: RunningChipProps) => {
	const { t } = useTranslation(['result']);

	return (
		<ChipStatus
			icon={
				<CachedOutlined
					sx={{
						animation: 'spin 2s linear infinite',
						'@keyframes spin': {
							'0%': {
								transform: 'rotate(360deg)',
							},
							'100%': {
								transform: 'rotate(0deg)',
							},
						},
					}}
				/>
			}
			label={withLabel ? t(i18nLabelKey(percentage), { percentage }) : null}
			status='Running'
			onClick={onClick}
			clickable={typeof onClick === 'function'}
			size={size}
			data-testid='running-chip-status'
		/>
	);
};

export { RunningChip };
