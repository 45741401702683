import Box, { BoxProps } from '@mui/material/Box';

export type ComponentTabPanelProps = {
	value: number;
	index: number;
} & BoxProps;
const ComponentTabPanel = ({ value, index, children, ...other }: ComponentTabPanelProps) => (
	<Box hidden={value !== index} {...other}>
		{value === index && children}
	</Box>
);
export { ComponentTabPanel };
