const patterns = {
	base: '/settings',
	generalSettings: '/settings/general',
	users: '/settings/users',
	workspaces: '/settings/workspaces',
	infrastructureProviders: '/settings/infrastructure-providers',
	subscription: '/settings/subscription',
};

const settingsRoutes = {
	patterns: patterns,
	base: patterns.base,
	generalSettings: patterns.generalSettings,
	users: patterns.users,
	workspaces: patterns.workspaces,
	infrastructureProviders: patterns.infrastructureProviders,
	subscription: patterns.subscription,
};

export { settingsRoutes as SettingsRoutes };
