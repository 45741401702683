import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Close from '@mui/icons-material/Close';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { downloadTableAsCsv, TableData } from './download-values-comparison';
import { CsvDownloadContext } from './csv-download-context';
import { TextEllipsisTooltip } from '../../../../common/text-ellipsis-tooltip';

export type DashboardTileProps = {
	title: string;
	children: ReactNode;
	contentType?: string;
	isReadOnly: boolean;
	onDelete: () => void;
	onSwitchEdition: () => void;
	editionEnabled?: boolean;
	editionHidden?: boolean;
};

const DashboardTile = ({
	title,
	children,
	contentType,
	isReadOnly,
	onDelete,
	onSwitchEdition,
	editionEnabled = true,
	editionHidden = false,
}: DashboardTileProps) => {
	const { t } = useTranslation(['dashboard']);
	const [over, setOver] = useState(false);
	const [dataToDownload, setDataToDownload] = useState<TableData | undefined>(undefined);

	const context = useMemo(
		() => ({
			setDataToDownload,
		}),
		[setDataToDownload]
	);

	const getAction = () => (
		<Box padding={0} margin={0} hidden={!over}>
			<ButtonGroup variant='text' className='tile-action-buttons'>
				{!isReadOnly && (
					<>
						{contentType === 'VALUES_COMPARISON' && (
							<Tooltip arrow title={t('common:downloadCsv')}>
								<IconButton
									size='small'
									onClick={() => downloadTableAsCsv(title, dataToDownload)}
									disabled={dataToDownload === undefined}
								>
									<DownloadOutlined />
								</IconButton>
							</Tooltip>
						)}
						{!editionHidden && (
							<Tooltip arrow title={t('tile.edit.button')}>
								<IconButton size='small' onClick={onSwitchEdition} disabled={!editionEnabled}>
									<EditOutlined />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip arrow title={t('tile.delete.button')}>
							<IconButton size='small' onClick={onDelete}>
								<Close />
							</IconButton>
						</Tooltip>
					</>
				)}
			</ButtonGroup>
		</Box>
	);
	return (
		<Card
			variant='elevation'
			elevation={1}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				paddingBottom: (theme) => `${theme.spacing(isReadOnly ? 1 : 2)} !important`,
			}}
			onMouseEnter={() => setOver(true)}
			onMouseLeave={() => setOver(false)}
			data-tile
		>
			<CardHeader
				title={
					<Box
						sx={{
							paddingRight: (theme) => theme.spacing(over ? 0 : 1),
						}}
					>
						<TextEllipsisTooltip title={title} variant={'subtitle2'} data-tile-title />
					</Box>
				}
				sx={{
					height: (theme) => theme.spacing(6),
					minHeight: (theme) => theme.spacing(6),
					padding: (theme) => theme.spacing(1),
					cursor: isReadOnly ? 'default' : 'move',
					'& .MuiCardHeader-content': {
						padding: (theme) => theme.spacing(0),
						marginLeft: (theme) => theme.spacing(1),
						overflow: 'hidden',
					},
					'& .MuiCardHeader-action': {
						margin: (theme) => theme.spacing(0),
					},
				}}
				action={getAction()}
				className={'tile-header'}
			/>
			<CardContent
				sx={{
					flex: '1 0 0',
					height: '100%',
					overflow: 'auto',
					padding: '0px !important',
				}}
				data-tile-content
				data-tile-content-type={contentType ?? 'UNKNOWN'}
			>
				<CsvDownloadContext.Provider value={context}>{children}</CsvDownloadContext.Provider>
			</CardContent>
		</Card>
	);
};

export { DashboardTile };
