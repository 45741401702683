import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StatisticsBoxItem, StatisticsBoxItemProps } from './statistics-box-item';
import { DoubleColumnRow } from './statistics-box-double-columns';

export type StatisticsBoxProps = {
	title: string;
	left: StatisticsBoxItemProps[];
	right?: StatisticsBoxItemProps[];
}
const StatisticsBox = ({ title, left, right = [] }: StatisticsBoxProps) => (
	<>
		<Typography variant='subtitle1' sx={{ width: '100%' }} gutterBottom>
			{title}
		</Typography>
		{left.map((leftRow, index) =>
			right.length === left.length ? (
				<DoubleColumnRow key={leftRow.statisticKey} leftRow={leftRow} rightRow={right[index]} />
			) : (
				<Box paddingTop={1} key={leftRow.statisticKey}>
					<StatisticsBoxItem {...leftRow} />
				</Box>
			)
		)}
	</>
);

export { StatisticsBox };
