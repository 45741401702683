import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import Typography from '@mui/material/Typography';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';

/**
 * generate render functions for a type of object
 * @param type root of translation object. This must have columns[key] {title, description} objects
 * @returns
 */
const useRenderFunctions = (type: string) => {
	const { t } = useTranslation([type]);
	return {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		renderHeader: (params: GridColumnHeaderParams<any, any, any>): React.ReactNode => (
			<Tooltip arrow title={t(`columns.${params.field}.description`, '')}>
				<Typography noWrap variant='subtitle2'>
					{t(`columns.${params.field}.title`)}
				</Typography>
			</Tooltip>
		),
	};
};

export { useRenderFunctions };
