import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import Box from '@mui/material/Box';
import {
	defaultTestExecutionFormError,
	defaultTestExecutionFormSuccess,
	getTestExecutionPartialError,
	isFetchBaseQueryError,
	getPartialErrorSubscription,
} from './test-execution-helpers';
import { TestExecution } from './test-execution';
import { TestExecutionForm } from './types.d';
import { Spinner } from '../../common/spinner';
import {
	CloseFunction,
	MethodNotAllowedError,
	Reservation,
	SetConfirmableFunction,
	Settings,
	Subscription,
	useGetV4ReservationsQuery,
	useGetV4SettingsQuery,
	useGetV4SubscriptionsQuery,
	usePostV4TestExecutionsMutation,
	ValueUpdaterFunction,
} from '@neoload/api';
import { useCurrentWorkspace, useSetSnackbars } from '@neoload/hooks';

export type ModalRunTestProps = {
	testId: string;
	valueUpdater: ValueUpdaterFunction;
	setConfirmable: SetConfirmableFunction;
	close: CloseFunction;
};

const ModalTestExecution = ({ testId, valueUpdater, setConfirmable, close }: ModalRunTestProps) => {
	const { t } = useTranslation(['test']);
	const { showError } = useSetSnackbars();
	const [testExecution] = usePostV4TestExecutionsMutation();
	const [{ id: workspaceId }] = useCurrentWorkspace();
	const {
		data: subscription,
		error: subscriptionError,
		isLoading: isSubscriptionLoading,
	} = useGetV4SubscriptionsQuery({ workspaceId });
	const { data: settings, isLoading: isSettingsLoading } = useGetV4SettingsQuery();
	const { data: reservations, isLoading: isReservationsLoading } = useGetV4ReservationsQuery({
		workspaceId,
		status: 'AVAILABLE',
	});
	const [defaultValues, setDefaultValues] = useState<TestExecutionForm | null>(null);

	const setDataRunModalError = useCallback(
		(
			error: FetchBaseQueryError | SerializedError,
			subscription?: Subscription,
			setting?: Settings,
			availableReservations?: Reservation[]
		) => {
			if (isFetchBaseQueryError(error)) {
				const partialError = getTestExecutionPartialError(error);

				if (partialError?.result && partialError.errors) {
					setDefaultValues(
						defaultTestExecutionFormError(
							partialError.result,
							partialError.errors,
							subscription,
							setting,
							availableReservations
						)
					);
				} else {
					let text = t('common:errors.otherError.content');
					if (
						error.status === 405 &&
						(error.data as MethodNotAllowedError).description === 'The Neoload cloud platform is not available'
					) {
						text = t('testExecution.errors.ncpUnreachable');
					}
					close();
					showError({
						text,
						id: 'error-no-cnp',
					});
				}
			}
		},
		[close, showError, t]
	);

	useEffect(() => {
		if (!isSubscriptionLoading && !isSettingsLoading && !isReservationsLoading) {
			let subs = subscription;
			if (subscriptionError) {
				subs = getPartialErrorSubscription(subscriptionError);
			}
			const openRunModal = (testId: string) => {
				testExecution({
					dryRun: true,
					testExecutionInput: {
						testId: testId,
					},
				})
					.unwrap()
					.then((testExecution) => {
						setDefaultValues(defaultTestExecutionFormSuccess(testExecution, subs, settings, reservations?.items));
						setConfirmable(true);
					})
					.catch((error) => {
						setDataRunModalError(error, subs, settings, reservations?.items);
						setConfirmable(false);
					});
			};

			openRunModal(testId);
		}
	}, [
		testId,
		showError,
		t,
		testExecution,
		setDataRunModalError,
		subscription,
		isSubscriptionLoading,
		setConfirmable,
		isSettingsLoading,
		settings,
		reservations,
		isReservationsLoading,
		subscriptionError,
	]);

	if (defaultValues === null) {
		return (
			<Box sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '40px' }}>
				<Spinner />
			</Box>
		);
	}

	return <TestExecution defaultValues={defaultValues} valueUpdater={valueUpdater} setConfirmable={setConfirmable} />;
};

export { ModalTestExecution };
