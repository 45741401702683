import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { addSnackbar, removeAllSnackbars, removeSnackbar } from '@neoload/api';

function getRandomInt() {
	return Math.floor(Math.random() * 10_000) + '';
}

export type Snackbar = {
	id?: string;
	text?: string | null;
	actionText?: string | null;
	actionCallback?: () => void | null;
	autoHideDuration?: number | null;
};

const useSetSnackbars = () => {
	const dispatch = useDispatch();

	return {
		showInfo: useCallback(
			({ id, text, actionText, actionCallback, autoHideDuration = 6000 }: Snackbar) =>
				dispatch(
					addSnackbar({
						severity: 'info',
						text,
						actionText,
						actionCallback,
						id: id ?? getRandomInt(),
						autoHideDuration,
					})
				),
			[dispatch]
		),
		showWarning: useCallback(
			({ id, text, actionText, actionCallback, autoHideDuration = 6000 }: Snackbar) =>
				dispatch(
					addSnackbar({
						severity: 'warning',
						text,
						actionText,
						actionCallback,
						id: id ?? getRandomInt(),
						autoHideDuration,
					})
				),
			[dispatch]
		),
		showError: useCallback(
			({ id, text, actionText, actionCallback, autoHideDuration = null }: Snackbar) =>
				dispatch(
					addSnackbar({
						severity: 'error',
						text,
						actionText,
						actionCallback,
						id: id ?? getRandomInt(),
						autoHideDuration,
					})
				),
			[dispatch]
		),
		showSuccess: useCallback(
			({ id, text, actionText, actionCallback, autoHideDuration = 6000 }: Snackbar) =>
				dispatch(
					addSnackbar({
						severity: 'success',
						text,
						actionText,
						actionCallback,
						id: id ?? getRandomInt(),
						autoHideDuration,
					})
				),
			[dispatch]
		),
		removeSnackbar: useCallback(
			(id: string) => {
				dispatch(removeSnackbar(id));
			},
			[dispatch]
		),
		removeAllSnackbars: useCallback(() => {
			dispatch(removeAllSnackbars({}));
		}, [dispatch]),
	};
};

export { useSetSnackbars };
