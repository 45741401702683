import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirmModal, useCrudBatchMessage } from '../modals';
import { TestResult, useDeleteV4ResultsByResultIdMutation } from '@neoload/api';

/**
 * Provide a function that opens the delete Result modal, and when confirm do API call to delete the result
 *
 * @param redirectRoute the route to navigate after the result is deleted
 * @returns the delete function
 */
const useDeleteResults = (redirectRoute?: string) => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const { t } = useTranslation(['result']);
	const { remove } = useCrudBatchMessage('result');
	const [deleteResult] = useDeleteV4ResultsByResultIdMutation();
	const navigate = useNavigate();

	return useCallback(
		(resultsToDelete: Pick<TestResult, 'id' | 'name'>[]) => {
			const onDeleteResults = async (resultIds: string[]) => {
				await remove(resultIds, (resultId) => deleteResult({ resultId: resultId }).unwrap());
				if (redirectRoute) {
					navigate(redirectRoute);
				}
			};
			const title = t('actions.delete.title', { count: resultsToDelete.length });
			const content = t('actions.delete.content', { count: resultsToDelete.length });

			openDeleteConfirmModal({
				title,
				content,
				handleConfirm: async () => onDeleteResults(resultsToDelete.map((result) => result.id)),
			});
		},
		[deleteResult, openDeleteConfirmModal, remove, t, navigate, redirectRoute]
	);
};

export { useDeleteResults };
