import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snack from '@mui/material/Snackbar';
import { useSetSnackbars } from '@neoload/hooks';
import { SnackbarState } from '@neoload/api';

const Snackbar = ({ snack }: { snack: SnackbarState }) => {
	const { removeSnackbar } = useSetSnackbars();

	return (
		<Snack
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom',
			}}
			autoHideDuration={snack.autoHideDuration}
			open={true}
			onClose={(_, reason) => {
				if (reason === 'timeout') {
					removeSnackbar(snack.id);
				}
			}}
			sx={{
				position: 'initial',
			}}
			data-testid={`snackbar-${snack.id}`}
		>
			<Alert onClose={() => removeSnackbar(snack.id)} severity={snack.severity}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '300px',
					}}
				>
					<Box>{snack.text}</Box>
					{snack.actionText && snack.actionCallback && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								size='small'
								onClick={() => {
									removeSnackbar(snack.id);
									if (snack.actionCallback !== undefined) {
										snack.actionCallback();
									}
								}}
								sx={{ lineHeight: '12px' }}
								data-testid={`snackbar-action-${snack.id}`}
							>
								{snack.actionText}
							</Button>
						</Box>
					)}
				</Box>
			</Alert>
		</Snack>
	);
};

export { Snackbar };
