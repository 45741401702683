import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useFormContext, useWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { EmptyProject } from './empty-project';
import { allowedFileUploadExtensionString, getReadableSize } from './utils';
import { TestConfiguration } from '../test-configuration';
import { ConfigurationFormData } from '../types';
import { timeUtils } from '@neoload/utils';
import { useGetV4InformationQuery } from '@neoload/api';

const Project = () => {
	const { t } = useTranslation(['test']);
	const { data: information, isLoading: isInformationLoading } = useGetV4InformationQuery();
	const [inputKey, setInputKey] = useState(Date.now());
	const { setValue } = useFormContext<ConfigurationFormData>();
	const projectName = useWatch<ConfigurationFormData, 'project.name'>({ name: 'project.name' });
	const projectSize = useWatch<ConfigurationFormData, 'project.size'>({ name: 'project.size' });
	const projectUpdatedAt = useWatch<ConfigurationFormData, 'project.updatedAt'>({ name: 'project.updatedAt' });
	const projectUserModifierName = useWatch<ConfigurationFormData, 'project.userModifierName'>({
		name: 'project.userModifierName',
	});
	const projectSource = useWatch<ConfigurationFormData, 'project.source'>({ name: 'project.source' });
	const disabled = useWatch<ConfigurationFormData, 'configurationDisabled'>({ name: 'configurationDisabled' });

	const getTypeUploaded = () =>
		projectSource === 'GIT' ? t('configuration.project.sourceGit') : t('configuration.project.sourceLocal');

	useEffect(() => {
		if (information) {
			setValue('projectMaxSize', information?.projectMaxSize);
		}
	}, [information, setValue]);

	const actionButton = (
		<Button component='label' role={undefined} variant='outlined' disabled={isInformationLoading}>
			{t('configuration.project.file.button')}
			<input
				key={inputKey}
				type='file'
				hidden
				accept={allowedFileUploadExtensionString.replaceAll(' ', '')}
				onChange={(event) => {
					const files = (event.target as HTMLInputElement).files;
					setValue('file', files?.[0]);
					setValue('fileName', files?.[0].name);
					setInputKey(Date.now());
				}}
			/>
		</Button>
	);

	return (
		<TestConfiguration name={t('configuration.project.title')} actions={!disabled && actionButton}>
			{disabled ? (
				<EmptyProject />
			) : (
				<Box sx={{ paddingTop: 2, paddingRight: 2, paddingBottom: 2, gap: 2 }}>
					<Stack useFlexGap flexDirection='row' sx={{ paddingRight: 2, gap: 5 }}>
						<ItemProject name={t('configuration.project.project')} value={projectName} />
						<ItemProject
							name={t('configuration.project.size')}
							value={projectSize ? getReadableSize(t, projectSize, 2) : undefined}
						/>
						<ItemProject
							name={t('configuration.project.uploaded')}
							value={timeUtils.dateRelative(projectUpdatedAt)}
							title={timeUtils.dateTimeAbsolute(projectUpdatedAt)}
						/>
						<ItemProject name={t('configuration.project.uploadedBy')} value={projectUserModifierName} />
						<ItemProject name={t('configuration.project.source')} value={getTypeUploaded()} />
					</Stack>
				</Box>
			)}
		</TestConfiguration>
	);
};

type ItemProjectProps = {
	name: string;
	value: string | undefined;
	title?: string;
};

const ItemProject = ({ name, value, title }: ItemProjectProps) => (
	<Stack gap={1}>
		<Typography variant='body2' color='text.secondary'>
			{name}
		</Typography>
		{value ? (
			<Typography variant='body1' title={title}>
				{value}
			</Typography>
		) : (
			<Typography variant='body2' color='text.secondary'>
				—
			</Typography>
		)}
	</Stack>
);

export { Project };
