import { useSelector } from 'react-redux';
import { getSelectedWorkspace } from '@neoload/api';

const useCurrentWorkspace = () => {
	const selectedWorkspace = useSelector(getSelectedWorkspace);
	const isSelected = selectedWorkspace.id.length > 0;
	return [selectedWorkspace, isSelected] as const;
};

export { useCurrentWorkspace };
