import { ChipProps } from '@mui/material/Chip';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import ChipStatus from '@tricentis/aura/components/ChipStatus.js';

export type FailedChipProps = {
	onClick?: MouseEventHandler<HTMLDivElement>;
	withLabel?: boolean;
	size?: ChipProps['size'];
};

const FailedChip = ({ withLabel = false, onClick, size = 'medium' }: FailedChipProps) => {
	const { t } = useTranslation(['result']);

	return (
		<ChipStatus
			status='Failed'
			data-testid='failed-chip-status'
			label={withLabel ? t('resultStatus.failed') : null}
			onClick={onClick}
			clickable={typeof onClick === 'function'}
			size={size}
		/>
	);
};

export { FailedChip };
