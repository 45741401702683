import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridTreeDataGroupingCell } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { SlaRow } from './slas-data-grid';

const SlaCategoryCell = (props: GridRenderCellParams<SlaRow>) => {
	const { t } = useTranslation('result');
	const { path } = props.row;
	if (path.includes('/')) {
		return null;
	}
	return <GridTreeDataGroupingCell {...props} formattedValue={t(`slas.categories.${path}`)} />;
};
export { SlaCategoryCell };
