import Divider from '@mui/material/Divider';
import { LoadZones } from './zones/load-zones';
import { LoadScenarios } from './scenarios/load-scenarios';
import { useConfigPatch } from './use-config-patch';
import { Project } from './project/project';
import { AdvancedConfiguration } from './advanced/advanced-configuration';
import { ConfigurationFormData } from './types.d';
import { PatchTestInput, Test } from '@neoload/api';

export type ConfigurationProps = {
	patchTest: (patchTestInput: PatchTestInput) => Promise<Test | void>;
	defaultValues: ConfigurationFormData;
};

const Configuration = ({ patchTest, defaultValues }: ConfigurationProps) => {
	useConfigPatch(patchTest, defaultValues);
	return (
		<>
			<Project />
			<Divider />
			<LoadScenarios />
			<Divider />
			<LoadZones />
			<Divider />
			<AdvancedConfiguration />
		</>
	);
};

export { Configuration };
