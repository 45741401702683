import Button, { ButtonProps } from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

type CreateResourceButtonProps = Omit<ButtonProps, 'startIcon'>;

const CreateResourceButton = ({ ...buttonProps }: CreateResourceButtonProps) => (
	<Button
		{...buttonProps}
		startIcon={<AddIcon />}
		variant={buttonProps.variant ?? 'contained'}
		color={buttonProps.color ?? 'primary'}
	/>
);

export { CreateResourceButton };
