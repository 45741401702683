import React, { ChangeEvent, MouseEventHandler, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { LimitedSizeTextField } from '../../common/input/limited-size-text-field';
import { useCurrentWorkspace } from '@neoload/hooks';
import { type TestPage, useGetV4TestsQuery } from '@neoload/api';
import { defaultRowsPerPage } from '@neoload/utils';

const defaultPage: TestPage = {
	total: 0,
	pageNumber: 0,
	pageSize: defaultRowsPerPage,
	items: [],
};

type CreateTestModalProps = {
	handleClose: MouseEventHandler<HTMLButtonElement>;
	handleConfirm: (createTest: CreateTest) => void;
	open?: boolean;
	errorMessage?: string;
};

type CreateTest = {
	name: string;
	description: string;
	workspaceId: string;
};

const CreateTestModal = ({ open = false, handleClose, handleConfirm, errorMessage = '' }: CreateTestModalProps) => {
	const { t } = useTranslation(['test']);
	const [error, setError] = useState({ error: false, helper: '' });
	const [currentWorkspace] = useCurrentWorkspace();
	const [numberTest, setNumberTest] = useState(0);

	const defaultName = t('newTest', { number: numberTest + 1 });

	const [createTestValue, setCreateTestValue] = useState({
		name: defaultName,
		description: '',
		workspaceId: '',
	});

	const { data: testPageData = defaultPage } = useGetV4TestsQuery({
		workspaceId: currentWorkspace.id,
	});

	useEffect(() => {
		setNumberTest(testPageData.total);
	}, [testPageData.total, setNumberTest]);

	useEffect(() => {
		setCreateTestValue((previousState) => ({
			name: previousState.name,
			description: previousState.description,
			workspaceId: currentWorkspace.id,
		}));
	}, [currentWorkspace]);

	useEffect(() => {
		if (errorMessage === '') {
			setError({ error: false, helper: '' });
		} else {
			setError({ error: true, helper: errorMessage });
		}
	}, [errorMessage]);

	useEffect(() => {
		setCreateTestValue((previousState) => ({
			name: defaultName,
			description: '',
			workspaceId: previousState.workspaceId,
		}));
	}, [open, defaultName]);

	return (
		<Dialog open={open}>
			<DialogTitle>{t('grid.actions.createTest')}</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
						width: 500,
					}}
				>
					<LimitedSizeTextField
						max={100}
						onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
							if (createTestValue.name !== '' && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
								handleConfirm(createTestValue);
							}
						}}
						onChange={(event: ChangeEvent<HTMLInputElement>) => {
							setCreateTestValue({
								...createTestValue,
								name: event.currentTarget.value,
							});
							if (event.currentTarget.value === '') {
								setError({ error: true, helper: t('test:validation.name.empty') });
							} else {
								setError({ error: false, helper: '' });
							}
						}}
						name='name'
						value={createTestValue.name}
						label={t('test:labelName')}
						fullWidth
						error={error.error}
						helperText={error.helper}
						required={true}
						data-testid='create-test-name-input'
					/>
					<LimitedSizeTextField
						max={500}
						multiline={true}
						value={createTestValue.description}
						name='description'
						label={t('test:placeholderDescription')}
						onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
							setCreateTestValue({
								...createTestValue,
								description: event.currentTarget.value,
							})
						}
						rows={5}
						data-testid='create-test-description-input'
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary' data-testid='cancel-button'>
					{t('test:buttonCancel')}
				</Button>
				<Button
					disabled={createTestValue.name === ''}
					onClick={() => handleConfirm(createTestValue)}
					variant='contained'
					data-testid='create-and-configure-button'
				>
					{t('test:buttonConfirmCreate')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { CreateTestModal, CreateTest };
