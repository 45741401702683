import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { GridSortItem, useGridApiRef } from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { ComponentPropsWithoutRef, MutableRefObject, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridLinkOperator, GridRowModel, GridSelectionModel, GridSortModel } from '@mui/x-data-grid/models';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import ShieldOutlined from '@mui/icons-material/ShieldOutlined';
import Box from '@mui/material/Box';
import { useDashboardColumns } from './use-dashboard-columns';
import { DashboardsEmptyState } from './dashboards-empty-state';
import { defaultGridProps, onColumnChange } from '../../../common/datagrid';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { NoRowsOverlay } from '../../../common/no-rows-overlay';
import { NoResultsOverlay } from '../../../common/no-results-overlay';
import { CreateResourceButton } from '../../../common/button/create-resource-button';
import { ContextMenu, ContextMenuFuncts } from '../../../common/context-menu/context-menu';
import {
	DatagridAction,
	filterToSecondaryActions,
	toContextMenuItem,
} from '../../../common/data-grid/actions/data-grid-actions';
import { DashboardCreateDialog } from '../create/dashboard-create-dialog';
import { ColumnSortMapper, formatSort } from '../../../common/data-grid/sort';
import { TestResultContext } from '../../../context';
import { defaultRowsPerPage } from '@neoload/utils';
import {
	Dashboard,
	DashboardInput,
	DashboardPage,
	GetV4DashboardsApiArg,
	PatchV4DashboardsByDashboardIdApiArg,
	useGetV4DashboardsQuery,
} from '@neoload/api';
import { useColumnsState, useConfirmModal, useCurrentWorkspace, useGetMe, useUrlSearchParams } from '@neoload/hooks';

const columnsStateKey = 'DASHBOARDS_COLUMNS_STATE';

type DashboardsDataGridProps = {
	onDashboardCreate?: (dashboardInput: DashboardInput) => void;
	onDashboardDuplicate: (dashboardId: string) => void;
	onDashboardsUpdate: (patches: PatchV4DashboardsByDashboardIdApiArg[]) => void;
	onDeleteDashboards: (dashboardIds: string[]) => Promise<void>;
	fromResult?: string;
};

type ShareStatus = 'UNSHARED' | 'SHARED_WRITE';

const checkDashboardIntegrity = (dashboard: Dashboard | undefined) => {
	if (!dashboard) {
		throw new Error('Dashboard not found');
	}
	return dashboard;
};

const emptyDashboardPage: DashboardPage = { items: [], total: 0 };

const sortMappers: ColumnSortMapper[] = [];

const defaultGridSort: GridSortItem = { field: 'createdAt', sort: 'desc' };

const initialState = {
	sorting: {
		sortModel: [defaultGridSort],
	},
};

const useBuildGetDashboardsQueryArgument = ({ sort }: { sort?: string }): [GetV4DashboardsApiArg, boolean] => {
	const [selectedWorkspace, isWorkspaceSelected] = useCurrentWorkspace();
	const testResultContext = useContext(TestResultContext);
	if (testResultContext) {
		const testResult = testResultContext.testResult;
		return testResult
			? [
					{
						workspaceId: testResult.workspaceId,
						resultId: testResult.id,
						sort,
					},
					false,
			  ]
			: [
					{
						workspaceId: '',
						resultId: '',
						sort,
					},
					true,
			  ];
	} else {
		if (isWorkspaceSelected) {
			return [
				{
					workspaceId: selectedWorkspace.id,
					sort,
				},
				false,
			];
		}
		return [
			{
				workspaceId: '',
				sort,
			},
			true,
		];
	}
};

const DashboardsDataGrid = ({
	onDashboardCreate,
	onDashboardDuplicate,
	onDashboardsUpdate,
	onDeleteDashboards,
	fromResult,
}: DashboardsDataGridProps) => {
	const apiRef = useGridApiRef();
	const [{ createDashboard }, setUrlSearchParams] = useUrlSearchParams('createDashboard');
	const { t } = useTranslation(['dashboard']);
	const [pageSize, setPageSize] = useState(defaultRowsPerPage);
	const { openDeleteConfirmModal } = useConfirmModal();
	const [sort, setSort] = useState<string>(formatSort(defaultGridSort, sortMappers));
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();
	const [currentUser] = useGetMe();
	const [getDashboardsQueryArgument, shouldSkipGetDashboardsQuery] = useBuildGetDashboardsQueryArgument({
		sort,
	});
	const { data: dashboardPage = emptyDashboardPage, isLoading } = useGetV4DashboardsQuery(getDashboardsQueryArgument, {
		skip: shouldSkipGetDashboardsQuery,
	});

	function onShareDashboard(dashboard: Dashboard) {
		onDashboardsUpdateShareStatus([dashboard], 'SHARED_WRITE');
	}

	function onUnshareDashboard(dashboard: Dashboard) {
		onDashboardsUpdateShareStatus([dashboard], 'UNSHARED');
	}

	function onDashboardsUpdateShareStatus(dashboards: Dashboard[], shareStatus: ShareStatus) {
		const patches = dashboards
			.filter((dashboard: Dashboard) => shouldUpdateDashboardShareStatus(dashboard, shareStatus))
			.map((dashboard) => ({
				dashboardId: dashboard.id,
				dashboardPatchRequest: {
					shareStatus: shareStatus,
				},
			}));
		if (patches.length > 0) {
			onDashboardsUpdate(patches);
		}
	}
	function onDuplicateDashboard(dashboard: Dashboard) {
		onDashboardDuplicate(dashboard.id);
	}

	const onDuplicateSelectedDashboard = (dashboardId: string) => {
		const dashboardToDuplicate = dashboardPage.items.find((dashboard) => dashboard.id === dashboardId);
		if (!dashboardToDuplicate) {
			throw new Error('Dashboard not found');
		}
		onDuplicateDashboard(dashboardToDuplicate);
	};

	function openConfirmationDashboardDialog(dashboard: Dashboard) {
		const title = t('dashboard:deleteSingleDashboardDialog.title');
		const content = t('dashboard:deleteSingleDashboardDialog.label', { dashboard: dashboard.name });
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => onDeleteDashboards([dashboard.id]),
		});
	}

	function openConfirmationAllDashboardsDialog() {
		const title = t('dashboard:deleteMultipleDashboardDialog.title', {
			dashboardNumber: selectedDashboardIds.length.toString(),
		});
		const content = t('dashboard:deleteMultipleDashboardDialog.label');
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => onDeleteDashboards(selectedDashboardIds.map((x) => x as string)),
		});
	}

	const [selectedDashboardIds, setSelectedDashboardIds] = useState<GridSelectionModel>([]);

	const tooltipTitle = t('common:delete');

	const shouldUpdateDashboardShareStatus = (dashboard: Dashboard, updatedShareStatus: ShareStatus) =>
		dashboard.ownerId === currentUser.id && dashboard.shareStatus !== updatedShareStatus;

	const isOneDashboardOwnedByOtherUser = (isCurrentUserGuest = false) =>
		dashboardPage.items
			.filter((dashboard: Dashboard) => selectedDashboardIds.includes(dashboard.id))
			.some((dashboard: Dashboard) => dashboard.ownerId !== currentUser.id && isCurrentUserGuest);

	const shouldHideBulkShareOption = () =>
		dashboardPage.items
			.filter((dashboard: Dashboard) => selectedDashboardIds.includes(dashboard.id))
			.every((dashboard: Dashboard) => dashboard.shareStatus === 'SHARED_WRITE');

	const actions: DatagridAction[] = [
		{
			icon: <ShareOutlined />,
			text: t('shareButton'),
			action: () => {
				onDashboardsUpdateShareStatus(
					selectedDashboardIds
						.map((dashboardId) => dashboardPage.items.find((dashboard) => dashboard.id === dashboardId))
						.map((dashboard) => checkDashboardIntegrity(dashboard)),
					'SHARED_WRITE'
				);
			},
			hidden: shouldHideBulkShareOption() || isOneDashboardOwnedByOtherUser(true),
			tooltip: t('shareButton'),
			singleItem: (dashboard: Dashboard) => ({
				action: () => {
					onShareDashboard(dashboard);
				},
				hidden: dashboard.shareStatus === 'SHARED_WRITE' || dashboard.ownerId !== currentUser.id,
			}),
		},
		{
			icon: <ShieldOutlined />,
			text: t('unshareButton'),
			action: () => {
				onDashboardsUpdateShareStatus(
					selectedDashboardIds
						.map((dashboardId) => dashboardPage.items.find((dashboard) => dashboard.id === dashboardId))
						.map((dashboard) => checkDashboardIntegrity(dashboard)),
					'UNSHARED'
				);
			},
			hidden: !shouldHideBulkShareOption() || isOneDashboardOwnedByOtherUser(true),
			tooltip: t('unshareButton'),
			singleItem: (dashboard: Dashboard) => ({
				action: () => {
					onUnshareDashboard(dashboard);
				},
				hidden: dashboard.shareStatus === 'UNSHARED' || dashboard.ownerId !== currentUser.id,
			}),
		},
		{
			icon: <FileCopyOutlined />,
			text: t('common:duplicate'),
			action: () => {
				if (selectedDashboardIds.length !== 1) {
					throw new Error(t('duplicate.moreThanOneSelected'));
				}
				onDuplicateSelectedDashboard(selectedDashboardIds[0] as string);
			},
			disabled: selectedDashboardIds.length !== 1,
			tooltip: selectedDashboardIds.length === 1 ? t('common:duplicate') : t('duplicate.moreThanOneSelected'),
			singleItem: (dashboard: Dashboard) => ({
				action: () => {
					onDuplicateSelectedDashboard(dashboard.id);
				},
				disabled: false,
			}),
		},
		{
			icon: <DeleteOutlined />,
			text: tooltipTitle,
			color: 'error',
			action: () => {
				if (selectedDashboardIds.length === 1) {
					const dashboardToDelete = dashboardPage.items.find((dashboard) => dashboard.id === selectedDashboardIds[0]);
					if (dashboardToDelete) {
						openConfirmationDashboardDialog(dashboardToDelete);
					}
				} else {
					openConfirmationAllDashboardsDialog();
				}
			},
			hidden: isOneDashboardOwnedByOtherUser(currentUser.role === 'GUEST'),
			tooltip: t('common:delete'),
			singleItem: (dashboard: Dashboard) => ({
				action: () => {
					openConfirmationDashboardDialog(dashboard);
				},
				hidden: dashboard.ownerId !== currentUser.id && currentUser.role === 'GUEST',
				sx: { color: 'error' },
			}),
		},
	];

	const columns = useDashboardColumns(currentUser.id, actions, fromResult);

	const createDashboardAction = {
		children: (
			<CreateResourceButton
				onClick={() => {
					setUrlSearchParams({ createDashboard: 'true' });
				}}
				data-testid='createDashboard'
				key='createDashboard'
			>
				{t('createButton')}
			</CreateResourceButton>
		),
	};

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar>; row: GridRowModel } = {
		toolbar: {
			defaultFiltersOperator: GridLinkOperator.And,
			description: t('dashboard:description'),
			displayColumnOptions: true,
			displaySearchBox: true,
			hideColumnsFromColumnOptions: ['__check__', 'id', 'name'],
			hideFiltersIcon: true,
			secondaryActions: selectedDashboardIds.length > 0 ? filterToSecondaryActions(actions) : [],
			syncLocalStorage: {
				datagridId: 'neoloadDashboardDataGrid',
				isSyncEnabled: true,
			},
			...(!fromResult && {
				mainActions: [createDashboardAction],
				title: t('dashboard:title'),
			}),
		},
		row: {
			onContextMenu: contextMenu.current?.openContextMenu,
		},
	};

	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef
	);

	const onSortModelChange = (gridSortModel: GridSortModel) => {
		setSort(formatSort(gridSortModel[0], sortMappers));
	};

	const onClose = (okButton: boolean, dashboardInput?: DashboardInput) => {
		if (okButton) {
			if (!dashboardInput) {
				throw new Error('DashboardInput is null');
			}
			if (onDashboardCreate) {
				onDashboardCreate(dashboardInput);
			}
		}
		setUrlSearchParams({ createDashboard: null });
	};

	return (
		<>
			{!isLoading && dashboardPage.total === 0 && !fromResult ? (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DashboardsEmptyState />
				</Box>
			) : (
				<>
					<Datagrid
						{...defaultGridProps}
						{...onColumnChange(storeColumnState)}
						apiRef={apiRef}
						loading={isLoading}
						disableSelectionOnClick={false}
						columns={updatedColumns}
						rows={dashboardPage.items}
						pageSize={pageSize}
						onPageSizeChange={setPageSize}
						sortingMode='server'
						onSortModelChange={onSortModelChange}
						initialState={updatedInitialState}
						onSelectionModelChange={(model: GridSelectionModel) => setSelectedDashboardIds(model)}
						componentsProps={componentsProps}
						components={{
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Toolbar: Toolbar,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							NoRowsOverlay: NoRowsOverlay,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							NoResultsOverlay: NoResultsOverlay,
						}}
					/>
					<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={actions.map(toContextMenuItem)} />
				</>
			)}
			<DashboardCreateDialog isOpen={createDashboard === 'true'} onClose={onClose} />
		</>
	);
};

export { DashboardsDataGrid };
