import './result-overview-graphs.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import HighchartsReact from 'highcharts-react-official';
import Typography from '@mui/material/Typography';
import {
	getRequestsChartDefaultOptions,
	getUsersChartDefaultOptions,
	SeriesIds,
} from './result-overview-graphs-config';
import { FixedWindowDuration, ResultOverviewGraphsFetcher } from './result-overview-graphs-fetcher';
import { TimeWindow, TimeWindowButtons } from './timeWindow/time-window-buttons';
import { IntervalHoverProps, IntervalPopper } from './interval/popper/interval-popper';
import { intervalGraphUtils, IntervalStyles } from './interval/interval-graph-utils';
import { ResultOverviewGraphLegend } from './result-overview-graph-legend';
import { IntervalAddButton } from './interval/interval-add-button';
import { IntervalsDataGrid } from './interval/data-grid/intervals-data-grid';
import { buildTitleText } from '../../../common/chart/chart-config';
import { DEFAULT_INTERVAL_COLOR, isRawDataAvailable, timeUtils } from '@neoload/utils';
import { Interval, useGetV4ResultsByResultIdIntervalsQuery } from '@neoload/api';

HighchartsAccessibility(Highcharts);
NoDataToDisplay(Highcharts);

export type Points = number[][];

export type ErrorsUsersPoints = {
	errorsPoints: Points;
	userLoadPoints: Points;
	resultId: string;
};

export type RequestsPoints = {
	reqDurationPoints: Points;
	reqPerSecPoints: Points;
	resultId: string;
};

export type ResultOverviewGraphsProps = {
	resultId: string;
	isRunning: boolean;
	startDate: string;
	resultDuration: string;
	resultEndDate?: string;
};

export type GraphType = 'USERS' | 'REQUESTS';

export type IntervalEditionState = (
	| {
			mode: 'CREATION';
			editedChart: 'USERS';
			editedIntervalId: undefined;
	  }
	| {
			mode: 'EDITION';
			editedIntervalId: string;
			editedChart: GraphType;
	  }
) & { editedColor?: string };

export type IntervalEditionDragState = {
	isDragging: boolean;
	editedStartOffset?: string;
	editedEndOffset?: string;
	minX?: number;
	maxX?: number;
};

const tenMinutes = 10 * 60 * 1000;
const thirtyMinutes = 30 * 60 * 1000;

const toFixedWindowDuration = (isRunning: boolean, startDate: string, timeWindow: TimeWindow): FixedWindowDuration => {
	if (isRunning) {
		const elapsedTime = timeUtils.getElapsedTimeMillisUntilNow(startDate);
		if (elapsedTime < tenMinutes) {
			return 'PT10M';
		}
		if (elapsedTime < thirtyMinutes) {
			return timeWindow === 'last10' ? 'PT10M' : 'PT30M';
		}
		if (timeWindow === 'last10') {
			return 'PT10M';
		}
		if (timeWindow === 'last30') {
			return 'PT30M';
		}
	}
	return undefined;
};

const getValueAtXaxis = (points: Points, xAxisMax?: number, toFixed?: number): string => {
	let value = points.at(-1)?.[1];
	if (xAxisMax === undefined || points.length === 0 || value === undefined) {
		return '';
	}
	for (let index = 0; index < points.length - 1; index++) {
		if (points[index][0] <= xAxisMax && points[index + 1][0] > xAxisMax) {
			value = points[index][1];
		}
	}
	return toFixed ? value.toFixed(toFixed) : value.toString();
};

const isAnySeriesEmpty = (errorsUsersPoints: ErrorsUsersPoints, requestsPoints: RequestsPoints) =>
	errorsUsersPoints.errorsPoints.length === 0 ||
	errorsUsersPoints.userLoadPoints.length === 0 ||
	requestsPoints.reqDurationPoints.length === 0 ||
	requestsPoints.reqPerSecPoints.length === 0;

const getIntervalRectY = (intervalRect: DOMRect, resultEndDate: string | undefined) =>
	intervalRect.y - intervalRect.height - (isRawDataAvailable(resultEndDate) ? 256 : 314);

const ResultOverviewGraphs = ({
	resultId,
	isRunning,
	startDate,
	resultDuration,
	resultEndDate,
}: ResultOverviewGraphsProps) => {
	const { t } = useTranslation(['result']);

	const theme = useTheme();
	const { data: intervalResponse } = useGetV4ResultsByResultIdIntervalsQuery({ resultId: resultId });
	const [hoveredIntervalId, setHoveredIntervalId] = useState<string | undefined>(undefined);
	const [intervalDragState, setIntervalDragState] = useState<IntervalEditionDragState>({ isDragging: false });
	const [usersChartOptions, setUsersChartOptions] = useState<Highcharts.Options>(
		getUsersChartDefaultOptions(t('overview.charts.errors'), t('overview.charts.userLoad'), theme)
	);
	const [requestsChartOptions, setRequestsChartOptions] = useState<Highcharts.Options>(
		getRequestsChartDefaultOptions(t('overview.charts.reqDuration'), t('overview.charts.reqPerSec'), theme)
	);

	const [displayedId, setDisplayedId] = useState(resultId);
	const usersChart = useRef<HighchartsReact.RefObject>(null);
	const requestsChart = useRef<HighchartsReact.RefObject>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [xAxisMax, setXAxisMax] = useState<number | undefined>(undefined);
	const [timeWindow, setTimeWindow] = useState<TimeWindow>('last10');
	const [intervalEditionState, setIntervalEditionState] = useState<IntervalEditionState | undefined>(undefined);
	const fixedWindowDuration = useMemo(
		() => toFixedWindowDuration(isRunning, startDate, timeWindow),
		[isRunning, startDate, timeWindow]
	);

	const [lastUserValue, setLastUserValue] = useState('');
	const [lastErrorValue, setLastErrorValue] = useState('');
	const [lastRequestsDurationValue, setLastRequestsDurationValue] = useState('');
	const [lastRequestsPerSecValue, setLastRequestsPerSecValue] = useState('');
	const [intervalPopoverProps, setIntervalPopoverProps] = useState<IntervalHoverProps | undefined>();
	const editionInProgress = intervalEditionState !== undefined;

	useEffect(() => {
		if (isLoading) {
			return;
		}

		const onIntervalEditionDragged = (x1?: number, x2?: number) => {
			setIntervalDragState((current) => ({
				...current,
				isDragging: true,
				...(x1 && { editedStartOffset: timeUtils.toIsoStringRoundedToSeconds(x1) }),
				...(x2 && { editedEndOffset: timeUtils.toIsoStringRoundedToSeconds(x2) }),
			}));
		};

		const onIntervalEditionDropped = () => {
			setIntervalDragState((current) => ({
				...current,
				isDragging: false,
			}));
		};

		const onMouseOutOfPlotBand = () => {
			setIntervalPopoverProps((current) =>
				current
					? {
							...current,
							intervalIsHovered: false,
					  }
					: undefined
			);
			setHoveredIntervalId(undefined);
		};

		const intervalStyles: IntervalStyles = {
			defaultIntervalColor: DEFAULT_INTERVAL_COLOR,
			handleFillColor: theme.palette.background.paper,
			handleLineColor: theme.palette.action.active,
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const onMouseMovedOverPlotBand = (event: any, interval: Interval, graphType: GraphType) => {
			if (!editionInProgress) {
				if (hoveredIntervalId !== interval.id) {
					setHoveredIntervalId(interval.id);
				}
				setIntervalPopoverProps({
					interval: interval,
					intervalRect: event.target.getBoundingClientRect(),
					intervalIsHovered: true,
					mouseX: event.clientX,
					hoveredGraphType: graphType,
				});
			}
		};

		intervalGraphUtils.updatePlotbandAndEditionRange({
			intervals: intervalResponse?.items.filter((interval) => !interval.hidden) ?? [],
			usersChart: usersChart.current?.chart,
			requestsChart: requestsChart.current?.chart,
			intervalEditionState: intervalEditionState,
			resultEndDate: resultEndDate,
			hoveredIntervalId: hoveredIntervalId,
			intervalStyles: intervalStyles,
			onMouseMovedOverPlotBand: onMouseMovedOverPlotBand,
			onMouseOutOfPlotBand: onMouseOutOfPlotBand,
			intervalDragState: intervalDragState,
			onIntervalEditionDragged: onIntervalEditionDragged,
			onIntervalEditionDropped: onIntervalEditionDropped,
		});
		//do not listen to intervalDragState.isDragging state change, as it would trigger the useEffect briefly before the last
		//drag event is taken into account else it generates a slight blinking effect when dragging and dropping the interval fastly.
		// TODO - react-compile
		// eslint-disable-next-line react-compiler/react-compiler
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isLoading,
		intervalEditionState,
		editionInProgress,
		hoveredIntervalId,
		intervalDragState.editedStartOffset,
		intervalDragState.editedEndOffset,
		intervalResponse?.items,
		resultDuration,
		intervalDragState.maxX,
		theme,
		intervalDragState.minX,
	]);

	useEffect(() => {
		setIntervalPopoverProps((current) => {
			const filtered = intervalResponse?.items.find(({ id }) => current?.interval.id === id);
			return filtered && current
				? {
						...current,
						interval: filtered,
				  }
				: current;
		});
	}, [intervalResponse?.items]);

	const onUpdatePoints = (
		nextIsLoading: boolean,
		errorsUsersPoints: ErrorsUsersPoints,
		requestsPoints: RequestsPoints,
		nextXAxisMin?: number,
		nextXAxisMax?: number
	) => {
		if (nextIsLoading || isAnySeriesEmpty(errorsUsersPoints, requestsPoints)) {
			setUsersChartOptions({
				...getUsersChartDefaultOptions(t('overview.charts.errors'), t('overview.charts.userLoad'), theme),
				xAxis: {
					min: undefined,
					max: undefined,
				},
			});
			setRequestsChartOptions({
				...getRequestsChartDefaultOptions(t('overview.charts.reqDuration'), t('overview.charts.reqPerSec'), theme),
				xAxis: {
					min: undefined,
					max: undefined,
				},
			});
			setLastUserValue('');
			setLastErrorValue('');
			setLastRequestsDurationValue('');
			setLastRequestsPerSecValue('');
		} else if (nextXAxisMax === undefined || xAxisMax !== nextXAxisMax || displayedId !== resultId) {
			usersChart.current?.chart.update({
				xAxis: {
					min: nextXAxisMin,
					max: nextXAxisMax,
				},
				series: [
					{ data: errorsUsersPoints.errorsPoints, type: 'area' },
					{ data: errorsUsersPoints.userLoadPoints, type: 'area' },
				],
			});
			requestsChart.current?.chart.update({
				xAxis: {
					min: nextXAxisMin,
					max: nextXAxisMax,
				},
				series: [
					{ id: SeriesIds.requestDuration, data: requestsPoints.reqDurationPoints, type: 'area' },
					{ id: SeriesIds.requestPerSeconds, data: requestsPoints.reqPerSecPoints, type: 'area' },
				],
			});
			setXAxisMax(nextXAxisMax);
			setLastUserValue(getValueAtXaxis(errorsUsersPoints.userLoadPoints, nextXAxisMax));
			setLastErrorValue(getValueAtXaxis(errorsUsersPoints.errorsPoints, nextXAxisMax));
			setLastRequestsDurationValue(getValueAtXaxis(requestsPoints.reqDurationPoints, nextXAxisMax, 3));
			setLastRequestsPerSecValue(getValueAtXaxis(requestsPoints.reqPerSecPoints, nextXAxisMax, 1));
		}
		setIntervalDragState((current) => ({
			...current,
			minX: nextXAxisMin,
			maxX: nextXAxisMax,
		}));
		setDisplayedId(resultId);
		setIsLoading(nextIsLoading);
	};

	useEffect(() => setIsLoading(true), [resultId, fixedWindowDuration]);

	useEffect(() => {
		const legendEnabled = !isRunning;
		if (usersChartOptions.legend?.enabled !== legendEnabled) {
			setUsersChartOptions((previous) => ({
				...previous,
				legend: { enabled: legendEnabled },
				title: {
					text: isRunning
						? undefined
						: buildTitleText(t('overview.charts.errors'), t('overview.charts.userLoad'), theme),
				},
			}));
		}
		if (requestsChartOptions.legend?.enabled !== legendEnabled) {
			setRequestsChartOptions({
				legend: { enabled: !isRunning },
				title: {
					text: isRunning
						? undefined
						: buildTitleText(t('overview.charts.reqDuration'), t('overview.charts.reqPerSec'), theme),
				},
			});
		}
	}, [isRunning, requestsChartOptions.legend?.enabled, t, usersChartOptions.legend?.enabled, theme]);

	/*
	 * 'clearTooltipAndMarker', 'synchronizeCrosshairs' and the override of 'reset' allow to
	 * make the crosshairs (vertical line when hover) synchronized
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const clearTooltipAndMarker = (rawEvent: any) => {
		// Hide tooltips and clear selected point in the graph
		for (const c of [usersChart, requestsChart]) {
			const chart = c?.current?.chart;
			if (chart) {
				const event = chart.pointer.normalize(rawEvent);
				if (event) {
					for (const series of chart.series) {
						const point = series.searchPoint(event, true);
						if (point) {
							point.setState('');
							chart.tooltip.hide();
						}
					}
				}
			}
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const synchronizeCrosshairs = (rawEvent: any) => {
		if (!editionInProgress) {
			for (const c of [usersChart, requestsChart]) {
				const chart = c?.current?.chart;
				if (chart) {
					const event = chart.pointer.normalize(rawEvent);
					// The tooltip will appear on the second serie if not hidden
					const visibleSerie = chart.series[1].visible ? 1 : 0;
					const point = chart.series[visibleSerie].searchPoint(event, true);
					if (point) {
						point.onMouseOver(); // Show the hover marker
					}
				}
			}
		}
	};
	Highcharts.Pointer.prototype.reset = () => undefined; // Prevent reset tooltip when out of the graphg

	// Show/Hide loading text in the graph
	isLoading ? usersChart?.current?.chart.showLoading() : usersChart?.current?.chart.hideLoading();
	isLoading ? requestsChart?.current?.chart.showLoading() : requestsChart?.current?.chart.hideLoading();

	useEffect(() => {
		const enableTooltips = !editionInProgress;
		requestsChart.current?.chart.tooltip.update({
			enabled: enableTooltips,
		});
		usersChart.current?.chart.tooltip.update({
			enabled: enableTooltips,
		});
	}, [editionInProgress]);

	useEffect(() => {
		if (!editionInProgress) {
			setIntervalDragState((current) => ({
				...current,
				isDragging: false,
				editedStartOffset: undefined,
				editedEndOffset: undefined,
			}));
		}
	}, [editionInProgress]);

	const onOffsetsInputsEdited = (start: string | undefined, end: string | undefined) => {
		if (
			!intervalDragState.isDragging &&
			(start !== intervalDragState.editedStartOffset || end !== intervalDragState.editedEndOffset)
		) {
			setIntervalDragState((current) => ({
				...current,
				...(start && { editedStartOffset: start }),
				...(end && { editedEndOffset: end }),
			}));
		}
	};

	const startCreating = () => {
		setIntervalEditionState({
			mode: 'CREATION',
			editedChart: 'USERS',
			editedIntervalId: undefined,
		});
		setIntervalDragState((current) => ({
			...current,
			isDragging: false,
			...(current.minX !== undefined &&
				current.maxX !== undefined && {
					editedStartOffset: timeUtils.toIsoStringRoundedToSeconds(
						intervalGraphUtils.getInitialCreationRangeStart(current.minX, current.maxX)
					),
					editedEndOffset: timeUtils.toIsoStringRoundedToSeconds(
						intervalGraphUtils.getInitialCreationRangeEnd(current.minX, current.maxX)
					),
				}),
		}));
	};

	const stopCreating = () => {
		setIntervalEditionState(undefined);
		setIntervalDragState((current) => ({
			...current,
			isDragging: false,
			editedStartOffset: undefined,
			editedEndOffset: undefined,
		}));
	};

	const getDomRectForCreation = () => {
		const graphRect = intervalGraphUtils.getChartsDomRect(usersChart.current);
		return graphRect
			? new DOMRect(graphRect.x, getIntervalRectY(graphRect, resultEndDate), graphRect.width, graphRect.height)
			: undefined;
	};

	const onColorChanged = useCallback(
		(color: string) =>
			setIntervalEditionState(
				(current) =>
					current && {
						...current,
						editedColor: color,
					}
			),
		[setIntervalEditionState]
	);

	return (
		<>
			<Grid
				container
				display='flex'
				justifyContent={'space-between'}
				alignItems={'baseline'}
				sx={{
					borderTop: `1px solid ${theme.palette.divider}`,
					marginTop: 2,
					paddingTop: 2,
					paddingX: 2,
				}}
			>
				<Grid item>
					<Typography variant='subtitle1'>{t('overview.charts.metrics')}</Typography>
				</Grid>
				<Grid item>
					<IntervalAddButton
						getIntervalRect={getDomRectForCreation}
						resultId={resultId}
						resultDuration={
							intervalDragState.maxX ? timeUtils.parseDuration(intervalDragState.maxX).toISOString() : resultDuration
						}
						resultEndDate={resultEndDate}
						startCreating={startCreating}
						stopCreating={stopCreating}
						intervalDragState={intervalDragState}
						onOffsetsInputsEdited={onOffsetsInputsEdited}
						onColorChanged={onColorChanged}
						disabled={isLoading}
						chartContainer={usersChart?.current?.container?.current ?? undefined}
					/>
				</Grid>
			</Grid>
			{isRunning && <TimeWindowButtons disabled={isLoading} value={timeWindow} onUpdateValue={setTimeWindow} />}
			{intervalPopoverProps && (
				<IntervalPopper
					{...{
						intervalEditionState: intervalEditionState,
						setIntervalEditionState: setIntervalEditionState,
						resultDuration: intervalDragState.maxX
							? timeUtils.parseDuration(intervalDragState.maxX).toISOString()
							: resultDuration,
						resultEndDate,
						resultId,
					}}
					{...intervalPopoverProps}
					onOffsetsInputsEdited={onOffsetsInputsEdited}
					editedStartOffset={intervalDragState.editedStartOffset}
					editedEndOffset={intervalDragState.editedEndOffset}
					onColorChanged={onColorChanged}
					chartContainer={
						(intervalEditionState?.editedChart === 'USERS' ? usersChart : requestsChart)?.current?.container?.current ??
						undefined
					}
				/>
			)}
			<Box
				onMouseMove={synchronizeCrosshairs}
				onTouchMove={synchronizeCrosshairs}
				onTouchStart={synchronizeCrosshairs}
				onMouseOut={clearTooltipAndMarker}
				onMouseLeave={clearTooltipAndMarker}
				padding={2}
				paddingTop={1}
			>
				<ResultOverviewGraphsFetcher {...{ resultId, onUpdatePoints, fixedWindowDuration, editionInProgress }} />
				<Grid container spacing={2} columns={12}>
					<Grid item md={isRunning ? 10 : 12}>
						<HighchartsReact highcharts={Highcharts} ref={usersChart} options={usersChartOptions} />
					</Grid>
					{isRunning && (
						<Grid item md={2}>
							<ResultOverviewGraphLegend
								title={t('overview.charts.errors')}
								value={lastErrorValue}
								markerType={'square'}
								color='error'
							/>
							<ResultOverviewGraphLegend
								title={t('overview.charts.userLoad')}
								value={lastUserValue}
								markerType={'circle'}
								color='info'
							/>
						</Grid>
					)}
				</Grid>
				<Grid container spacing={2} columns={12}>
					<Grid item md={isRunning ? 10 : 12}>
						<HighchartsReact highcharts={Highcharts} ref={requestsChart} options={requestsChartOptions} />
					</Grid>
					{isRunning && (
						<Grid item md={2}>
							<ResultOverviewGraphLegend
								title={t('overview.charts.reqDuration')}
								value={lastRequestsDurationValue}
								markerType={'square'}
								color='warning'
							/>
							<ResultOverviewGraphLegend
								title={t('overview.charts.reqPerSec')}
								value={lastRequestsPerSecValue}
								markerType={'circle'}
								color='success'
							/>
						</Grid>
					)}
				</Grid>
			</Box>
			{intervalResponse?.items && intervalResponse.items.length > 0 && (
				<Grid item>
					<IntervalsDataGrid intervals={intervalResponse} resultId={resultId} />
				</Grid>
			)}
		</>
	);
};
export { ResultOverviewGraphs };
export const visibleForTesting = {
	toFixedWindowDuration,
	getValueAtXaxis,
	isAnySeriesEmpty,
};
