import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { setSelectedWorkspace, UserWorkspace } from '@neoload/api';

const useSetCurrentWorkspace = () => {
	const dispatch = useDispatch();
	return useCallback((workspace: UserWorkspace) => dispatch(setSelectedWorkspace(workspace)), [dispatch]);
};

export { useSetCurrentWorkspace };
