import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useWatch } from 'react-hook-form';
import { isZoneErrorLg } from './../../test-execution-helpers';
import { TestExecutionForm } from '../../types.d';

export type TestExecutionZoneLgProps = {
	nbLgsZone: number;
	nbLgsRequired: number;
};

const TestExecutionZoneLg = ({ nbLgsZone, nbLgsRequired }: TestExecutionZoneLgProps) => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();
	const errors = useWatch<TestExecutionForm, 'errors'>({ name: 'errors' });
	const lgError = nbLgsRequired > nbLgsZone && isZoneErrorLg(errors);

	if (nbLgsRequired === 0 || nbLgsRequired === undefined) {
		return (
			<Typography variant='body2' color={theme.palette.text.secondary}>
				—
			</Typography>
		);
	}

	if (lgError) {
		return (
			<Alert
				severity='error'
				sx={{
					border: 'none',
					textWrap: 'nowrap',
					alignItems: 'center',
					paddingX: theme.spacing(0.5),
					marginRight: 1,
					height: '32px',
					'& .MuiAlert-icon': { paddingLeft: 1, paddingY: '5px' },
					'& .MuiAlert-message': { paddingY: '5px' },
				}}
			>
				{nbLgsRequired}
				{nbLgsRequired > nbLgsZone && '/' + nbLgsZone} {t('testExecution.lg', { count: nbLgsRequired })}
			</Alert>
		);
	}

	return (
		<Typography variant='body2' color={theme.palette.text.primary} sx={{ paddingLeft: 1 }}>
			{nbLgsRequired} {t('testExecution.lg', { count: nbLgsRequired })}
		</Typography>
	);
};

export { TestExecutionZoneLg };
