import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import {
	ResultContext,
	TestResult,
	TestResultInput,
	useDeleteV4TestExecutionsByTestExecutionIdForcedMutation,
	useDeleteV4TestExecutionsByTestExecutionIdMutation,
	useGetV4ResultsByResultIdContextsQuery,
	useGetV4ResultsByResultIdIntervalsQuery,
	useGetV4ResultsQuery,
	usePatchV4ResultsByResultIdMutation,
} from '@neoload/api';
import { useFeatureEnabled, useGetResult, usePushDocumentTitleHandler, useSetSnackbars } from '@neoload/hooks';
import {
	ComponentTabPanel,
	ResultDashboardsTab,
	ResultDetails,
	ResultHeader,
	ResultHeaderNavigation,
	ResultTabEvent,
	ResultTabLogs,
	ResultTabOverview,
	ResultTabSlas,
	ResultTabValues,
	Spinner,
	TestResultContext,
} from '@neoload/ui';
import {
	checkUuid,
	CommonRoutes,
	createNeoLoadError,
	hasNotStarted,
	isAlive,
	isRunning,
	isStarting,
	ResultGenericParams,
	resultTabs,
} from '@neoload/utils';

/**
 * @returns the element in the list BEFORE the index, or undefined if index is out of the list or the first element
 */
const getPreviousResult = (list: TestResult[], index: number): TestResult | undefined =>
	index > 0 && index < list.length ? list[index - 1] : undefined;

/**
 * @returns the element in the list AFTER the index, or undefined if index is out of the list or the last element
 */
const getNextResult = (list: TestResult[], index: number): TestResult | undefined =>
	index >= 0 && index < list.length - 1 ? list[index + 1] : undefined;

const getNavigation = (resultListByTest: TestResult[], resultId: string): ResultHeaderNavigation | undefined => {
	if (resultListByTest.length > 0) {
		const resultIds = resultListByTest.map((r: TestResult) => r.id);
		const resultIdsIndex = resultIds.indexOf(resultId || '');
		const previousResult = getPreviousResult(resultListByTest, resultIdsIndex);
		const nextResult = getNextResult(resultListByTest, resultIdsIndex);

		return {
			resultIdsIndex: resultIdsIndex,
			resultIdsLength: resultIds.length,
			previousResultId: previousResult?.id,
			previousStartDate: previousResult?.startDate,
			nextResultId: nextResult?.id,
			nextStartDate: nextResult?.startDate,
		};
	}
	return undefined;
};

const logsNotAvailable = (selectedTab: number, startedByNlw?: boolean): boolean =>
	startedByNlw === false && selectedTab === resultTabs.indexOf('logs');

const defaultContext: ResultContext = {
	zones: [],
	populations: [],
	userPaths: [],
};

const ResultPage = () => {
	const location = useLocation();
	const fromTestExecution = location.state?.fromTestExecution;
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation(['result']);
	const { showError, showInfo } = useSetSnackbars();
	const { resultId, tab = 'overview' } = useParams<ResultGenericParams>() as ResultGenericParams;
	checkUuid(resultId);
	const { response, waiting } = useGetResult(resultId, fromTestExecution);
	const { data: result, isLoading, error: resultError } = response;
	const [{ isFeatureEnabled: resultDashboardsTabFeatureEnabled }] = useFeatureEnabled('result-dashboards-tab');

	const skipContext = !result || isStarting(result);
	const { data: resultContextData = defaultContext, isLoading: isLoadingZones } =
		useGetV4ResultsByResultIdContextsQuery({ resultId }, { skip: skipContext });
	const resultContext = skipContext ? defaultContext : resultContextData;

	const { data: { items: intervals = [] } = {} } = useGetV4ResultsByResultIdIntervalsQuery({
		resultId,
	});

	const [starting, setStarting] = useState<boolean>(false);
	const [resultListByTest, setResultListByTest] = useState<TestResult[]>([]);
	const [resultPageNumber, setResultPageNumber] = useState<number>(0);
	const pageSize = 200;
	const workspaceId = result?.workspaceId ?? '';
	const testId = result?.test?.id ?? '';
	const { data: resultListByTestPage, isLoading: resultListByTestLoading } = useGetV4ResultsQuery(
		{ testIds: [testId], workspaceId, sort: '-startDate', pageSize, pageNumber: resultPageNumber },
		{ skip: !testId }
	);

	useEffect(() => {
		if (resultListByTestPage) {
			setResultListByTest((t) => [...t, ...resultListByTestPage.items]);
			if (resultListByTestPage.items.length >= pageSize) {
				setResultPageNumber((p) => p + 1);
			}
		}
	}, [resultListByTestPage]);

	const [updateResult] = usePatchV4ResultsByResultIdMutation();
	const [stopTest] = useDeleteV4TestExecutionsByTestExecutionIdMutation();
	const [terminateTest] = useDeleteV4TestExecutionsByTestExecutionIdForcedMutation();

	const [isScrolling, setIsScrolling] = useState(false);
	const onScroll = (event: React.UIEvent) => setIsScrolling(event.currentTarget.scrollTop !== 0);

	const selectedTab: number = resultTabs.indexOf(tab);
	const notStarted = result ? hasNotStarted(result) : false;

	useEffect(() => {
		const updatedStarting = result !== undefined && isStarting(result);
		if (selectedTab === resultTabs.indexOf('logs') && starting && !updatedStarting) {
			navigate(CommonRoutes.results.overview(resultId));
		}
		setStarting(updatedStarting);
	}, [waiting, fromTestExecution, navigate, resultId, result, starting, selectedTab]);

	useEffect(() => {
		if (result && notStarted) {
			navigate(CommonRoutes.results.logs(resultId));
		} else if (selectedTab === -1 || logsNotAvailable(selectedTab, result?.startedByNlw)) {
			navigate(CommonRoutes.results.overview(resultId));
		}
	}, [selectedTab, navigate, resultId, result?.startedByNlw, result, notStarted]);

	usePushDocumentTitleHandler(result?.name);

	const testResultContext = useMemo(() => ({ testResult: result ?? null }), [result]);

	if (isLoading || resultListByTestLoading || isLoadingZones || waiting) {
		return <Spinner />;
	}
	if (!result) {
		throw createNeoLoadError(resultError);
	}

	const navigation = getNavigation(resultListByTest, resultId);

	const handleTabChange = (_event: React.SyntheticEvent, nextValue: number) => {
		navigate(CommonRoutes.results.generic(resultId, resultTabs[nextValue]));
	};

	const onStatusChange = (nextStatus: TestResultInput['qualityStatus']) => {
		updateResult({ resultId, testResultInput: { qualityStatus: nextStatus } })
			.unwrap()
			.catch((_error) => showError({ text: t('messages.updateStatusError') }));
	};

	const onEditTestResultName = (name: string) => {
		updateResult({ resultId, testResultInput: { name: name } })
			.unwrap()
			.catch((_error) => showError({ text: t('messages.updateNameError') }));
	};

	const onStopTest = async () => {
		stopTest({ testExecutionId: resultId })
			.unwrap()
			.then(() => showInfo({ text: t('messages.stopTestSuccess') }))
			.catch((_error) => showError({ text: t('messages.stopTestError') }));
	};

	const onTerminateTest = async () => {
		terminateTest({ testExecutionId: resultId })
			.unwrap()
			.then(() => showInfo({ text: t('messages.terminateTestSuccess') }))
			.catch((_error) => showError({ text: t('messages.terminateTestError') }));
	};

	return (
		<TestResultContext.Provider value={testResultContext}>
			<Grid container direction='column' sx={{ height: '100%' }} wrap='nowrap'>
				<Grid item>
					<ResultHeader
						result={result}
						externalUrl={result.externalUrl}
						{...{
							navigation,
							onStatusChange,
							onEditTestResultName,
							onStopTest,
							onTerminateTest,
						}}
					/>
				</Grid>
				<Grid item>
					<ResultDetails result={result} zones={resultContext.zones} />
				</Grid>
				<Grid item>
					<Tabs value={selectedTab} onChange={handleTabChange}>
						<Tab label={t('tabs.overview')} data-testid='result-overview-tab' disabled={notStarted} />
						<Tab label={t('tabs.values')} data-testid='result-values-tab' disabled={notStarted || starting} />
						<Tab label={t('tabs.events')} data-testid='result-events-tab' disabled={notStarted || starting} />
						<Tab label={t('tabs.slas')} data-testid='result-slas-tab' disabled={notStarted || starting} />

						<Tab
							sx={{ display: resultDashboardsTabFeatureEnabled ? 'inherit' : 'none' }}
							label={t('tabs.dashboards')}
							data-testid='result-dashboards-tab'
							disabled={notStarted || starting}
						/>

						{result.startedByNlw ? (
							<Tab label={t('tabs.logs')} data-testid='result-logs-tab' />
						) : (
							<Tooltip title={t('tabs.logsUnavailable')}>
								<span>
									<Tab label={t('tabs.logs')} disabled data-testid='result-logs-tab' />
								</span>
							</Tooltip>
						)}
					</Tabs>
				</Grid>
				<Grid
					item
					flexGrow={1}
					sx={{ overflowY: 'auto', height: '100%' }}
					onScroll={onScroll}
					boxShadow={isScrolling ? `inset 0 4px 8px -8px` : ''}
					borderTop={`1px solid ${theme.palette.divider}`}
				>
					<ComponentTabPanel value={selectedTab} index={0} data-testid='result-overview-panel' sx={{ height: '100%' }}>
						<ResultTabOverview
							resultId={result.id}
							resultDescription={result.description ?? ''}
							isRunning={isRunning(result)}
							isStarting={isStarting(result)}
							statisticsSamplingInterval={result.statisticsSamplingInterval}
							startDate={result.startDate}
							resultDuration={result.duration}
							resultEndDate={result.endDate}
						/>
					</ComponentTabPanel>
					<ComponentTabPanel
						value={selectedTab}
						index={1}
						data-testid='result-values-panel'
						sx={{ height: '100%', flexGrow: 1 }}
					>
						<ResultTabValues
							context={resultContext}
							isRunning={isAlive(result)}
							intervals={intervals}
							resultDuration={result.duration}
						/>
					</ComponentTabPanel>
					<ComponentTabPanel
						value={selectedTab}
						index={2}
						data-testid='result-events-panel'
						sx={{ height: '100%', flexGrow: 1 }}
					>
						<ResultTabEvent resultId={result.id} isAlive={isAlive(result)} />
					</ComponentTabPanel>
					<ComponentTabPanel
						value={selectedTab}
						index={3}
						data-testid='result-slas-panel'
						sx={{ height: '100%', flexGrow: 1 }}
					>
						<ResultTabSlas result={result} />
					</ComponentTabPanel>
					<ComponentTabPanel
						value={selectedTab}
						index={4}
						data-testid='result-dashboards-panel'
						sx={{ height: '100%', flexGrow: 1 }}
					>
						<ResultDashboardsTab resultId={resultId} />
					</ComponentTabPanel>
					<ComponentTabPanel
						value={selectedTab}
						index={5}
						data-testid='result-logs-panel'
						sx={{ height: '100%', flexGrow: 1 }}
					>
						<ResultTabLogs result={result} />
					</ComponentTabPanel>
				</Grid>
			</Grid>
		</TestResultContext.Provider>
	);
};

export { ResultPage };

export const visibleForTesting = {
	getNavigation,
	logsNotAvailable,
};
