import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { ExternalUrlPlainLink } from './external-url-plain-link';
import { EmptyExternalUrlLink } from './empty-external-url-link';
import { getClickableLink } from './external-url-util';
import { useConfirmModal, useGetMe, useSetSnackbars } from '@neoload/hooks';
import { ExternalUrl } from '@neoload/api';

export type ExternalUrlLinkProps = {
	externalUrl?: ExternalUrl;
	onChange: (updatedExternalUrl: ExternalUrl) => Promise<void>;
};

const ExternalUrlLink = ({ externalUrl, onChange }: ExternalUrlLinkProps) => {
	const { openModal } = useConfirmModal();
	const [{ hasTesterPermissions }] = useGetMe();
	const { t } = useTranslation(['result']);
	const { showInfo, showError } = useSetSnackbars();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const theme = useTheme();

	if (!externalUrl) {
		return <EmptyExternalUrlLink onChange={onChange} />;
	}

	const href = getClickableLink(externalUrl.url);

	const deleteLink = async () => {
		try {
			setAnchorEl(null);
			await onChange({ url: '' });
			showInfo({ text: t('externalUrl.delete.success') });
		} catch {
			showError({ text: t('externalUrl.delete.error') });
		}
	};
	const editLink = () => {
		setAnchorEl(null);
		openModal({
			title: t('externalUrl.edit.title'),
			content: [
				{
					name: 'url',
					label: t('externalUrl.url.label'),
					placeholder: t('externalUrl.url.placeholder'),
					required: true,
				},
				{
					name: 'title',
					label: t('externalUrl.title.label'),
					placeholder: t('externalUrl.title.placeholder'),
				},
			],
			defaultValue: externalUrl,
			confirm: {
				color: 'primary',
				text: t('common:save'),
			},
			validate: ({ url }) => !!url,
			handleConfirm: async ({ url, title }) => {
				try {
					await onChange({ url: url as string, title: title as string });
					showInfo({ text: t('externalUrl.edit.success') });
				} catch {
					showError({ text: t('externalUrl.edit.error') });
				}
			},
		});
	};
	const { title } = externalUrl;

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	if (!hasTesterPermissions) {
		return <ExternalUrlPlainLink title={title} href={href} />;
	}
	const openUrlLabel = t('externalUrl.open', { link: title ?? href });
	return (
		<>
			<IconButton
				data-testid={'external-url-popover-button'}
				sx={[
					{
						color: theme.palette.primary.main,
					},
				]}
				aria-haspopup='true'
				aria-label={t('externalUrl.withUrlButtonLabel')}
				onMouseEnter={handleClick}
			>
				<LinkOutlinedIcon />
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Grid container spacing={2} sx={{ height: 66, overflow: 'hidden', padding: '6px' }}>
					<Grid item>
						<Button
							sx={{
								maxWidth: '300px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								textTransform: 'initial',
								justifyContent: 'start',
							}}
							component={Link}
							href={href}
							target='_blank'
							rel='noreferrer'
							aria-label={openUrlLabel}
						>
							{openUrlLabel}
						</Button>
					</Grid>
					<Grid item sx={{ width: '20px' }}>
						<Divider orientation='vertical' sx={{ height: 36.5 }} flexItem />
					</Grid>
					<Grid item sx={{ width: '70px' }}>
						<Button sx={{ textTransform: 'capitalize' }} onClick={editLink} aria-label={t('externalUrl.editLabel')}>
							{t('common:edit')}
						</Button>
					</Grid>
					<Grid item sx={{ width: '70px', marginRight: '16px' }}>
						<Button sx={{ textTransform: 'capitalize' }} onClick={deleteLink} aria-label={t('externalUrl.deleteLabel')}>
							{t('common:delete')}
						</Button>
					</Grid>
				</Grid>
			</Popover>
		</>
	);
};

export { ExternalUrlLink };
