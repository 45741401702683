import { useInterval, useTimeout } from 'react-use';
import { useGetMe } from '.';

const ACCOUNT_INITIALIZATION_TIMEOUT_IN_MS = 15_000;
const ACCOUNT_INITIALIZATION_POLLING_INTERVAL_IN_MS = 1000;

export const useAccountInitialization = ({
	timeoutInMs = ACCOUNT_INITIALIZATION_TIMEOUT_IN_MS,
	pollingIntervalInMs = ACCOUNT_INITIALIZATION_POLLING_INTERVAL_IN_MS,
}: {
	timeoutInMs?: number;
	pollingIntervalInMs?: number;
}) => {
	const [me, refetchMe] = useGetMe();
	const [isReady, cancel] = useTimeout(timeoutInMs);

	const shouldStartPolling = me.isLoaded;

	if (!shouldStartPolling) {
		cancel();
	}

	const isAccountInitializationTimeout = isReady();

	useInterval(
		() => {
			refetchMe();
		},
		shouldStartPolling ? pollingIntervalInMs : null
	);

	if (me.isLoading) {
		return {
			isAccountInitializing: false,
			isAccountInitialized: true,
			isAccountInitializationTimeout,
		} as const;
	}

	return me.account.initializationStatus === 'INITIALIZING'
		? ({
				isAccountInitializing: true,
				isAccountInitialized: false,
				isAccountInitializationTimeout,
				status: me.account.initializationStatus,
				accountId: me.account.id,
				accountName: me.account.name,
		  } as const)
		: ({
				isAccountInitializing: false,
				isAccountInitialized: true,
				isAccountInitializationTimeout,
				status: me.account.initializationStatus,
		  } as const);
};
