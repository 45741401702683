import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import AddLinkIcon from '@mui/icons-material/AddLink';
import IconButton from '@mui/material/IconButton';
import { useSetSnackbars, useGetMe, useConfirmModal } from '@neoload/hooks';
import { ExternalUrl } from '@neoload/api';

export type EmptyExternalUrlLinkProps = {
	onChange: (updatedExternalUrl: ExternalUrl) => Promise<unknown>;
};

const EmptyExternalUrlLink = ({ onChange }: EmptyExternalUrlLinkProps) => {
	const [{ hasTesterPermissions }] = useGetMe();
	const { t } = useTranslation(['result']);
	const { openModal } = useConfirmModal();
	const { showInfo, showError } = useSetSnackbars();
	const addLink = () => {
		openModal({
			title: t('externalUrl.add.title'),
			content: [
				{
					name: 'url',
					label: t('externalUrl.url.label'),
					placeholder: t('externalUrl.url.placeholder'),
					required: true,
				},
				{
					name: 'title',
					label: t('externalUrl.title.label'),
					placeholder: t('externalUrl.title.placeholder'),
				},
			],
			defaultValue: { url: '', title: '' },
			confirm: {
				color: 'primary',
				text: t('common:save'),
			},
			validate: ({ url }) => !!url,
			handleConfirm: async ({ url, title }) => {
				try {
					await onChange({ url: url as string, title: title as string });
					showInfo({ text: t('externalUrl.add.success') });
				} catch {
					showError({ text: t('externalUrl.add.error') });
				}
			},
		});
	};

	if (hasTesterPermissions) {
		return (
			<Tooltip arrow title={t('externalUrl.add.title')}>
				<IconButton sx={{ flexGrow: 0 }} aria-label={t('externalUrl.add.title')} onClick={addLink}>
					<AddLinkIcon />
				</IconButton>
			</Tooltip>
		);
	}
	return null;
};

export { EmptyExternalUrlLink };
