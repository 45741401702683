import { GridColumns, GridDensity, useGridApiRef } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { defaultGridProps } from '../../../common/datagrid';
import { ResultsDataGridColumns } from '../../results/result-data-grid-columns';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { Spinner } from '../../../common/spinner';
import { TestResult, TestResultSearchCriteria, useGetV4ResultsQuery } from '@neoload/api';
import { useCurrentWorkspace, useGetMe } from '@neoload/hooks';

const DISPLAYED_COLS = new Set(['name', 'testName', 'startDate', 'qualityStatus']);
const PAGE_SIZE = 12;
const density: GridDensity = 'compact';

type LatestResultsTileProps = {
	pollingIntervalDurationInMs: number;
};

export const LatestResultsTile = ({ pollingIntervalDurationInMs }: LatestResultsTileProps) => {
	const [{ id: workspaceId }, isCurrentWorkspaceSelected] = useCurrentWorkspace();
	const apiRef = useGridApiRef();
	const [{ isGuest }] = useGetMe();

	const { data } = useGetV4ResultsQuery(
		{
			workspaceId,
			pageSize: PAGE_SIZE,
			sort: '-startDate',
		},
		{
			skip: !isCurrentWorkspaceSelected,
			pollingInterval: pollingIntervalDurationInMs,
		}
	);

	const actions: DatagridAction[] = [];
	const searchCriterias: TestResultSearchCriteria | undefined = undefined;
	const columns: GridColumns<TestResult> = ResultsDataGridColumns(actions, density, isGuest, searchCriterias)
		.filter(({ field }) => DISPLAYED_COLS.has(field))
		.map((column) => ({
			...column,
			sortable: false,
		}));

	return (
		<Box
			sx={{
				height: '100%',
				paddingTop: (theme) => theme.spacing(2),
			}}
		>
			{data ? (
				<Datagrid
					{...defaultGridProps}
					apiRef={apiRef}
					rows={data.items}
					columns={columns}
					density={density}
					sortingMode='server'
					checkboxSelection={false}
					pagination={false}
					hideFooter={true}
					disableChildrenSorting={true}
					disableMultipleColumnsSorting={true}
					disableColumnSelector={true}
					disableColumnReorder={true}
					disableSelectionOnClick={true}
					sx={{
						borderLeft: 0,
						borderRight: 0,
						'& .MuiLink-root': {
							textDecoration: 'none',
						},
						'& .MuiLink-root:hover': {
							textDecoration: 'underline',
						},
					}}
				/>
			) : (
				<Spinner />
			)}
		</Box>
	);
};
