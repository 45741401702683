import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import RGL, { WidthProvider } from 'react-grid-layout';
import './dashboard-tiles-container.css';
import { ReactNode, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import deepEqual from 'deep-equal';
import { DashboardTileWrapper } from './dashboard-tile-wrapper';
import { DashboardTileWithWipState, TileLayout } from '../types';
import { useUrlSearchParams } from '@neoload/hooks';

const ReactGridLayout = WidthProvider(RGL);

export type TileInfo = {
	data: DashboardTileWithWipState;
	contentType?: string;
	children: ReactNode;
};

export type DashboardTilesContainerProps = {
	tilesInfo: TileInfo[];
	isReadOnly: boolean;
	/** Event fired when move or resize of tiles. An array with the new tile layouts is received as parameter */
	onLayoutChange: (layouts: TileLayout[]) => void;
	onDashboardTileDelete: (dashboardTileId: string, dashboardTileName: string) => void;
	/** Number of columns of the dashboard. 4, by default */
	numberOfColumns?: number;
};

export const compareTiles = ({ data: tileInfoData1 }: TileInfo, { data: tileInfoData2 }: TileInfo): number => {
	const { x: xa, y: ya } = tileInfoData1;
	const { x: xb, y: yb } = tileInfoData2;
	if (ya === yb) {
		return xa - xb;
	}
	return ya - yb;
};

const tileHeight = 200;

const isEquals = (tilesArrangementA: TileLayout[], tilesArrangementB: TileLayout[]) => {
	const sortedTileArrangementA = [...tilesArrangementA].sort((a, b) => a.id.localeCompare(b.id));
	const sortedTileArrangementB = [...tilesArrangementB].sort((a, b) => a.id.localeCompare(b.id));
	return deepEqual(sortedTileArrangementA, sortedTileArrangementB);
};

export const DashboardTilesContainer = ({
	tilesInfo,
	isReadOnly,
	onLayoutChange,
	onDashboardTileDelete,
	numberOfColumns = 4,
}: DashboardTilesContainerProps) => {
	const [, setUrlSearchParams] = useUrlSearchParams('tileToEdit');

	const [currentLayout, setCurrentLayout] = useState<TileLayout[]>(
		tilesInfo.map((info) => info.data).map(({ id, x, y, width, height }) => ({ id, x, y, width, height }))
	);

	const theme = useTheme();
	const children = useMemo(() => {
		const sorted = [...tilesInfo].sort(compareTiles);
		return sorted.map(({ children, data, contentType }) => (
			<DashboardTileWrapper
				id={data.id}
				title={data.title}
				contentType={contentType}
				key={data.id}
				data-grid={{ x: data.x, y: data.y, w: data.width, h: data.height, isResizable: !isReadOnly }}
				children={children}
				onDashboardTileDelete={onDashboardTileDelete}
				onSwitchEdition={() => {
					setUrlSearchParams({ tileToEdit: data.id });
				}}
				isReadOnly={isReadOnly}
				editionEnabled={data?.type !== 'EMPTY'}
				editionHidden={data?.type === 'WIP'}
			/>
		));
	}, [tilesInfo, isReadOnly, onDashboardTileDelete, setUrlSearchParams]);

	const _onLayoutChange = (layouts: ReactGridLayout.Layout[]) => {
		const tilesLayout = layouts.map(({ i, x, y, w, h }) => ({ id: i, x, y, width: w, height: h }));
		if (!isEquals(tilesLayout, currentLayout)) {
			setCurrentLayout(tilesLayout);
			onLayoutChange(tilesLayout);
		}
	};

	return (
		<ReactGridLayout
			style={{ backgroundColor: theme.palette.other.navigationBackground }}
			margin={[16, 16]}
			className='layout'
			cols={numberOfColumns}
			isBounded={false}
			autoSize={true}
			draggableHandle='.tile-header'
			draggableCancel='.tile-action-buttons'
			onLayoutChange={_onLayoutChange}
			rowHeight={tileHeight}
			useCSSTransforms={true}
			isDraggable={!isReadOnly}
			isResizable={!isReadOnly}
		>
			{children}
		</ReactGridLayout>
	);
};
