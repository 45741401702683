import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import { IntervalForm } from './interval-form';
import { PopperWrapper } from './popper-wrapper';
import { GraphType, IntervalEditionState } from '../../result-overview-graphs';
import { ColorIcon } from '../../../../../common/color-icon';
import { HtmlArea } from '../../../../../common/html-area';
import { Interval, IntervalPostRequest, usePatchV4ResultsByResultIdIntervalsAndIntervalIdMutation } from '@neoload/api';
import {
	extractIntervalIncompatibilities,
	IntervalIncompatibility,
	isRawDataAvailable,
	timeUtils,
} from '@neoload/utils';
import { useDeleteIntervals, useSetSnackbars } from '@neoload/hooks';

export type IntervalHoverProps = {
	interval: Interval;
	intervalRect: DOMRect;
	mouseX: number;
	hoveredGraphType: GraphType;
	intervalIsHovered: boolean;
};

export type IntervalPopoverProps = IntervalHoverProps & {
	setIntervalEditionState: (editionState: IntervalEditionState | undefined) => void;
	intervalEditionState: IntervalEditionState | undefined;
	resultId: string;
	resultDuration: string;
	resultEndDate?: string;
	editedStartOffset?: string;
	editedEndOffset?: string;
	onOffsetsInputsEdited: (startOffset: string | undefined, endOffset: string | undefined) => void;
	onColorChanged: (hexColor: string) => void;
	chartContainer?: HTMLElement;
};

const getOffset = (mouseX: number, intervalRect: DOMRect) => {
	const intervalCenterX = intervalRect.x + intervalRect.width / 2;
	return mouseX - intervalCenterX;
};

const getIntervalPostRequest = (
	current: IntervalPostRequest,
	startOffset: string,
	editedStartOffset: string | undefined,
	endOffset: string,
	editedEndOffset: string | undefined
): IntervalPostRequest => ({
	...current,
	startOffset: editedStartOffset ?? startOffset,
	endOffset: editedEndOffset ?? endOffset,
});

const getIntervalRectY = (intervalRect: DOMRect, resultEndDate: string | undefined) =>
	intervalRect.y - intervalRect.height - (isRawDataAvailable(resultEndDate) ? 257 : 334);

/**
 * Popper to be shown on interval hover in graphs. The popper only closes when neither the interval nor the popper are
 * hovered, so that the user may mouse drag from the interval to the popper
 * To ensure user can drag from the interval to the popper without hovering another interval, it is displayed on the top
 * side of the interval, with an offset so that its x is centered on the mouse position.
 * Interval's DOMRect is used to provide a VirtualElement as anchor.
 * Collisions with window borders are handled automatically by the popper, no need to take this into account here in
 * offset's calculus
 * @param interval the interval to display in the popper
 * @param resultId the result's id of the interval
 * @param intervalRect the interval's DOMRect
 * @param intervalIsHovered whether the interval is hovered
 * @param mouseX the mouse position
 * @constructor
 */
const IntervalPopper = ({
	interval,
	intervalRect,
	intervalIsHovered,
	resultId,
	mouseX,
	setIntervalEditionState,
	intervalEditionState,
	resultDuration,
	resultEndDate,
	editedStartOffset,
	editedEndOffset,
	onOffsetsInputsEdited,
	hoveredGraphType,
	onColorChanged,
	chartContainer,
}: IntervalPopoverProps) => {
	const { t } = useTranslation(['result']);
	const [popperHovered, setPopperHovered] = useState(false);
	const theme = useTheme();
	const [patchInterval] = usePatchV4ResultsByResultIdIntervalsAndIntervalIdMutation();
	const deleteIntervals = useDeleteIntervals();

	//necessary to have offset as a state, as calling getOffset directly in the jsx can cause glitching effect
	const [offset, setOffset] = useState(getOffset(mouseX, intervalRect));
	const { id, name, description, startOffset, endOffset, color = theme.palette.primary.main } = interval;
	const { showError } = useSetSnackbars();

	const [intervalPostRequest, setIntervalPostRequest] = useState(
		getIntervalPostRequest(interval, startOffset, editedStartOffset, endOffset, editedEndOffset)
	);

	useEffect(() => {
		setOffset(getOffset(mouseX, intervalRect));
	}, [mouseX, intervalRect]);

	const handleSubmit = async (
		intervalToSubmit: Partial<Interval>,
		ignoreIncompatibilities: boolean
	): Promise<IntervalIncompatibility[]> =>
		await patchInterval({
			intervalId: id,
			resultId,
			intervalPatchRequest: intervalToSubmit,
			ignoreIncompatibilities,
		})
			.unwrap()
			.then(
				(_interval) => setIntervalEditionState(undefined),
				(error) => extractIntervalIncompatibilities(error) ?? showError({ text: t('intervals.updateFailed') })
			)
			.then((response) => (Array.isArray(response) ? response : []));

	const openDeleteModal = () => {
		setPopperHovered(false);
		deleteIntervals([id], resultId);
	};

	const editingInterval = intervalEditionState !== undefined && intervalEditionState.mode === 'EDITION';

	useEffect(() => {
		setIntervalPostRequest((current) =>
			getIntervalPostRequest(current, startOffset, editedStartOffset, endOffset, editedEndOffset)
		);
	}, [editedEndOffset, editedStartOffset, endOffset, startOffset]);

	useEffect(() => {
		setIntervalPostRequest(interval);
	}, [interval]);

	return (
		<PopperWrapper
			intervalRect={
				editingInterval
					? new DOMRect(
							intervalRect.x,
							getIntervalRectY(intervalRect, resultEndDate),
							intervalRect.width,
							intervalRect.height
					  )
					: intervalRect
			}
			open={popperHovered || intervalIsHovered || editingInterval}
			onMouseEnter={() => setPopperHovered(true)}
			onMouseLeave={() => setPopperHovered(false)}
			offset={offset}
			onClickAway={() => {
				setIntervalEditionState(undefined);
				setPopperHovered(false);
			}}
			chartContainer={chartContainer}
			placement={editingInterval ? 'bottom' : 'top'}
		>
			{editingInterval ? (
				<IntervalForm
					resultDuration={resultDuration}
					resultEndDate={resultEndDate}
					interval={intervalPostRequest}
					onSubmit={handleSubmit}
					onCancel={() => {
						setIntervalEditionState(undefined);
					}}
					onOffsetsInputsEdited={onOffsetsInputsEdited}
					mode={'EDIT'}
					onColorChanged={onColorChanged}
				/>
			) : (
				<Box sx={{ padding: 1.5 }}>
					<Grid
						container
						direction='column'
						columnSpacing={1}
						rowGap={1}
						sx={{
							maxWidth: 300,
						}}
					>
						<Grid item>
							<Grid container display={'flex'} justifyContent={'space-between'}>
								<Grid item sx={{ marginRight: 2 }}>
									<Typography variant='body2' sx={{ lineHeight: '30px' }}>
										{timeUtils.rangeTimeSeconds(startOffset, endOffset)}
									</Typography>
								</Grid>
								<Grid item>
									<IconButton
										onClick={() => {
											setIntervalEditionState({
												mode: 'EDITION',
												editedIntervalId: id,
												editedChart: hoveredGraphType,
											});
										}}
										size='small'
										data-testid='interval-popper-edit'
										aria-label={'edit interval'}
									>
										<EditOutlined />
									</IconButton>
									<IconButton
										onClick={() => {
											openDeleteModal();
										}}
										size='small'
										data-testid='interval-popper-delete'
										aria-label={'delete interval'}
									>
										<DeleteOutlined sx={{ color: 'error.main' }} />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sx={{ width: '100%' }}>
							<Grid display='flex' flexDirection='row' gap={1} sx={{ width: '100%' }}>
								<Grid flexGrow={0}>
									<ColorIcon color={color} data-testid='interval-color-card' size={2} elevation={0} />
								</Grid>
								<Grid sx={{ width: '95%' }}>
									<Typography
										variant='subtitle2'
										alignSelf='flex-start'
										data-testid='interval-name'
										sx={{
											overflowY: 'hidden',
											// eslint-disable-next-line @typescript-eslint/naming-convention
											WebkitBoxOrient: 'vertical',
											wordWrap: 'break-word',
											display: '-webkit-box',
											// eslint-disable-next-line @typescript-eslint/naming-convention
											WebkitLineClamp: 3,
										}}
									>
										{name}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							whiteSpace='normal'
							sx={{
								overflowY: 'auto',
								maxHeight: '250px',
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
								width: '100%',
							}}
							alignSelf='flex-start'
						>
							<HtmlArea data-testid='interval-description' html={description ?? ''} />
						</Grid>
					</Grid>
				</Box>
			)}
		</PopperWrapper>
	);
};

export { IntervalPopper };

export const visibleForTesting = {
	getOffset,
};
