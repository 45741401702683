import { useEffect, useRef, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { TestResult, useLazyGetV4ResultsByResultIdQuery, useGetV4ResultsQuery } from '@neoload/api';
import { useCurrentWorkspace } from '@neoload/hooks';

export function useGetInitialValue(initialResultId: string | undefined) {
	const [triggerGetResult] = useLazyGetV4ResultsByResultIdQuery();
	const result = useRef<TestResult | undefined>();
	const error = useRef<FetchBaseQueryError | SerializedError | undefined>();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (initialResultId) {
			const fetchData = async () => {
				const { data, error: fetchError } = await triggerGetResult({ resultId: initialResultId }, true);
				result.current = data;
				error.current = fetchError;
			};
			setIsLoading(true);
			fetchData().finally(() => setIsLoading(false));
		}
	}, [initialResultId, triggerGetResult]);
	return [result.current, error.current, isLoading] as const;
}

const pageSize = 25;
const sort = '-startDate';

export function useGetTestResultData(pageNumber: number) {
	const [{ id: workspaceId }] = useCurrentWorkspace();
	const {
		data: { items: dataItems = [], total = 0 } = {},
		error,
		isLoading,
	} = useGetV4ResultsQuery({
		workspaceId,
		sort,
		pageSize,
		pageNumber,
		status: ['TERMINATED'],
	});
	return [dataItems, total, error, isLoading] as const;
}
