import { ChipProps } from '@mui/material/Chip';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import ChipStatus from '@tricentis/aura/components/ChipStatus.js';

export type UnknownChipProps = {
	onClick?: MouseEventHandler<HTMLDivElement>;
	withLabel?: boolean;
	size?: ChipProps['size'];
};

const UnknownChip = ({ withLabel = false, onClick, size = 'medium' }: UnknownChipProps) => {
	const { t } = useTranslation(['result']);
	return (
		<ChipStatus
			status='NotApplicable'
			label={withLabel ? t('resultStatus.unknown') : null}
			onClick={onClick}
			clickable={typeof onClick === 'function'}
			size={size}
			data-testid='unknown-chip-status'
		/>
	);
};

export { UnknownChip };
