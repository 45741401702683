import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { InputNumber } from '../../../../common/input/input-number';
import { ConfigurationFormData, ConfigurationZoneFormData } from '../types.d';

export type ZoneRowProps = {
	index: number;
	disabledRemoved?: boolean;
	zone: ConfigurationZoneFormData;
};

const ZoneRow = ({ index, zone, disabledRemoved }: ZoneRowProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['test']);
	const { register, setValue } = useFormContext<ConfigurationFormData>();
	const controller = useWatch<ConfigurationFormData, 'zones.controller'>({ name: 'zones.controller' });
	const zoneTypeSelected = useWatch<ConfigurationFormData, 'zones.type'>({ name: 'zones.type' });
	const lgs = useWatch({ name: `zones.items.${index}.number` });

	const disabledController = zoneTypeSelected === 'NCP';
	const disabledLg = zoneTypeSelected === 'DYNAMIC' && controller !== zone.zoneId;
	const tooltipController =
		zoneTypeSelected === 'NCP' && controller === zone.zoneId ? t('configuration.zones.ncp.freeController') : '';
	const tooltipLg =
		zoneTypeSelected === 'DYNAMIC' && controller !== zone.zoneId ? t('configuration.zones.dynamic.noController') : '';

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				borderRadius: theme.spacing(0.5),
				borderColor: theme.palette.divider,
				borderWidth: '1px',
				borderStyle: 'solid',
				paddingY: 1,
				paddingX: 2,
			}}
		>
			<Tooltip arrow title={zone.name}>
				<Typography variant='body2' sx={{ flex: 1, paddingRight: 3 }} noWrap>
					{zone.name}
				</Typography>
			</Tooltip>
			<Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
				<Tooltip arrow title={tooltipLg}>
					<span>
						<InputNumber
							onChange={(value) => {
								setValue(`zones.items.${index}.number`, value);
							}}
							value={lgs}
							disabled={disabledLg}
							aria-label={t('configuration.zones.lg') + ' ' + zone.name}
							min={0}
						/>
					</span>
				</Tooltip>
				<Tooltip arrow title={tooltipController}>
					<span>
						<Radio
							{...register('zones.controller')}
							size='small'
							value={zone.zoneId}
							checked={zone.zoneId === controller}
							disabled={disabledController}
							aria-label={t('configuration.zones.controller') + ' ' + zone.name}
						/>
					</span>
				</Tooltip>
				{!disabledRemoved && (
					<Tooltip arrow title={t('common:remove')}>
						<IconButton aria-label={t('common:remove') + ' ' + zone.name}>
							<DeleteOutlined />
						</IconButton>
					</Tooltip>
				)}
			</Box>
		</Box>
	);
};

export { ZoneRow };
