import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { GitCloneForm } from './types.d';
import { useLazyGetV4ScmRepositoriesByRepositoryIdReferencesQuery } from '@neoload/api';

type AutocompleteType = { title: string };

const GitReferenceAutocomplete = () => {
	const { t } = useTranslation(['test']);
	const [triggerScmRepositoriesByRepositoryIdReferences] = useLazyGetV4ScmRepositoriesByRepositoryIdReferencesQuery();

	const { setValue } = useFormContext<GitCloneForm>();

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<AutocompleteType[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const setReference = (data: AutocompleteType | null) => {
		setValue('reference', data?.title ?? '');
	};

	const repositoryId = useWatch<GitCloneForm>({ name: 'repositoryId' });

	useEffect(() => {
		let active = true;

		if (!isLoading || !repositoryId) {
			setIsLoading(false);
			return undefined;
		}
		setIsLoading(true);
		(async () => {
			const { data: references } = await triggerScmRepositoriesByRepositoryIdReferences({
				repositoryId: repositoryId,
			});

			const items = references?.items?.map((reference) => ({ title: reference.name }));

			if (active && items) {
				setOptions(items);
			}
			setIsLoading(false);
		})();

		return () => {
			active = false;
		};
	}, [isLoading, repositoryId, triggerScmRepositoriesByRepositoryIdReferences]);

	useEffect(() => {
		if (open) {
			setIsLoading(true);
		} else {
			setOptions([]);
			setIsLoading(false);
		}
	}, [open]);

	return (
		<Autocomplete
			key={repositoryId}
			open={open}
			disabled={repositoryId === ''}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			getOptionLabel={(option) => option.title}
			options={options}
			noOptionsText={t('configuration.project.git.modal.noReferences')}
			loadingText={t('configuration.project.git.modal.loading')}
			loading={isLoading}
			onChange={(_, data) => setReference(data)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={t('configuration.project.git.modal.reference')}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export { GitReferenceAutocomplete };
