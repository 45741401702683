import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { snackbarReducer } from './store/snackbar/reducer';
import { modalReducer } from './store/modal/reducer';
import { workspaceReducer } from './store/workspace/reducer';
import { rtkQueryUnauthorizedHandler } from './rtk-query-unauthorized-handler';
import { nlwApiV4 } from './nlw-api-v4';
import { nlwFileApiV4 } from './nlw-file-api-v4';

export const store = configureStore({
	reducer: {
		// Add the generated reducer as a specific top-level slice
		[nlwApiV4.reducerPath]: nlwApiV4.reducer,
		[nlwFileApiV4.reducerPath]: nlwFileApiV4.reducer,
		snackbarReducer,
		modalReducer,
		workspaceReducer,
	},
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these action types
				ignoredActions: [
					'snackbar/addSnackbar',
					'modal/openModal',
					'apiV4/executeQuery/fulfilled',
					'apiV4/executeQuery/rejected',
					'apiV4/executeMutation/fulfilled',
					'apiV4/executeMutation/rejected',
					'fileApiV4/executeMutation/fulfilled',
					'fileApiV4/executeMutation/pending',
					'fileApiV4/executeMutation/rejected',
				],
				// Ignore these field paths in all actions
				ignoredActionPaths: ['snackbar.0.actionCallback', 'modal.handleConfirm'],
				// Ignore these paths in the state
				ignoredPaths: ['snackbarReducer', 'modalReducer', 'meta.baseQueryMeta.request'],
			},
		})
			// eslint-disable-next-line unicorn/prefer-spread
			.concat(nlwApiV4.middleware)
			// eslint-disable-next-line unicorn/prefer-spread
			.concat(nlwFileApiV4.middleware)
			// eslint-disable-next-line unicorn/prefer-spread
			.concat(rtkQueryUnauthorizedHandler),
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
