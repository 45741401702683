import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
	TestResult,
	TrendsConfigurationMetric,
	useLazyGetV4ResultsByResultIdQuery,
	useLazyGetV4TestsByTestIdTrendsConfigurationQuery,
	UserPathElementValueStatistic,
	ValueComparisonRow,
	ValuesComparisonColumn,
	ValuesComparisonDashboardTile,
} from '@neoload/api';

const createColumnFromResult = (result: TestResult): ValuesComparisonColumn => ({
	columnName: result.name,
	resultId: result.id,
	visible: true,
});

const createRowsFromTrendsConfiguration = (
	trendsConfigurationMetrics: TrendsConfigurationMetric[],
	t: TFunction
): ValueComparisonRow[] =>
	trendsConfigurationMetrics?.length
		? trendsConfigurationMetrics.map((metric) => ({
				path: metric.elementFullPath,
				elementType: 'TRANSACTION',
				rowName: `${metric.elementFullPath.at(-1)} - ${t(
					'statistics.userPathElementValueStatistics.' + metric.statistic
				)}`,
				rowType: 'ELEMENT',
				statistic: metric.statistic,
				userPathId: metric.elementFullPath[0] ?? '',
				visible: true,
		  }))
		: getDefaultRowsForReport(t);

const getDefaultRowsForReport = (t: TFunction): ValueComparisonRow[] => {
	const defaultStatistics: UserPathElementValueStatistic[] = [
		'AVERAGE_DURATION',
		'DURATION_PERCENTILE_50',
		'DURATION_PERCENTILE_90',
	];
	return defaultStatistics.map((statistic) => ({
		path: ['All Transactions'],
		elementType: 'TRANSACTION',
		rowName: `${t('allTransactions')} - ${t('statistics.userPathElementValueStatistics.' + statistic)}`,
		rowType: 'ELEMENT',
		statistic: statistic,
		visible: true,
	}));
};

const emptyValuesComparisonTile: ValuesComparisonDashboardTile = {
	id: '',
	columns: [],
	differenceType: 'NONE',
	height: 1,
	idMapping: [],
	rows: [],
	title: '',
	width: 1,
	x: 0,
	y: 0,
};

const getValuesComparisonTile = (
	column: ValuesComparisonColumn,
	rows: ValueComparisonRow[]
): ValuesComparisonDashboardTile => ({
	...emptyValuesComparisonTile,
	columns: [column],
	rows: rows,
});

/**
 * Hook to get the value comparison tile on a report.
 * 	- When creating a value comparison tile on a report (dashboard linked to a bench id) :
 * 		Initialises a column corresponding to the whole result (no interval)
 * 		Initialises rows corresponding to either :
 * 			- Trend configuration metrics if there are
 * 			- Avg duration, perc50 & perc90 of all transactions otherwise
 */
const useGetReportComparisonTile = () => {
	const [triggerFetchResult] = useLazyGetV4ResultsByResultIdQuery();
	const [triggerFetchTrendsConfiguration] = useLazyGetV4TestsByTestIdTrendsConfigurationQuery();
	const { t } = useTranslation(['common']);

	return useCallback(
		async (resultId: string): Promise<ValuesComparisonDashboardTile> => {
			const fetchTrendsConfigurationAndBuildTile = (
				testId: string,
				column: ValuesComparisonColumn
			): Promise<ValuesComparisonDashboardTile> =>
				triggerFetchTrendsConfiguration({ testId: testId }, true)
					.then((response) =>
						getValuesComparisonTile(column, createRowsFromTrendsConfiguration(response.data?.metrics ?? [], t))
					)
					.catch((error) => {
						console.error('Error while fetching trends configuration', error);
						//do not block user on error, still return the tile with the column from the result
						return getValuesComparisonTile(column, getDefaultRowsForReport(t));
					});

			return triggerFetchResult({ resultId: resultId }, true)
				.unwrap()
				.then((response) => {
					const column = createColumnFromResult(response);
					const testId = response.test?.id;
					return testId
						? fetchTrendsConfigurationAndBuildTile(testId, column)
						: getValuesComparisonTile(column, getDefaultRowsForReport(t));
				})
				.catch((error) => {
					console.error('Error while fetching result', error);
					//do not block user, return empty tile
					return emptyValuesComparisonTile;
				});
		},
		[t, triggerFetchResult, triggerFetchTrendsConfiguration]
	);
};

export { useGetReportComparisonTile };
