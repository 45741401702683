import {
	DashboardTileEmptyContent,
	DashboardTileErrorContent,
	SeriesFetcher,
	WidgetFetcher,
	ValuesComparisonFetcher,
} from '../..';
import { HtmlArea } from '../../../../common/html-area';
import { DashboardTileWorkInProgress } from '../dashboard-tile-work-in-progress';
import { DashboardTileWithWipState } from '../types';

type DashboardTileContentProps = {
	dashboardId: string;
	tile: DashboardTileWithWipState;
	isReadOnly: boolean;
};

export const DashboardTileContent = ({ dashboardId, tile, isReadOnly }: DashboardTileContentProps) => {
	switch (tile.type) {
		case 'SERIES': {
			return <SeriesFetcher dashboardId={dashboardId} seriesTile={tile} isReadOnly={isReadOnly} />;
		}
		case 'TEXT': {
			return <HtmlArea html={tile.text} />;
		}
		case 'WIDGET': {
			return <WidgetFetcher widgetTile={tile} />;
		}
		case 'VALUES_COMPARISON': {
			return <ValuesComparisonFetcher valuesComparisonTile={tile} />;
		}
		case 'WIP': {
			return <DashboardTileWorkInProgress title={tile.header} content={tile.content} />;
		}
		case 'EMPTY': {
			return <DashboardTileEmptyContent />;
		}
		default: {
			return <DashboardTileErrorContent />;
		}
	}
};
