import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypedLazyQueryTrigger } from '@reduxjs/toolkit/query/react';
import { FetchError, LoadingState } from './types';
import { WidgetDashboardTile } from '@neoload/api';

export function useWidgetDataFetcher<Argument, Response>(
	widgetTile: WidgetDashboardTile,
	argument: Argument,
	shouldStartFetching: boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	trigger: TypedLazyQueryTrigger<Response, Argument, any>
) {
	const { t } = useTranslation(['dashboard']);
	const [data, setData] = useState<Response | null>(null);
	const [error, setError] = useState<FetchError | null>(null);
	const [loadingState, setLoadingState] = useState<LoadingState>('UNLOADED');

	useEffect(() => {
		setLoadingState('UNLOADED');
	}, [widgetTile.resultId]);

	useEffect(() => {
		if (!shouldStartFetching) {
			return;
		}
		if (loadingState === 'UNLOADED') {
			setLoadingState('LOADING');
			trigger(argument, true)
				.then((result) => {
					if (result.isError) {
						setError(result.error);
					} else if (result.data) {
						setData(result.data);
					} else {
						setError({
							error: t('widget.error.dataNotFound', {
								widgetId: widgetTile.id,
							}),
							status: 'CUSTOM_ERROR',
						});
					}
				})
				.catch(() =>
					setError({
						error: t('widget.error.fetchFailure', {
							tileId: widgetTile.id,
						}),
						status: 'CUSTOM_ERROR',
					})
				)
				.finally(() => {
					setLoadingState('LOADED');
				});
		}
	}, [argument, loadingState, shouldStartFetching, t, widgetTile.id, trigger, data]);
	return [data, error, loadingState] as const;
}
