const convertShortToLongColor = (color: string): string => {
	if (color.length === 4) {
		return [...color]
			.map((item) => {
				if (item === '#') {
					return item;
				}
				return item + item;
			})
			.join('');
	}

	return color;
};

export { convertShortToLongColor };
