import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import { getRenderValue, listCellRenderer } from './interval-combobox-utils';
import { Interval, useGetV4ResultsByResultIdIntervalsQuery, useGetV4ResultsByResultIdQuery } from '@neoload/api';

export type SearchResultComboProps = {
	resultId?: string;
	onIntervalSelected?: (interval?: Interval) => void;
	intervalId?: string;
};

const findInterval = (intervals: Interval[], intervalId: string) =>
	intervals.find((interval) => interval.id === intervalId);

const SearchIntervalCombobox = ({ resultId, onIntervalSelected, intervalId = '*' }: SearchResultComboProps) => {
	const { t } = useTranslation(['result']);
	const theme = useTheme();

	const { data: { items: intervals = [] } = {}, error: fetchIntervalsError } = useGetV4ResultsByResultIdIntervalsQuery(
		{
			resultId: resultId ?? '',
		},
		{ skip: !resultId }
	);
	const { data: result, error: fetchResultError } = useGetV4ResultsByResultIdQuery(
		{
			resultId: resultId ?? '',
		},
		{
			skip: !resultId,
		}
	);

	const onIdSelected = (selectedId: string) =>
		onIntervalSelected && onIntervalSelected(findInterval(intervals, selectedId));

	return (
		<FormControl fullWidth variant='outlined'>
			<InputLabel>{t('filters.intervals')}</InputLabel>
			<Select
				label={t('filters.intervals')}
				error={!!fetchResultError || !!fetchIntervalsError}
				value={findInterval(intervals, intervalId) ? intervalId : '*'}
				onChange={(event) => onIdSelected(event.target.value)}
				sx={{ height: theme.spacing(7) }}
				// eslint-disable-next-line @typescript-eslint/naming-convention
				inputProps={{ 'data-testid': 'interval-combo' }}
				MenuProps={{
					style: {
						maxHeight: 500,
					},
				}}
				renderValue={getRenderValue(intervals, t)}
			>
				<MenuItem value='*' data-testid={'whole-result'}>
					{listCellRenderer(intervals, t, theme, result?.duration)('*')}
				</MenuItem>
				{intervals.map((interval) => (
					<MenuItem key={interval.id} value={interval.id} data-testid={interval.id}>
						{listCellRenderer(intervals, t, theme, result?.duration)(interval.id)}
					</MenuItem>
				))}
			</Select>
			{(!!fetchIntervalsError || !!fetchResultError) && (
				<FormHelperText error>
					{t(fetchIntervalsError ? 'intervals.intervalsFetchFailed' : 'messages.resultFetchFailed')}
				</FormHelperText>
			)}
		</FormControl>
	);
};

export { SearchIntervalCombobox };
