import * as React from 'react';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import Box from '@mui/material/Box';
import Brand from '@tricentis/aura/components/Brand.js';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import MuiAppBar from '@mui/material/AppBar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from '@mui/material/styles';
import SelectSpace from '@tricentis/aura/components/SelectSpace.js';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import AccountOutlined from '@mui/icons-material/AccountCircleOutlined';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlined from '@mui/icons-material/LightModeOutlined';
import { AccountMenu } from '../account-menu/account-menu';
import { HelpMenu } from '../help-menu/help-menu';
import { useCurrentWorkspace, useSetCurrentWorkspaceId, useGetMe, usePreferredTheme } from '@neoload/hooks';
import { SettingsRoutes, shouldRedirectToHome, CommonRoutes, HomeRoutes } from '@neoload/utils';
import { usePatchV4MePreferencesMutation } from '@neoload/api';
type AppBarProps = {
	withWorkspaceSelector?: boolean;
};

const AppBar = ({ withWorkspaceSelector = true }: AppBarProps) => {
	const navigate = useNavigate();
	const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<null | HTMLElement>(null);
	const setCurrentWorkspaceId = useSetCurrentWorkspaceId();
	const [updatePreferences] = usePatchV4MePreferencesMutation();
	const [currentUser] = useGetMe();
	const [currentWorkspace] = useCurrentWorkspace();
	const userMenuOpen = Boolean(userMenuAnchorEl);
	const helpMenuOpen = Boolean(helpMenuAnchorEl);
	const { t } = useTranslation('menu');
	const location = useLocation();
	const { mode } = useColorScheme();
	const { switchPreferredTheme } = usePreferredTheme();

	const onUserMenuClicked = (event: React.MouseEvent<HTMLElement>) => {
		if (userMenuAnchorEl === null) {
			setUserMenuAnchorEl(event.currentTarget);
			setHelpMenuAnchorEl(null);
		} else {
			setUserMenuAnchorEl(null);
		}
	};

	const closeUserMenu = () => {
		setUserMenuAnchorEl(null);
	};

	const onHelpMenuClicked = (event: React.MouseEvent<HTMLElement>) => {
		if (helpMenuAnchorEl === null) {
			setHelpMenuAnchorEl(event.currentTarget);
			setUserMenuAnchorEl(null);
		} else {
			setHelpMenuAnchorEl(null);
		}
	};

	const closeHelpMenu = () => {
		setHelpMenuAnchorEl(null);
	};

	const handleChangeWorkspace = (event: React.ComponentPropsWithoutRef<typeof SelectSpace>['options'][number]) => {
		if (shouldRedirectToHome(location)) {
			navigate(HomeRoutes.base);
		}
		const nextWorkspaceId = event.id.toString();
		setCurrentWorkspaceId(nextWorkspaceId);
		updatePreferences({ patchPreferencesRequest: { currentWorkspaceId: nextWorkspaceId } });
	};

	const items: React.ComponentPropsWithoutRef<typeof SelectSpace>['items'] = [
		{
			id: 1,
			name: t('workspaceOverview'),
		},
	];

	const handleItemClick: React.ComponentPropsWithoutRef<typeof SelectSpace>['onClick'] = (event) => {
		if (event.id === 1) {
			navigate(CommonRoutes.settings.patterns.workspaces);
		}
	};

	return (
		<MuiAppBar>
			<Toolbar>
				<Box>
					<Button
						aria-label={t('menu.home')}
						component={Link}
						to={HomeRoutes.base}
						sx={{
							display: 'flex',
							height: '100%',
							cursor: 'pointer',
							padding: '0',
						}}
					>
						<Brand sx={{ width: 118 }} />
					</Button>
					{withWorkspaceSelector && currentUser.workspaces && (
						<SelectSpace
							data-testid='space-selector'
							mainButtonProps={{
								'aria-label': t('menu.workspaces'),
							}}
							onChange={(event) => handleChangeWorkspace(event)}
							onClick={(event) => handleItemClick(event)}
							options={currentUser.workspaces}
							items={items}
							sx={{ width: 220 }}
							value={currentWorkspace}
						/>
					)}
				</Box>
				<Box>
					<Stack spacing={1.5} direction='row' color='secondary.light' alignItems='center'>
						<Tooltip title={t('menu.preview.tooltip')} arrow>
							<Chip label={t('menu.preview.title')} variant='outlined' color='warning' />
						</Tooltip>
						<IconButton data-testid='documentation-icon-button' onClick={onHelpMenuClicked}>
							<Tooltip title={!helpMenuOpen && t('menu.help')} arrow>
								<HelpOutlineOutlined />
							</Tooltip>
						</IconButton>
						<HelpMenu open={helpMenuOpen} anchorEl={helpMenuAnchorEl} handleClose={closeHelpMenu} />
						<IconButton onClick={switchPreferredTheme}>
							<Tooltip title={t(mode === 'light' ? t('theme.switchToDarkTheme') : t('theme.switchToLightTheme'))} arrow>
								{mode === 'light' ? <DarkModeOutlined /> : <LightModeOutlined />}
							</Tooltip>
						</IconButton>
						<IconButton data-testid='settings-icon-button' component={Link} to={SettingsRoutes.users}>
							<Tooltip title={t('menu.account')} arrow>
								<SettingsOutlined />
							</Tooltip>
						</IconButton>
						<IconButton data-testid='account-icon-button' onClick={onUserMenuClicked}>
							<Tooltip title={!userMenuOpen && t('menu.user')} arrow>
								<AccountOutlined />
							</Tooltip>
						</IconButton>
						<AccountMenu open={userMenuOpen} anchorEl={userMenuAnchorEl} handleClose={closeUserMenu} />
					</Stack>
				</Box>
			</Toolbar>
		</MuiAppBar>
	);
};

export { AppBar };
