import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import Divider from '@mui/material/Divider';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlined from '@mui/icons-material/ChevronLeftOutlined';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useInterval } from 'react-use';
import { ResultHeaderMenu } from './result-header-menu';
import { ExternalUrlLink } from './external-url/external-url-link';
import { styleTypo } from './details/styles-utils';
import { ResultStopButtons } from './result-stop-buttons';
import { tooltipUtils } from './tooltip-utils';
import { ResultStatusData, RunChip } from '../../data-grid/run-chip';
import { EditableRunChip } from '../../data-grid/run-chip/editable-run-chip';
import { InitializingChip } from '../../data-grid/run-chip/initializing-chip';
import {
	InitializationState,
	useGetInitializationState,
	useGetMe,
	useLockResults,
	useUrlSearchParams,
} from '@neoload/hooks';
import { ExternalUrl, TestResult, TestResultInput, usePatchV4ResultsByResultIdMutation } from '@neoload/api';
import {
	CommonRoutes,
	isEditable,
	isRunning,
	isStarting,
	isStoppable,
	ResultGenericParams,
	timeUtils,
} from '@neoload/utils';

export type ResultHeaderNavigation = {
	// Index of the test result in the list
	resultIdsIndex: number;
	resultIdsLength: number;
	previousResultId?: string;
	previousStartDate?: string;
	nextResultId?: string;
	nextStartDate?: string;
};

export type ResultHeaderProps = {
	result: Pick<
		TestResult,
		| 'id'
		| 'name'
		| 'description'
		| 'startDate'
		| 'duration'
		| 'locked'
		| 'status'
		| 'qualityStatus'
		| 'terminationReason'
		| 'startedByNlw'
	>;
	navigation?: ResultHeaderNavigation;
	externalUrl?: ExternalUrl;
	onEditTestResultName: (name: string) => void;
	onStatusChange: (nextStatus: TestResultInput['qualityStatus']) => void;
	onStopTest: () => Promise<void>;
	onTerminateTest: () => Promise<void>;
};

export type RunningData = {
	remainingTime: string;
	runningPercent: undefined | number;
};

type ResultHeaderChipProps = {
	result: ResultStatusData;
	testIsStarting: boolean;
	initializationState: InitializationState;
	onStatusChange: (nextStatus: TestResultInput['qualityStatus']) => void;
	remainingData: RunningData;
};

const ResultHeaderChip = ({
	result,
	testIsStarting,
	initializationState,
	onStatusChange,
	remainingData,
}: ResultHeaderChipProps) => {
	const { t } = useTranslation(['result']);
	const [{ hasTesterPermissions }] = useGetMe();

	if (testIsStarting && hasTesterPermissions) {
		return <InitializingChip step={initializationState.step} stepName={t(initializationState.stepName)} withLabel />;
	} else if (isEditable(result) && hasTesterPermissions) {
		return <EditableRunChip resultStatusData={result} withLabel onStatusChange={onStatusChange} />;
	} else {
		return <RunChip resultStatusData={result} percentage={remainingData.runningPercent} withLabel />;
	}
};

const ResultHeader = ({
	result,
	externalUrl,
	navigation: nav,
	onEditTestResultName,
	onStatusChange,
	onStopTest,
	onTerminateTest,
}: ResultHeaderProps) => {
	const { t } = useTranslation(['result']);
	const navigate = useNavigate();
	const styleFlexCenter = { display: 'flex', alignItems: 'center' };
	const styleDivider = { display: 'flex', margin: 2 };
	const { tab = 'overview', subTab } = useParams<ResultGenericParams>();
	const searchParams = useUrlSearchParams()[2];
	const [patchResult] = usePatchV4ResultsByResultIdMutation();
	const [{ hasTesterPermissions, isGuest, isLoaded }] = useGetMe();
	const remaining = t('header.remaining');
	const testIsStarting = isStarting(result);
	const onToggleLockState = useLockResults();
	const displayLockButton = isLoaded && !isGuest;

	const initializationState = useGetInitializationState(result.id, testIsStarting);

	const [remainingData, setRemainingData] = useState<RunningData>(
		isRunning(result)
			? {
					remainingTime: timeUtils.getRemainingTime(result.startDate, result.duration),
					runningPercent: timeUtils.getRemainingTimePercent(result.startDate, result.duration),
			  }
			: { remainingTime: '', runningPercent: undefined }
	);

	useInterval(
		() => {
			setRemainingData({
				remainingTime: timeUtils.getRemainingTime(result.startDate, result.duration),
				runningPercent: timeUtils.getRemainingTimePercent(result.startDate, result.duration),
			});
		},
		isRunning(result) ? 1000 : null
	);

	const hasPrevious: boolean = nav?.previousResultId !== undefined;
	const hasNextResult: boolean = nav?.nextResultId !== undefined;

	return (
		<Grid container overflow='hidden' wrap='nowrap' padding={2} data-testid={'result-header'}>
			<Grid item xs='auto' sx={styleFlexCenter}>
				<Box sx={{ maxWidth: '1000px' }}>
					<SectionTitle
						onClickBack={(_event) => navigate(CommonRoutes.results.base)}
						title={result.name}
						description={result.description}
						label={t('header.name')}
						editable={hasTesterPermissions}
						slotProps={{
							input: {
								onApply: (editedName: Record<string, string>) => {
									onEditTestResultName(editedName.source);
								},
							},
						}}
					/>
				</Box>
				<Divider sx={styleDivider} orientation='vertical' />
			</Grid>
			<Grid item xs='auto' sx={styleFlexCenter} data-testid={'test-result-status'}>
				<ResultHeaderChip
					result={result}
					testIsStarting={testIsStarting}
					initializationState={initializationState}
					onStatusChange={onStatusChange}
					remainingData={remainingData}
				/>
			</Grid>
			<Grid item xs='auto' sx={styleFlexCenter}>
				{remainingData.remainingTime && isRunning(result) && (
					<Typography variant='caption' sx={styleTypo} data-testid={'test-result-remaining-time'}>
						{remainingData.remainingTime}
						{remaining}
					</Typography>
				)}
			</Grid>

			<Grid item xs />
			<Grid item xs='auto' sx={styleFlexCenter}>
				<ExternalUrlLink
					externalUrl={externalUrl}
					onChange={async (externalUrl: ExternalUrl) => {
						await patchResult({
							resultId: result.id,
							testResultInput: {
								externalUrl: externalUrl,
							},
						}).unwrap();
					}}
				/>
				{displayLockButton && (
					<Tooltip arrow title={tooltipUtils.getLockTooltip(result.locked, t)}>
						<IconButton
							data-testid={'toggle-deletion-protection'}
							onClick={(_event) => onToggleLockState([result.id], !result.locked)}
							color={result.locked ? 'primary' : 'default'}
						>
							{result.locked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
						</IconButton>
					</Tooltip>
				)}

				<Divider sx={styleDivider} orientation='vertical' />
				{nav && (
					<>
						<Typography marginX={1} data-testid='header-navigation'>
							{t('header.page', { page: nav.resultIdsIndex + 1, total: nav.resultIdsLength })}
						</Typography>
						<Tooltip arrow title={tooltipUtils.getPreviousTooltip(nav, hasPrevious, t)}>
							<span>
								<IconButton
									data-testid='navigateToPreviousResult'
									onClick={() => {
										if (nav.previousResultId) {
											navigate({
												pathname: CommonRoutes.results.generic(nav.previousResultId, tab, subTab),
												search: searchParams.toString(),
											});
										}
									}}
									disabled={!hasPrevious}
								>
									<ChevronLeftOutlined />
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip arrow title={tooltipUtils.getNextTooltip(nav, hasNextResult, t)}>
							<span>
								<IconButton
									data-testid='navigateToNextResult'
									onClick={() => {
										if (nav.nextResultId) {
											navigate({
												pathname: CommonRoutes.results.generic(nav.nextResultId, tab, subTab),
												search: searchParams.toString(),
											});
										}
									}}
									disabled={!hasNextResult}
								>
									<ChevronRightOutlined />
								</IconButton>
							</span>
						</Tooltip>
						<Divider sx={styleDivider} orientation='vertical' />
					</>
				)}
				{hasTesterPermissions && isStoppable(result, initializationState.step) && (
					<ResultStopButtons
						onStopTest={onStopTest}
						onTerminateTest={onTerminateTest}
						disabled={!result.startedByNlw}
						terminateUnavaible={testIsStarting}
					/>
				)}
				{hasTesterPermissions && <ResultHeaderMenu result={result} />}
			</Grid>
		</Grid>
	);
};

export { ResultHeader };
