import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { useUrlSearchParams } from '@neoload/hooks';

export type ValuesFilterProps = {
	options: string[];
	paramName: string;
	title: string;
	listCellRenderer?: (value: string) => React.ReactNode;
	renderValue?: (value: string) => string;
};
const ValuesFilter = ({ options, title, paramName, listCellRenderer, renderValue }: ValuesFilterProps) => {
	const { t } = useTranslation(['result']);
	const [urlSearchParams, setUrlSearchParams] = useUrlSearchParams(paramName);
	const theme = useTheme();

	const handleChange = (event: SelectChangeEvent<string>) => {
		const value = event.target.value;
		setUrlSearchParams({
			[paramName]: value && value !== '*' ? value : null,
		});
	};
	return (
		<Box sx={{ width: theme.spacing(30), marginLeft: theme.spacing(2) }}>
			<FormControl fullWidth variant='outlined'>
				<InputLabel id={title}>{t(title)}</InputLabel>
				<Select
					labelId={title}
					label={t(title)}
					value={urlSearchParams[paramName] ?? '*'}
					onChange={handleChange}
					sx={{ height: theme.spacing(5) }}
					data-testid={title}
					renderValue={renderValue}
				>
					<MenuItem value='*' data-testid={'option_' + title + '*'}>
						{listCellRenderer ? listCellRenderer('*') : t('filters.all')}
					</MenuItem>
					{options.map((option) => (
						<MenuItem key={option} value={option} data-testid={'option_' + title + option}>
							{listCellRenderer ? listCellRenderer(option) : option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export { ValuesFilter };
