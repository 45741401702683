import { DashboardTile } from '@neoload/api';

export const findNextPosition = (tiles: DashboardTile[], createdTile: DashboardTile) => {
	let xPos = 0;
	let yPos = 0;

	const isPositionOccupied = (x: number, y: number, width: number, height: number): boolean =>
		tiles.some(
			(tile) => x + width > tile.x && x < tile.x + tile.width && y + height > tile.y && y < tile.y + tile.height
		);

	while (isPositionOccupied(xPos, yPos, createdTile.width, createdTile.height)) {
		xPos++;
		if (xPos + createdTile.width > 4) {
			xPos = 0;
			yPos++;
		}
	}
	return [xPos, yPos];
};
