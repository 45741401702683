import { useTranslation } from 'react-i18next';
import IconPasteBelowOutlined from '@tricentis/aura/components/IconPasteBelowOutlined.js';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { useSetSnackbars } from '@neoload/hooks';

export type CopyToClipboardTokenActionProps = {
	selectedAccessTokenIds: string[];
}

export const useCopyToClipboardTokenAction = ({
	selectedAccessTokenIds,
}: CopyToClipboardTokenActionProps): DatagridAction => {
	const { showInfo } = useSetSnackbars();
	const { t } = useTranslation(['user']);

	const copyToClipboard = (token: string) => {
		navigator.clipboard.writeText(token);
		showInfo({
			text: t('token.clipboardSuccess'),
		});
	};

	const clipboard = {
		icon: <IconPasteBelowOutlined />,
		text: t('token.clipboard'),
		disabled: selectedAccessTokenIds.length !== 1,
		action: () => {
			if (selectedAccessTokenIds.length === 1) {
				copyToClipboard(selectedAccessTokenIds[0]);
			}
		},
		singleItem: (token: string) => ({
			disabled: false,
			action: () => {
				copyToClipboard(token);
			},
		}),
	};
	return clipboard;
};
