/* eslint-disable react-compiler/react-compiler */
// TODO - Fix react-compiler
import { TypedUseQuery } from '@reduxjs/toolkit/query/react';
import { useEffect, useState } from 'react';

type UseLoadAllPagesProps<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends TypedUseQuery<R, A, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	A = T extends TypedUseQuery<any, infer U, any> ? U : never,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	R extends ResultTypePaginated = T extends TypedUseQuery<infer U, any, any> ? U : never
> = {
	hook: T;
	params: Omit<A, 'pageNumber'>;
	skip: boolean;
	onAllItemsLoaded: (allItems: R['items']) => void;
};

type ResultTypePaginated = {
	items: object[];
	total: number;
	pageNumber?: number;
	pageSize?: number;
};

const DEFAULT_MAXIMUM_PAGE_SIZE = 200;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLoadAllPages = <T extends TypedUseQuery<any, any, any>>({
	hook,
	params,
	skip,
	onAllItemsLoaded,
}: UseLoadAllPagesProps<T>) => {
	const [stepPage, setStepPage] = useState(0);
	const [allItems, setAllItems] = useState<object[]>([]);
	const pageSize = params.pageSize ?? DEFAULT_MAXIMUM_PAGE_SIZE;

	const { data: page } = hook(
		{
			...params,
			pageNumber: stepPage,
			pageSize,
		},
		{ skip }
	);

	useEffect(() => {
		if (page) {
			const hasNextPage = page.items.length >= pageSize;
			if (hasNextPage) {
				setAllItems((previous) => [...previous, ...page.items]);
				// fetch next page
				setStepPage((p) => p + 1);
			} else {
				// All pages fetched, trigger download and reset state
				onAllItemsLoaded([...allItems, ...page.items]);
				setStepPage(0);
				setAllItems([]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	return {
		stepPage,
		totalPage: page ? Math.floor(page.total / pageSize) + 1 : 0,
	};
};

export { useLoadAllPages };
