import Typography, { TypographyProps } from '@mui/material/Typography';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { ComponentPropsWithoutRef, useRef, useState, MouseEvent } from 'react';

export type TextEllipsisTooltipProps = Omit<
	ComponentPropsWithoutRef<typeof Tooltip>,
	'open' | 'arrow' | 'children' | 'variant'
> &
	Pick<TypographyProps, 'variant'>;

export const TextEllipsisTooltip = ({ variant: variantTypography, ...props }: TextEllipsisTooltipProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [overflowing, setOverflowing] = useState(false);
	const handleOverflowing = (event: MouseEvent<HTMLDivElement>) => {
		if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
			setOverflowing(true);
		}
	};
	return (
		<Tooltip {...props} open={overflowing} onClose={() => setOverflowing(false)} arrow>
			<Typography
				onMouseEnter={handleOverflowing}
				variant={variantTypography}
				ref={ref}
				sx={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				{props.title}
			</Typography>
		</Tooltip>
	);
};
