import {
	BaseQueryFn,
	FetchArgs,
	QueryActionCreatorResult,
	QueryDefinition,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { FetchError, FetchResult, LoadingState } from './types';

export const startFetching = async <Data, Identifier, Context, Result extends FetchResult<Data, Identifier, Context>>(
	fetchTriggers: {
		fetchId: Identifier;
		trigger: QueryActionCreatorResult<
			QueryDefinition<
				unknown,
				BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
				string,
				Data,
				'apiV4'
			>
		>;
	}[],
	context: Context
): Promise<Result[]> => {
	const results = await Promise.allSettled(fetchTriggers.map((fetchTrigger) => fetchTrigger.trigger)).then(
		(settledResults) =>
			settledResults.map((settledResult) => {
				if (settledResult.status === 'rejected') {
					return {
						error: {
							error: settledResult.reason,
							status: 'CUSTOM_ERROR',
						} as FetchError,
					};
				} else if (settledResult.value.isError) {
					return {
						error: settledResult.value.error,
					};
				} else if (!settledResult.value.data) {
					return {
						error: {
							error: 'Missing result data',
							status: 'CUSTOM_ERROR',
						} as FetchError,
					};
				}
				return {
					data: settledResult.value.data,
				};
			})
	);
	return fetchTriggers.map((fetchTrigger, index) => {
		const result = results.at(index);
		if (result) {
			if (result.data) {
				return {
					id: fetchTrigger.fetchId,
					data: result.data,
					context,
				} as Result;
			} else if (result.error) {
				return {
					id: fetchTrigger.fetchId,
					error: result.error,
				} as Result;
			}
		}
		throw new Error('Unexpected error while fetching data');
	});
};

export const computeLoadingState = (loadingStates: LoadingState[]): LoadingState => {
	if (loadingStates.every((result) => result === 'UNLOADED')) {
		return 'UNLOADED';
	}
	if (loadingStates.every((result) => result === 'LOADING')) {
		return 'LOADING';
	}
	if (loadingStates.every((result) => result === 'LOADED')) {
		return 'LOADED';
	}
	return 'PARTIALLY_LOADED';
};
