import FileSaver from 'file-saver';

type EntryOf<T> = {
	[K in keyof T]-?: [K, Exclude<T[K], undefined>];
}[keyof T];

type EntriesOf<T extends object> = EntryOf<T>[];

const entriesOf = <T extends object>(object: T) => Object.entries(object) as EntriesOf<T>;

type PropertyConverter<T, P extends keyof T> = {
	/** The CSV column name */
	columnName: string;
	/** The function to convert the value for the item property to string */
	convert: (value: T[P]) => string;
};

export type ConverterConfig<T> = {
	[P in keyof T]?: PropertyConverter<T, P>;
};

export const NEW_LINE = '\n';
export const CSV_SEPARATOR = ';';
export const FILE_TYPE = 'text/csv;charset=utf-8';
const QUOTE = '"';
const QUOTE_REGEX = /"/g;
const ESCAPED_QUOTE = QUOTE + QUOTE;

export const csvEscape = (value: string): string =>
	value ? QUOTE + value.replaceAll(QUOTE_REGEX, ESCAPED_QUOTE) + QUOTE : '';

/**
 * Build a CSV file from items and trigger the download of the file in the browser
 *
 * @param converterConfig The conversion from item properties to the CSV columns
 * @param items the List of items to be in the CSV
 * @param fileName The CSV file name to be downloaded
 */
export const downloadAsCsv = <T extends object>(converterConfig: ConverterConfig<T>, items: T[], fileName: string) => {
	const entries = entriesOf<ConverterConfig<T>>(converterConfig);
	const headerString = entries
		.filter((tuple) => tuple !== undefined)
		.map(([_key, value]) => value.columnName)
		.map(csvEscape)
		.join(CSV_SEPARATOR);

	const blob = new Blob([headerString, ...items.flatMap((item) => [NEW_LINE, itemToCsvLine(entries, item)])], {
		type: FILE_TYPE,
	});
	FileSaver.saveAs(blob, fileName);
};

const itemToCsvLine = <T>(entries: EntriesOf<ConverterConfig<T>>, item: T): string =>
	entries
		.filter((tuple) => tuple !== undefined)
		.map(([key, value]) => value.convert(item[key]))
		.map(csvEscape)
		.join(CSV_SEPARATOR);
