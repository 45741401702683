import Paper from '@mui/material/Paper';
import { ComponentProps } from 'react';

type ColorIconProps = {
	/**
	 * Color of the icon
	 */
	color: string;
	/**
	 * Size (width and height) in MUI theme spacing unit.
	 * @default 3
	 */
	size?: number;
	/**
	 * Elvation of the icon: create a shadow around it.
	 * @default 1
	 */
	elevation?: ComponentProps<typeof Paper>['elevation'];
	/**
	 * `data-testid` that will be set on the icon.
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	'data-testid'?: string;
};
const ColorIcon = ({ color, 'data-testid': dataTestId, elevation = 1, size = 3 }: ColorIconProps) => (
	<Paper
		elevation={elevation}
		data-testid={dataTestId}
		sx={{
			backgroundColor: color,
			height: (theme) => theme.spacing(size),
			width: (theme) => theme.spacing(size),
		}}
	/>
);

export { ColorIcon };
