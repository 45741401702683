import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Link as RouterLink } from 'react-router-dom';
import ChipGroup from '@tricentis/aura/components/ChipGroup.js';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { DetailInfo } from './detail-info';
import { styleDivider, styleFlexCenter, styleTypo } from './styles-utils';
import { ResultDetailsMenu } from './result-details-menu';
import { tooltipUtils } from '../tooltip-utils';
import { isStarting, hasNotStarted, isRunning, TestRoutes, timeUtils } from '@neoload/utils';
import { TestResult } from '@neoload/api';

export type ResultDetailsProps = {
	result: Pick<
		TestResult,
		| 'startDate'
		| 'endDate'
		| 'duration'
		| 'status'
		| 'test'
		| 'vusCount'
		| 'scenario'
		| 'project'
		| 'terminationReason'
		| 'author'
		| 'lgCount'
		| 'debug'
	>;
	zones?: string[];
};

const ResultDetails = ({ result, zones }: ResultDetailsProps) => {
	const { test, startDate, duration, vusCount, scenario, project, endDate, author } = result;
	const { t } = useTranslation(['result']);
	const theme = useTheme();

	const getDuration = useCallback(
		() => (isRunning(result) ? timeUtils.getElapsedTimeUntilNow(startDate) : timeUtils.durationFull(duration)),
		[result, startDate, duration]
	);

	const [elapsedTime, setElapsedTime] = useState(getDuration());

	useEffect(() => {
		setElapsedTime(getDuration());
	}, [getDuration]);

	useInterval(
		() => {
			setElapsedTime(timeUtils.getElapsedTimeUntilNow(startDate));
		},
		isRunning(result) ? 1000 : null
	);

	const testIsStarting = isStarting(result);

	return (
		<Box>
			<Grid sx={{ flexDirection: 'row', display: 'flex' }} paddingBottom={1}>
				{test && (
					<Grid item sx={styleFlexCenter}>
						<Typography variant='body2' color='text.secondary' sx={styleTypo} id='test-name'>
							{t('details.test')}
						</Typography>
						<Link
							component={RouterLink}
							to={TestRoutes.test(test.id)}
							variant='body2'
							sx={{
								maxWidth: 250,
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							title={test.name}
							aria-labelledby='test-name'
						>
							{test.name}
						</Link>
						<Divider sx={styleDivider} orientation='vertical' />
					</Grid>
				)}
				<Grid item sx={styleFlexCenter} marginRight={2}>
					<Tooltip arrow title={tooltipUtils.getStartDateToolTip(author, t)}>
						<span>
							<DetailInfo id='started' title={t('details.started')} value={timeUtils.dateTimeAbsolute(startDate)} />
						</span>
					</Tooltip>
					<Tooltip arrow title={tooltipUtils.getDurationToolTip(endDate, t)}>
						<span>
							<DetailInfo id='duration' title={t('details.duration')} value={elapsedTime} loading={testIsStarting} />
						</span>
					</Tooltip>
					<DetailInfo id='vus' title={t('details.vus')} value={vusCount} />
					<DetailInfo id='scenario' title={t('details.scenario')} value={scenario} />
					<DetailInfo id='project' title={t('details.project')} value={project} />
					{zones && !hasNotStarted(result) && (
						<>
							<DetailInfo
								id='zone'
								title={t('details.zone', { count: zones.length })}
								value={zones.at(0) ?? ''}
								loading={testIsStarting}
							/>
							{!testIsStarting && zones.length > 1 && (
								<ChipGroup
									sx={(theme) => ({ width: 35, height: theme.spacing(2.5), marginLeft: 1 })}
									plusSx={{ backgroundColor: 'transparent' }}
									chips={zones.slice(1).map((zone) => ({
										label: zone,
										key: zone,
										size: 'small',
										sx: {
											backgroundColor: 'transparent',
											borderColor: 'transparent',
											fontSize: theme.typography.body2.fontSize,
										},
									}))}
								/>
							)}
						</>
					)}
				</Grid>
				<ResultDetailsMenu result={result} zones={zones} />
			</Grid>
		</Box>
	);
};

export { ResultDetails };
