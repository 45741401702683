import Grid from '@mui/material/Grid';
import { StatisticsBoxItem, StatisticsBoxItemProps } from './statistics-box-item';

const DoubleColumnRow = ({
	leftRow,
	rightRow,
}: {
	leftRow: StatisticsBoxItemProps;
	rightRow: StatisticsBoxItemProps;
}) => (
	<Grid
		container
		sx={{
			minWidth: 200,
		}}
		spacing={0}
		wrap='nowrap'
	>
		<Grid item sm={12} paddingTop={1}>
			<StatisticsBoxItem {...leftRow} />
		</Grid>
		<Grid item sm={12} paddingTop={1}>
			<StatisticsBoxItem {...rightRow} />
		</Grid>
	</Grid>
);

export { DoubleColumnRow };
