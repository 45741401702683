import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { InvalidPropertyValueError } from '@neoload/api';

type RtkErrorResponse = FetchBaseQueryError | SerializedError | undefined;

enum NeoLoadErrorType {
	Forbidden = 'forbidden',
	NotFound = 'notFound',
	ServerError = 'serverError',
	ServerNotReached = 'serverNotReached',
	Error = 'error',
}

type NeoLoadError = {
	type: NeoLoadErrorType;
	httpCode?: number;
	customMessageI18nKey?: 'badIdFormat';
	cause: Error;
};

const isNeoLoadError = (error: unknown): error is NeoLoadError =>
	error instanceof Object && 'type' in error && 'cause' in error;

const toNeoLoadType = (httpStatus: number): NeoLoadErrorType => {
	switch (httpStatus) {
		case 403: {
			return NeoLoadErrorType.Forbidden;
		}
		case 404: {
			return NeoLoadErrorType.NotFound;
		}
		default: {
			return NeoLoadErrorType.ServerError;
		}
	}
};

/**
 * Convert error into a NeoLoadError.
 *
 * NeoLoadErrors are catched by the router and display an Error page according to its NeoLoadErrorType.
 *
 * Usage : see `libs/utils/README.md`
 *
 * @param error An error returned by the API client
 */
const createNeoLoadError = (error?: RtkErrorResponse): NeoLoadError => {
	if (error && 'status' in error && typeof error.status === 'number') {
		return {
			type: toNeoLoadType(error.status),
			httpCode: error.status,
			cause: new Error(JSON.stringify(error)),
		};
	}
	return {
		type: NeoLoadErrorType.ServerNotReached,
		cause: new Error(JSON.stringify(error)),
	};
};

const isNotFoundError = (error: RtkErrorResponse): boolean =>
	error !== undefined && 'status' in error && error.status === 404;

const isV4InvaliPropertyError = (
	error: RtkErrorResponse
): error is {
	status: 400;
	data: InvalidPropertyValueError;
} => error !== undefined && 'status' in error && error.status === 400;

export {
	NeoLoadErrorType,
	NeoLoadError,
	RtkErrorResponse,
	isNeoLoadError,
	toNeoLoadType,
	createNeoLoadError,
	isNotFoundError,
	isV4InvaliPropertyError,
};
