import DGColumnContextMenuButton from '@tricentis/aura/components/DGColumnContextMenuButton.js';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { DatagridAction, filterToContextMenuItems, toSingleItemAction } from './data-grid-actions';

export type DatagridActionCellProps<T extends GridRowModel> = {
	actions: DatagridAction[];
	rowData: T;
}
const ActionsCellMenu = <T extends GridRowModel>({ actions, rowData }: DatagridActionCellProps<T>) => (
	<DGColumnContextMenuButton
		rowData={rowData}
		contextMenu={filterToContextMenuItems(actions.map((action) => toSingleItemAction(action, rowData)))}
	/>
);

export { ActionsCellMenu };
