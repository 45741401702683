import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { ComponentPropsWithoutRef } from 'react';

type GraphLegendProps = {
	title: string;
	value: string;
	markerType: 'circle' | 'square';
	color: ComponentPropsWithoutRef<typeof Alert>['severity'];
};

const ResultOverviewGraphLegend = ({ title, value, markerType, color = 'info' }: GraphLegendProps) => (
	<Alert severity={color} icon={false} sx={{ marginBottom: 1, padding: 0.5, '& .MuiAlert-message': { padding: 0.5 } }}>
		<Typography variant='body2' sx={{ paddingBottom: 0.5 }}>
			<Box
				component='span'
				sx={(theme) => ({ color: theme.palette[color].main, marginRight: '8px', fontSize: '21px' })}
			>
				{markerType === 'circle' && '●'}
				{markerType === 'square' && '■'}
			</Box>
			<Box component='span' sx={{ color: 'text.primary' }}>
				{title}
			</Box>
		</Typography>
		<Typography variant='h5' sx={{ color: 'text.primary' }}>
			{value}
		</Typography>
	</Alert>
);

export { ResultOverviewGraphLegend };
