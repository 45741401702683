import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import welcomeTileHero from '../../../../assets/home/welcome-tile.svg';
import { useGetMe } from '@neoload/hooks';

export const WelcomeTile = () => {
	const { t } = useTranslation(['home']);
	const [me] = useGetMe();
	return (
		<Box
			height={'100%'}
			sx={{
				background: 'linear-gradient(270deg, #FFFFFF, #F2F1FF, #9BC1FF)',
				borderRadius: '4px',
			}}
		>
			<Stack direction={'row'} alignItems={'center'} height={'100%'}>
				<Box
					sx={{
						flex: 1,
						textAlign: 'center',
						paddingX: (theme) => theme.spacing(8),
					}}
				>
					<Box
						component='img'
						src={welcomeTileHero}
						alt={'Tricentis hero illustration'}
						sx={{
							maxHeight: '250px',
							width: '100%',
						}}
					/>
				</Box>
				<Box
					sx={{
						flex: 1,
						paddingX: (theme) => theme.spacing(8),
					}}
				>
					<Typography variant='h4'>
						{me.isLoaded ? (
							t('grid.tile.welcome.hello', {
								name: me.firstName ?? me.lastName ?? '?',
							})
						) : (
							<Skeleton role='progressbar' />
						)}
					</Typography>
					<Typography variant='body1'>{t('grid.tile.welcome.sentence')}</Typography>
				</Box>
			</Stack>
		</Box>
	);
};
