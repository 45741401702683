import { CSV_SEPARATOR, csvEscape, FILE_TYPE, NEW_LINE } from '../../../../common/data-grid/csv/download-as-csv';
import { BaseCell, Column, ComparisonDataCell, StatusCell, TextCell } from '../../../../common/table-grid';
import { downloadFile } from '@neoload/utils';

export type TableData = {
	columns: Column[];
	values: BaseCell[][];
};

export const downloadTableAsCsv = (filename: string, tableData: TableData | undefined) => {
	if (tableData) {
		const csvString = tableDataToCsvString(tableData);
		const fileName = filename + '.csv';
		downloadFile({
			data: csvString,
			fileName,
			fileType: FILE_TYPE,
		});
	}
};

const getCellValue = (cell: BaseCell): string => {
	switch (cell.type) {
		case 'status': {
			return (cell as StatusCell).status;
		}
		case 'comparison': {
			return (cell as ComparisonDataCell).value;
		}
		default: {
			return (cell as TextCell).text;
		}
	}
};

const tableDataToCsvString = (tableData: TableData): string => {
	const firstRow = tableData.columns
		.map((column) => column.label)
		.map(csvEscape)
		.join(CSV_SEPARATOR);
	const remainingRows = tableData.values.map((table) =>
		table
			.map((cell) => getCellValue(cell))
			.map(csvEscape)
			.join(CSV_SEPARATOR)
	);
	return [firstRow, ...remainingRows].join(NEW_LINE);
};
