import Popper from '@mui/material/Popper';
import { ComponentProps, ReactNode } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';

type PopperWrapperProps = ComponentProps<typeof Popper> & {
	intervalRect: DOMRect;
	onClickAway: () => void;
	offset?: number;
	children: ReactNode;
	chartContainer?: HTMLElement;
};

const PopperWrapper = (intervalPopperProps: PopperWrapperProps) => {
	const { intervalRect, onClickAway, offset, children, chartContainer } = intervalPopperProps;

	return (
		<ClickAwayListener
			onClickAway={(event) => {
				const target = event.target;

				// this specific condition happens when a Select is clicked. Selects use portals and therefore menus are not children of the popper
				// Disabling portals makes the menu positionning wrong
				// Since the text type in RichTextEditor is the only select clickable from interval edition
				// we ignore the clickAway event if this condition is trigered from edition
				const selectClicked = (event.target as HTMLElement).tagName === 'BODY';

				//this condition is to not close the popper when drag n dropping (or even clicking on the graph, as user would
				//lose its edition for missing the handles by a few pixels)
				//Clicks outside the graph will close the popper
				const editedGraphClicked = target instanceof Node && chartContainer?.contains(target);
				if (!intervalPopperProps.open || editedGraphClicked || selectClicked) {
					return;
				}
				onClickAway();
			}}
		>
			<Popper
				{...intervalPopperProps}
				data-testid='interval-popper'
				autoFocus={false}
				sx={{
					zIndex: 1299,
					paddingBottom: 1,
				}}
				anchorEl={{
					getBoundingClientRect: () => intervalRect,
					nodeType: Node.ELEMENT_NODE,
				}}
				modifiers={[
					{
						name: 'offset',
						options: {
							offset: [offset ?? 0, 0],
						},
					},
					// This modifier is used to moderate the fact that the popper go outside of the window when we
					// edit the Request chart (the one in the bottom) and we click on the "Description" tab.
					// With the 'document' value for the rootBoundary the popper is going outside of the window but
					// we can scroll to see it fully.
					// If we put the value 'viewport' instead, the popper will be moved up as soon as we will scroll.
					{
						name: 'preventOverflow',
						enabled: true,
						options: {
							altAxis: true,
							rootBoundary: 'document',
						},
					},
				]}
			>
				<Paper elevation={5}>{children}</Paper>
			</Popper>
		</ClickAwayListener>
	);
};

export { PopperWrapper };
