import { useState, useEffect } from 'react';
import { FetchResult, LoadingState } from './types';
import { startFetching, computeLoadingState } from './fetcher-utils';
import { useReloading } from './use-reloading';
import {
	DashboardFilter,
	DashboardSeriesStatistic,
	ElementsPercentilesFilter,
	ElementsTimeseriesFilter,
	FetchElementPercentilesResponse,
	MonitorTimeSeries,
	MonitorsTimeseriesFilter,
	ElementTimeSeries,
	extractElementStatisticName,
	useLazyGetV4ResultsByResultIdElementsAndElementIdPercentilesQuery,
	useLazyGetV4ResultsByResultIdElementsAndElementIdTimeseriesQuery,
	useLazyGetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesQuery,
	DashboardSeries,
} from '@neoload/api';

type DashboardFilterType = DashboardFilter['type'];

export type SeriesIdentifier = {
	seriesId: string;
};
export type SeriesContext<FilterType extends DashboardFilterType> = {
	filterType: FilterType;
};

type UseElementTimeseriesFetcherResult = FetchResult<
	ElementTimeSeries,
	SeriesIdentifier,
	SeriesContext<'ELEMENTS_TIMESERIES'>
>;
type UseMonitorTimeseriesFetcherResult = FetchResult<
	MonitorTimeSeries,
	SeriesIdentifier,
	SeriesContext<'MONITORS_TIMESERIES'>
>;
type UseElementPercentilesFetcherResult = FetchResult<
	FetchElementPercentilesResponse,
	SeriesIdentifier,
	SeriesContext<'ELEMENTS_PERCENTILES'>
>;

type Element = {
	id: string;
	resultId: string;
	filter: ElementsTimeseriesFilter;
	statistic: DashboardSeriesStatistic;
};

type Monitor = {
	id: string;
	resultId: string;
	filter: MonitorsTimeseriesFilter;
};
type Percentile = {
	id: string;
	resultId: string;
	filter: ElementsPercentilesFilter;
};

type FetcherData = Element | Monitor | Percentile;

function useElementTimeseriesFetcher(
	seriesList: {
		id: string;
		resultId: string;
		filter: ElementsTimeseriesFilter;
		statistic: DashboardSeriesStatistic;
	}[],
	shouldStartFetching: boolean
) {
	const filterType: DashboardFilterType = 'ELEMENTS_TIMESERIES';
	const [results, setResults] = useState<UseElementTimeseriesFetcherResult[]>(
		seriesList.map((that) => ({ id: { seriesId: that.id }, data: undefined, context: { filterType } }))
	);
	const [loadingState, setLoadingState] = useState<LoadingState>('UNLOADED');
	const [trigger] = useLazyGetV4ResultsByResultIdElementsAndElementIdTimeseriesQuery();
	const reload = useReloading<FetcherData>(seriesList);
	useEffect(() => {
		if (reload) {
			setLoadingState('UNLOADED');
		}
	}, [reload]);

	useEffect(() => {
		if (!shouldStartFetching) {
			return;
		}
		if (loadingState === 'UNLOADED') {
			setLoadingState('LOADING');
			const fetchTriggers = seriesList.map((that) => ({
				fetchId: { seriesId: that.id },
				trigger: trigger(
					{
						resultId: that.resultId,
						elementId: that.filter.elementId,
						userPathId: that.filter.userPathId,
						statistics: [extractElementStatisticName(that.statistic)],
					},
					true
				),
			}));
			startFetching<
				ElementTimeSeries,
				SeriesIdentifier,
				SeriesContext<'ELEMENTS_TIMESERIES'>,
				UseElementTimeseriesFetcherResult
			>(fetchTriggers, { filterType })
				.then(setResults)
				.finally(() => setLoadingState('LOADED'));
		}
	}, [shouldStartFetching, seriesList, trigger, loadingState, reload]);

	return [results, loadingState] as const;
}

function useMonitorTimeseriesFetcher(
	seriesList: {
		id: string;
		resultId: string;
		filter: MonitorsTimeseriesFilter;
	}[],
	shouldStartFetching: boolean
) {
	const filterType: DashboardFilterType = 'MONITORS_TIMESERIES';
	const [results, setResults] = useState<UseMonitorTimeseriesFetcherResult[]>(
		seriesList.map((that) => ({ id: { seriesId: that.id }, data: undefined, context: { filterType } }))
	);
	const [loadingState, setLoadingState] = useState<LoadingState>('UNLOADED');
	const [trigger] = useLazyGetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesQuery();
	const reload = useReloading<FetcherData>(seriesList);
	useEffect(() => {
		if (reload) {
			setLoadingState('UNLOADED');
		}
	}, [reload]);

	useEffect(() => {
		if (!shouldStartFetching) {
			return;
		}
		if (loadingState === 'UNLOADED') {
			setLoadingState('LOADING');
			const fetchTriggers = seriesList.map((that) => ({
				fetchId: { seriesId: that.id },
				trigger: trigger(
					{
						resultId: that.resultId,
						monitorId: that.filter.monitorId,
					},
					true
				),
			}));
			startFetching<
				MonitorTimeSeries,
				SeriesIdentifier,
				SeriesContext<'MONITORS_TIMESERIES'>,
				UseMonitorTimeseriesFetcherResult
			>(fetchTriggers, { filterType })
				.then(setResults)
				.finally(() => setLoadingState('LOADED'));
		}
	}, [shouldStartFetching, seriesList, trigger, loadingState]);

	return [results, loadingState] as const;
}

function useElementPercentilesFetcher(
	seriesList: {
		id: string;
		resultId: string;
		filter: ElementsPercentilesFilter;
	}[],
	shouldStartFetching: boolean
) {
	const filterType: DashboardFilterType = 'ELEMENTS_PERCENTILES';
	const [results, setResults] = useState<UseElementPercentilesFetcherResult[]>(
		seriesList.map((that) => ({ id: { seriesId: that.id }, data: undefined, context: { filterType } }))
	);
	const [loadingState, setLoadingState] = useState<LoadingState>('UNLOADED');
	const [trigger] = useLazyGetV4ResultsByResultIdElementsAndElementIdPercentilesQuery();
	const reload = useReloading(seriesList);
	useEffect(() => {
		if (reload) {
			setLoadingState('UNLOADED');
		}
	}, [reload]);
	useEffect(() => {
		if (!shouldStartFetching) {
			return;
		}
		if (loadingState === 'UNLOADED') {
			setLoadingState('LOADING');
			const fetchTriggers = seriesList.map((that) => ({
				fetchId: { seriesId: that.id },
				trigger: trigger(
					{
						resultId: that.resultId,
						elementId: that.filter.elementId,
						userPathId: that.filter.userPathId,
					},
					true
				),
			}));
			startFetching<
				FetchElementPercentilesResponse,
				SeriesIdentifier,
				SeriesContext<'ELEMENTS_PERCENTILES'>,
				UseElementPercentilesFetcherResult
			>(fetchTriggers, { filterType })
				.then(setResults)
				.finally(() => setLoadingState('LOADED'));
		}
	}, [shouldStartFetching, seriesList, trigger, loadingState]);

	return [results, loadingState] as const;
}

export function useSeriesDataFetcher(seriesList: DashboardSeries[], shouldStartFetching: boolean) {
	const [elementTimeseriesResults, elementTimeseriesLoadingState] = useElementTimeseriesFetcher(
		seriesList
			.filter((series) => series.filter.type === 'ELEMENTS_TIMESERIES')
			.map((series) => ({
				id: series.id,
				filter: series.filter as ElementsTimeseriesFilter,
				resultId: series.resultId,
				statistic: series.statistic,
			})),
		shouldStartFetching
	);
	const [monitorTimeseriesResults, monitorTimeseriesLoadingState] = useMonitorTimeseriesFetcher(
		seriesList
			.filter((series) => series.filter.type === 'MONITORS_TIMESERIES')
			.map((series) => ({
				id: series.id,
				filter: series.filter as MonitorsTimeseriesFilter,
				resultId: series.resultId,
				statistic: series.statistic,
			})),
		shouldStartFetching
	);
	const [elementPercentilesResults, elementPercentilesLoadingState] = useElementPercentilesFetcher(
		seriesList
			.filter((series) => series.filter.type === 'ELEMENTS_PERCENTILES')
			.map((series) => ({
				id: series.id,
				filter: series.filter as ElementsPercentilesFilter,
				resultId: series.resultId,
				statistic: series.statistic,
			})),
		shouldStartFetching
	);

	const results = [...elementTimeseriesResults, ...monitorTimeseriesResults, ...elementPercentilesResults] as const;

	const loadingState = computeLoadingState([
		elementTimeseriesLoadingState,
		monitorTimeseriesLoadingState,
		elementPercentilesLoadingState,
	]);

	return [results, loadingState] as const;
}
