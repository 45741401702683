import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { timeUtils } from '@neoload/utils';

export type ScenarioParametersProps = {
	virtualUsers?: number;
	duration?: string;
};

const ScenarioParameters = ({ virtualUsers, duration }: ScenarioParametersProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['test']);
	return (
		<Box
			data-testid='scenarioParameters'
			display='flex'
			flexDirection={'column'}
			rowGap={'8px'}
			sx={{ padding: '8px' }}
		>
			<Typography variant='subtitle1'>{t('configuration.scenarios.parameters')}</Typography>
			<Box display='flex' flexDirection={'row'} sx={{ gap: '8px' }}>
				<Box sx={{ color: theme.palette.text.secondary }} display='flex' flexDirection={'column'} rowGap={'8px'}>
					<Typography variant='body1'>{t('configuration.scenarios.virtualUsers')}</Typography>
					<Typography variant='body1'>{t('configuration.scenarios.duration')}</Typography>
				</Box>
				<Box display='flex' flexDirection={'column'} rowGap={'8px'}>
					<Typography variant='body1'>{virtualUsers ?? t('configuration.scenarios.undetermined')}</Typography>{' '}
					<Typography variant='body1'>
						{duration ? timeUtils.durationFull(duration) : t('configuration.scenarios.undetermined')}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
export { ScenarioParameters };
