const user = {
	//User properties
	description:
		'The user view allows you to invite new users to the platform, assign them to one or more workspaces, and delete them.',
	email: 'Email',
	firstName: 'First Name',
	lastName: 'Last Name',
	role: 'Role',
	lastLogin: 'Last Login',
	workspaces: 'Workspaces',
	status: 'Status',
	resendInvitation: 'Resend invitation',

	create: {
		success_one: 'Invitation sent to {{name}}',
		success_other: '{{count}} invitations sent',

		error_one: 'Invitation has not been sent: {{cause}}',
		error_other: '{{count}} invitations have not been sent: {{cause}}',
	},
	update: {
		success_one: 'User {{name}} has been updated',
		error_one: 'User has not been updated : {{cause}}',
	},
	delete: {
		success_one: 'User {{name}} has been deleted',
		success_other: '{{count}} users have been deleted',

		error_one: 'One user has not been deleted : {{cause}}',
		error_other: '{{count}} users have not been deleted',
	},

	userWorkspacesChanged: 'User {{userName}} workspaces changed',

	userRole: {
		accountAdmin: 'Account Admin',
		neoloadAdmin: 'Neoload Admin',
		tester: 'Tester',
		guest: 'Guest',
		none: 'None',
	},
	userStatus: {
		active: 'Active',
		pending: 'Pending',
	},

	//User role
	accountAdmin: 'Account Admin',
	neoloadAdmin: 'Neoload Admin',
	tester: 'Tester',
	guest: 'Guest',
	none: 'None',

	invite: 'Invite User',
	inviteDialog: {
		title: 'Invite user to {{accountName}}',
		emailsField: 'Email address',
		emailsFieldPlaceholder: 'person1@mycompany.com{{separator}}person2@mycompany.com',
		emailsFieldHelper: 'Invite multiple users using "{{separator}}" as separator.',
		emailsFieldError: {
			invalid: 'Invalid email address. Enter one or more valid email addresses separated by "{{separator}}"',
		},
		roleField: 'Role',
		roleFieldError: {
			required: 'A valid role is required.',
		},
		okButton: 'Send invite',
	},
	inviteError: {
		emailUniqueness_one: 'email is already used by a user.',
		emailUniqueness_other: 'emails are already used by other users.',
	},

	deleteButton: 'Delete user',

	deleteSingleUserDialog: {
		title: 'Delete {{user}}',
		label: 'Are you sure you want to delete the user : {{user}}?',
	},
	deleteMultipleUserDialog: {
		title: 'Delete {{userNumber}} users',
		label: 'Are you sure you want to delete those {{userNumber}} users?',
	},

	// User Tokens
	token: {
		description: 'Manage Access tokens to connect NeoLoad with NeoLoad Web and use NeoLoad Web API',
		token: 'Access token',
		tokens: 'Access tokens',
		name: 'Name',
		generate: 'Generate token',
		show: 'Show access token',
		hide: 'Hide access token',
		delete: 'Delete access token',
		deleteDialogHeader_one: 'Deleting access token',
		deleteDialogHeader_other: 'Deleting access tokens',
		deleteDialogContent_one: 'Are you sure you want to delete the selected access token?',
		deleteDialogContent_other: 'Are you sure you want to delete {{count}} access tokens?',
		clipboard: 'Copy to clipboard',
		clipboardSuccess: 'Access token copied to clipboard.',
		configureNlG: 'Configure NeoLoad with this token',
		dialog: {
			create: {
				name: 'Name',
				createButton: 'Generate and copy',
			},
		},
		crud: {
			create: {
				success_one: 'Access token {{name}} generated and copied to clipboard.',
				error_one: 'Failed to generate the access token : {{cause}}',
			},
			delete: {
				success_one: 'Access token deleted.',
				success_other: '{{count}} access tokens deleted.',

				error_one: 'Failed to delete an access token : {{cause}}',
				error_other: 'Failed to delete {{count}} access tokens.',
			},
		},

		error: {
			nameRequired: 'Token name is required',
		},
	},
};
export { user };
