import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useGetV4TestsByTestIdProjectQuery, useGetV4TestsByTestIdQuery, useGetV4ZonesQuery } from '@neoload/api';
import { createNeoLoadError, TestGenericParams, validateUuid } from '@neoload/utils';
import { usePushDocumentTitleHandler } from '@neoload/hooks';
import {
	ConfigurationFormData,
	ConfigurationProjectScenarioFormData,
	ConfigurationZoneFormData,
	ConfigurePage,
	ScenarioTypes,
	Spinner,
	TestView,
} from '@neoload/ui';

function getScenarioType(testName: string, selectedScenarioName?: string) {
	if (!selectedScenarioName || selectedScenarioName === testName) {
		return ScenarioTypes.Custom;
	}
	return ScenarioTypes.FromProject;
}

type TestPageProps = {
	configure?: boolean;
};

const TestPage = ({ configure = false }: TestPageProps) => {
	const { testId = '' } = useParams<TestGenericParams>();
	validateUuid(testId);
	const { data: test, error: testError, isLoading: isLoadingTest } = useGetV4TestsByTestIdQuery({ testId });
	const { data: zonesPageData = { items: [], total: 0 }, isLoading: isLoadingZones } = useGetV4ZonesQuery({});
	const {
		data: projectData = {
			name: '',
			updatedAt: '',
			size: undefined,
			uploadSettings: undefined,
			userModifierName: undefined,
			scenarios: [],
			total: 0,
		},
		isLoading: isLoadingProject,
		isError: isErrorProject,
	} = useGetV4TestsByTestIdProjectQuery({
		testId: testId,
	});

	usePushDocumentTitleHandler(test?.name);

	if (isLoadingTest || isLoadingZones || isLoadingProject) {
		return <Spinner />;
	}
	if (!test) {
		throw createNeoLoadError();
	}
	if (testError) {
		throw createNeoLoadError(testError);
	}

	const buildConfigurationFormData = (): ConfigurationFormData => {
		const zones = zonesPageData.items;
		const projectScenarios = projectData.scenarios;
		const zoneTab: ConfigurationZoneFormData[] = [];
		const scenarioTab: ConfigurationProjectScenarioFormData[] = [];

		for (const { name, zoneId, type } of zones) {
			const zone: ConfigurationZoneFormData = {
				zoneId: zoneId,
				number: test.lgsByZone?.[zoneId] ?? 0,
				type: type,
				name: name,
			};
			zoneTab.push(zone);
		}
		for (const { maximumVu, name, duration } of projectScenarios ?? []) {
			const scenarioData: ConfigurationProjectScenarioFormData = {
				duration: duration,
				maximumVu: maximumVu,
				name: name,
				type: ScenarioTypes.FromProject,
			};
			scenarioTab.push(scenarioData);
		}

		const controllerZone = zones.filter((z) => z.zoneId === test.controllerZoneId);

		return {
			dragActive: false,
			file: undefined,
			configurationDisabled: isErrorProject,
			zones: {
				items: zoneTab,
				type: controllerZone.length === 1 ? controllerZone[0].type : 'NCP',
				controller: test.controllerZoneId ?? '',
			},
			scenario: {
				type: getScenarioType(test.name, test.selectedScenarioName),
				selected: scenarioTab.find((value) => value.name === test.selectedScenarioName) ?? scenarioTab[0],
				fromProject: scenarioTab,
			},
			test: {
				id: test.id,
				name: test.name,
				isConfigured: test.isConfigured,
				updatedAt: test.updatedAt,
				userModifierName: test.userModifierName,
				description: test.description,
			},
			project: {
				name: projectData.name,
				source: projectData.uploadSettings?.source,
				updatedAt: projectData.updatedAt,
				size: projectData.size,
				userModifierName: projectData.userModifierName,
			},
			testResultMask: test.testResultNameTemplate,
		};
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			{configure ? (
				<ConfigurePage testInfos={buildConfigurationFormData()} />
			) : (
				<TestView testInfos={buildConfigurationFormData()} />
			)}
		</Box>
	);
};

export { TestPage };
