import * as React from 'react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TableChartOutlined from '@mui/icons-material/TableChartOutlined';
import FormatSizeOutlined from '@mui/icons-material/FormatSizeOutlined';
import LineAxisOutlined from '@mui/icons-material/LineAxisOutlined';
import Menu from '@mui/material/Menu';
import WidgetsOutlined from '@mui/icons-material/WidgetsOutlined';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { CreateResourceButton } from '../../../../common/button/create-resource-button';
import { useFeatureEnabled, useUrlSearchParams } from '@neoload/hooks';

export const TileCreationPopover = () => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const [, setUrlSearchParams] = useUrlSearchParams('tileToEdit');
	const { t } = useTranslation(['dashboard']);
	const open = Boolean(anchorEl);
	const id = open ? 'selection-tile-popover' : undefined;
	const [{ isFeatureEnabled: comparisonFeatureEnabled }] = useFeatureEnabled('comparison-tile');

	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const handleTileCreation = (tileParam: string) => {
		setAnchorEl(null);
		setUrlSearchParams({ tileToEdit: tileParam });
	};

	const renderItem = (tileParam: string, i18nKey: string, icon: ReactNode): ReactNode => (
		<MenuItem onClick={() => handleTileCreation(tileParam)} key={i18nKey}>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={t(`tile.create.types.${i18nKey}`)} secondary={t(`tile.create.description.${i18nKey}`)} />
		</MenuItem>
	);

	return (
		<div>
			<CreateResourceButton onClick={openMenu} data-testid='createTile' key='createTile'>
				{t('tile.create.button')}
			</CreateResourceButton>
			<Menu
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={closeMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				sx={{ marginTop: 1 }}
			>
				{renderItem('SERIES', 'series', <LineAxisOutlined />)}
				{comparisonFeatureEnabled && renderItem('VALUES_COMPARISON', 'valueComparison', <TableChartOutlined />)}
				{renderItem('TEXT', 'text', <FormatSizeOutlined />)}
				<Divider sx={{ display: 'flex', margin: 1 }} orientation='horizontal' />
				{renderItem('WIDGET', 'widget', <WidgetsOutlined />)}
			</Menu>
		</div>
	);
};
