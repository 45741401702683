/* eslint-disable react-compiler/react-compiler */
// TODO - Fix react-compiler
import { useEffect, useState } from 'react';
import useTimeoutFn from 'react-use/lib/useTimeoutFn';
import { TypedUseQuery, TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react';
import { isNotFoundError } from '@neoload/utils';

const computeDefaultPollingInterval = (
	maximumMillisecondsToWait: number,
	retryInterval: number,
	pollingIntervalFromOptions?: number
) => (maximumMillisecondsToWait > 0 ? retryInterval : pollingIntervalFromOptions ?? 0);

/**
 * Queries using the given useQuery, but retries every retryInterval up to maxRetries times if the query returns
 * not found error.
 * If the query succeeds or throws another error, stops trying.
 * It returns the hook's response as is, but also specifying whether we are still waiting. Caller can then decide
 * to ignore the error or lack of data in response while waiting
 *
 * If you want the hook to do polling after the initial retry period you need to specify valid useQuery options through the `options` parameter.
 *
 * @param useQuery the query's hook
 * @param args the query hook's arguments
 * @param maxRetries the maximum number of tries before stopping to wait. Passing 0 will not wait at all
 * @param retryInterval the delay, in milliseconds, between two tries
 * @param options the useQuery options.
 */
const useGetWithRetry = <ArgumentType, ResponseType>(
	/* eslint-disable @typescript-eslint/no-explicit-any*/
	useQuery: TypedUseQuery<ResponseType, ArgumentType, any>,
	args: ArgumentType,
	maxRetries: number,
	retryInterval: number,
	options: { pollingInterval?: number; skip?: boolean } = {}
): {
	response: TypedUseQueryHookResult<ResponseType, ArgumentType, any>;
	waiting: boolean;
} => {
	/* eslint-enable @typescript-eslint/no-explicit-any*/
	const maximumMillisecondsToWait = retryInterval * maxRetries;

	const [pollingInterval, setPollingInterval] = useState(
		computeDefaultPollingInterval(maximumMillisecondsToWait, retryInterval, options.pollingInterval)
	);
	const [isElapsed, setIsElapsed] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, cancel] = useTimeoutFn(() => setIsElapsed(true), maximumMillisecondsToWait);
	const response = useQuery(args, { ...options, pollingInterval: pollingInterval });
	const { data, error, isLoading, isFetching } = response;

	useEffect(() => {
		if (!isLoading || !isFetching) {
			if (isElapsed) {
				setPollingInterval(options.pollingInterval ?? 0);
			} else {
				const otherError: boolean = error !== undefined && !isNotFoundError(error);
				const querySuccess: boolean = data !== undefined;

				if (querySuccess || otherError) {
					setPollingInterval(options.pollingInterval ?? 0);
					cancel();
					setIsElapsed(true);
				}
			}
		}
	}, [data, error, maxRetries, isElapsed, cancel, isLoading, isFetching, options.pollingInterval]);

	return {
		response: response,
		waiting: !isElapsed,
	};
};

export { useGetWithRetry };
