import { useMemo, useRef, useState } from 'react';
import PopperWithArrow from '@tricentis/aura/components/PopperWithArrow.js';
import { ColorResult, SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { ColorIcon } from '../color-icon';

export type ColorPickerProps = {
	initialColor?: string;
	onColorPicked: (color: ColorResult) => void;
};

export const ColorPicker = ({ initialColor = '#FFFFFF', onColorPicked }: ColorPickerProps) => {
	const { t } = useTranslation(['common']);
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>(initialColor);
	const popperAnchorElementRef = useRef<HTMLButtonElement>(null);

	const popperMemo = useMemo(
		() => (
			<PopperWithArrow
				style={{ zIndex: 999_999 }}
				open={open}
				arrow={true}
				onClose={() => setOpen(false)}
				anchorEl={popperAnchorElementRef.current}
			>
				<SketchPicker
					color={color}
					disableAlpha={true}
					onChange={(pickedColor, _event) => {
						if (color !== pickedColor.hex) {
							onColorPicked(pickedColor);
							setColor(pickedColor.hex);
						}
					}}
				/>
			</PopperWithArrow>
		),
		[color, onColorPicked, open]
	);

	return (
		<>
			<Tooltip
				title={t('colorPicker.title', {
					color: color,
				})}
				arrow
				enterDelay={500}
				leaveDelay={200}
			>
				<IconButton onClick={() => setOpen(!open)} ref={popperAnchorElementRef}>
					<ColorIcon color={color} />
				</IconButton>
			</Tooltip>
			{popperMemo}
		</>
	);
};
