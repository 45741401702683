import { DataGridPro, GridApi, GridCallbackDetails, GridFilterModel, DataGridProProps } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import './datagrid.css';

type DatagridProps = DataGridProProps & {
	apiRef: React.MutableRefObject<GridApi>;
};

const Datagrid = (props: DatagridProps) => {
	const quickFilterRef = useRef(['']);
	const onFilterChange = (filterModel: GridFilterModel, details: GridCallbackDetails) => {
		if (props.onFilterModelChange) {
			props.onFilterModelChange(filterModel, details);
		}
		if (filterModel.quickFilterValues) {
			quickFilterRef.current = filterModel.quickFilterValues;
		}
	};

	useEffect(() => {
		props.apiRef.current.setQuickFilterValues(quickFilterRef.current);
	});

	return <DataGridPro {...props} onFilterModelChange={onFilterChange} apiRef={props.apiRef} />;
};

export { Datagrid };
