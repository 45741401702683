import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { Spinner } from '../../../../../../common/spinner';
import { useTreeDataWhenTestResultChange } from '../tree-data-hooks';
import { AddSeriesFormData } from '../add-series-form';
import { SplitPane } from '../split-pane';
import { Statistic, StatsSelectionList } from '../statistics';
import { StatisticTree, TreeNode, TreeNodeSearchResult } from '../../statistic-tree/statistic-tree';
import { ComparisonSerieDefaultValue } from '../../form/comparison/row/use-find-in-tree';
import { TestResult, useLazyGetV4ResultsByResultIdElementsQuery, UserPathElement } from '@neoload/api';

type ElementAndUserPath = {
	element: UserPathElement;
	userPathId?: string;
	userPath?: string[];
	rootElementType: UserPathElement['type'];
};

type UserPathTreeProps<T extends Statistic> = {
	comparison?: boolean;
	multiple?: boolean;
	defaultValue?: ComparisonSerieDefaultValue<T>;
};

const useGetElementsWhenTestResultChange = (
	testResult: TestResult | undefined
): [UserPathElement[], boolean, string | undefined] => {
	const [triggerGetElementsByResultId] = useLazyGetV4ResultsByResultIdElementsQuery();
	return useTreeDataWhenTestResultChange<UserPathElement>(testResult, triggerGetElementsByResultId);
};
const toTreeNode = (
	element: UserPathElement,
	userPathId?: string,
	parentUserPath?: string[]
): TreeNode<ElementAndUserPath> => {
	const userPath = [...(parentUserPath ?? []), element.name];
	return {
		id: element.id,
		label: element.name,
		data: {
			element,
			rootElementType: element.type,
			userPath,
			userPathId: element.type === 'USER_PATH' ? element.id : userPathId,
		},
		children: element.children.map((child) =>
			toTreeNode(child, element.type === 'USER_PATH' ? element.id : userPathId, userPath)
		),
	};
};

export const getAvailablesStatistics = (userPathElement?: UserPathElement, comparison?: boolean): Statistic[] => {
	let defaultStatistics: Statistic[] = userPathElement?.statistics ?? [];
	if (comparison) {
		if (['init-container', 'actions-container', 'end-container'].includes(userPathElement?.id ?? '')) {
			return [];
		}
		defaultStatistics = defaultStatistics.filter((s) => s !== 'ERRORS_PER_SECOND' && s !== 'THROUGHPUT');
		if (defaultStatistics.includes('DURATION_PERCENTILES')) {
			defaultStatistics = [
				...defaultStatistics.filter((s) => s !== 'DURATION_PERCENTILES'),
				'DURATION_PERCENTILE_99',
				'DURATION_PERCENTILE_95',
				'DURATION_PERCENTILE_90',
				'DURATION_PERCENTILE_50',
			];
		}
	}
	return defaultStatistics;
};

export const UserPathTree = <T extends Statistic>({ comparison, multiple, defaultValue }: UserPathTreeProps<T>) => {
	const { watch, setValue } = useFormContext<AddSeriesFormData<T>>();
	const [elementAndUserPathItems, isLoading, error] = useGetElementsWhenTestResultChange(watch('testResult'));
	const { t } = useTranslation(['dashboard'], { keyPrefix: 'tile.edition.addSeries' });
	const [userPathElement, stats = []] = watch(['userPathElement', 'stats']);
	const availablesStatistics = getAvailablesStatistics(userPathElement, comparison) as T[];
	const selection = defaultValue ? { nodeId: defaultValue?.nodeId, expandedIds: defaultValue?.expandedIds } : undefined;
	const userPathsNodes = elementAndUserPathItems.map((element) => toTreeNode(element));

	const handleNodeSelect = ({ node }: TreeNodeSearchResult<ElementAndUserPath>) => {
		const { element, userPathId, userPath, rootElementType } = node;
		setValue('userPathElement', element);
		setValue('userPathId', element.type === 'USER_PATH' ? element.id : userPathId);
		setValue('userPath', userPath);
		setValue('rootElementType', rootElementType);
		setValue('stats', []);
		setValue('type', 'USERPATH');
	};
	if (isLoading) {
		return <Spinner />;
	}
	if (error) {
		console.error(t('errorLoadingData'), error);
		return (
			<Box sx={{ textAlign: 'center', height: '200px' }}>
				<FormHelperText error>{t('errorLoadingData')}</FormHelperText>
			</Box>
		);
	}
	return (
		<SplitPane proportions={[0.65, 0.35]}>
			<StatisticTree<ElementAndUserPath>
				rootNodes={userPathsNodes}
				defaultValue={selection}
				onChange={handleNodeSelect}
				label={t('userPathTree.label')}
			/>
			<>
				<StatsSelectionList
					multiple={multiple}
					statisticPrefix={comparison ? 'common:statistics.userPathElementValueStatistics' : 'statistics'}
					availablesStatistics={availablesStatistics}
					value={stats}
					onUpdate={(updatedStats: T[]) => {
						setValue('stats', updatedStats);
					}}
				/>
				{!userPathElement && (
					<Box sx={{ display: 'flex', alignItems: 'center', height: '100px', justifyContent: 'center' }}>
						<Typography variant={'body2'} color={(theme) => theme.palette.text.secondary}>
							{t('msgNoUserPathElementSelected')}
						</Typography>
					</Box>
				)}
			</>
		</SplitPane>
	);
};
