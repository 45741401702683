import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import MonitoringResults from '../../../../assets/MonitoringResults.svg';

export type DashboardWorkInProgressProps = {
	title: string;
	content: string;
};

export const DashboardTileWorkInProgress = ({ title, content }: DashboardWorkInProgressProps) => (
	<ZeroState
		illustration={<img src={MonitoringResults} alt='' />}
		title={title}
		containerSx={{ backgroundColor: 'transparent' }}
	>
		<Typography variant='caption'>{content}</Typography>
	</ZeroState>
);
