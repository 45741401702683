import Tooltip from '@tricentis/aura/components/Tooltip.js';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { getClickableLink } from './external-url-util';

export type ExternalUrlPlainLinkProps = {
	href?: string;
	title?: string;
};

const ExternalUrlPlainLink = ({ href, title }: ExternalUrlPlainLinkProps) => {
	if (!href) {
		return null;
	}

	const clickableHref = getClickableLink(href);

	return (
		<Tooltip arrow title={title ?? clickableHref}>
			<IconButton
				component={Link}
				href={clickableHref}
				target='_blank'
				data-testid={'external-url-plain-link'}
				color='primary'
			>
				<LinkOutlinedIcon />
			</IconButton>
		</Tooltip>
	);
};

export { ExternalUrlPlainLink };
