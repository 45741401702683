import { GridSortItem } from '@mui/x-data-grid-pro';

export type ColumnSortMapper = {
	sortColumnField: string;
	sortApiParameter: string;
};

const formatDirection = (sortItem: GridSortItem, sortApiParameter: string): string =>
	sortItem.sort === 'asc' ? `+${sortApiParameter}` : `-${sortApiParameter}`;

/**
 * Formats the string to give as sort parameter for the next api request from
 * a datagrid's sort model
 * @param sortItem the datagrid's sort item
 * @param sortMappers an array of mappers between column names and api parameter
 * names. If they are the same no need to specify it
 */
const formatSort = (sortItem: GridSortItem, sortMappers: ColumnSortMapper[]): string => {
	const sortMapper = sortMappers.find((mapper) => mapper.sortColumnField === sortItem.field);
	return formatDirection(sortItem, sortMapper ? sortMapper.sortApiParameter : sortItem.field);
};

const alphabeticSort = (toBeSorted: string[]): string[] =>
	[...toBeSorted].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

export { formatSort, alphabeticSort };
