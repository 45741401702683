import { DashboardSeriesStatistic } from '../nlw-api-v4';

export function extractElementStatisticName(statistic: DashboardSeriesStatistic) {
	return statistic.name as
		| 'AVERAGE_DURATION'
		| 'MINIMUM_DURATION'
		| 'MAXIMUM_DURATION'
		| 'ELEMENT_COUNT'
		| 'THROUGHPUT'
		| 'ELEMENTS_PER_SECOND'
		| 'ERROR_COUNT'
		| 'ERRORS_PER_SECOND'
		| 'ERROR_RATE'
		| 'AVERAGE_TTFB'
		| 'MINIMUM_TTFB'
		| 'MAXIMUM_TTFB';
}
