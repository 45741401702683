import ListItem from '@mui/material/ListItem';
import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { ColorPicker } from '../../../../../../common/color-picker';
import { TileEditionFormFields } from '../tile-edition-form';
import { matchAndReplaceWithI18N } from '../../../../dashboard-common';
import { DashboardSeries } from '@neoload/api';

export type SeriesListItemProps = {
	series: DashboardSeries;
};

export const SeriesListItem = ({ series }: SeriesListItemProps) => {
	const { t } = useTranslation(['dashboard']);
	const { register, watch, setValue } = useFormContext<TileEditionFormFields>();
	const seriesFormList = watch('series');
	const index = seriesFormList.findIndex((that) => series.id === that.id);
	const seriesForm = seriesFormList.at(index);

	if (!seriesForm) {
		throw new Error(t('tile.edition.fields.series.errors.mismatch'));
	}

	return (
		<ListItem
			sx={{
				display: seriesForm.deleteOnSubmit ? 'none' : 'initial',
				padding: (theme) => theme.spacing(1),
				borderRadius: '4px',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: (theme) => theme.palette.divider,
			}}
			aria-hidden={seriesForm.deleteOnSubmit}
		>
			<Stack direction={'row'} alignItems={'center'} spacing={0} width={'100%'}>
				<Box>
					{seriesForm.visible ? (
						<Tooltip title={t('tile.edition.fields.series.visibility.hide')} arrow>
							<IconButton
								onClick={() => {
									setValue(`series.${index}.visible`, false, { shouldDirty: true });
								}}
							>
								<VisibilityOutlined />
							</IconButton>
						</Tooltip>
					) : (
						<Tooltip title={t('tile.edition.fields.series.visibility.show')} arrow>
							<IconButton onClick={() => setValue(`series.${index}.visible`, true, { shouldDirty: true })}>
								<VisibilityOffOutlined color={'disabled'} />
							</IconButton>
						</Tooltip>
					)}
					<TextField {...register(`series.${index}.visible`)} type='hidden' variant='standard' />
				</Box>
				<Box>
					<ColorPicker
						onColorPicked={(color) => setValue(`series.${index}.color`, color.hex, { shouldDirty: true })}
						initialColor={seriesForm.color}
					/>
					<TextField {...register(`series.${index}.color`)} type='hidden' variant='standard' />
				</Box>
				<Box
					sx={{
						padding: (theme) => theme.spacing(1),
						width: '100%',
						minWidth: 0,
					}}
				>
					<Stack>
						<Typography
							title={matchAndReplaceWithI18N(series.legend)}
							variant='body1'
							noWrap
							color={series.visible ? 'text.primary' : 'text.disabled'}
						>
							{matchAndReplaceWithI18N(series.legend)}
						</Typography>
						<Typography
							title={series.resultName}
							variant='body2'
							noWrap
							color={series.visible ? 'text.primary' : 'text.disabled'}
						>
							{series.resultName}
						</Typography>
						{series.testName && (
							<Typography
								title={series.testName}
								variant='body2'
								noWrap
								color={series.visible ? 'text.primary' : 'text.disabled'}
							>
								{series.testName}
							</Typography>
						)}
					</Stack>
				</Box>
				<Box>
					<Tooltip title={t('tile.edition.fields.series.delete')} arrow>
						<IconButton
							onClick={() => {
								if (seriesForm.deleteOnSubmit) {
									setValue(`series.${index}.deleteOnSubmit`, false, { shouldDirty: true });
								} else {
									setValue(`series.${index}.deleteOnSubmit`, true, { shouldDirty: true });
								}
							}}
						>
							{seriesForm.visible ? <DeleteOutlined /> : <DeleteOutlined color={'disabled'} />}
						</IconButton>
					</Tooltip>
					<TextField {...register(`series.${index}.deleteOnSubmit`)} type='hidden' variant='standard' />
				</Box>
			</Stack>
		</ListItem>
	);
};
