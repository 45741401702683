import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import StopCircleOutlined from '@mui/icons-material/StopCircleOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useConfirmModal } from '@neoload/hooks';

export type ResultStopButtonsProps = {
	onStopTest: () => Promise<void>;
	onTerminateTest: () => Promise<void>;
	disabled: boolean;
	terminateUnavaible?: boolean;
};

const ResultStopButtons = ({
	onStopTest,
	onTerminateTest,
	disabled,
	terminateUnavaible = false,
}: ResultStopButtonsProps) => {
	const { t } = useTranslation(['result']);
	const { openModal } = useConfirmModal();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const stopTestOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const openStopModal = () => {
		openModal({
			title: t('actions.stop.title'),
			content: t('actions.stop.description'),
			cancel: { text: t('actions.stop.cancel') },
			confirm: { text: t('actions.stop.validate'), color: 'error' },
			handleConfirm: () => onStopTest(),
		});
	};

	const openTerminateModal = () => {
		handleClose();
		openModal({
			title: t('actions.terminate.title'),
			content: t('actions.terminate.description'),
			cancel: { text: t('actions.terminate.cancel') },
			confirm: { text: t('actions.terminate.validate'), color: 'error' },
			handleConfirm: () => onTerminateTest(),
		});
	};

	return (
		<>
			<Tooltip arrow title={disabled ? t('actions.stop.notStartedByNlw') : undefined}>
				<ButtonGroup disabled={disabled}>
					<Button
						variant={'outlined'}
						color={'error'}
						startIcon={<StopCircleOutlined />}
						onClick={openStopModal}
						data-testid={'result-stop-button'}
					>
						{t('actions.stop.button')}
					</Button>
					{!terminateUnavaible && (
						<Button size={'small'} color={'error'} onClick={handleClick} data-testid={'result-stop-button-dropdown'}>
							<ArrowDropDownIcon />
						</Button>
					)}
				</ButtonGroup>
			</Tooltip>
			<Menu
				open={stopTestOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				data-testid={'result-terminate-menu'}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				MenuListProps={{ sx: { py: 0 } }}
			>
				<MenuItem onClick={openTerminateModal} data-testid={'result-terminate-button'}>
					<BlockOutlinedIcon color={'error'} sx={{ fontSize: '20px', marginLeft: -0.5, marginRight: 1 }} />
					<Typography color={'error'} variant={'body2'} fontWeight={500} lineHeight={1.75}>
						{t('actions.terminate.button')}
					</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export { ResultStopButtons };
