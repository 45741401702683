import { encode } from 'html-entities';
import { Ref, forwardRef } from 'react';
import { mapToReadableStatisticName, mapToReadableStatisticUnit } from './trend-statistics';
import { TrendsGraphTooltip } from './tooltip';
import { Chart, ChartProps, Series, ChartRef } from '../../../../common/chart';
import { i18n } from '../../../../../i18n';
import { timeUtils } from '@neoload/utils';
import {
	TrendPage,
	TrendPoint,
	TrendsConfiguration,
	TrendsConfigurationMetric,
	TrendsConfigurationPatchRequest,
} from '@neoload/api';

type TrendsGraphProps = {
	trendsPage: TrendPage;
	trendsConfiguration: TrendsConfiguration;
	sendTrendsPatchConfigurationRequest: (trendsConfigurationPatchRequest: TrendsConfigurationPatchRequest) => void;
};

export const TrendsGraph = forwardRef(
	({ trendsPage, trendsConfiguration, sendTrendsPatchConfigurationRequest }: TrendsGraphProps, ref: Ref<ChartRef>) => {
		const seriesList = toSeriesList(trendsPage, trendsConfiguration, sendTrendsPatchConfigurationRequest);
		const metadataBySeriesKeyMap = toMetadataBySeriesCategory(trendsPage);
		const tooltipComponent: ChartProps['tooltipComponent'] = (context) => {
			if (metadataBySeriesKeyMap.size === 0 || !context.points) {
				return null;
			}
			for (const [index, point] of context.points.entries()) {
				const objectiveValue = trendsConfiguration.metrics[index]?.objectiveValue;
				if (objectiveValue !== undefined && point.y !== null && point.y !== undefined) {
					context.points[index].percentage =
						objectiveValue === 0 && point.y === 0 ? 0 : (100 * (point.y - objectiveValue)) / objectiveValue;
				}
			}
			return <TrendsGraphTooltip context={context} metadataBySeriesCategory={metadataBySeriesKeyMap} />;
		};
		return (
			<Chart
				series={seriesList}
				configuration={{
					tooltip: {
						shared: true,
					},
					noDataLabel: {
						i18n: 'test:trends.graph.noData',
					},
					axis: {
						y: {
							maximumDisplayedCount: 'unlimited',
						},
					},
				}}
				tooltipComponent={tooltipComponent}
				ref={ref}
			/>
		);
	}
);

const toSeriesList = (
	trendsPage: TrendPage,
	trendsConfiguration: TrendsConfiguration,
	sendTrendsPatchConfigurationRequest: TrendsGraphProps['sendTrendsPatchConfigurationRequest']
) => {
	const seriesList: Series[] = [];
	for (const metric of trendsConfiguration.metrics) {
		const elementFullPath = metric.elementFullPath;
		const elementName = elementFullPath.at(-1);
		const color = metric.color;
		const title = i18n.t('trends.graph.legend', {
			statistic: mapToReadableStatisticName(metric.statistic),
			element: elementName,
			ns: 'test',
		});
		const yAxisUnit = mapToReadableStatisticUnit(metric.statistic);
		const points: [string, number | null][] = [];
		for (const item of trendsPage.items) {
			const point = findPointForMetric(item.points, metric);
			points.push([timeUtils.dateTimeAbsolute(item.metadata.resultStartDate), point?.value ?? null]);
		}
		seriesList.push({
			points,
			color,
			title: encode(title),
			axis: {
				x: {
					type: 'CATEGORY',
				},
				y: {
					displayLegend: true,
					unit: yAxisUnit.long,
				},
			},
			targetValue: metric.objectiveValue,
			isError: false,
			visible: metric.visible,
			legendItemClick: () => {
				sendTrendsPatchConfigurationRequest({
					metrics: [
						{
							elementFullPath: metric.elementFullPath,
							statistic: metric.statistic,
							visible: !metric.visible,
						},
					],
				});
				return true;
			},
		});
	}
	return seriesList;
};

const findPointForMetric = (points: TrendPoint[], metric: TrendsConfigurationMetric) =>
	points.find(
		(point) =>
			point.statistic === metric.statistic &&
			(point.element.id === metric.elementId || areArraysEqual(point.element.fullPath, metric.elementFullPath))
	);

const areArraysEqual = (a: string[], b: string[]) =>
	a.length === b.length && a.every((element, index) => element === b[index]);

const toMetadataBySeriesCategory = (trendPage: TrendPage) =>
	new Map(trendPage.items.map(({ metadata }) => [timeUtils.dateTimeAbsolute(metadata.resultStartDate), metadata]));
