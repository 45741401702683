import { TFunction } from 'i18next';
import { ResultHeaderNavigation } from './result-header';
import { timeUtils } from '@neoload/utils';

export const getDeleteToolTip = (
	atLeastOneSelectedLocked: boolean,
	atLeastOneSelectedAlive: boolean,
	toDeleteCount: number,
	t: TFunction
) => {
	if (atLeastOneSelectedLocked && atLeastOneSelectedAlive) {
		return t('actions.delete.lockedAndRunning', { count: toDeleteCount });
	} else if (atLeastOneSelectedLocked) {
		return t('actions.delete.locked', { count: toDeleteCount });
	} else if (atLeastOneSelectedAlive) {
		return t('actions.delete.running', { count: toDeleteCount });
	} else {
		return t('common:delete');
	}
};

const getStartDateToolTip = (author: string, t: TFunction) => t('header.startedBy', { author: author });

const getDurationToolTip = (endDate: string | undefined, t: TFunction) =>
	endDate && t('details.endedAt', { endDate: timeUtils.dateTimeAbsolute(endDate) });

const getNextTooltip = (nav: ResultHeaderNavigation, hasNext: boolean, t: TFunction) => {
	if (hasNext) {
		return nav.nextStartDate
			? tooltipUtils.getFormattedTooltip([t('header.older'), timeUtils.dateTimeAbsolute(nav.nextStartDate)])
			: t('header.older');
	}
	return t('header.noOlder');
};

const getPreviousTooltip = (nav: ResultHeaderNavigation, hasPrevious: boolean, t: TFunction) => {
	if (hasPrevious) {
		return nav.previousStartDate
			? tooltipUtils.getFormattedTooltip([t('header.newer'), timeUtils.dateTimeAbsolute(nav.previousStartDate)])
			: t('header.newer');
	}
	return t('header.noNewer');
};

const getLockTooltip = (locked: boolean, t: TFunction) => (locked ? t('header.menu.unlock') : t('header.menu.lock'));

const getFormattedTooltip = (lines: string[]) => (
	<>
		{lines.map((line) => (
			<span key={line}>
				{line}
				<br />
			</span>
		))}
	</>
);

const tooltipUtils = {
	getFormattedTooltip,
	getNextTooltip,
	getPreviousTooltip,
	getDurationToolTip,
	getStartDateToolTip,
	getLockTooltip,
};

export { tooltipUtils };
