import Box from '@mui/material/Box';
import { ErrorPage } from './error-page';

const GlobalErrorPage = () => (
	<Box sx={{ height: '100vh', width: '100vw', backgroundColor: 'white' }}>
		<ErrorPage />
	</Box>
);

export { GlobalErrorPage };
