import { SummaryFetcher } from './summary-fetcher';
import { KpiFetcher } from './kpi-fetcher';
import { TableFetcher } from './table-fetcher';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { WidgetDashboardTile } from '@neoload/api';

type WidgetFetcherProps = {
	widgetTile: WidgetDashboardTile;
};

export const WidgetFetcher = ({ widgetTile }: WidgetFetcherProps) => {
	switch (widgetTile.visualization) {
		case 'SUMMARY': {
			return <SummaryFetcher widgetTile={widgetTile} />;
		}
		case 'COUNTER': {
			return <KpiFetcher widgetTile={widgetTile} />;
		}
		case 'TABLE': {
			return <TableFetcher widgetTile={widgetTile} />;
		}
		default: {
			return <DashboardTileErrorContent />;
		}
	}
};
