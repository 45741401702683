import { Statistic } from '../../add-series/statistics';
import { DashboardSeries, DashboardSeriesStatistic, UserPathElementStatistic } from '@neoload/api';

export const extractYUnit = (statistic: UserPathElementStatistic): DashboardSeriesStatistic['xAxisUnit'] => {
	switch (statistic) {
		case 'AVERAGE_DURATION':
		case 'MAXIMUM_DURATION':
		case 'MINIMUM_DURATION':
		case 'AVERAGE_TTFB':
		case 'MAXIMUM_TTFB':
		case 'MINIMUM_TTFB':
		case 'DURATION_PERCENTILES': {
			return 'SECONDS';
		}
		case 'ELEMENT_COUNT': {
			return 'ELEMENTS';
		}
		case 'ELEMENTS_PER_SECOND': {
			return 'ELEMENTS_PER_SECOND';
		}
		case 'THROUGHPUT': {
			return 'BITS_PER_SECOND';
		}
		case 'ERROR_COUNT': {
			return 'ERRORS';
		}
		case 'ERRORS_PER_SECOND': {
			return 'ERRORS_PER_SECOND';
		}
		case 'ERROR_RATE': {
			return 'PERCENTAGE';
		}
	}
};

export const extractXUnit = (statistic: Statistic): DashboardSeriesStatistic['yAxisUnit'] => {
	if (statistic === 'DURATION_PERCENTILES') {
		return 'PERCENTAGE';
	}
	return 'SECONDS';
};

export const checkDuplicate = (seriesList: DashboardSeries[], nextSeries: DashboardSeries): boolean =>
	seriesList.some(
		(item) =>
			item.resultId === nextSeries.resultId &&
			(nextSeries.filter.type === 'ELEMENTS_TIMESERIES' || nextSeries.filter.type === 'ELEMENTS_PERCENTILES') &&
			item.filter.type === nextSeries.filter.type &&
			item.filter.elementId === nextSeries.filter.elementId &&
			item.statistic.name === nextSeries.statistic.name &&
			(item.filter.userPathId ?? '') === (nextSeries.filter.userPathId ?? '')
	) ||
	seriesList.some(
		(item) =>
			nextSeries.filter.type === 'MONITORS_TIMESERIES' &&
			item.filter.type === nextSeries.filter.type &&
			item.filter.monitorId === nextSeries.filter.monitorId &&
			item.resultId === nextSeries.resultId
	);

export const seriesListNotDeleted = (
	seriesList: DashboardSeries[],
	existingSeriesList: (
		| {
				id?: string;
				deleteOnSubmit?: boolean;
		  }
		| undefined
	)[] = []
) => {
	const notDeleted = existingSeriesList.filter((item) => !item?.deleteOnSubmit).map((item) => item?.id);
	return seriesList.filter((item) => notDeleted?.includes(item.id));
};

export const checkCompatible = (seriesList: DashboardSeries[], nextSeries: DashboardSeries): boolean =>
	seriesList.every((item) => item.statistic.xAxisUnit === nextSeries.statistic.xAxisUnit);
