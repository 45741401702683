import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { useWatch } from 'react-hook-form';
import ChipGroup from '@tricentis/aura/components/ChipGroup.js';
import { useEffect, useState } from 'react';
import { ConfigurationZoneFormData } from './configuration/types';
import { DetailInfo } from '../results/details/detail-info';
import { timeUtils } from '@neoload/utils';

export const CurrentConfigurationHeader = () => {
	const { t } = useTranslation(['result']);
	const [zonesSelected, setZonesSelected] = useState<ConfigurationZoneFormData[]>([]);
	const scenario = useWatch({ name: 'scenario' });
	const zones = useWatch({ name: 'zones' });
	const project = useWatch({ name: 'project' });

	useEffect(() => {
		setZonesSelected(zones.items.filter((zone: ConfigurationZoneFormData) => zone.number > 0));
	}, [zones]);

	return (
		<Grid
			sx={{
				flexDirection: 'row',
				display: 'flex',
				maxWidth: 'fit-content',
				alignItems: 'center',
				color: (theme) => theme.palette.text.primary,
			}}
		>
			{project.name && <DetailInfo id='project' title={t('details.project')} value={project.name} />}
			{scenario.selected?.name && (
				<DetailInfo id='scenario' title={t('details.scenario')} value={scenario.selected.name} />
			)}
			{scenario.selected?.duration && (
				<DetailInfo
					id='duration'
					title={t('details.duration')}
					value={timeUtils.durationFull(scenario.selected.duration)}
				/>
			)}
			{scenario.selected?.maximumVu && (
				<DetailInfo id='vus' title={t('details.vus')} value={scenario.selected.maximumVu} />
			)}
			{zonesSelected.length === 1 && (
				<DetailInfo id='zone' title={t('details.zone_one')} value={zonesSelected[0].name} />
			)}
			{zonesSelected.length > 1 && (
				<>
					<DetailInfo id='zone' title={t('details.zone_other')} value={zonesSelected[0].name} />
					<ChipGroup
						sx={{ marginLeft: 1 }}
						plusSx={{ backgroundColor: 'transparent' }}
						chips={zonesSelected.slice(1).map((zone) => ({
							label: zone.name,
							key: zone.zoneId,
							size: 'small',
							sx: {
								backgroundColor: 'transparent',
								borderColor: 'transparent',
								fontSize: (theme) => theme.typography.body2.fontSize,
							},
						}))}
					/>
				</>
			)}
		</Grid>
	);
};
