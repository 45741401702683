import Stack from '@mui/material/Stack';
import { Snackbar } from './snackbar';
import { useGetSnackbars } from '@neoload/hooks';

const StackedSnackbars = () => {
	const snackbars = useGetSnackbars();
	const reverseSnackbars = [...snackbars].reverse();

	return (
		<Stack
			sx={{
				position: 'absolute',
				bottom: '48px',
				left: '50%',
				right: 'auto',
			}}
			spacing={2}
			data-testid='stacked-snackbars'
		>
			{reverseSnackbars?.map((s) => (
				<Snackbar snack={s} key={s.id} />
			))}
		</Stack>
	);
};

export { StackedSnackbars };
