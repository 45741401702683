import { useTranslation } from 'react-i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { Spinner } from '../../../common/spinner';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { Cell, TableGrid } from '../../../common/table-grid';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import {
	ColumnDefinition,
	columnDefinitionsToColumns,
	sortToSortColumnIndex,
	sortToSortDirection,
} from '../dashboard-common';
import {
	ErrorCountByCode,
	ErrorsCountFilter,
	GetV4ResultsByResultIdEventsErrorsApiArg,
	GetV4ResultsByResultIdEventsErrorsApiResponse,
	useLazyGetV4ResultsByResultIdEventsErrorsQuery,
	WidgetDashboardTile,
} from '@neoload/api';

type ErrorsByCodeFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

export const _ErrorsByCodeFetcher = ({ shouldStartFetching, widgetTile }: ErrorsByCodeFetcherProps) => {
	const { t } = useTranslation(['dashboard']);
	const [triggerEventsErrorsByCode] = useLazyGetV4ResultsByResultIdEventsErrorsQuery();

	const { pageSize, pageNumber, sort } = widgetTile.filter as ErrorsCountFilter;

	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdEventsErrorsApiArg,
		GetV4ResultsByResultIdEventsErrorsApiResponse
	>(
		widgetTile,
		{
			errorsType: 'CODE',
			resultId: widgetTile.resultId,
			pageSize,
			pageNumber,
			sort,
		},
		shouldStartFetching,
		triggerEventsErrorsByCode
	);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	const columnDefs: ColumnDefinition[] = [
		{
			name: 'CODE',
			i18nKey: 'errors.errorCode',
		},
		{
			name: 'COUNT',
			i18nKey: 'errors.errorCount',
			align: 'right',
		},
	];

	return (
		<TableGrid
			columns={columnDefinitionsToColumns(columnDefs, t)}
			sortColumnIndex={sortToSortColumnIndex(columnDefs, sort)}
			sortDirection={sortToSortDirection(sort)}
			values={errorsByCodeArrayToTable(data.items as ErrorCountByCode[])}
		/>
	);
};

export const VisibleForTesting = _ErrorsByCodeFetcher;
export const ErrorsByCodeFetcher = withLazyFetching(_ErrorsByCodeFetcher);

function errorsByCodeArrayToTable(errorsByCodes: ErrorCountByCode[]): Cell[][] {
	const rows = [];
	for (const errorByCode of errorsByCodes) {
		rows.push(errorsByCodeToTableRow(errorByCode));
	}
	return rows;
}

function errorsByCodeToTableRow(errorsByCode: ErrorCountByCode): Cell[] {
	return [{ text: errorsByCode.code }, { text: errorsByCode.count.toString() }];
}
