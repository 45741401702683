import { GetTestExecutionResponse, useGetV4TestExecutionsByTestExecutionIdQuery } from '@neoload/api';

export type InitializationState = {
	step: number;
	stepName: string;
};

const getInitializationState = (testExec: GetTestExecutionResponse | undefined): InitializationState => {
	const initializationState = { step: 0, stepName: '' };

	if (testExec === undefined) {
		return initializationState;
	}

	switch (testExec.step) {
		case 'INITIALIZING': {
			initializationState.step = 0;
			initializationState.stepName = 'header.initialization.initializing';
			break;
		}
		case 'PREPARING_CONTROLLER': {
			initializationState.step = 1;
			initializationState.stepName = 'header.initialization.preparingController';
			break;
		}
		case 'FAILED_TO_PREPARE_CONTROLLER': {
			initializationState.step = 1;
			initializationState.stepName = 'header.initialization.failedToPrepareController';
			break;
		}
		case 'PREPARING_LGS': {
			initializationState.step = 2;
			initializationState.stepName = 'header.initialization.preparingLgs';
			break;
		}
		case 'FAILED_TO_PREPARE_LGS': {
			initializationState.step = 2;
			initializationState.stepName = 'header.initialization.failedToPrepareLgs';
			break;
		}
		case 'SENDING_PROJECT': {
			initializationState.step = 3;
			initializationState.stepName = 'header.initialization.sendingProject';
			break;
		}
		case 'PREPARING_TEST': {
			initializationState.step = 4;
			initializationState.stepName = 'header.initialization.preparingTest';
			break;
		}
		case 'STARTING_TEST': {
			initializationState.step = 5;
			initializationState.stepName = 'header.initialization.startingTest';
			break;
		}
		case 'STARTED_TEST': {
			initializationState.step = 5;
			initializationState.stepName = 'header.initialization.startedTest';
			break;
		}
		case 'CANCELLED': {
			initializationState.step = 5;
			initializationState.stepName = 'header.initialization.cancelled';
			break;
		}
		case 'FAILED': {
			initializationState.step = 5;
			initializationState.stepName = 'header.initialization.failed';
			break;
		}
	}

	return initializationState;
};

const useGetInitializationState = (resultId: string, testIsStarting: boolean): InitializationState => {
	const { data: testExec } = useGetV4TestExecutionsByTestExecutionIdQuery(
		{ testExecutionId: resultId },
		{ pollingInterval: testIsStarting ? 2000 : 0, skip: !testIsStarting }
	);

	return getInitializationState(testExec);
};

export { useGetInitializationState };
