import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

export const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

export const apiSwaggerUiUrl = apiBaseUrl.length > 0 ? apiBaseUrl : '/explore/';

export const emptySplitApiV4 = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrl,
		credentials: 'include',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		paramsSerializer: (params: Record<string, any>) => queryString.stringify(params),
	}),
	reducerPath: 'apiV4',
	keepUnusedDataFor: 10,
	endpoints: () => ({}),
});
