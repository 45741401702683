const home = {
	title: 'Welcome to NeoLoad',
	errors: {
		noWorkspaceAssigned: 'You currently have no assigned workspace. Contact your account administrator.',
	},
	footer: {
		links: {
			documentation: 'Documentation',
			generalTermsOfUse: 'General Terms of Use',
			privacyPolicy: 'Privacy Policy',
			support: 'Support',
		},
		credits: '© 2024 Tricentis',
	},
	grid: {
		tile: {
			header: {
				recommendations: 'Get it all done in Tricentis NeoLoad',
				viewAll: 'View all',
			},
			welcome: {
				title: 'Welcome',
				hello: 'Hello, {{name}}',
				sentence: 'Collaborate easier, release faster, deliver better quality.',
			},
			recommendations: {
				tests: {
					title: 'Create and edit your tests',
					content: 'A test is the basic testable unit. Build, run and analyse its results in one place.',
					createTest: 'Create test',
					viewTests: 'View tests',
				},
				results: {
					title: 'Review progress and results',
					content: 'Check the progress of running tests and review the results when finished.',
					viewResults: 'View results',
				},
				dashboards: {
					title: 'Build custom dashboards',
					content: 'Analyze your test results in personalized views tailored to your specific needs.',
					createDashboard: 'Create dashboard',
					viewDashboards: 'View dashboards',
				},
			},
			latestResults: {
				title: 'Latest results',
			},
			kpi: {
				title: 'Tests',
				total: 'Total',
				totalValueTitle: 'totalValue',
				runningValueTitle: 'runningValue',
				running: 'Running',
				error: {
					title: 'Unknown error',
					description: 'We couldn’t retrieve the data. Try to reload the tile/page.',
					button: 'Reload page',
				},
			},
		},
	},
};

export { home };
