import { Preferences, UserWorkspace } from '../../nlw-api-v4';
import { unselectedWorkspace } from '../../store/workspace';

export const decideCurrentWorkspace = (workspaces: UserWorkspace[], preferences?: Preferences) => {
	if (workspaces.length <= 0) {
		return unselectedWorkspace;
	}
	if (preferences?.currentWorkspaceId) {
		const currentWorkspaceFromPreferences = workspaces.find((w) => w.id === preferences.currentWorkspaceId);
		if (currentWorkspaceFromPreferences) {
			return currentWorkspaceFromPreferences;
		}
	}
	return workspaces[0];
};
