import { useCallback, useEffect, useState } from 'react';
import { DeepPartialSkipArrayKey, useFormContext, useWatch } from 'react-hook-form';
import deepEqual from 'deep-equal';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { ConfigurationFormData, ConfigurationProjectScenarioFormData } from './types.d';
import { ScenarioTypes } from './scenarios/scenario-radio-group';
import { allowedFileUploadExtension, allowedFileUploadExtensionString, getReadableSize } from './project/utils';
import { ConfigurationError, translateErrorMessages, ConfigurationErrorTypes } from './configuration-helpers';
import { FileUpload, PatchTestInput, Test } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

const checkFileUploadExtension = (fileName: string | undefined) => {
	if (fileName === undefined) {
		return false;
	}

	const fileExtension = fileName.split('.').at(-1);

	if (fileExtension === undefined) {
		return false;
	}

	return allowedFileUploadExtension.has(fileExtension);
};

const isFileSizeOk = (value: DeepPartialSkipArrayKey<ConfigurationFormData>) =>
	value?.file?.size && value.projectMaxSize && value.file.size < value.projectMaxSize;

const canUploadFile = (value: DeepPartialSkipArrayKey<ConfigurationFormData>) =>
	checkFileUploadExtension(value.fileName) && isFileSizeOk(value);

const useConfigPatch = (
	patchTest: (patchTestInput: PatchTestInput) => Promise<Test | void>,
	defaultValues: ConfigurationFormData
) => {
	const [patch, setPatch] = useState<PatchTestInput>({});
	const [previous, setPrevious] = useState<ConfigurationFormData>(defaultValues);
	const value = useWatch<ConfigurationFormData>();
	const { setValue } = useFormContext<ConfigurationFormData>();
	const [uploadFile] = FileUpload.usePostV4TestsByTestIdProjectMutation();
	const { showError, showInfo } = useSetSnackbars();
	const { t } = useTranslation(['test']);

	const postUploadFile = useCallback(
		async (uploadRequest: FileUpload.UploadRequest): Promise<FileUpload.Project | void> => {
			const configurationErrorToString = (configurationError: ConfigurationError[]) =>
				configurationError
					.map((error) => {
						if (error.type !== ConfigurationErrorTypes.NotImplemented) {
							return t(error.sentenceKey);
						}

						return error.sentence;
					})
					.join(', ');

			if (value.test?.id) {
				showInfo({
					text: t('configuration.snackbar.uploading', { name: value.fileName }),
					id: 'upload',
					autoHideDuration: null,
				});
				return await uploadFile({ testId: value.test.id, uploadRequest: uploadRequest })
					.unwrap()
					.then((project) => {
						showInfo({ text: t('configuration.snackbar.uploadSuccess', { name: value.fileName }), id: 'upload' });
						return project;
					})
					.catch((error) => {
						const configurationError = translateErrorMessages(error.data);

						showError({
							text: t('configuration.snackbar.uploadFail', {
								name: value.fileName,
								error: configurationErrorToString(configurationError),
							}),
							id: 'upload',
						});
						setValue('file', undefined);
						setValue('fileName', undefined);
					});
			}
		},
		[value, showInfo, t, uploadFile, showError, setValue]
	);

	useEffect(() => {
		const hasFileChange = (value: DeepPartialSkipArrayKey<ConfigurationFormData>) =>
			value.dragActive !== previous.dragActive || value.file !== previous.file;

		const handleFileChange = (value: DeepPartialSkipArrayKey<ConfigurationFormData>) => {
			if (canUploadFile(value)) {
				// Need to do this due to https://github.com/reduxjs/redux-toolkit/issues/3063
				const body = new FormData();
				body.append('file', value.file as Blob);
				postUploadFile(body as unknown as FileUpload.UploadRequest).then((project) => {
					const scenarioTab: ConfigurationProjectScenarioFormData[] = [];
					if (project) {
						setValue('project', project);
						setValue('configurationDisabled', false);
						for (const { maximumVu, name, duration } of project.scenarios ?? []) {
							const scenarioData: ConfigurationProjectScenarioFormData = {
								duration: duration,
								maximumVu: maximumVu,
								name: name,
								type: ScenarioTypes.FromProject,
							};
							scenarioTab.push(scenarioData);
						}
						setValue('scenario.fromProject', scenarioTab);
						setValue('scenario.type', ScenarioTypes.FromProject);
						setValue('scenario.selected', scenarioTab[0]);
					}
					setValue('file', undefined);
				});
			} else {
				if (value.fileName !== undefined && !checkFileUploadExtension(value.fileName)) {
					showError({
						text: t('configuration.snackbar.fileExtensionsIncorrect', {
							name: value.fileName,
							extensions: allowedFileUploadExtensionString,
						}),
						id: 'upload',
					});
				}
				if (value.projectMaxSize && value?.file?.size !== undefined && !isFileSizeOk(value)) {
					showError({
						text: t('configuration.snackbar.fileTooLarge', {
							name: value.fileName,
							maxSize: getReadableSize(t, value.projectMaxSize),
						}),
						id: 'upload',
					});
				}
				setValue('file', undefined);
				setValue('fileName', undefined);
			}
		};

		if (deepEqual(value, previous)) {
			return;
		}
		if (value.test?.isConfigured !== previous.test.isConfigured) {
			setPrevious((previous) => ({ ...previous, test: { ...previous.test, isConfigured: value.test?.isConfigured } }));
			return;
		}
		if (hasFileChange(value)) {
			handleFileChange(value);
			return;
		}
		setValue('test.isConfigured', false);
		const patch: PatchTestInput = {};
		patch.controllerZoneId = value.zones?.controller;
		patch.selectedScenarioName =
			value.scenario?.type === ScenarioTypes.FromProject ? value.scenario?.selected?.name : value.test?.name;
		patch.testResultNameTemplate = value.testResultMask;
		const { type, items: zones } = value.zones || {};
		if (zones !== undefined && type !== undefined) {
			patch.lgsByZone = {};
			for (const zone of zones) {
				if (zone?.number !== undefined && zone.zoneId !== undefined && zone.number > 0 && patch.lgsByZone) {
					patch.lgsByZone[zone.zoneId] = zone.number;
				}
			}
		}
		setPatch(patch);
		setPrevious(value as ConfigurationFormData);
	}, [postUploadFile, previous, setValue, showError, t, value]);

	useDebounce(
		() => {
			if (Object.keys(patch).length > 0) {
				patchTest(patch).then((test) => {
					if (test) {
						setValue('test.isConfigured', test.isConfigured);
					}
				});
			}
		},
		500,
		[patch]
	);
};

export { useConfigPatch };
