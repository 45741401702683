import { forwardRef, MutableRefObject, Ref, useImperativeHandle, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { GridApi } from '@mui/x-data-grid-pro';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { rightClickSelection } from '../data-grid/events/selection';
import { ContextMenuItemProps } from '../data-grid/actions/data-grid-actions';

export type ContextMenuPosition = {
	mouseX: number;
	mouseY: number;
} | null;

export type ContextMenuFuncts = {
	openContextMenu: (event: React.MouseEvent) => void;
	closeContextMenu: () => void;
};

export type ContextMenuProps = {
	contextMenuItemsList: ContextMenuItemProps[];
	apiRef: MutableRefObject<GridApi>;
};

const ContextMenuItem = ({
	onClickMenuItem,
	closeContextMenu,
	text,
	color,
	icon,
	disabled,
}: Omit<ContextMenuItemProps, 'tooltipProps'> & {
	closeContextMenu: () => void;
}) => (
	<MenuItem
		onClick={(clickEvent) => {
			closeContextMenu();
			onClickMenuItem(clickEvent);
		}}
		disabled={disabled}
	>
		<ListItemIcon sx={{ color }}>{icon}</ListItemIcon>
		<ListItemText sx={{ color }}>{text}</ListItemText>
	</MenuItem>
);

const ContextMenu = forwardRef(
	({ contextMenuItemsList, apiRef }: ContextMenuProps, ref: Ref<ContextMenuFuncts | undefined>) => {
		const [contextMenu, setContextMenu] = useState<ContextMenuPosition>(null);

		const closeContextMenu = () => {
			setContextMenu(null);
		};

		const openContextMenu = (event: React.MouseEvent) => {
			event.preventDefault();
			const clickedId = event.currentTarget.getAttribute('data-id') as string;
			if (clickedId !== null) {
				rightClickSelection([...apiRef.current.getSelectedRows().keys()], clickedId, apiRef.current.setSelectionModel);
			}
			setContextMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
		};
		useImperativeHandle(ref, () => ({ openContextMenu, closeContextMenu }));

		return (
			<Menu
				open={contextMenu !== null}
				onClose={closeContextMenu}
				anchorReference='anchorPosition'
				anchorPosition={contextMenu === null ? undefined : { top: contextMenu.mouseY, left: contextMenu.mouseX }}
				slotProps={{
					root: {
						onContextMenu: (mouseEvent) => {
							mouseEvent.preventDefault();
							closeContextMenu();
						},
					},
				}}
			>
				{contextMenuItemsList
					.filter(({ hidden }) => !hidden)
					.map(({ onClickMenuItem, text, disabled, icon, color, tooltipProps }) => {
						if (disabled && tooltipProps) {
							return (
								<Tooltip {...tooltipProps} key={text} placement='top' role='tooltip'>
									<div>
										<ContextMenuItem
											onClickMenuItem={onClickMenuItem}
											closeContextMenu={closeContextMenu}
											disabled={disabled}
											text={text}
											icon={icon}
											color={color}
										/>
									</div>
								</Tooltip>
							);
						}

						return (
							<ContextMenuItem
								onClickMenuItem={onClickMenuItem}
								closeContextMenu={closeContextMenu}
								disabled={disabled}
								text={text}
								icon={icon}
								color={color}
								key={text}
							/>
						);
					})}
			</Menu>
		);
	}
);
export { ContextMenu };
