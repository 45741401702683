import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlined from '@mui/icons-material/ErrorOutlined';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';
import { gap, width } from '../constant';
import { TestExecutionForm } from '../types.d';
import { TextEllipsisTooltip } from '../../../common/text-ellipsis-tooltip';
import { timeUtils } from '@neoload/utils';

type TestExecutionTestConfigProps = {
	errorScenario: boolean;
};

const TestExecutionTestConfig = ({ errorScenario }: TestExecutionTestConfigProps) => {
	const { t, i18n } = useTranslation(['test']);
	const { getValues } = useFormContext<TestExecutionForm>();
	const theme = useTheme();
	const integerFormatter = new Intl.NumberFormat(i18n.language, { compactDisplay: 'short' });

	return (
		<Box sx={{ display: 'flex', gap: gap(theme), minWidth: '45%', flexDirection: 'column' }}>
			<Typography variant='subtitle1'>{t('test:testExecution:configuration')}</Typography>
			<Box sx={{ display: 'flex', gap: gap(theme) }}>
				<Typography variant='subtitle2' width={width}>
					{t('test:testExecution:project')}
				</Typography>
				<TextEllipsisTooltip
					variant='body2'
					aria-label={t('test:testExecution:project')}
					title={getValues('test.projectName')}
				/>
			</Box>
			<Box sx={{ display: 'flex', gap: gap(theme) }}>
				<Typography width={width} variant='subtitle2'>
					{t('test:testExecution:scenario')}
				</Typography>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
					<Typography variant='body2' aria-label={t('test:testExecution:scenario')}>
						{getValues('test.scenarioName')}
					</Typography>
					<Box display='flex'>
						<Typography variant='body2' sx={{ marginRight: theme.spacing(1) }} color={theme.palette.text.secondary}>
							{t('test:testExecution:duration')}:{' '}
						</Typography>
						<Typography
							variant='body2'
							color={theme.palette.text.primary}
							aria-label={t('test:testExecution:duration')}
						>
							{getValues('test.duration') === '' ? t('testExecution.durationUndetermined') : timeUtils.durationFull(getValues('test.duration'))}
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<Box sx={{ marginRight: theme.spacing(2), display: 'flex' }}>
							<Typography color={theme.palette.text.secondary} variant='body2' sx={{ marginRight: theme.spacing(1) }}>
								{t('test:testExecution:virtualUsers')}:
							</Typography>
							{errorScenario && (
								<ErrorOutlined sx={{ color: theme.palette.error.main, marginRight: '10px', marginLeft: '2px' }} />
							)}
							<Typography
								color={theme.palette.text.primary}
								variant='body2'
								aria-label={t('test:testExecution:virtualUsers')}
							>
								{integerFormatter.format(getValues('test.vus'))}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export { TestExecutionTestConfig };
