import Popover, { PopoverProps } from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { SlaStatus } from '../../slas/sla-status';
import { SlaThreshold, SlaThresholdCondition } from '@neoload/api';
import { SlaOperator, Sla, slaUtils } from '@neoload/utils';

type SlaValuePopoverProps = PopoverProps & {
	slaThreshHold: SlaThreshold;
};

export const SlaValuePopover = ({ slaThreshHold, anchorEl, open }: SlaValuePopoverProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['result']);

	const warningCondition = slaUtils.getSlaConditionBySeverity(slaThreshHold, 'WARNING');
	const failCondition = slaUtils.getSlaConditionBySeverity(slaThreshHold, 'CRITICAL');

	const createConditionLine = (operator: SlaOperator, value: number) => (
		<Typography variant='body1'>{slaUtils.formatConditions(value, operator, slaThreshHold.identifier)}</Typography>
	);

	const createConditions = (condition: SlaThresholdCondition) => 'BETWEEN' === condition.operator ? (
				<Grid sx={{ display: 'flex', flexDirection: 'column' }}>
					{condition.min !== undefined && createConditionLine('GREATER_THAN', condition.min)}
					{condition.max !== undefined && createConditionLine('LESS_THAN', condition.max)}
				</Grid>
			) : <Grid>{condition.min !== undefined && createConditionLine(condition.operator, condition.min)}</Grid>;

	const createThreshHoldInfo = (condition: SlaThresholdCondition, badgeStatus: Sla) => (
		<Grid sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
			<SlaStatus status={badgeStatus} />
			{createConditions(condition)}
		</Grid>
	);

	return (
		<Popover
			disableAutoFocus={true}
			sx={{
				pointerEvents: 'none',
				marginTop: theme.spacing(1),
			}}
			open={open}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			data-testid='slaValuePopover'
		>
			<Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', margin: 2, gap: 2 }}>
				<Typography variant='subtitle1' sx={{ fontWeight: theme.typography.fontWeightMedium }}>
					{t('slas.setThreshHolds')}
				</Typography>
				{failCondition && createThreshHoldInfo(failCondition, 'FAILED')}
				{warningCondition && createThreshHoldInfo(warningCondition, 'WARN')}
			</Grid>
		</Popover>
	);
};
