import { useEffect } from 'react';
import { useColorScheme } from '@mui/material/styles';
import { usePreferredTheme } from '@neoload/hooks';

/**
 * A hook controlling the app theme based on the user preferences.
 */
const useThemeMode = () => {
	const { setMode } = useColorScheme();
	const { preferredTheme } = usePreferredTheme();

	useEffect(() => {
		if (preferredTheme) {
			setMode(preferredTheme);
		}
	}, [preferredTheme, setMode]);
};

export { useThemeMode };
