const patterns = {
	base: '/user',
	accessTokens: '/user/access-tokens',
	profile: '/user/profile',
	gitRepositories: '/user/git-repositories',
};

const userRoutes = {
	patterns: patterns,
	base: patterns.base,
	accessTokens: patterns.accessTokens,
	profile: patterns.profile,
	gitRepositories: patterns.gitRepositories,
};

export { userRoutes as UserRoutes };
