import Highcharts, { PointDragEventObject, SeriesXrangeOptions, XAxisPlotBandsOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { GraphType, IntervalEditionDragState, IntervalEditionState } from './../result-overview-graphs';
import { Interval } from '@neoload/api';
import { convertShortToLongColor, DEFAULT_INTERVAL_COLOR, isRawDataAvailable, timeUtils } from '@neoload/utils';

const shade8p = '14';
const intervalEditionXRangeId = 'intervalEditionRange';

export type IntervalStyles = {
	defaultIntervalColor: string;
	handleLineColor: string;
	handleFillColor: string;
};

type ChartAndType = {
	chart?: Highcharts.Chart;
	graphType: GraphType;
};

export type PlotbandsAndRangeProps = {
	intervals: Interval[];
	usersChart?: Highcharts.Chart;
	requestsChart?: Highcharts.Chart;
	intervalEditionState?: IntervalEditionState;
	resultEndDate?: string;
	hoveredIntervalId?: string;
	intervalStyles: IntervalStyles;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMouseMovedOverPlotBand: (event: any, interval: Interval, graphType: GraphType) => void;
	onMouseOutOfPlotBand: () => void;
	intervalDragState: IntervalEditionDragState;
	onIntervalEditionDragged: (x1?: number, x2?: number) => void;
	onIntervalEditionDropped: () => void;
};

const commonXrangeSeriesOption: SeriesXrangeOptions = {
	type: 'xrange',
	states: {
		inactive: {
			//keep the range with the same opacity when not hovered. By default is invisible when not hovered
			opacity: 1,
		},
	},
	id: intervalEditionXRangeId,
	cursor: 'move',
	zIndex: 2,
	borderRadius: 0,
	borderWidth: 2,
	pointWidth: 500,
	animation: false,
	yAxis: 2,
	showInLegend: false,
	dragDrop: {
		draggableX: true,
		draggableEnd: true,
		draggableX1: true,
		draggableX2: true,
		draggableY: false,
		liveRedraw: true,
		dragHandle: {
			pathFormatter: () => ['M -4 43', 'A4,4 0 0,1 4,43', 'L 4 57', 'A4,4 0 0,1 -4,57', 'L -4 43', 'Z'],
			cursor: 'col-resize',
			lineWidth: 1,
		},
	},
};

const getSeriesXrangeOptions = (
	x1: number,
	x2: number,
	intervalEditionDragState: IntervalEditionDragState,
	onDrag: (x1?: number, x2?: number) => void,
	onDrop: () => void,
	intervalStyles: IntervalStyles,
	color: string
): SeriesXrangeOptions => {
	const longColor = convertShortToLongColor(color);
	return {
		...commonXrangeSeriesOption,
		data: [
			{
				x: x1,
				x2: x2,
				color: longColor + shade8p,
			},
		],
		borderColor: longColor,
		dragDrop: {
			...commonXrangeSeriesOption.dragDrop,
			dragMinX: intervalEditionDragState.minX,
			dragMaxX: intervalEditionDragState.maxX,
			dragHandle: {
				...commonXrangeSeriesOption.dragDrop?.dragHandle,
				lineColor: intervalStyles.handleLineColor,
				color: intervalStyles.handleFillColor,
			},
		},
		point: {
			events: {
				drag: function (dragEvent: PointDragEventObject) {
					if (dragEvent.newPoints) {
						/*eslint-disable @typescript-eslint/no-explicit-any*/
						const x1 = (dragEvent as any).newPoint.x;
						const x2 = (dragEvent as any).newPoint.x2;
						/*eslint-enable @typescript-eslint/no-explicit-any*/
						onDrag(x1, x2);
					}
				},
				drop: onDrop,
			},
		},
	};
};

/**
 * Builds the highcharts options for the xrange series used to replace the interval's plotband when editing it allowing
 * to drag and drop it on the graph.
 * @param interval the interval to add as xrange
 * @param intervalEditionDragState the dragging state of the overview graphs
 * @param onDrag the method to call when dragging the range
 * @param onDrop the method to call when dropping the range
 * @param intervalStyles a set of colors used to style the interval
 * */
const buildIntervalEditionSeriesXrange = (
	interval: Interval,
	intervalEditionDragState: IntervalEditionDragState,
	onDrag: (x1?: number, x2?: number) => void,
	onDrop: () => void,
	intervalStyles: IntervalStyles,
	color: string
): SeriesXrangeOptions => {
	const editionX1 = timeUtils.asMilliseconds(intervalEditionDragState.editedStartOffset ?? interval.startOffset);
	const editionX2 = timeUtils.asMilliseconds(intervalEditionDragState.editedEndOffset ?? interval.endOffset);
	return getSeriesXrangeOptions(editionX1, editionX2, intervalEditionDragState, onDrag, onDrop, intervalStyles, color);
};

/**
 * Default plotband at creation is initialized at [30% - 70%] of visible graph.
 */
const getPercentOfAbciss = (minX: number, maxX: number, percent: number) => minX + percent * (maxX - minX);

const getInitialCreationRangeStart = (minX: number, maxX: number) => getPercentOfAbciss(minX, maxX, 0.3);

const getInitialCreationRangeEnd = (minX: number, maxX: number) => getPercentOfAbciss(minX, maxX, 0.7);

/**
 * Builds the highcharts options for the xrange series used as temporary plotband during creation
 * to drag and drop it on the graph.
 * @param intervalEditionDragState the dragging state of the overview graphs
 * @param onDrag the method to call when dragging the range
 * @param onDrop the method to call when dropping the range
 * @param intervalStyles a set of colors used to style the interval
 * */
const buildIntervalCreationSeriesXrange = (
	intervalEditionDragState: IntervalEditionDragState,
	onDrag: (x1?: number, x2?: number) => void,
	onDrop: () => void,
	intervalStyles: IntervalStyles,
	color: string
): SeriesXrangeOptions | undefined => {
	if (intervalEditionDragState.editedStartOffset && intervalEditionDragState.editedEndOffset) {
		const creationX1 = timeUtils.asMilliseconds(intervalEditionDragState.editedStartOffset);
		const creationX2 = timeUtils.asMilliseconds(intervalEditionDragState.editedEndOffset);
		return getSeriesXrangeOptions(
			creationX1,
			creationX2,
			intervalEditionDragState,
			onDrag,
			onDrop,
			intervalStyles,
			color
		);
	}
};

const getIntervalDuration = (interval: Interval) =>
	timeUtils.asMilliseconds(interval.endOffset) - timeUtils.asMilliseconds(interval.startOffset);

/**
 * Sorts intervals by descending duration. UseFull to add in that order, so that smaller intervals may still be hoverable
 * even when included in bigger intervals, as mouse events will be handled in priority to the last added intervals
 * @param intervals the intervals to sort
 */
const sortIntervalsByDescDuration = (intervals: Interval[]) =>
	[...intervals].sort((interval1, interval2) => {
		if (getIntervalDuration(interval2) > getIntervalDuration(interval1)) {
			return 1;
		}
		return -1;
	});

/**
 * Returns whether a given interval is draggable on graph. This checks if the interval is being edited on a given graph
 * and if raw data is available
 * @param interval the interval
 * @param editionState the edition state of the overview graphs
 * @param graphType the graph type
 * @param resultEndDate the result's end date
 */
const isIntervalDraggableOnGraph = (
	interval: Interval,
	editionState: IntervalEditionState | undefined,
	graphType: GraphType,
	resultEndDate?: string
): boolean =>
	isRawDataAvailable(resultEndDate) &&
	editionState !== undefined &&
	editionState.mode === 'EDITION' &&
	interval.id === editionState.editedIntervalId &&
	graphType === editionState.editedChart;

/**
 * Returns whether an interval is being created on a given graph
 * @param editionState the edition state of the overview graphs
 * @param graphType the graph type
 */
const isIntervalBeingCreatedOnGraph = (editionState: IntervalEditionState | undefined, graphType: GraphType): boolean =>
	editionState !== undefined && editionState.mode === 'CREATION' && graphType === editionState.editedChart;

/**
 * Builds the plotband's options from a given array of intervals
 * @param intervals the intervals to add as plotbands
 * @param hoveredIntervalId the hovered interval's id if there is
 * @param editionState the graph overview's eidtion state
 * @param intervalStyles a set of colors used to style the interval
 * @param onMouseMove the method to call when moving the mouse over the graph
 * @param onMouseOut the method to call when moving the out of the graph
 * @param graphType the chart's type
 */
const getPlotBandsFromIntervals = (
	intervals: Interval[],
	hoveredIntervalId: string | undefined,
	editionState: IntervalEditionState | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMouseMove: (event: any, interval: Interval, graphType: GraphType) => void,
	onMouseOut: () => void,
	graphType: GraphType
): XAxisPlotBandsOptions[] =>
	sortIntervalsByDescDuration(intervals).map((interval) => {
		const color = convertShortToLongColor(getPlotbandColor(interval, graphType, editionState));
		const isHovered = hoveredIntervalId === interval.id;
		return {
			id: interval.id,
			from: timeUtils.asMilliseconds(interval.startOffset),
			to: timeUtils.asMilliseconds(interval.endOffset),
			color: color + shade8p,
			borderColor: isHovered ? color : color + shade8p,
			borderWidth: 1,
			zIndex: editionState ? 1 : 5,
			events: {
				mousemove: (mouseMoveEvent) => onMouseMove(mouseMoveEvent, interval, graphType),
				mouseout: onMouseOut,
			},
		};
	});

/**
 * Updates plotbands on a given chart, removing present ones if there are
 * @param intervals the intervals to add as plotbands
 * @param hoveredIntervalId the hovered interval's id if there is
 * @param editionState the graph overview's eidtion state
 * @param intervalStyles a set of colors used to style the interval
 * @param onMouseMove the method to call when moving the mouse over the graph
 * @param onMouseOut the method to call when moving the out of the graph
 * @param chartsAndType the chart & type to update
 */
const updatePlotBands = (
	intervals: Interval[],
	hoveredIntervalId: string | undefined,
	editionState: IntervalEditionState | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMouseMove: (event: any, interval: Interval, graphType: GraphType) => void,
	onMouseOut: () => void,
	chartsAndType: ChartAndType
) => {
	const plotBands: XAxisPlotBandsOptions[] = getPlotBandsFromIntervals(
		intervals,
		hoveredIntervalId,
		editionState,
		onMouseMove,
		onMouseOut,
		chartsAndType.graphType
	);
	const currentPlotBands = chartsAndType.chart?.xAxis[0].options.plotBands;
	if (currentPlotBands) {
		//it is needed to copy the array before removing plotbands. With spread or slice there is an eslint error
		//eslint-disable-next-line unicorn/prefer-spread
		for (const plotBand of currentPlotBands.slice()) {
			if (plotBand.id) {
				chartsAndType.chart?.xAxis[0].removePlotBand(plotBand.id);
			}
		}
	}
	for (const plotband of plotBands) {
		chartsAndType.chart?.xAxis[0].addPlotBand(plotband);
	}
};

/**
 * Updates the interval's edition range on a given chart. If one is already there, removes it.
 * @param plotbandEditionRange the range's option to add if any
 * @param chartAndType the chart & type to update
 */
const updateIntervalEditionRange = (
	plotbandEditionRange: SeriesXrangeOptions | undefined,
	chartAndType: ChartAndType
) => {
	const currentOption = chartAndType.chart?.get(intervalEditionXRangeId);
	if (chartAndType.chart && plotbandEditionRange) {
		//an update method exists but when the result is running, the handles are not repainted and stay where they were,
		//despite the range being correctly updated. Works fine with remove + add
		if (currentOption) {
			currentOption.remove();
		}
		chartAndType.chart?.addSeries(plotbandEditionRange);
	} else if (currentOption) {
		currentOption.remove();
	}
};

const getPlotbandColor = (interval: Interval, graphType: GraphType, editionState?: IntervalEditionState) => {
	//Edited interval might be a plotband and not a range if edges are not draggable so the use case exists
	const isBeingEdited = editionState?.editedChart === graphType && editionState?.editedIntervalId === interval.id;
	const editionColor = isBeingEdited ? editionState?.editedColor : undefined;
	return getDisplayedColor(editionColor, interval.color);
};

const getDisplayedColor = (editedColor?: string, intervalColor?: string): string =>
	editedColor ?? intervalColor ?? DEFAULT_INTERVAL_COLOR;

/**
 * Updates all plotbands and edition range for both graphs.
 * Edition takes place in one graph only, so filters the dragged interval if there is one to update it on the edited
 * graph. The other graph will have it as plotband
 */
const updatePlotbandAndEditionRange = (plotbandsAndRangeProps: PlotbandsAndRangeProps) => {
	const {
		intervals,
		usersChart,
		requestsChart,
		intervalEditionState,
		resultEndDate,
		hoveredIntervalId,
		intervalStyles,
		onMouseMovedOverPlotBand,
		onMouseOutOfPlotBand,
		intervalDragState,
		onIntervalEditionDragged,
		onIntervalEditionDropped,
	} = plotbandsAndRangeProps;
	const chartsAndTypes: ChartAndType[] = [
		{
			chart: usersChart,
			graphType: 'USERS',
		},
		{
			chart: requestsChart,
			graphType: 'REQUESTS',
		},
	];
	for (const chartAndType of chartsAndTypes) {
		const draggableInterval = intervals.find((interval) =>
			isIntervalDraggableOnGraph(interval, intervalEditionState, chartAndType.graphType, resultEndDate)
		);
		const otherIntervals = intervals.filter(
			(interval) => !isIntervalDraggableOnGraph(interval, intervalEditionState, chartAndType.graphType, resultEndDate)
		);
		updatePlotBands(
			otherIntervals,
			hoveredIntervalId,
			intervalEditionState,
			onMouseMovedOverPlotBand,
			onMouseOutOfPlotBand,
			chartAndType
		);
		if (!intervalDragState.isDragging) {
			let xRangeSeries: SeriesXrangeOptions | undefined;
			const color = getDisplayedColor(intervalEditionState?.editedColor, draggableInterval?.color);
			if (draggableInterval) {
				xRangeSeries = buildIntervalEditionSeriesXrange(
					draggableInterval,
					intervalDragState,
					onIntervalEditionDragged,
					onIntervalEditionDropped,
					intervalStyles,
					color
				);
			} else if (isIntervalBeingCreatedOnGraph(intervalEditionState, chartAndType.graphType)) {
				xRangeSeries = buildIntervalCreationSeriesXrange(
					intervalDragState,
					onIntervalEditionDragged,
					onIntervalEditionDropped,
					intervalStyles,
					color
				);
			}
			updateIntervalEditionRange(xRangeSeries, chartAndType);
		}
	}
};

/**
 * Returns the chart's DOMRect. This is used in creation mode, to position the popper above the graph
 * @param usersChart the user's chart
 */
const getChartsDomRect = (usersChart: HighchartsReact.RefObject | null): DOMRect | undefined => {
	const chartsDomRect: DOMRect | undefined = usersChart?.container.current?.getBoundingClientRect();
	const yOffset = usersChart?.chart.plotTop ?? 0;
	return chartsDomRect
		? new DOMRect(chartsDomRect.x, chartsDomRect.y + yOffset, chartsDomRect.width, chartsDomRect.height)
		: undefined;
};

const intervalGraphUtils = {
	buildIntervalEditionSeriesXrange,
	sortIntervalsByDescDuration,
	getPlotBandsFromIntervals,
	updateIntervalEditionRange,
	updatePlotBands,
	isIntervalDraggableOnGraph,
	commonXrangeSeriesOption,
	updatePlotbandAndEditionRange,
	getChartsDomRect,
	getInitialCreationRangeStart,
	getInitialCreationRangeEnd,
	getPlotbandColor,
};
export { intervalGraphUtils };
