import React, { useEffect, useMemo, useState } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { inputOffsetFormatUtils } from './input-offset-format-util';

type InputOffsetProps = {
	value?: string;
	maxValue?: string;
	includeMillis?: boolean;
	onChange: (value: string, isUserInput: boolean) => void;
	textFieldProps?: Pick<
		TextFieldProps,
		'color' | 'error' | 'helperText' | 'size' | 'variant' | 'placeholder' | 'InputProps' | 'disabled' | 'label'
	>;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	'data-testid'?: string;
};

type CustomProps = {
	customOnChange: (inputValue: string, isUserInput: boolean) => void;
};

const getPatternFormatCustom = (format: string) =>
	React.forwardRef<PatternFormatProps, CustomProps>(function PatternFormatCustom(props, ref) {
		const { customOnChange, ...other } = props;

		return (
			<PatternFormat
				{...other}
				format={format}
				mask='_'
				getInputRef={ref}
				onValueChange={(inputValue, sourceInfo) => {
					customOnChange(inputValue.formattedValue, sourceInfo.source === 'event');
				}}
			/>
		);
	});

const InputOffset = ({
	onChange,
	value = 'PT0S',
	maxValue,
	includeMillis = true,
	textFieldProps,
	'data-testid': dataTestId,
}: InputOffsetProps) => {
	const maxUnit = maxValue ? inputOffsetFormatUtils.getMaxUnitFromDuration(maxValue) : inputOffsetFormatUtils.daysInfo;
	const minUnit = includeMillis ? inputOffsetFormatUtils.millisInfo : inputOffsetFormatUtils.secondsInfo;
	const placeHolder = inputOffsetFormatUtils.getPlaceHolder(maxUnit, minUnit);
	const inputFormat = inputOffsetFormatUtils.getInputFormat(maxUnit, minUnit);
	const [time, setTime] = useState<string>(inputOffsetFormatUtils.getFormattedStringFromValue(value, maxUnit, minUnit));
	const patternFormatCustom = useMemo(() => getPatternFormatCustom(inputFormat), [inputFormat]);

	useEffect(() => {
		setTime(inputOffsetFormatUtils.getFormattedStringFromValue(value, maxUnit, minUnit));
	}, [value, maxUnit, minUnit]);

	return (
		<TextField
			{...textFieldProps}
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{...(dataTestId && { 'data-testid': dataTestId })}
			type='text'
			margin='none'
			placeholder={placeHolder}
			defaultValue={value}
			value={time}
			sx={{ width: '100%' }}
			InputProps={{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				inputComponent: patternFormatCustom as any,
				inputProps: {
					customOnChange: (inputValue: string, isUserInput: boolean) => {
						setTime(inputValue);
						onChange(
							inputOffsetFormatUtils.getValueFromFormattedString(inputValue, maxUnit, minUnit).toISOString(),
							isUserInput
						);
					},
				},
			}}
		/>
	);
};

export { InputOffset };
