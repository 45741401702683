import { ComponentPropsWithoutRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import {
	GridEnrichedColDef,
	GridFilterItem,
	GridFilterModel,
	GridFilterPanelProps,
	GridInitialState,
	GridLinkOperator,
	useGridApiRef,
} from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import { useTranslation } from 'react-i18next';
import { GridColumns, GridDensity, GridRowModel, GridSelectionModel, GridSortModel } from '@mui/x-data-grid/models';
import Lock from '@mui/icons-material/LockOutlined';
import LockOpenOutlined from '@mui/icons-material/LockOpenOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import DGCustomRow from '@tricentis/aura/components/DGCustomRow.js';
import DriveFileMoveOutlined from '@mui/icons-material/DriveFileMoveOutlined';
import { GridRowId } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import { ResultMoveDialog } from './move/result-move-dialog';
import { ResultsDataGridColumns } from './result-data-grid-columns';
import { getDeleteToolTip } from './tooltip-utils';
import { NoRowsOverlay } from '../../common/no-rows-overlay';
import { NoResultsOverlay } from '../../common/no-results-overlay';
import { defaultGridProps, onColumnChange } from '../../common/datagrid';
import { Datagrid } from '../../common/data-grid/datagrid';
import { ContextMenu, ContextMenuFuncts } from '../../common/context-menu/context-menu';
import {
	DatagridAction,
	filterToContextMenuItems,
	filterToSecondaryActions,
} from '../../common/data-grid/actions/data-grid-actions';
import { ColumnSortMapper, formatSort } from '../../common/data-grid/sort';
import { useColumnsState, useCurrentWorkspace, useDeleteResults, useGetMe, useLockResults } from '@neoload/hooks';
import {
	TestResult,
	TestResultSearchCriteria,
	useGetV4ResultsQuery,
	useGetV4ResultsSearchCriteriaQuery,
} from '@neoload/api';
import { createNeoLoadError, defaultRowsPerPage, isAlive, isDeletable } from '@neoload/utils';

const sortMappers: ColumnSortMapper[] = [
	{
		sortColumnField: 'errors',
		sortApiParameter: 'statTotalGlobalCountFailure',
	},
	{
		sortColumnField: 'requestPerSecond',
		sortApiParameter: 'statTotalRequestCountPerSecond',
	},
	{
		sortColumnField: 'transactionTime',
		sortApiParameter: 'statTotalTransactionDurationAverage',
	},
];

const getNewFilterArguments = (
	filterModel: GridFilterModel,
	previousDescription: string,
	searchCriteria: TestResultSearchCriteria | undefined
): FilterArgumentss => {
	const nextDateRange: string[] = getNewDateRangeValue(filterModel);
	const datesValid: boolean = areDatesValid(nextDateRange);
	const nextNameOrDescription: string = filterModel.quickFilterValues
		? filterModel.quickFilterValues.join(' ')
		: previousDescription;
	const nextStatusValue: FilterableRunStatus[] | null = getNewStatusValue(filterModel);
	const nextProjectNames: string[] | null = getNewProjectNames(filterModel);
	const nextTestIds: string[] | null = getNewTestIds(filterModel, searchCriteria);
	return {
		nameOrDescription: nextNameOrDescription,
		status: nextStatusValue ?? [],
		forceEmpty: !nextStatusValue || !datesValid || !nextProjectNames || !nextTestIds,
		startedBefore: datesValid ? nextDateRange[0] : '',
		stoppedAfter: datesValid ? nextDateRange[1] : '',
		projectNames: nextProjectNames ?? [],
		testIds: nextTestIds ?? [],
	};
};

const areDatesValid = (dateRange: string[]): boolean =>
	dateRange[0] === '' || dateRange[1] === '' || new Date(dateRange[0]) >= new Date(dateRange[1]);

const shouldUpdateFilter = (
	nextFilterArguments: FilterArgumentss,
	previousFilterArguments: FilterArgumentss
): boolean =>
	nextFilterArguments.nameOrDescription !== previousFilterArguments.nameOrDescription ||
	hasArrayChanged(nextFilterArguments.status, previousFilterArguments.status) ||
	hasArrayChanged(nextFilterArguments.projectNames, previousFilterArguments.projectNames) ||
	hasArrayChanged(nextFilterArguments.testIds, previousFilterArguments.testIds) ||
	nextFilterArguments.forceEmpty !== previousFilterArguments.forceEmpty ||
	nextFilterArguments.startedBefore !== previousFilterArguments.startedBefore ||
	nextFilterArguments.stoppedAfter !== previousFilterArguments.stoppedAfter;

const hasArrayChanged = (nextArray: unknown[], previousArray: unknown[]): boolean =>
	!(nextArray.length === previousArray.length && nextArray.every((element, index) => element === previousArray[index]));

const getNewDateRangeValue = (filterModel: GridFilterModel): string[] => {
	const dateItems: GridFilterItem[] = filterModel.items.filter(
		(item) => 'startDate' === item.columnField && item.value
	);

	const stoppedAfterValue =
		dateItems
			.filter((item) => item.operatorValue === 'after')
			.map((item) => new Date(item.value))
			.sort((a, b) => (a < b ? 1 : -1))
			.find(() => true)
			?.toISOString() ?? '';

	const startedBeforeValue =
		dateItems
			.filter((item) => item.operatorValue === 'before')
			.map((item) => new Date(item.value))
			.sort((a, b) => (a > b ? 1 : -1))
			.find(() => true)
			?.toISOString() ?? '';

	return [startedBeforeValue, stoppedAfterValue];
};

/**
 * Returns an array of test ids to pass as next query args. This is needed here
 * as a workaround of aura bug, being that the filter's chip group displays
 * value instead of labels, so we pass label only to the input, and compute value back here
 */
const getNewTestIds = (
	filterModel: GridFilterModel,
	searchCriteria: TestResultSearchCriteria | undefined
): string[] | null => {
	const names: string[] | null = getIsAnyOfOperatorValues(filterModel, 'testName') as string[] | null;
	if (names) {
		return searchCriteria
			? searchCriteria.tests.filter((test) => test.name && names.includes(test.name)).map((test) => test.id)
			: [];
	}
	return null;
};

const getNewProjectNames = (filterModel: GridFilterModel): string[] | null =>
	getIsAnyOfOperatorValues(filterModel, 'project') as string[] | null;

const getNewStatusValue = (filterModel: GridFilterModel): FilterableRunStatus[] | null =>
	getIsAnyOfOperatorValues(filterModel, 'qualityStatus') as FilterableRunStatus[] | null;

const getIsAnyOfOperatorValues = (filterModel: GridFilterModel, columnField: string): unknown[] | null => {
	const items: GridFilterItem[] = filterModel.items.filter(
		(item) => columnField === item.columnField && item.value?.length > 0
	);
	if (items.length === 0) {
		return [];
	}
	let result: NonNullable<unknown>[] = items[0].value;
	for (const item of items) {
		const values: NonNullable<unknown>[] = item.value;
		result = result.filter((element) => values.includes(element));
	}
	if (result.length === 0) {
		return null;
	}
	return result;
};

const prependEmptyTest = (
	emptyTestName: string,
	searchCriterias?: TestResultSearchCriteria
): TestResultSearchCriteria | undefined =>
	searchCriterias
		? { ...searchCriterias, tests: [{ id: 'Empty', name: emptyTestName }, ...searchCriterias.tests] }
		: undefined;

type FilterableRunStatus = 'PASSED' | 'FAILED' | 'RUNNING';

export type FilterArgumentss = {
	status: FilterableRunStatus[];
	nameOrDescription: string;
	forceEmpty: boolean;
	startedBefore: string;
	stoppedAfter: string;
	projectNames: string[];
	testIds: string[];
};

const initialArguments: FilterArgumentss = {
	status: [],
	nameOrDescription: '',
	forceEmpty: false,
	startedBefore: '',
	stoppedAfter: '',
	projectNames: [],
	testIds: [],
};

export type ResultDataGridProps = {
	// FilterModel in props to easily test the filter in component tests
	filterModel?: GridFilterModel;
	testId?: string;
	isAutoHeight?: boolean;
};

const isLocked = (result: TestResult) => result.locked;

const density: GridDensity = 'standard';

function getPollingInterval(shouldPoll: boolean) {
	return shouldPoll ? 5000 : 0;
}

function getSkip(isCurrentWorkspaceSelected: boolean, filterArguments: FilterArgumentss) {
	return !isCurrentWorkspaceSelected || filterArguments.forceEmpty;
}

function extractedSeachCriteria(
	testId: string | undefined,
	searchCriterias: TestResultSearchCriteria | undefined,
	results:
		| {
				total: number | undefined;
				pageNumber?: number;
				pageSize?: number;
				items: TestResult[];
		  }
		| undefined
) {
	if (testId !== undefined) {
		searchCriterias = searchCriterias && {
			...searchCriterias,
			projects: [...new Set(results?.items.map((result) => result.project))],
		};
	}
	return searchCriterias;
}

function getTestResultTooltip(runningResult: boolean, t: TFunction<string[], undefined>, selectedIds: GridRowId[]) {
	return runningResult
		? t('actions.moveTestResults.cantMoveTooltip')
		: t('actions.moveTestResults.title', { count: selectedIds.length });
}

function getSingleItem(
	t: TFunction<string[], undefined>,
	onToggleLockState: (resultIds: string[], locked: boolean) => void
) {
	return (result: TestResult) => ({
		icon: result.locked ? <LockOpenOutlined /> : <Lock />,
		text: t(result.locked ? 'header.menu.unlock' : 'header.menu.lock'),
		action: () => onToggleLockState([result.id], !result.locked),
	});
}

function extractedColumns(testId: string | undefined, columns: GridEnrichedColDef<TestResult>[]) {
	if (testId !== undefined) {
		columns = columns.filter(({ field }) => field !== 'testName');
	}
	return columns;
}

function getSecondaryActions(isGuest: boolean, selectedIds: GridRowId[], actions: DatagridAction[]) {
	return !isGuest && selectedIds.length > 0 ? filterToSecondaryActions(actions) : [];
}

const ResultDataGrid = ({ filterModel, testId, isAutoHeight }: ResultDataGridProps) => {
	const { t } = useTranslation(['result']);
	const [currentWorkspace, isCurrentWorkspaceSelected] = useCurrentWorkspace();
	const [sort, setSort] = useState<string>();
	const [filterArguments, setFilterArguments] = useState(initialArguments);
	const [shouldPoll, setShouldPoll] = useState(false);
	const apiRef = useGridApiRef();
	const [pageNumber, setPageNumber] = useState(0);
	const [pageSize, setPageSize] = useState(defaultRowsPerPage);
	const [{ isGuest }] = useGetMe();
	const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);
	const [movingResultIds, setMovingResultIds] = useState<string[]>([]);
	const [runningResult, setRunningResult] = useState(false);
	const [selectedResultsAreLocked, setSelectedResultsAreLocked] = useState<boolean>(true);
	const [selectedResultsAreDeletable, setSelectedResultsAreDeletable] = useState<boolean>(true);
	const [atLeastOneSelectedLocked, setAtLeastOneSelectedLocked] = useState<boolean>(false);
	const [atLeastOneSelectedAlive, setAtLeastOneSelectedAlive] = useState<boolean>(false);
	const resultListColumnsStateKey = 'RESULT_LIST_COLUMNS_STATE';
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();
	const deleteResults = useDeleteResults();
	const onToggleLockState = useLockResults();

	const {
		data: results = {
			items: [],
			total: 0,
			pageNumber,
			pageSize,
		},
		isLoading,
		isFetching,
		error: resultsError,
	} = useGetV4ResultsQuery(
		{
			workspaceId: currentWorkspace.id,
			sort,
			pageNumber,
			pageSize,
			...(filterArguments.nameOrDescription !== '' && { nameOrDescription: filterArguments.nameOrDescription }),
			...(filterArguments.status.length > 0 && { status: filterArguments.status }),
			...(filterArguments.projectNames.length > 0 && { projectNames: filterArguments.projectNames }),
			...(testId ? { testIds: [testId] } : filterArguments.testIds.length > 0 && { testIds: filterArguments.testIds }),
			...(filterArguments.startedBefore !== '' && { startedBefore: filterArguments.startedBefore }),
			...(filterArguments.stoppedAfter !== '' && { stoppedAfter: filterArguments.stoppedAfter }),
		},
		{ pollingInterval: getPollingInterval(shouldPoll), skip: getSkip(isCurrentWorkspaceSelected, filterArguments) }
	);

	const { data: originalSearchCriterias } = useGetV4ResultsSearchCriteriaQuery(
		{ workspaceId: currentWorkspace.id },
		{ skip: !isCurrentWorkspaceSelected }
	);
	let searchCriterias = prependEmptyTest(t('filters.emptyTestName'), originalSearchCriterias);

	searchCriterias = extractedSeachCriteria(testId, searchCriterias, results);

	useEffect(() => {
		setSelectedResultsAreLocked(results.items.filter((result) => selectedIds.includes(result.id)).every(isLocked));
	}, [results.items, selectedIds]);

	useEffect(() => {
		setSelectedResultsAreDeletable(
			results.items.filter((result) => selectedIds.includes(result.id)).every(isDeletable)
		);
	}, [results.items, selectedIds]);

	useEffect(() => {
		setAtLeastOneSelectedLocked(results.items.filter((result) => selectedIds.includes(result.id)).some(isLocked));
	}, [results.items, selectedIds]);

	useEffect(() => {
		setAtLeastOneSelectedAlive(results.items.filter((result) => selectedIds.includes(result.id)).some(isAlive));
	}, [results.items, selectedIds]);

	useEffect(() => {
		setRunningResult(results.items.filter((result) => selectedIds.includes(result.id)).some(isAlive));
	}, [selectedIds, results.items]);

	useEffect(() => {
		const aliveResultsPresent = results?.items.find(isAlive) !== undefined;
		if (aliveResultsPresent !== shouldPoll) {
			setShouldPoll(aliveResultsPresent);
		}
	}, [results, shouldPoll]);

	function openMoveTestResultsDialog(resultIds: string[]) {
		setMovingResultIds(resultIds);
	}

	const closeMoveTestResultsDialog = () => setMovingResultIds([]);

	const openDeleteConfirmationDialog = (resultIds: string[]) => {
		const resultsToDelete = results.items.filter(isDeletable).filter((result) => resultIds.includes(result.id));
		deleteResults(resultsToDelete);
	};

	const actions: DatagridAction[] = [
		{
			icon: <DriveFileMoveOutlined />,
			text: t('actions.moveTestResults.title', { count: 1 }),
			disabled: runningResult,
			action: () => openMoveTestResultsDialog(selectedIds as string[]),
			tooltip: getTestResultTooltip(runningResult, t, selectedIds),
			singleItem: (result: TestResult) => ({
				disabled: isAlive(result),
				action: () => openMoveTestResultsDialog([result.id]),
			}),
		},
		{
			icon: selectedResultsAreLocked ? <LockOpenOutlined /> : <Lock />,
			text: t(selectedResultsAreLocked ? 'header.menu.unlock' : 'header.menu.lock'),
			action: () => onToggleLockState(selectedIds as string[], !selectedResultsAreLocked),
			tooltip: t(selectedResultsAreLocked ? 'header.menu.unlock' : 'header.menu.lock'),
			singleItem: getSingleItem(t, onToggleLockState),
		},
		{
			icon: <DeleteOutlined />,
			text: t('common:delete'),
			disabled: !selectedResultsAreDeletable,
			color: 'error',
			action: () => openDeleteConfirmationDialog(selectedIds as string[]),
			tooltip: getDeleteToolTip(atLeastOneSelectedLocked, atLeastOneSelectedAlive, selectedIds.length, t),
			singleItem: (result: TestResult) => ({
				disabled: !isDeletable(result),
				action: () => openDeleteConfirmationDialog([result.id]),
			}),
		},
	];

	let columns: GridColumns<TestResult> = ResultsDataGridColumns(actions, density, isGuest, searchCriterias);
	columns = extractedColumns(testId, columns);

	const initialState: GridInitialState = {
		sorting: {
			sortModel: [{ field: 'startDate', sort: 'desc' }],
		},
		columns: { columnVisibilityModel: { description: false } },
		filter: { filterModel },
	};

	const { updatedColumns, updatedInitialState, storeColumnState } = useColumnsState(
		resultListColumnsStateKey,
		initialState,
		columns,
		apiRef
	);

	const componentsProps: {
		toolbar: ComponentPropsWithoutRef<typeof Toolbar>;
		row: GridRowModel;
		filterPanel: GridFilterPanelProps;
	} = {
		toolbar: {
			displayColumnOptions: true,
			description: t('description'),
			displaySearchBox: true,
			hideColumnsFromColumnOptions: ['__check__', 'name'],
			hideFiltersIcon: false,
			secondaryActions: getSecondaryActions(isGuest, selectedIds, actions),
			syncLocalStorage: {
				datagridId: 'neoloadResultDataGrid',
				isSyncEnabled: true,
			},
			title: t('results'),
		},
		row: {
			onContextMenu: contextMenu.current && !isGuest ? contextMenu.current.openContextMenu : null,
		},
		filterPanel: {
			linkOperators: [GridLinkOperator.And],
		},
	};

	const onSortModelChange = (gridSortModel: GridSortModel) => {
		setSort(formatSort(gridSortModel[0], sortMappers));
	};

	const handleOnPageChange = (page: number) => {
		setPageNumber(page);
	};

	const handleOnPageSizeChange = (pageSize: number) => {
		setPageSize(pageSize);
	};

	const onFilterChange = (filterModel: GridFilterModel) => {
		const nextFilterArguments: FilterArgumentss = getNewFilterArguments(
			initialState.filter?.filterModel ?? filterModel,
			filterArguments.nameOrDescription,
			searchCriterias
		);
		if (shouldUpdateFilter(nextFilterArguments, filterArguments)) {
			setFilterArguments(nextFilterArguments);
		}
	};

	if (resultsError) {
		throw createNeoLoadError(resultsError);
	}

	return (
		<>
			<Datagrid
				{...defaultGridProps}
				{...onColumnChange(storeColumnState)}
				apiRef={apiRef}
				rows={filterArguments.forceEmpty ? [] : results.items}
				loading={isLoading || isFetching}
				rowCount={results.total}
				columns={updatedColumns}
				disableSelectionOnClick={isGuest}
				checkboxSelection={!isGuest}
				initialState={updatedInitialState}
				componentsProps={componentsProps}
				sortingMode='server'
				onSortModelChange={onSortModelChange}
				paginationMode='server'
				page={pageNumber}
				pageSize={pageSize}
				autoHeight={results.items.length > 0 && isAutoHeight}
				density={density}
				onPageChange={handleOnPageChange}
				onPageSizeChange={handleOnPageSizeChange}
				components={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					Toolbar: Toolbar,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					Row: DGCustomRow,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					NoRowsOverlay: NoRowsOverlay,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					NoResultsOverlay: NoResultsOverlay,
				}}
				onFilterModelChange={onFilterChange}
				onSelectionModelChange={setSelectedIds}
				sx={{
					height: results.items.length === 0 ? '100%' : undefined,
					'& .MuiLink-root': {
						textDecoration: 'none',
					},
					'& .MuiLink-root:hover': {
						textDecoration: 'underline',
					},
				}}
			/>
			<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
			<ResultMoveDialog
				isOpen={movingResultIds.length > 0}
				onClose={closeMoveTestResultsDialog}
				movingResultIds={movingResultIds}
			/>
		</>
	);
};

export { ResultDataGrid };

export const visibleForTesting = {
	getIsAnyOfOperatorValues,
	getNewDateRangeValue,
	getNewFilterArgs: getNewFilterArguments,
	shouldUpdateFilter,
	getNewTestIds,
	prependEmptyTest,
};
