import { ComponentPropsWithoutRef, useState } from 'react';
import { GridFilterPanelProps, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { GridColumns, GridFilterItem, GridFilterModel, GridLinkOperator } from '@mui/x-data-grid/models';
import { EventsDatagridColumns, FilterableTypes, defaultFilters } from './events-datagrid-columns';
import { Spinner } from '../../../common/spinner';
import { ColumnSortMapper, formatSort } from '../../../common/data-grid/sort/sort-util';
import { ConverterConfig, downloadAsCsv } from '../../../common/data-grid/csv/download-as-csv';
import { getOffsetRangeValuesFromOffsetPicker } from '../../../common/data-grid/input/datagrid-offset-filter-input';
import { Datagrid } from '../../../common/data-grid/datagrid';
import { defaultGridProps, onColumnChange } from '../../../common/datagrid';
import { dataGridStyle } from '../values/values-data-grid-common';
import { Event, GetV4ResultsByResultIdEventsApiArg, useGetV4ResultsByResultIdEventsQuery } from '@neoload/api';
import { defaultRowsPerPage, createNeoLoadError, timeUtils } from '@neoload/utils';
import { useColumnsState, useLoadAllPages } from '@neoload/hooks';

const eventColumnsKey = 'EVENTS_COLUMNS_STATE';

export type EventsDataGridProps = {
	resultId: string;
	eventIdShown?: string;
	showDetails: (event: Event, openOnScreenShot: boolean) => void;
	isAlive: boolean;
};

type FilterArguments = {
	types: FilterableTypes;
	fromOffset: string;
	toOffset: string;
};

const initialArguments: FilterArguments = {
	types: [],
	fromOffset: '',
	toOffset: '',
};

const initialState = {
	columns: {
		columnVisibilityModel: {
			type: false,
		},
	},
	pinnedColumns: { right: ['details'] },
};

const sortMappers: ColumnSortMapper[] = [
	{
		sortColumnField: 'timeRange',
		sortApiParameter: 'offset',
	},
];

const eventConverter: ConverterConfig<Event> = {
	id: {
		columnName: 'Id',
		convert: (value) => value,
	},
	type: {
		columnName: 'Type',
		convert: (value) => value,
	},
	offset: {
		columnName: 'Offset(ms)',
		convert: (value) => (value ? timeUtils.asMilliseconds(value).toFixed(0) : ''),
	},
	duration: {
		columnName: 'Duration(ms)',
		convert: (value) => (value ? timeUtils.asMilliseconds(value).toFixed(0) : ''),
	},
	fullName: {
		columnName: 'Name',
		convert: (value) => value ?? '',
	},
	code: {
		columnName: 'Code',
		convert: (value) => value ?? '',
	},
	source: {
		columnName: 'Source',
		convert: (value) => value,
	},
};

const hasArrayChanged = (nextArray: unknown[], previousArray: unknown[]): boolean =>
	!(nextArray.length === previousArray.length && nextArray.every((element, index) => element === previousArray[index]));

const getNewTypeValues = (filterModel: GridFilterModel): FilterableTypes | null => {
	const typeItems: GridFilterItem[] = filterModel.items.filter(
		(item) => 'type' === item.columnField && item.value?.length > 0
	);
	if (typeItems.length === 0) {
		return [];
	}
	let result: NonNullable<FilterableTypes> = [];
	for (const type of typeItems) {
		if (type.operatorValue === 'is') {
			result = [...result, type.value.toString().toUpperCase()];
		}
	}
	if (result.length === 0) {
		return null;
	}
	return result;
};

const EventsDataGrid = ({ resultId, showDetails, eventIdShown, isAlive }: EventsDataGridProps) => {
	const [sort, setSort] = useState<string>();
	const apiRef = useGridApiRef();
	const [pageSize, setPageSize] = useState(defaultRowsPerPage);
	const [pageNumber, setPageNumber] = useState(0);
	const [filterArguments, setFilterArguments] = useState<FilterArguments>(initialArguments);
	const { t } = useTranslation(['common']);

	const apiParameterFilters: Pick<GetV4ResultsByResultIdEventsApiArg, 'types' | 'fromOffset' | 'toOffset'> = {
		...(filterArguments.types.length > 0 && { types: filterArguments.types }),
		...(filterArguments.fromOffset && { fromOffset: filterArguments.fromOffset }),
		...(filterArguments.toOffset && { toOffset: filterArguments.toOffset }),
	};

	const [isLoadingForCsv, setIsLoadingForCsv] = useState(false);
	const onAllEventsLoaded = (allEvents: Event[]): void => {
		downloadAsCsv<Event>(eventConverter, allEvents, `${resultId}_events.csv`);
		setIsLoadingForCsv(false);
	};

	const { stepPage, totalPage } = useLoadAllPages<typeof useGetV4ResultsByResultIdEventsQuery>({
		// TODO - Fix react-compiler
		// eslint-disable-next-line react-compiler/react-compiler
		hook: useGetV4ResultsByResultIdEventsQuery,
		params: { resultId, sort, pageSize: 2000, ...apiParameterFilters },
		skip: !isLoadingForCsv,
		onAllItemsLoaded: onAllEventsLoaded,
	});

	const {
		data: eventsPage,
		isLoading,
		isFetching,
		error,
		refetch,
	} = useGetV4ResultsByResultIdEventsQuery({ resultId, sort, pageNumber, pageSize, ...apiParameterFilters });

	const columns: GridColumns<Event> = EventsDatagridColumns();
	const { updatedColumns, updatedInitialState, storeColumnState } = useColumnsState(
		eventColumnsKey,
		initialState,
		columns,
		apiRef
	);

	if (isLoading) {
		return <Spinner />;
	}
	if (!eventsPage) {
		throw createNeoLoadError(error);
	}

	const refreshButton = {
		children: (
			<Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<RefreshOutlined color='primary' sx={{ width: '20px', marginRight: '4px' }} />
				<Typography color='primary' variant='body2'>
					{t('refresh')}
				</Typography>
			</Grid>
		),
		disabled: isFetching,
		onClick: () => refetch(),
	};
	const secondaryAction: ComponentPropsWithoutRef<typeof Toolbar>['secondaryActions'] = [
		...(isAlive ? [refreshButton] : []),
		{
			children: isLoadingForCsv ? <CircularProgress size={20} color='inherit' /> : <DownloadOutlined />,
			disabled: isLoadingForCsv,
			onClick: () => setIsLoadingForCsv(true),
			tooltipProps: {
				arrow: true,
				title: isLoadingForCsv ? t('result:events.downloadingCsv', { stepPage, totalPage }) : t('downloadCsv'),
			},
		},
	];

	const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar>; filterPanel: GridFilterPanelProps } = {
		toolbar: {
			displaySearchBox: false,
			hideFiltersIcon: false,
			displayColumnOptions: true,
			secondaryActions: secondaryAction,
			syncLocalStorage: {
				datagridId: 'neoloadEventsDataGrid',
				isSyncEnabled: true,
			},
			defaultFilters: defaultFilters,
		},
		filterPanel: {
			linkOperators: [GridLinkOperator.And],
		},
	};

	const onSortModelChange = (gridSortModel: GridSortModel) => {
		setSort(formatSort(gridSortModel[0], sortMappers));
	};

	const onPageSizeChange = (nextPageSize: number) => {
		setPageSize(nextPageSize);
	};

	const onPageChange = (page: number) => {
		setPageNumber(page);
	};

	const shouldUpdateFilter = (nextFilterArguments: FilterArguments, currentFilterArguments: FilterArguments): boolean =>
		hasArrayChanged(nextFilterArguments.types, currentFilterArguments.types) ||
		nextFilterArguments.fromOffset !== currentFilterArguments.fromOffset ||
		nextFilterArguments.toOffset !== currentFilterArguments.toOffset;

	const getNewFilterArguments = (filterModel: GridFilterModel): FilterArguments => {
		const nextTypeValues: FilterableTypes | null = getNewTypeValues(filterModel);
		const nextOffsetRange: string[] = getOffsetRangeValuesFromOffsetPicker(filterModel);
		return {
			types: nextTypeValues ?? [],
			fromOffset: nextOffsetRange[0],
			toOffset: nextOffsetRange[1],
		};
	};

	const onFilterChange = (filterModel: GridFilterModel) => {
		const nextFilterArguments: FilterArguments = getNewFilterArguments(filterModel);
		if (shouldUpdateFilter(nextFilterArguments, filterArguments)) {
			setFilterArguments(nextFilterArguments);
		}
	};

	return (
		<Datagrid
			apiRef={apiRef}
			loading={isFetching}
			{...defaultGridProps}
			{...onColumnChange(storeColumnState)}
			disableSelectionOnClick={false}
			checkboxSelection={false}
			rows={eventsPage.items}
			rowCount={eventsPage.total}
			columns={updatedColumns}
			sortingMode={'server'}
			onSortModelChange={onSortModelChange}
			initialState={updatedInitialState}
			pageSize={pageSize}
			onPageChange={onPageChange}
			density='compact'
			paginationMode={'server'}
			onCellClick={(params) => showDetails(params.row, params.row.hasScreenshot && params.field === 'screenshot')}
			selectionModel={eventIdShown ? [eventIdShown] : []}
			components={{
				// eslint-disable-next-line @typescript-eslint/naming-convention
				Toolbar,
			}}
			componentsProps={componentsProps}
			onPageSizeChange={onPageSizeChange}
			filterMode='server'
			onFilterModelChange={onFilterChange}
			sx={{ ...dataGridStyle }}
		/>
	);
};

export { EventsDataGrid };
