import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { SlaStatus } from '../../slas/sla-status';
import { Sla } from '@neoload/utils';

export const SlaBadgeWithPercentage = ({ status, value }: { status: Sla; value: number }) => {
	const { i18n } = useTranslation();
	const theme = useTheme();
	const formatter = new Intl.NumberFormat(i18n.language, {
		compactDisplay: 'short',
		style: 'percent',
		maximumFractionDigits: 0,
	});
	return (
		<SlaStatus
			status={status}
			variant='body2'
			label={formatter.format(value)}
			labelSx={{ color: theme.palette.text.primary }}
		/>
	);
};
