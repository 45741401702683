const workspace = {
	selectWorkspace: 'Workspaces',
	workspaceOf: 'Workspace of',
	edit: 'Manage workspace(s)',

	deleteDialog: {
		title: 'Remove {{workspace}}',
		label: 'Are you sure you want to remove {{user}} from the workspace: "{{workspace}}"?',
	},
};
export { workspace };
