import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { applicationsTitleList, productName } from '@neoload/utils';

export const useDocumentTitleHandler = (page?: string, subPage?: string) =>
	useEffect(() => {
		document.title = [subPage, page, productName].filter(Boolean).join(' | ');
	}, [page, subPage]);

export const usePushDocumentTitleHandler = (subPage?: string) => {
	const location = useLocation();
	const { t } = useTranslation(['menu']);

	useEffect(() => {
		const oldTitle = document.title.split('|');
		if (oldTitle.length === 1) {
			const foundRoute = applicationsTitleList(t).find((route) =>
				location.pathname.toLowerCase().startsWith(route.path.toLowerCase())
			);
			if (foundRoute) {
				document.title = [subPage, foundRoute.title, productName].filter(Boolean).join(' | ');
			}
		} else {
			document.title = [subPage, oldTitle.at(-2)?.trim(), productName].filter(Boolean).join(' | ');
		}
	}, [location.pathname, subPage, t]);
};
