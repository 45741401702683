import { SlaRow, SlasDatagrid } from './slas-data-grid';
import { Spinner } from '../../../common/spinner';
import { createNeoLoadError } from '@neoload/utils';
import { TestResult } from '@neoload/api';
import { useGetSlas } from '@neoload/hooks';

export type ResultTabSlasProps = {
	result: {
		id: string;
		status: TestResult['status'];
		qualityStatus: TestResult['qualityStatus'];
	};
};

const ResultTabSlas = ({ result }: ResultTabSlasProps) => {
	const {
		data: slasGlobal,
		isLoading: isLoadingGlobal,
		isFetching: isFetchingGlobal,
		error: slasErrorGlobal,
	} = useGetSlas(result, 'PER_RUN', ['SCENARIO']);

	const {
		data: slasPerRun,
		isLoading: isLoadingPerRun,
		isFetching: isFetchingPerRun,
		error: slasErrorPerRun,
	} = useGetSlas(result, 'PER_RUN', ['TRANSACTION', 'PAGE', 'ACTION', 'REQUEST']);

	const {
		data: slasPerInterval,
		isLoading: isLoadingPerInterval,
		isFetching: isFetchingPerInterval,
		error: slasErrorPerInterval,
	} = useGetSlas(result, 'PER_TIME_INTERVAL');

	if (!slasPerInterval || !slasPerRun || !slasGlobal) {
		if (slasErrorGlobal) {
			throw createNeoLoadError(slasErrorPerInterval);
		}
		if (slasErrorPerInterval) {
			throw createNeoLoadError(slasErrorPerInterval);
		}
		if (slasErrorPerRun) {
			throw createNeoLoadError(slasErrorPerRun);
		}
		return null;
	}
	if (isLoadingPerInterval && isLoadingPerRun && isLoadingGlobal) {
		return <Spinner />;
	}
	const isFetching = isFetchingPerInterval || isFetchingPerRun || isFetchingGlobal;
	const rows: SlaRow[] = [
		{ path: 'GLOBAL', statuses: slasGlobal.statuses, rowCategory: 'TREE' },
		...slasGlobal.items.map((item, index) => ({
			...item,
			path: `GLOBAL/${item.elementId}-${item.slaThreshold.id}-${index}`,
			rowCategory: 'GLOBAL' as const,
		})),
		{ path: 'PER_RUN', statuses: slasPerRun.statuses, rowCategory: 'TREE' },
		...slasPerRun.items.map((item, index) => ({
			path: `PER_RUN/${item.elementId}-${item.slaThreshold.id}-${index}`,
			...item,
			rowCategory: 'PER_RUN' as const,
		})),
		{ path: 'PER_TIME_INTERVAL', statuses: slasPerInterval.statuses, rowCategory: 'TREE' },
		...slasPerInterval.items.map((item, index) => ({
			path: `PER_TIME_INTERVAL/${item.elementId}-${item.slaThreshold.id}-${index}`,
			...item,
			rowCategory: 'PER_TIME_INTERVAL' as const,
		})),
	];

	return <SlasDatagrid rows={rows} isFetching={isFetching} />;
};
export { ResultTabSlas };
