import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import IconPlaylistsOutlined from '@tricentis/aura/components/IconPlaylistsOutlined.js';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { MouseEventHandler } from 'react';
import DifferenceOutlined from '@mui/icons-material/DifferenceOutlined';
import Box from '@mui/material/Box';
import { RecommendationSection } from './recommendation-section';
import { CommonRoutes } from '@neoload/utils';
import { useGetMe } from '@neoload/hooks';

type CustomButtonProps = {
	variant: 'outlined' | 'text' | 'contained';
	text: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
};
const CustomButton = ({ variant, text, onClick }: CustomButtonProps) => (
	<Button variant={variant} sx={{ padding: (theme) => theme.spacing(0.5, 1.3) }} onClick={onClick}>
		{text}
	</Button>
);

export const RecommendationsTile = () => {
	const { t } = useTranslation(['home'], { keyPrefix: 'grid.tile.recommendations' });
	const navigate = useNavigate();
	const [me] = useGetMe();

	return (
		<Box sx={{ height: '100%', padding: (theme) => theme.spacing(2) }}>
			<Stack sx={{ height: '100%' }} spacing={2}>
				<RecommendationSection
					title={t('tests.title')}
					content={t('tests.content')}
					buttons={
						<Stack direction={{ sm: 'column', md: 'column', lg: 'row' }} spacing={2} alignItems={'flex-start'}>
							{me.hasTesterPermissions && (
								<CustomButton
									variant={'contained'}
									text={t('tests.createTest')}
									onClick={() => navigate(CommonRoutes.tests.base + '?createTest=true')}
								/>
							)}
							<CustomButton
								variant={'outlined'}
								text={t('tests.viewTests')}
								onClick={() => navigate(CommonRoutes.tests.base)}
							/>
						</Stack>
					}
					image={
						<DifferenceOutlined sx={{ width: '48px', height: '48px', color: (theme) => theme.palette.primary.main }} />
					}
				/>
				<RecommendationSection
					title={t('results.title')}
					content={t('results.content')}
					buttons={
						<CustomButton
							variant={'outlined'}
							text={t('results.viewResults')}
							onClick={() => navigate(CommonRoutes.results.base)}
						/>
					}
					image={
						<IconPlaylistsOutlined
							sx={{ width: '48px', height: '48px', color: (theme) => theme.palette.primary.main }}
						/>
					}
				/>
				<RecommendationSection
					title={t('dashboards.title')}
					content={t('dashboards.content')}
					buttons={
						<Stack
							direction={{ sm: 'column', md: 'column', lg: 'column', xl: 'row' }}
							spacing={2}
							alignItems={'flex-start'}
						>
							{me.hasTesterPermissions && (
								<CustomButton
									variant={'contained'}
									text={t('dashboards.createDashboard')}
									onClick={() => navigate(CommonRoutes.dashboards.base + '?createDashboard=true')}
								/>
							)}
							<CustomButton
								variant={'outlined'}
								text={t('dashboards.viewDashboards')}
								onClick={() => navigate(CommonRoutes.dashboards.base)}
							/>
						</Stack>
					}
					image={
						<DashboardOutlinedIcon
							sx={{ width: '48px', height: '48px', color: (theme) => theme.palette.primary.main }}
						/>
					}
				/>
			</Stack>
		</Box>
	);
};
