import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { ZoneTypes } from './zone-types';
import { Zones } from './zones';
import { ConfigurationFormData } from '../types.d';
import { TestConfiguration } from '../test-configuration';

const LoadZones = () => {
	const { t } = useTranslation(['test']);
	const disabled = useWatch<ConfigurationFormData, 'configurationDisabled'>({ name: 'configurationDisabled' });

	return (
		<TestConfiguration name={t('configuration.zones.title')} disabled={disabled}>
			<ZoneTypes disabled={disabled} />
			{!disabled && <Zones />}
		</TestConfiguration>
	);
};

export { LoadZones };
