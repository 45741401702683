import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { TestExecutionResultInformation } from './sections/test-execution-result-information';
import { TestExecutionConfiguration } from './sections/test-execution-configuration';
import { TestExecutionErrorTypes, TestExecutionForm } from './types.d';
import { executionModalErrors, getTestExecutionPartialError, isFetchBaseQueryError } from './test-execution-helpers';
import {
	SetConfirmableFunction,
	TestExecutionInput,
	usePostV4TestExecutionsMutation,
	ValueUpdaterFunction,
} from '@neoload/api';

type TestExecutionProps = {
	defaultValues: TestExecutionForm;
	valueUpdater: ValueUpdaterFunction;
	setConfirmable: SetConfirmableFunction;
};

const TestExecution = ({ defaultValues, valueUpdater, setConfirmable }: TestExecutionProps) => {
	const { t } = useTranslation(['test']);

	const { control, getValues, setValue, ...methods } = useForm<TestExecutionForm>({
		mode: 'onChange',
		defaultValues,
	});
	const [testExecution] = usePostV4TestExecutionsMutation();

	const fields = useWatch<TestExecutionForm>({ control });
	const errors = useWatch<TestExecutionForm, 'errors'>({ control, name: 'errors' });
	const errorsGlobal = errors.filter((error) => error.type === TestExecutionErrorTypes.Global);
	const isLicenseForStaticResourceMissing =
		!fields.resources?.reservationId &&
		getValues('resources.zoneType') === 'STATIC' &&
		getValues('resources.webVu.needed') === 0 &&
		getValues('resources.sapVu.needed') === 0 &&
		errors.length === 0;

	useEffect(() => {
		if (fields.test?.id && errors.length === 0) {
			const inputs: TestExecutionInput = fields.resources?.reservationId
				? {
						testId: fields.test?.id,
						name: fields.test?.name,
						description: fields.test?.description,
						reservationId: fields.resources?.reservationId,
				  }
				: {
						testId: fields.test?.id,
						name: fields.test?.name,
						description: fields.test?.description,
						webVu: fields.resources?.webVu?.needed,
						sapVu: fields.resources?.sapVu?.needed,
						duration: fields.resources?.duration,
				  };

			valueUpdater(inputs);
			setConfirmable(false);
			testExecution({
				dryRun: true,
				testExecutionInput: inputs,
			})
				.unwrap()
				.then((testExecution) => {
					setValue('errors', []);
					setValue(
						'resources.cloudCredits.needed',
						testExecution.resources.consumedCurrencyByConcurrencyType?.CLOUD_CREDITS ?? 0
					);
					setValue('resources.vuh.needed', testExecution.resources.consumedCurrencyByConcurrencyType?.VUH ?? 0);
					setConfirmable(true);
				})
				.catch((error) => {
					if (isFetchBaseQueryError(error)) {
						const partialError = getTestExecutionPartialError(error);
						if (partialError?.errors) {
							setValue('errors', executionModalErrors(partialError.errors));
						}
					}
				});
		}
	}, [
		setConfirmable,
		fields.resources?.webVu?.needed,
		fields.resources?.sapVu?.needed,
		fields.resources?.duration,
		fields.test?.id,
		fields.test?.name,
		fields.test?.description,
		fields.resources?.reservationId,
		setValue,
		testExecution,
		valueUpdater,
		errors.length,
	]);

	return (
		<FormProvider {...methods} control={control} getValues={getValues} setValue={setValue}>
			{errorsGlobal.length > 0 && (
				<Alert sx={{ marginBottom: 2, marginTop: 0, alignItems: 'center' }} severity='error'>
					<Typography variant='body2'>{errorsGlobal[0].sentence}</Typography>
				</Alert>
			)}
			{isLicenseForStaticResourceMissing && (
				<Alert sx={{ marginBottom: 2, marginTop: 0, alignItems: 'center' }} severity='warning'>
					<Typography variant='body2'>{t('testExecution.warningRunTestWithoutValidLicense')}</Typography>
				</Alert>
			)}
			<TestExecutionConfiguration />
			<Divider />
			<TestExecutionResultInformation />
		</FormProvider>
	);
};

export { TestExecution };
