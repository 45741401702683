import { ApiError, InvalidPropertyValueErrorDescription, isInvalidPropertyError } from '@neoload/api';

export enum ConfigurationErrorTypes {
	MissingProjectContent = 'MissingProjectContent',
	InvalidFile = 'InvalidFile',
	InvalidProject = 'InvalidProject',
	ParsingFailure = 'ParsingFailure',
	NotImplemented = 'NotImplemented',
}

export type ConfigurationError = { type: ConfigurationErrorTypes; sentenceKey: string; sentence: string };

export const translateErrorMessages = (error: ApiError): ConfigurationError[] => {
	const testExecutionErrors: ConfigurationError[] = [];
	if (isInvalidPropertyError(error) && error.errors !== undefined) {
		for (const apiError of error.errors) {
			testExecutionErrors.push(translateApiError(apiError));
		}
	}

	return testExecutionErrors;
};

const translateApiError = (apiError: InvalidPropertyValueErrorDescription): ConfigurationError => {
	switch (apiError.validationType) {
		case 'required': {
			return {
				type: ConfigurationErrorTypes.InvalidFile,
				sentence: apiError.message,
				sentenceKey: 'configuration.errors.invalidFile',
			};
		}
		case 'notFound': {
			return {
				type: ConfigurationErrorTypes.MissingProjectContent,
				sentence: apiError.message,
				sentenceKey: 'configuration.errors.missingProjectContent',
			};
		}
		case 'parsingFailure': {
			return {
				type: ConfigurationErrorTypes.ParsingFailure,
				sentence: apiError.message,
				sentenceKey: 'configuration.errors.parsingFailure',
			};
		}
		case 'incompatible': {
			return {
				type: ConfigurationErrorTypes.InvalidProject,
				sentence: apiError.message,
				sentenceKey: 'configuration.errors.invalidProject',
			};
		}
		default: {
			return {
				type: ConfigurationErrorTypes.NotImplemented,
				sentence: apiError.message,
				sentenceKey: '',
			};
		}
	}
};
