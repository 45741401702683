import { useEffect, useState } from 'react';
import { EventsDataGrid } from './events-data-grid';
import { EventDrawer } from './drawer/event-drawer';
import { Event } from '@neoload/api';

export type ResultTabEventProps = {
	resultId: string;
	isAlive: boolean;
};

type DetailsToOpen = {
	event: Event;
	showScreenShot: boolean;
};

const ResultTabEvent = ({ resultId, isAlive }: ResultTabEventProps) => {
	const [detailsToOpen, setDetailsToOpen] = useState<DetailsToOpen>();
	useEffect(() => {
		setDetailsToOpen(undefined);
	}, [resultId]);
	return (
		<div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
			<EventsDataGrid
				resultId={resultId}
				eventIdShown={detailsToOpen?.event.id}
				showDetails={(event, openOnScreenShot) =>
					setDetailsToOpen({
						event: event,
						showScreenShot: openOnScreenShot,
					})
				}
				isAlive={isAlive}
			/>
			{!!detailsToOpen?.event && (
				<EventDrawer
					resultId={resultId}
					event={detailsToOpen.event}
					showScreenShot={detailsToOpen.showScreenShot}
					onClose={() => setDetailsToOpen(undefined)}
				/>
			)}
		</div>
	);
};

export { ResultTabEvent };
