import { useColorScheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Preferences, useGetV4MePreferencesQuery, usePatchV4MePreferencesMutation } from '@neoload/api';

type Theme = NonNullable<Parameters<ReturnType<typeof useColorScheme>['setMode']>[0]>;

const convertThemeFromPreferences = (themeFromPreferences?: Preferences['theme']): Theme => {
	switch (themeFromPreferences) {
		case 'LIGHT': {
			return 'light';
		}
		case 'DARK': {
			return 'dark';
		}
		default: {
			return 'light';
		}
	}
};

const usePreferredTheme = () => {
	const { data } = useGetV4MePreferencesQuery();
	const [updatePreferences] = usePatchV4MePreferencesMutation();

	const preferredTheme = useMemo(() => convertThemeFromPreferences(data?.theme), [data]);

	const switchPreferredTheme = useCallback(
		() => updatePreferences({ patchPreferencesRequest: { theme: preferredTheme === 'light' ? 'DARK' : 'LIGHT' } }),
		[preferredTheme, updatePreferences]
	);

	return { preferredTheme, switchPreferredTheme };
};
export { usePreferredTheme };
