import { useEffect, useMemo, useState } from 'react';
import { FetchResult, LoadingState } from './types';
import { startFetching, computeLoadingState } from './fetcher-utils';
import { useReloading } from './use-reloading';
import {
	ValuesComparisonDashboardTile,
	MonitorsValuesPage,
	GetResultElementValuesResponse,
	ElementComparisonRow,
	ValueComparisonRow,
	useLazyGetV4ResultsByResultIdMonitorsValuesQuery,
	useLazyGetV4ResultsByResultIdElementsValuesQuery,
} from '@neoload/api';
import { ElementType, isAnAllXxxElement, sharedContainersRootElementName } from '@neoload/utils';

type ValueComparisonRowType = ValueComparisonRow['rowType'];

type UseElementValuesFetchResult = FetchResult<
	GetResultElementValuesResponse,
	ValuesComparisonIdentifier,
	ElementContext
>;
type UseMonitorValuesFetchResult = FetchResult<MonitorsValuesPage, ValuesComparisonIdentifier, MonitorContext>;

export type ValuesComparisonIdentifier = {
	resultId: string;
	intervalId?: string;
	userPath?: string;
};
export type ValuesComparisonContext<RowType extends ValueComparisonRowType> = {
	rowType: RowType;
};

export type ElementContext = ValuesComparisonContext<'ELEMENT'>;
export type MonitorContext = ValuesComparisonContext<'MONITOR'>;

type RequestParameter = ValuesComparisonIdentifier &
	({ elementIds?: string[]; elementType?: ElementType } | { monitorIds?: string[] });

const initResults = (requestParameterList: RequestParameter[], rowType: ValueComparisonRowType) => {
	const results = requestParameterList.map((requestParameter) => ({
		id: {
			resultId: requestParameter.resultId,
			intervalId: requestParameter.intervalId,
			userPath: requestParameter.userPath,
		},
		data: undefined,
		context: { rowType },
	}));

	return rowType === 'ELEMENT'
		? (results as UseElementValuesFetchResult[])
		: (results as UseMonitorValuesFetchResult[]);
};

const useItemValuesFetcher = (
	requestParameterList: RequestParameter[],
	shouldStartFetching: boolean,
	rowType: ValueComparisonRowType
) => {
	const context = useMemo(() => ({ rowType }), [rowType]);
	const [elementTrigger] = useLazyGetV4ResultsByResultIdElementsValuesQuery();
	const [monitorTrigger] = useLazyGetV4ResultsByResultIdMonitorsValuesQuery();
	const [loadingState, setLoadingState] = useState<LoadingState>('UNLOADED');
	const [results, setResults] = useState<UseElementValuesFetchResult[] | UseMonitorValuesFetchResult[]>(
		initResults(requestParameterList, rowType)
	);
	const reload = useReloading<RequestParameter>(requestParameterList);
	useEffect(() => {
		if (reload) {
			setLoadingState('UNLOADED');
		}
	}, [reload]);

	useEffect(() => {
		if (!shouldStartFetching) {
			return;
		}
		if (loadingState === 'UNLOADED') {
			setLoadingState('LOADING');

			if (rowType === 'ELEMENT') {
				const fetchTriggers = requestParameterList.map((requestParameter) => ({
					fetchId: {
						resultId: requestParameter.resultId,
						intervalId: requestParameter.intervalId,
						userPath: requestParameter.userPath,
					},
					trigger: elementTrigger(requestParameter, true),
				}));

				startFetching<
					GetResultElementValuesResponse,
					ValuesComparisonIdentifier,
					ElementContext,
					UseElementValuesFetchResult
				>(fetchTriggers, { rowType })
					.then(setResults)
					.finally(() => setLoadingState('LOADED'));
			} else {
				const fetchTriggers = requestParameterList.map((requestParameter) => ({
					fetchId: { resultId: requestParameter.resultId, intervalId: requestParameter.intervalId },
					trigger: monitorTrigger(requestParameter, true),
				}));

				startFetching<MonitorsValuesPage, ValuesComparisonIdentifier, MonitorContext, UseMonitorValuesFetchResult>(
					fetchTriggers,
					{ rowType }
				)
					.then(setResults)
					.finally(() => setLoadingState('LOADED'));
			}
		}
	}, [requestParameterList, context, elementTrigger, loadingState, monitorTrigger, rowType, shouldStartFetching]);

	return [results, loadingState] as const;
};

const addRowToMap = (map: Map<string, ElementComparisonRow[]>, key: string, value: ElementComparisonRow) => {
	if (map.has(key)) {
		map.get(key)?.push(value);
	} else {
		map.set(key, [value]);
	}
};

const toRowsByUserPath = (rows: ElementComparisonRow[]): Map<string, ElementComparisonRow[]> => {
	const map = new Map<string, ElementComparisonRow[]>();

	for (const row of rows) {
		addRowToMap(map, row.userPathId ?? '', row);
	}

	return map;
};

const arePathEquals = (path1: string[], path2: string[]): boolean => path1.join('>') === path2.join('>');

const getAllXxxElementTypes = (rows: ElementComparisonRow[]): ElementType[] => [
	...new Set(rows.filter((row) => isAnAllXxxElement(row.path)).map((row) => row.elementType)),
];

const isSharedElement = (path: string[]): boolean => path[0] === sharedContainersRootElementName;

const findRowsWithOtherElementTypesOrSharedElements = (
	rows: ElementComparisonRow[],
	allXxxElementTypes: ElementType[]
): ElementComparisonRow[] =>
	// ... we need to remove the rows with the same type than the all-xxx elements because they will
	// be returned by the calls by elementType, but we keep the shared elements that cannot be returned
	// with these calls ...
	rows.filter((row) => isSharedElement(row.path) || !allXxxElementTypes.includes(row.elementType));

const formatGetElementsRequestParameterList = (tile: ValuesComparisonDashboardTile): RequestParameter[] =>
	// The columns are represented by a resultId and an intervalId,
	// We will send at least one request by column, it could be more if we have different user paths defined
	tile.columns
		.flatMap((column) => {
			// We need to group the rows by user path, to decrease the number of requests
			// Because the userPath is used as a filter on the request
			const rowsByUserPathMap = toRowsByUserPath(
				tile.rows.filter((row) => row.rowType === 'ELEMENT') as ElementComparisonRow[]
			);

			const requestParameterList: RequestParameter[] = [];

			// We will group the element ids to request regarding the resultId, intervalId and the userPath
			for (const [userPath, rows] of rowsByUserPathMap) {
				// If rows contain an all-xxx element then we get all the elements to grab the total value
				const allXxxElementTypes = getAllXxxElementTypes(rows);

				for (const elementType of allXxxElementTypes) {
					requestParameterList.push({
						resultId: column.resultId,
						intervalId: column.intervalId,
						userPath: userPath === '' ? undefined : userPath,
						elementType,
					});
				}

				// If we have rows with other types than the ones of all-xxx elements
				// we need the send a request with their elementId
				const remainingRows = findRowsWithOtherElementTypesOrSharedElements(rows, allXxxElementTypes);
				if (remainingRows) {
					requestParameterList.push({
						resultId: column.resultId,
						intervalId: column.intervalId,
						userPath: userPath === '' ? undefined : userPath,
						// To retrieve the element ids ...
						elementIds: [
							...new Set(
								remainingRows
									// ... we need to find the mappings related to these elements by their path ...
									.map((row) => tile.idMapping.find((mapping) => arePathEquals(mapping.path, row.path)))
									// ... then we get the id of these elements for the result id of the column
									.flatMap((mapping) => (mapping === undefined ? [] : mapping.metricIdByItemIds[column.resultId]))
							),
						],
					});
				}
			}

			return requestParameterList;
		})
		.filter(
			(requestParam) =>
				('elementIds' in requestParam && requestParam.elementIds && requestParam.elementIds.length > 0) ||
				('elementType' in requestParam && requestParam.elementType)
		);

const formatGetMonitorsRequestParameterList = (tile: ValuesComparisonDashboardTile): RequestParameter[] =>
	// The columns are represented by a resultId and an intervalId,
	// We will send one request by column
	tile.columns
		.map((column) => ({
			resultId: column.resultId,
			intervalId: column.intervalId,
			// To retrieve the monitor ids ...
			monitorIds: [
				...new Set(
					tile.rows
						.filter((row) => row.rowType === 'MONITOR')
						// ... we need first to find the mappings related to these monitors by their path ...
						.map((row) => tile.idMapping.find((mapping) => arePathEquals(mapping.path, row.path)))
						// ... then we get the id of these monitors for the result id of the column
						.flatMap((mapping) => (mapping === undefined ? [] : mapping.metricIdByItemIds[column.resultId]))
				),
			],
		}))
		.filter((requestParam) => requestParam.monitorIds.length > 0);

export const useValuesDataFetcher = (tile: ValuesComparisonDashboardTile, shouldStartFetching: boolean) => {
	const [elementValuesResults, elementValuesLoadingState] = useItemValuesFetcher(
		formatGetElementsRequestParameterList(tile),
		shouldStartFetching,
		'ELEMENT'
	);

	const [monitorValuesResults, monitorValuesLoadingState] = useItemValuesFetcher(
		formatGetMonitorsRequestParameterList(tile),
		shouldStartFetching,
		'MONITOR'
	);

	const results = [...elementValuesResults, ...monitorValuesResults] as const;

	const loadingState = computeLoadingState([elementValuesLoadingState, monitorValuesLoadingState]);

	return [results, loadingState] as const;
};
