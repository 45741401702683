import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import IconInfoOutlined from '@tricentis/aura/components/IconInfoOutlined.js';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import Alert from '@mui/material/Alert';
import { useFormContext, useWatch } from 'react-hook-form';
import { calcWidth, gap, width } from '../constant';
import { TestExecutionErrorTypes, TestExecutionForm } from '../types.d';

type TestExecutionNcpProps = {
	needed: number;
	available: number;
	label: string;
	labelDescription: string;
	errorType: TestExecutionErrorTypes;
};

const ErrorRow = ({ needed, available }: Pick<TestExecutionNcpProps, 'available' | 'needed'>) => {
	const theme = useTheme();

	return (
		<Alert
			severity='error'
			sx={{
				display: 'flex',
				border: 'none',
				textWrap: 'nowrap',
				justifyContent: 'start',
				alignItems: 'center',
				paddingY: 0,
				paddingX: theme.spacing(0.5),
				height: theme.spacing(4),
				'& .MuiAlert-icon': { paddingY: '5px' },
				'& .MuiAlert-message': { paddingY: '5px' },
				width: '100%',
			}}
		>
			<NoErrorRow available={available} needed={needed} />
		</Alert>
	);
};

const NoErrorRow = ({ needed, available }: Pick<TestExecutionNcpProps, 'available' | 'needed'>) => {
	const { t, i18n } = useTranslation(['test']);
	const theme = useTheme();

	const formatterValue = new Intl.NumberFormat(i18n.language, {
		compactDisplay: 'short',
		maximumFractionDigits: 3,
		minimumIntegerDigits: 1,
	});

	return (
		<>
			<Typography
				sx={{ color: theme.palette.text.primary, margin: theme.spacing(1), display: 'inline-block' }}
				variant='body2'
			>
				{formatterValue.format(needed)}
			</Typography>
			<Typography color={theme.palette.text.secondary} variant='caption' aria-label={t('test:testExecution:available')}>
				({formatterValue.format(available)} {t('test:testExecution:available')})
			</Typography>
		</>
	);
};

const TestExecutionNcp = ({ needed, available, label, labelDescription, errorType }: TestExecutionNcpProps) => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();

	const errors = useWatch<TestExecutionForm, 'errors'>({ name: 'errors' });
	const errorNcp = errors.filter((errors) => errors.type === errorType);

	return (
		<Box sx={{ marginTop: 2 }}>
			<Box sx={{ display: 'flex', gap: gap(theme) }}>
				<Box sx={{ alignItems: 'center', display: 'flex', width: width }}>
					<Typography variant='subtitle2'>{label}</Typography>
					<Tooltip title={labelDescription} arrow>
						<IconInfoOutlined fontSize='small' sx={{ marginLeft: 0.5, color: theme.palette.action.active }} />
					</Tooltip>
				</Box>
				<Box
					sx={{
						backgroundColor: theme.palette.background.default,
						borderRadius: theme.spacing(0.5),
						display: 'flex',
						width: calcWidth(theme),
						alignItems: 'center',
					}}
				>
					{errorNcp.length > 0 ? (
						<ErrorRow available={available} needed={needed} />
					) : (
						<NoErrorRow available={available} needed={needed} />
					)}
				</Box>
			</Box>
			{errorNcp.length > 0 &&
				errorNcp.map((error) => (
					<Alert sx={{ marginTop: 2, alignItems: 'center' }} severity='error' key={error.type}>
						<Typography variant='body2'>{t('testExecution.errors.' + error.type)}</Typography>
					</Alert>
				))}
		</Box>
	);
};

const TestExecutionVuh = () => {
	const { t } = useTranslation(['test']);
	const { getValues } = useFormContext<TestExecutionForm>();
	const vuhNeeded = useWatch<TestExecutionForm, 'resources.vuh.needed'>({ name: 'resources.vuh.needed' });

	return (
		<TestExecutionNcp
			needed={vuhNeeded}
			available={getValues('resources.vuh.available')}
			label={t('test:testExecution:vuhEstimation')}
			labelDescription={t('test:testExecution:vuhEstimationDescription')}
			errorType={TestExecutionErrorTypes.NotEnoughVuh}
		/>
	);
};
const TestExecutionCloud = () => {
	const { t } = useTranslation(['test']);
	const { getValues } = useFormContext<TestExecutionForm>();
	const cloudCreditsNeeded = useWatch<TestExecutionForm, 'resources.cloudCredits.needed'>({
		name: 'resources.cloudCredits.needed',
	});

	return (
		<TestExecutionNcp
			needed={cloudCreditsNeeded}
			available={getValues('resources.cloudCredits.available')}
			label={t('test:testExecution:cloud')}
			labelDescription={t('test:testExecution:cloudDescription')}
			errorType={TestExecutionErrorTypes.NotEnoughCloudCredits}
		/>
	);
};

export { TestExecutionVuh, TestExecutionCloud };
