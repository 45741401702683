import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid/models';
import { useTranslation } from 'react-i18next';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import DGCustomRow from '@tricentis/aura/components/DGCustomRow.js';
import { GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { TestsDataGridColumns } from './tests-data-grid-columns';
import { EmptyStateTests } from './empty-state-tests';
import { useGetTestsDataGridAction } from './tests-data-grid-actions';
import { CreateResourceButton } from '../../common/button/create-resource-button';
import { filterToContextMenuItems, filterToSecondaryActions } from '../../common/data-grid/actions/data-grid-actions';
import { Datagrid } from '../../common/data-grid/datagrid';
import { defaultGridProps, onColumnChange } from '../../common/datagrid';
import { NoRowsOverlay } from '../../common/no-rows-overlay';
import { NoResultsOverlay } from '../../common/no-results-overlay';
import { ContextMenu, ContextMenuFuncts } from '../../common/context-menu/context-menu';
import { Spinner } from '../../common/spinner';
import { formatSort } from '../../common/data-grid/sort';
import { Test, type TestPage, useGetV4TestsQuery } from '@neoload/api';
import { useColumnsState, useCurrentWorkspace, useGetMe } from '@neoload/hooks';
import { defaultRowsPerPage } from '@neoload/utils';
// eslint-disable-next-line no-restricted-imports
import type { GridSortItem } from '@mui/x-data-grid/models/gridSortModel';

const columnsStateKey = 'TEST_LIST_COLUMNS_STATE';

const defaultPage: TestPage = {
	total: 0,
	pageNumber: 0,
	pageSize: defaultRowsPerPage,
	items: [],
};

export type TestsListRow = {
	id: string;
	name: string;
	project?: string;
	lastRun?: string;
	lastModifiedBy?: string;
	lastModifiedDate?: string;
};

export type TestsDataGridProps = {
	runTest: (test: Test) => void;
	openCreateModal: () => void;
};

type TestsFilterArguments = {
	name: string;
};

const initialArguments: TestsFilterArguments = {
	name: '',
};

const shouldUpdateFilter = (
	nextFilterArguments: TestsFilterArguments,
	previousFilterArguments: TestsFilterArguments
): boolean => nextFilterArguments.name !== previousFilterArguments.name;

const TestsDataGrid = ({ runTest, openCreateModal }: TestsDataGridProps) => {
	const apiRef = useGridApiRef();
	const contextMenu: MutableRefObject<ContextMenuFuncts | undefined> = useRef();
	const { t } = useTranslation(['test']);
	const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);
	const [selectedTests, setSelectedTests] = useState<Test[]>([]);
	const [{ isGuest }] = useGetMe();
	const [currentWorkspace] = useCurrentWorkspace();
	const [pageSize, setPageSize] = useState(defaultRowsPerPage);
	const [pageNumber, setPageNumber] = useState(0);
	const [sort, setSort] = useState('-updatedAt');
	const [filterArguments, setFilterArguments] = useState(initialArguments);
	const [isFirstCall, setIsFirstCall] = useState(true);

	const actions = useGetTestsDataGridAction(runTest, selectedIds, selectedTests);

	const columns = TestsDataGridColumns(actions);

	const addTestButton = (openCreateModal: () => void) => ({
		children: (
			<CreateResourceButton onClick={openCreateModal} data-testid='add-test' key='addTest'>
				{t('grid.actions.createTest')}
			</CreateResourceButton>
		),
	});

	const {
		data: testPageData = defaultPage,
		isLoading,
		isFetching,
	} = useGetV4TestsQuery({
		workspaceId: currentWorkspace.id,
		pageSize,
		pageNumber,
		sort,
		...(filterArguments.name !== '' && { name: filterArguments.name }),
	});

	useEffect(() => {
		if (!isLoading && isFetching) {
			setIsFirstCall(false);
		}
	}, [isLoading, isFetching]);

	useEffect(() => {
		setIsFirstCall(true);
		return () => setIsFirstCall(true);
	}, []);

	useEffect(() => {
		setIsFirstCall(true);
		return () => setIsFirstCall(true);
	}, [currentWorkspace.id]);

	useEffect(() => {
		setSelectedTests(testPageData.items.filter((value) => selectedIds.includes(value.id)));
	}, [selectedIds, testPageData, setSelectedTests]);

	const onSortModelChange = (gridSortModel: GridSortModel) => {
		setSort(formatSort(gridSortModel[0], []));
	};

	const onFilterChange = (filterModel: GridFilterModel) => {
		const nextFilterArguments: TestsFilterArguments = {
			name: filterModel.quickFilterValues ? filterModel.quickFilterValues.join(' ') : filterArguments.name,
		};
		if (shouldUpdateFilter(nextFilterArguments, filterArguments)) {
			setFilterArguments(nextFilterArguments);
		}
	};

	const initialState = {
		sorting: {
			sortModel: [{ field: 'updatedAt', sort: 'desc' } as GridSortItem],
		},
		filter: { filterModel: undefined },
	};
	const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
		columnsStateKey,
		initialState,
		columns,
		apiRef
	);

	const componentsProps: { toolbar: Parameters<typeof Toolbar>[0] } = {
		toolbar: {
			description: t('description'),
			displayColumnOptions: true,
			displaySearchBox: true,
			hideColumnsFromColumnOptions: ['__check__', 'id', 'name', 'projectName', 'latest_runs'],
			hideFiltersIcon: true,
			mainActions: isGuest ? [] : [addTestButton(openCreateModal)],
			secondaryActions: !isGuest && selectedIds.length > 0 ? filterToSecondaryActions(actions) : [],
			syncLocalStorage: {
				datagridId: 'neoloadTestsDataGrid',
				isSyncEnabled: true,
			},
			title: t('grid.title'),
		},
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (testPageData.total === 0 && isFirstCall) {
		return <EmptyStateTests openCreateModal={openCreateModal} />;
	}

	return (
		<>
			<Datagrid
				{...defaultGridProps}
				{...onColumnChange(storeColumnState)}
				apiRef={apiRef}
				checkboxSelection={!isGuest}
				loading={isFetching}
				rows={testPageData.items}
				disableSelectionOnClick={false}
				rowCount={testPageData.total}
				columns={updatedColumns}
				initialState={updatedInitialState}
				page={testPageData.pageNumber}
				pageSize={testPageData.pageSize}
				paginationMode='server'
				onPageChange={setPageNumber}
				onPageSizeChange={setPageSize}
				onSelectionModelChange={setSelectedIds}
				sortingMode='server'
				onSortModelChange={onSortModelChange}
				filterMode='server'
				onFilterModelChange={onFilterChange}
				componentsProps={{
					...componentsProps,
					row: {
						...(isGuest ? {} : { onContextMenu: contextMenu.current?.openContextMenu }),
					},
				}}
				components={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					Toolbar: Toolbar,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					Row: DGCustomRow,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					NoRowsOverlay: NoRowsOverlay,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					NoResultsOverlay: NoResultsOverlay,
				}}
				sx={{
					'& .MuiDataGrid-pinnedColumns': {
						boxShadow: 'unset',
					},

					'& .MuiDataGrid-pinnedColumnHeaders': {
						boxShadow: 'unset',
					},
					'& .MuiLink-root': {
						textDecoration: 'none',
					},
					'& .MuiLink-root:hover': {
						textDecoration: 'underline',
					},
				}}
			/>
			<ContextMenu apiRef={apiRef} ref={contextMenu} contextMenuItemsList={filterToContextMenuItems(actions)} />
		</>
	);
};

export { TestsDataGrid };
