const result = {
	description: 'The Result of the load test displays detailed measures, values and graphs computed during the result',
	tabs: {
		overview: 'Overview',
		values: 'Values',
		events: 'Events',
		logs: 'Logs',
		logsUnavailable: 'No logs available. The test was run outside of the web application.',
		slas: 'SLAs',
		slasUnavailable: 'No Slas available. Please configure it before running test to consult Slas.',
		dashboards: 'Dashboards',
		transactions: 'Transactions',
		pages: 'Pages',
		requests: 'Requests',
		actions: 'Actions',
		counters: 'Counters',
	},
	header: {
		name: 'Test Result name',
		remaining: ' remaining',
		page: '{{page}} of {{total}}',
		reportButton: 'view report',
		lockTooltip: 'Deletion protection',
		externalUrlToolTip: 'External link',
		locked: 'This Result is protected against direct manual and automated deletion.',
		newer: 'Newer result',
		older: 'Older result',
		noNewer: 'No newer result',
		noOlder: 'No older result',
		startedBy: 'Started by {{author}}',
		menu: {
			tooltip: 'More actions',
			unlock: 'Disable deletion protection',
			lock: 'Enable deletion protection',
			delete: 'Delete',
			button: 'Menu Button',
		},
		initialization: {
			initializing: 'Initializing',
			preparingController: 'Preparing Controller',
			failedToPrepareController: 'Failed to prepare Controller',
			preparingLgs: 'Preparing LGs',
			failedToPrepareLgs: 'Failed to prepare LGs',
			sendingProject: 'Sending Project',
			failed: 'Failed',
			preparingTest: 'Preparing Test',
			startingTest: 'Starting Test',
			startedTest: 'Test Started',
			cancelled: 'Cancelled',
		},
	},
	overview: {
		statistics: {
			transactions: 'Transactions',
			transactionsPerSecond: 'Transactions/s',
			averageTime: 'Avg. duration',
			passed: 'Passed',
			failed: 'Failed',
			requests: 'Requests',
			requestsPerSecond: 'Requests/s',
			throughput: 'Throughput',
			totalThroughput: 'Total',
			throughputPerSecond: 'Avg./s',
			vuIterations: 'VU iterations',
		},
		charts: {
			userLoad: 'User load',
			errors: 'Errors',
			metrics: 'Metrics',
			reqPerSec: 'Requests per second',
			reqDuration: 'Requests duration (s)',
			timeWindow: {
				last10: 'Last 10m',
				last30: 'Last 30m',
				start: 'From start',
			},
		},
		currentStatusDetail: 'Current status details',
		slaFailed_zero: 'No SLA failed',
		slaFailed_one: '1 SLA failed',
		slaFailed_other: '{{count}} SLAs failed',
		clickToAddDescription: 'Click to add description',
		emptyDescription: 'No description',
		interval: {
			addButtonLabel: 'Add interval',
			defaultName: 'New interval',
		},
	},
	details: {
		test: 'Test:',
		started: 'Started:',
		ended: 'Ended: ',
		endedAt: 'Ended at {{endDate}}',
		duration: 'Duration:',
		vus: 'VUs:',
		zone_one: 'Zone:',
		zone_other: 'Zones:',
		scenario: 'Scenario:',
		project: 'Project:',
		author: 'Author: ',
		lg: 'Load generators: ',
		moreInfo: 'More parameters',
		termination: {
			reason: 'Termination reason: ',
			policy: 'Execution policy',
			variable: 'No more variable values',
			manual: 'Manual action',
			lgAvailability: 'LG unavailability',
			license: 'Licence issue',
			unknown: 'Unknown reason',
			cancelled: 'Cancellation',
			failedToStart: 'Launching error',
			reservationEnded: 'Reservation end',
		},
		debug: {
			mode: 'Debug mode: ',
			enabled: 'Enabled',
			disabled: 'Disabled',
		},
	},

	values: {
		userPath: 'User path',
		sla: 'SLA',
		element: 'Element',
		parent: 'Parent',
		minDuration: 'Min',
		maxDuration: 'Max',
		avgDuration: 'Avg',
		count: 'Count',
		failureCount: 'Errors',
		failureRate: 'Error rate',
		elementPerSecond: 'Elt/s',
		minTtfb: 'Min. TTFB',
		avgTtfb: 'Avg. TTFB',
		maxTtfb: 'Max. TTFB',
		percentile50: 'Perc 50',
		percentile90: 'Perc 90',
		percentile95: 'Perc 95',
		percentile99: 'Perc 99',
		alltransactions: '<all-transactions>',
		allpages: '<all-pages>',
		allrequests: '<all-requests>',
		allactions: '<all-actions>',
		noIntervalFiltering: {
			zeroStateTitle: "Can't filter values",
			zeroStateContent: 'Result is too big to allow interval filtering on values',
		},
	},
	counters: {
		name: 'Name',
		min: 'Min',
		max: 'Max',
		avg: 'Avg',
		noIntervalFiltering: {
			zeroStateTitle: "Can't filter counters",
			zeroStateContent: 'Interval is too big to allow interval filtering on counters',
		},
	},
	events: {
		time: 'Time',
		name: 'Name',
		code: 'Code',
		source: 'Source',
		viewDetails: 'View details',
		type: 'Type',
		pending: 'pending',
		sla: 'SLA',
		objective: 'Objective',
		monitoring: 'Monitoring',
		downloadingCsv: 'Downloading... {{stepPage}}/{{totalPage}}',
		screenshotHeaderTooltip: 'Attached screenshot',
		details: {
			title: 'Event details',
			fetchError: 'Failed to fetch details for this event, please try again later.',
			time: 'Time',
			name: 'Name',
			code: 'Code',
			source: 'Source',
			transaction: 'Transaction',
			populationName: 'Population',
			zone: 'Zone',
			userPathId: 'User path',
			value: 'Value',
			accuracy: 'Accuracy',
			accuracyComputed_zero: 'Not enough previous passed results to compute a value for this objective',
			accuracyComputed_one: 'Static objective',
			accuracyComputed_other: 'Objective computed over {{count}} results',
			monitoredMachine: 'Monitored Machine',
			monitor: 'Monitor',
			requestDuration: 'Duration',
			request: 'Request',
			assertions: 'Assertions',
			description: 'Description',
			url: 'URL',
			screenshotAltTxt: 'Screenshot',
			screenshotNotSaved:
				'NeoLoad Controller did not store the screenshot of this error. For more information, see the first occurrences of this error.',
			screenshotFetchError: 'Failed to fetch screenshot for this event, please try again later.',
		},
		tabs: {
			details: 'Details',
			content: 'Content',
			screenshot: 'Screenshot',
		},
		content: {
			request: 'Request',
			response: 'Response',
			previous: 'Previous',
			current: 'Current',
			showError: 'Fail to fetch content for this event, please try again later.',
			headers: 'headers',
			content: 'content',
			statusLine: 'status',
			warnPropertiesFromFirstOccurence_one:
				'The {{propety}} is from the first occurrence of this action, not the actual current one.',
			warnPropertiesFromFirstOccurence_other:
				'The {{propety}} are from the first occurrence of this action, not the actual current ones.',
			and: ' and ',
			noContent: {
				noRequestTitle: 'No request',
				noResponseTitle: 'No response',
				content: 'NeoLoad controller did not save the details of this error.',
			},
		},
	},
	filters: {
		intervals: 'Intervals',
		zones: 'Zones',
		populations: 'Populations',
		userPaths: 'User paths',
		all: 'All',
		none: 'None',
		isAnyOf: 'is any of',
		is: 'is',
		runningFilter: 'Running',
		emptyTestName: '[is empty]',
		and: 'And',
		before: 'before',
		after: 'after',
	},
	messages: {
		failedToPatchDescription: 'An error occurred, failed to update description.',
		stopTestError: 'Failed to stop test.',
		stopTestSuccess: 'Test is stopping, this may take a few moments',
		terminateTestError: 'Failed to terminate test.',
		terminateTestSuccess: 'Test is terminating, this may take a few moments',
		updateNameError: 'Fail to update name.',
		updateStatusError: 'Fail to update status.',
		lockError: 'Fail to enable deletion protection.',
		unlockError: 'Fail to disable deletion protection.',
		resultFetchFailed: 'An error has occurred retrieving the result',
	},
	intervals: {
		create: 'Create an interval',
		startOffset: 'Start',
		endOffset: 'End',
		name: 'Title',
		hide: 'Hide',
		show: 'Show',
		cannotEditOffsets:
			"Raw data for results older than 30 days is unavailable. Can't edit Start and End to prevent percentile recalculation.",
		noPercAvailable: "Can't calculate percentiles data for results older than 30 days.",
		formError: {
			name: {
				empty: "Interval name can't be empty.",
			},
			offset: {
				intervalAfterBenchEnd: 'Interval cannot happen after bench end.',
			},
			description: {
				tooLong: 'Description is too long.',
			},
		},
		updateFailed: 'Interval update failed.',
		createFailed: 'Interval creation failed.',
		intervalsFetchFailed: 'An error has occurred retrieving intervals',
		delete: {
			title: 'Delete Interval',
			content:
				'Deleting the Interval will permanently erase it and its computed Values from your data.\n' +
				"If your Result is 30+ days old, you won't be able to recompute Percentiles on Transactions in new Intervals.",

			success_one: 'Interval has been deleted',
			success_other: '{{count}} intervals have been deleted',

			error_one: 'The interval has not been deleted.',
			error_other: '{{count}} intervals have not been deleted',
		},
		incompatibility: {
			elementValues: "Sorry, can't add intervals to this result. The data is too complex to process.",
			percentilesAndMonitors:
				"Interval is too long to calculate some values: transaction percentiles and monitor data won't be available. Try shortening the interval.",
			percentiles:
				"Interval is too long to calculate some values: transaction percentiles won't be available. Try shortening the interval.",
			monitors:
				"Interval is too long to calculate some values: monitor data won't be available. Try shortening the interval.",
			unknown: 'Unkown incompatibility',
		},
		gridHeader: 'Intervals',
		tabs: {
			range: 'Range',
			description: 'Description',
		},
	},
	unlock: {
		update: {
			success_one: 'Deletion protection disabled',
			success_other: 'Deletion protection disabled for {{count}} results',

			error_one: 'Fail to disable deletion protection.',
			error_other: 'Fail to disable deletion protection for {{count}} results',
		},
	},
	lock: {
		update: {
			success_one: 'Deletion protection enabled',
			success_other: 'Deletion protection enabled for {{count}} results',

			error_one: 'Fail to enable deletion protection.',
			error_other: 'Fail to enable deletion protection for {{count}} results',
		},
	},
	delete: {
		success_one: 'Result has been deleted',
		success_other: '{{count}} results have been deleted',

		error_one: 'One result has not been deleted.',
		error_other: '{{count}} results have not been deleted',
	},
	resultStatus: {
		passed: 'Passed',
		failed: 'Failed',
		unknown: 'N/A',
		running: 'Running',
		runningPercent: 'Running ({{percentage}}%)',
		planned: 'Planned',
		cancelled: 'Cancelled',
		initializingWithStep: 'Initializing ({{step}}/5)',
		initializing: 'Initializing',
	},
	externalUrl: {
		confirmAdd: 'Add link',
		remove: 'Remove link',
		open: 'Open {{link}}',
		withUrlButtonLabel: 'Open or manage external URL',
		editLabel: 'Edit external URL',
		deleteLabel: 'Delete external URL',
		edit: {
			title: 'Edit external link',
			success: 'External link edited',
			error: 'Failed to edit external link',
		},
		add: {
			title: 'Add external link',
			success: 'External link added',
			error: 'Failed to add external link',
		},
		delete: {
			success: 'External link deleted',
			error: 'Failed to delete external link',
		},
		url: {
			label: 'URL',
			placeholder: 'Type or paste URL',
		},
		title: {
			label: 'Label',
			placeholder: 'Label to display',
		},
	},
	results: 'Results',
	columns: {
		name: {
			title: 'Name',
		},
		project: {
			title: 'Project',
		},
		testName: {
			title: 'Test',
		},
		startDate: {
			title: 'Started',
		},
		duration: {
			title: 'Duration',
		},
		vusCount: {
			title: 'VUs',
		},
		qualityStatus: {
			title: 'Status',
		},
		errors: {
			title: 'Errors',
		},
		requestPerSecond: {
			title: 'Req/s',
		},
		transactionTime: {
			title: 'Avg transaction time',
		},
		description: {
			title: 'Description',
		},
	},
	actions: {
		moveTestResults: {
			title_one: 'Move result to test',
			title_other: 'Move results to test',
			label_one: 'Select the test into which you want to move your result',
			label_other: 'Select the test into which you want to move your results',
			runningTestWarning: 'Running Results will not be moved.',
			dashboardWarning:
				'Your target test is in a different workspace. All existing dashboards linked to the result in the current workspace will be deleted.',
			targetWorkspace: 'Target workspace:',
			targetTest: 'Target test:',
			move: 'Move',
			moveAnyway: 'Move result anyway',
			cantMoveTooltip: "Running results can't be moved",
			update: {
				success_one: 'Result {{name}} moved to test {{testName}}',
				success_other: '{{count}} results moved to test {{testName}}',
				error_one: 'One result has not been moved.',
				error_other: '{{count}} results have not been moved',
			},
		},
		delete: {
			locked_one: 'The test result has deletion protection enabled',
			locked_other: 'Some of the selected results have deletion protection enabled',
			running_one: "Running test results can't be deleted",
			running_other: 'Some of the selected results are running',
			lockedAndRunning_one: 'The selected result has deletion protection enabled and is running',
			lockedAndRunning_other: 'Some of the selected results have deletion protection enabled and some are running',
			title_zero: 'Delete Result',
			title_one: 'Delete result',
			title_other: 'Delete results',
			content_one:
				"Deleting the result will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
			content_other:
				"Deleting the results will permanently erase them from your data. It's not reversible, and you won't be able to retrieve them once removed.",
		},
		stop: {
			button: 'Stop test',
			title: 'Stop test?',
			description:
				"When you stop the test, the project defined stop policy will be applied and the test won't be completeed.",
			validate: 'Stop test',
			cancel: 'Keep running',
			notStartedByNlw: 'Only results started by NeoLoad Web can be stopped',
		},
		terminate: {
			button: 'Terminate',
			title: 'Terminate?',
			description:
				'Terminate the test will ignore stop policy, all LGs and the test stop immediatly. Some collected statistics may be wrong or incomplete.',
			validate: 'Terminate',
			cancel: 'Keep running',
		},
	},
	update: {
		success_one: 'Result {{name}} updated',
		success_other: '{{count}} results updated',
		error_one: 'One result has not been updated.',
		error_other: '{{count}} results have not been updated',
	},
	slas: {
		category: 'Category',
		status: 'Status',
		identifier: 'Name',
		userPath: 'User path',
		element: 'Element',
		elementType: 'Element type',
		parent: 'Parent',
		value: 'Value',
		setThreshHolds: 'Set thresholds',
		failed: 'Failed',
		warning: 'Warning',
		categories: {
			/* eslint-disable @typescript-eslint/naming-convention */
			GLOBAL: 'Global',
			PER_TIME_INTERVAL: 'Per time interval',
			PER_RUN: 'Per run',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		identifiers: {
			averageRequestResponseTime: 'Average request response time',
			averagePageResponseTime: 'Average page response time',
			averageContainerResponseTime: 'Average transaction response time',
			percentileContainerResponseTime: 'Percentile transaction response time',
			averageHitsPerSecond: 'Average requests per second',
			averageThroughputPerSecond: 'Average throughput per second',
			totalErrors: 'Total errors',
			totalHits: 'Total count',
			totalThroughput: 'Total throughput',
			averageResponseTime: 'Average response time',
			errorsPerSecond: 'Errors per second',
			errorPercentile: 'Error rate',
		},
		units: {
			megabitsPerSecond: 'Mb/s',
			second: 's',
			megabytes: 'MB',
		},
	},
	testResultCombo: {
		testResult: 'Test result',
		loading: 'Loading test results...',
		loadingError: 'An error occurred calling API',
	},
};
export { result };
