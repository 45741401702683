import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { darken, lighten, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Brand from '@tricentis/aura/components/Brand.js';
import CloudUpload from '@mui/icons-material/CloudUploadOutlined';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import GitHub from '@mui/icons-material/GitHub';
import Box from '@mui/material/Box';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useColorScheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import { allowedFileUploadExtensionString, getReadableSize } from './utils';
import { ModalGit } from './modal-git';
import { ConfigurationFormData } from '../types.d';
import { useGetV4InformationQuery } from '@neoload/api';
import { useFeatureEnabled } from '@neoload/hooks';

const EmptyProject = () => {
	const { t } = useTranslation(['test']);
	const [inputKey, setInputKey] = useState(Date.now());
	const { data: information, isLoading: isInformationLoading } = useGetV4InformationQuery();
	const [showGitModal, setShowGitModal] = useState(false);

	const theme = useTheme();
	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const { setValue, getValues } = useFormContext<ConfigurationFormData>();
	const [{ isFeatureEnabled: uploadGitFeatureEnabled }] = useFeatureEnabled('upload-git');

	useEffect(() => {
		if (information) {
			setValue('projectMaxSize', information?.projectMaxSize);
		}
	}, [information, setValue]);

	return (
		<>
			<Stack
				useFlexGap
				alignItems='center'
				sx={{ paddingTop: 2, paddingRight: 2, paddingBottom: 4, paddingLeft: 2, gap: 2 }}
			>
				<Stack useFlexGap gap={theme.spacing(2)} flexDirection='row' width='100%' alignItems='center'>
					<input
						key={inputKey}
						ref={uploadInputRef}
						type='file'
						hidden={true}
						accept={allowedFileUploadExtensionString.replaceAll(' ', '')}
						onChange={(event) => {
							const files = (event.target as HTMLInputElement).files;
							setValue('file', files?.[0]);
							setValue('fileName', files?.[0].name);
							setInputKey(Date.now());
						}}
					/>
					<ItemEmptyProject
						description={t('configuration.project.export.description')}
						title={t('configuration.project.export.title')}
						icon={<Brand variant='mark' color='tertiary' sx={{ width: theme.spacing(2.5) }} />}
					/>
					<Divider sx={{ height: '80px' }} orientation='vertical'>
						<Typography variant='body2' color={theme.palette.text.disabled}>
							{t('configuration.project.separator')}
						</Typography>
					</Divider>
					<ItemEmptyProject
						description={t('configuration.project.file.description')}
						title={t('configuration.project.file.title')}
						button={t('configuration.project.file.button')}
						icon={<CloudUpload sx={{ color: theme.palette.action.active }} />}
						action={() => uploadInputRef.current && uploadInputRef.current.click()}
					/>
					{uploadGitFeatureEnabled && (
						<>
							<Divider sx={{ height: '80px' }} orientation='vertical' content={t('configuration.project.separator')}>
								<Typography variant='body2' color={theme.palette.text.disabled}>
									{t('configuration.project.separator')}
								</Typography>
							</Divider>
							<ItemEmptyProject
								description={t('configuration.project.git.description')}
								title={t('configuration.project.git.title')}
								button={t('configuration.project.git.button')}
								icon={<GitHub sx={{ color: theme.palette.action.active }} />}
								action={() => setShowGitModal(true)}
							/>
						</>
					)}
				</Stack>
				<Stack>
					{isInformationLoading || information === undefined ? (
						<Skeleton sx={{ width: '200px' }} />
					) : (
						<Typography color={theme.palette.text.secondary} variant='body2'>
							{t('configuration.project.file.limit', { size: getReadableSize(t, information.projectMaxSize) })}
						</Typography>
					)}
				</Stack>
			</Stack>
			{showGitModal && <ModalGit close={() => setShowGitModal(false)} testId={getValues('test.id')} />}
		</>
	);
};

type ItemEmptyProjectProps = {
	icon: ReactNode;
	description: string;
	title: string;
	button?: string;
	action?: () => void | null;
};
const ItemEmptyProject = ({ icon, description, title, button, action }: ItemEmptyProjectProps) => {
	const theme = useTheme();
	const active = useWatch<ConfigurationFormData, 'dragActive'>({ name: 'dragActive' });
	const { mode } = useColorScheme();
	const activeColor =
		mode === 'light' ? lighten(theme.palette.primary.main, 0.96) : darken(theme.palette.primary.main, 0.96);

	return (
		<Box
			sx={{
				backgroundColor: active ? activeColor : theme.palette.background.paper,
				width: '33%',
				border: `1px solid ${theme.palette.divider}`,
				borderRadius: theme.spacing(0.75),
				padding: theme.spacing(3),
				alignSelf: 'stretch',
			}}
		>
			<Stack useFlexGap flexDirection='row' gap={1} marginBottom={2}>
				{icon}
				<Typography variant='subtitle1'>{title}</Typography>
			</Stack>
			<Typography variant='body1' color={theme.palette.text.primary} marginBottom={2}>
				{description}
			</Typography>
			{button ? (
				<Button onClick={action ?? action} variant='outlined'>
					{button}
				</Button>
			) : (
				''
			)}
		</Box>
	);
};

export { EmptyProject };
