import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';
import { ErrorsUsersPoints, Points, RequestsPoints } from './result-overview-graphs';
import {
	ResultTimeseries,
	ElementTimeSeries,
	useGetV4ResultsByResultIdElementsAndElementIdTimeseriesQuery,
	useGetV4ResultsByResultIdTimeseriesQuery,
} from '@neoload/api';
import { timeUtils } from '@neoload/utils';

export type FixedWindowDuration = 'PT10M' | 'PT30M' | undefined;

export type ResultOverviewGraphsFetcherProps = {
	resultId: string;
	onUpdatePoints: (
		isLoading: boolean,
		errorsUsersPoints: ErrorsUsersPoints,
		requestsPoints: RequestsPoints,
		xAxisMin?: number,
		xAxisMax?: number
	) => void;
	fixedWindowDuration: FixedWindowDuration;
	editionInProgress: boolean;
};

const mergeErrorsUsersPoints = (
	previousPoints: ErrorsUsersPoints,
	errorsUsersTimeseries: ResultTimeseries,
	resultId: string
): ErrorsUsersPoints => {
	const errorsPoints = errorsUsersTimeseries.points.map((pt) => [
		timeUtils.asMilliseconds(pt.offset),
		pt.requestErrors,
	]);
	const deletePreviousPoints = previousPoints.resultId !== resultId || errorsUsersTimeseries.isFromScratch;
	const userLoadPoints = errorsUsersTimeseries.points.map((pt) => [timeUtils.asMilliseconds(pt.offset), pt.userLoad]);
	return {
		resultId,
		errorsPoints: mergePoints(deletePreviousPoints ? [] : previousPoints.errorsPoints, errorsPoints),
		userLoadPoints: mergePoints(deletePreviousPoints ? [] : previousPoints.userLoadPoints, userLoadPoints),
	};
};

const mergeRunningRequestsPoints = (
	previousPoints: RequestsPoints,
	requestsTimeseries: ResultTimeseries,
	resultId: string
): RequestsPoints => {
	const requestDurationPoints = requestsTimeseries?.points.map((pt) => [
		timeUtils.asMilliseconds(pt.offset),
		pt.requestAvgDuration,
	]);
	const requestsPerSecPoints = requestsTimeseries?.points.map((pt) => [
		timeUtils.asMilliseconds(pt.offset),
		pt.requestCountPerSecond,
	]);
	const deletePreviousPoints = previousPoints.resultId !== resultId || requestsTimeseries.isFromScratch;
	return {
		resultId,
		reqDurationPoints: mergePoints(deletePreviousPoints ? [] : previousPoints.reqDurationPoints, requestDurationPoints),
		reqPerSecPoints: mergePoints(deletePreviousPoints ? [] : previousPoints.reqPerSecPoints, requestsPerSecPoints),
	};
};

const mergeRequestsPoints = (
	previousPoints: RequestsPoints,
	requestsTimeseries: ElementTimeSeries,
	resultId: string
): RequestsPoints => {
	const requestsDurationPoints = requestsTimeseries?.points.map((pt) => [
		timeUtils.asMilliseconds(pt.offset),
		pt.statisticsValues.AVERAGE_DURATION,
	]);
	const requestsPerSecPoints = requestsTimeseries?.points.map((pt) => [
		timeUtils.asMilliseconds(pt.offset),
		pt.statisticsValues.ELEMENTS_PER_SECOND,
	]);
	const deletePreviousPoints = previousPoints.resultId !== resultId || requestsTimeseries.isFromScratch;
	return {
		resultId,
		reqDurationPoints: mergePoints(
			deletePreviousPoints ? [] : previousPoints.reqDurationPoints,
			requestsDurationPoints
		),
		reqPerSecPoints: mergePoints(deletePreviousPoints ? [] : previousPoints.reqPerSecPoints, requestsPerSecPoints),
	};
};

const mergePoints = (previousPoints: Points, nextPoints: (number | undefined)[][]): Points => {
	const nextPointsOffsets = new Set(nextPoints.map((p) => p[0]));
	const previous = previousPoints.filter((p) => !nextPointsOffsets.has(p[0]));
	const next = nextPoints.filter((p) => p[1] !== undefined);
	if (!isPoints(next)) {
		console.warn('This should never happen');
		return [...previous];
	}
	return previous.length + next.length > 1 ? [...previous, ...next] : [];
};

const isPoints = (pts: (number | undefined)[][]): pts is Points => pts.every((pt) => pt.every((p) => p !== undefined));

const scheduleNextFetch = (
	setNextToken: React.Dispatch<SetStateAction<string | undefined>>,
	refetch: () => unknown,
	timeseries: { samplingInterval: string; nextRequestToken?: string }
): NodeJS.Timeout =>
	setTimeout(() => {
		setNextToken((previousToken) => {
			if (previousToken === timeseries.nextRequestToken && previousToken) {
				refetch();
			}
			return timeseries.nextRequestToken;
		});
	}, timeUtils.asMilliseconds(timeseries.samplingInterval));

const refetchNow = (
	setNextToken: React.Dispatch<SetStateAction<string | undefined>>,
	refetch: () => unknown,
	isUninitialized: boolean
) => {
	setNextToken((previousToken) => {
		if (!previousToken && !isUninitialized) {
			refetch();
		}
		return undefined;
	});
};

const ResultOverviewGraphsFetcher = ({
	resultId,
	onUpdatePoints,
	fixedWindowDuration,
	editionInProgress,
}: ResultOverviewGraphsFetcherProps) => {
	const [errorUsersPoints, setErrorUsersPoints] = useState<ErrorsUsersPoints>({
		errorsPoints: [],
		userLoadPoints: [],
		resultId: '',
	});
	const [nextUsersToken, setNextUsersToken] = useState<string | undefined>(undefined);

	const [requestsPoints, setRequestsPoints] = useState<RequestsPoints>({
		reqDurationPoints: [],
		reqPerSecPoints: [],
		resultId: '',
	});
	const [nextRequestToken, setNextRequestToken] = useState<string | undefined>(undefined);
	const [nextAllToken, setNextAllToken] = useState<string | undefined>(undefined);

	const {
		data: allTimeseries,
		isLoading: isLoadingAll,
		isUninitialized: isUninitializedAll,
		refetch: refetchAll,
	} = useGetV4ResultsByResultIdTimeseriesQuery(
		{
			resultId,
			series: ['requestErrors', 'userLoad', 'requestAvgDuration', 'requestCountPerSecond'],
			fixedWindowDuration,
			requestToken: nextAllToken,
		},
		{ skip: !fixedWindowDuration }
	);
	const {
		data: errorsUsersTimeseries,
		isLoading: isLoadingUsers,
		isUninitialized: isUninitializedUsers,
		refetch: refetchUsers,
	} = useGetV4ResultsByResultIdTimeseriesQuery(
		{
			resultId,
			series: ['requestErrors', 'userLoad'],
			requestToken: nextUsersToken,
		},
		{ skip: !!fixedWindowDuration }
	);
	const {
		data: requestsTimeseries,
		isLoading: isLoadingRequests,
		isUninitialized: isUninitializedRequests,
		refetch: refetchRequest,
	} = useGetV4ResultsByResultIdElementsAndElementIdTimeseriesQuery(
		{
			resultId,
			elementId: 'all-requests',
			statistics: ['AVERAGE_DURATION', 'ELEMENTS_PER_SECOND'],
			requestToken: nextRequestToken,
		},
		{ skip: !!fixedWindowDuration }
	);

	// When the result change or when fixedWindowDuration is updated, we need to fetch the series from scratch
	useEffect(() => {
		if (fixedWindowDuration) {
			refetchNow(setNextAllToken, refetchAll, isUninitializedAll);
		} else {
			refetchNow(setNextUsersToken, refetchUsers, isUninitializedUsers);
			refetchNow(setNextRequestToken, refetchRequest, isUninitializedRequests);
		}
	}, [resultId, fixedWindowDuration, refetchAll, isUninitializedAll, refetchUsers, isUninitializedUsers, refetchRequest, isUninitializedRequests]);

	// Schedule next refetch for all graphs
	useEffect(() => {
		if (allTimeseries) {
			const timer = scheduleNextFetch(setNextAllToken, refetchAll, allTimeseries);
			return () => clearTimeout(timer);
		}
	}, [allTimeseries, refetchAll]);

	// Update the points received for all graphs
	useEffect(() => {
		if (allTimeseries) {
			setErrorUsersPoints((previousPoints) => mergeErrorsUsersPoints(previousPoints, allTimeseries, resultId));
			setRequestsPoints((previousPoints) => mergeRunningRequestsPoints(previousPoints, allTimeseries, resultId));
		}
	}, [allTimeseries, resultId]);

	// Schedule next refetch of the first graph
	useEffect(() => {
		if (errorsUsersTimeseries) {
			const timer = scheduleNextFetch(setNextUsersToken, refetchUsers, errorsUsersTimeseries);
			return () => clearTimeout(timer);
		}
	}, [errorsUsersTimeseries, refetchUsers]);

	// Update the points received of the first graph
	useEffect(() => {
		if (errorsUsersTimeseries) {
			setErrorUsersPoints((previousPoints) => mergeErrorsUsersPoints(previousPoints, errorsUsersTimeseries, resultId));
		}
	}, [errorsUsersTimeseries, resultId]);

	// Schedule next refetch of the second graph
	useEffect(() => {
		if (requestsTimeseries) {
			setRequestsPoints((previousPoints) => mergeRequestsPoints(previousPoints, requestsTimeseries, resultId));
		}
	}, [requestsTimeseries, resultId]);

	// Update the points received of the first graph
	useEffect(() => {
		if (requestsTimeseries) {
			const timer = scheduleNextFetch(setNextRequestToken, refetchRequest, requestsTimeseries);
			return () => clearTimeout(timer);
		}
	}, [requestsTimeseries, refetchRequest]);

	const isLoading = useMemo(
		() => isLoadingAll || isLoadingUsers || isLoadingRequests,
		[isLoadingAll, isLoadingRequests, isLoadingUsers]
	);

	const xAxisMax = useMemo(() => {
		const lastError = errorUsersPoints.errorsPoints?.at(-1)?.at(0) ?? -1;
		const lastUser = errorUsersPoints.userLoadPoints?.at(-1)?.at(0) ?? -1;
		const lastRd = requestsPoints.reqDurationPoints?.at(-1)?.at(0) ?? -1;
		const lastRps = requestsPoints.reqPerSecPoints?.at(-1)?.at(0) ?? -1;
		const minOfMax = Math.min(lastError, lastUser, lastRd, lastRps);
		return minOfMax === -1 ? undefined : minOfMax;
	}, [errorUsersPoints, requestsPoints]);

	const xAxisMin = useMemo(() => {
		if (!xAxisMax || !fixedWindowDuration) {
			return 0;
		}
		return Math.max(0, xAxisMax - timeUtils.asMilliseconds(fixedWindowDuration));
	}, [xAxisMax, fixedWindowDuration]);

	useInterval(
		() => onUpdatePoints(isLoading, errorUsersPoints, requestsPoints, xAxisMin, xAxisMax),
		editionInProgress ? null : 1000
	);

	useInterval(() => {
		// Remove points below the minimum to prevent the chart from having too many points
		setErrorUsersPoints({
			...errorUsersPoints,
			errorsPoints: errorUsersPoints.errorsPoints.filter(isAboveXAxisMin),
			userLoadPoints: errorUsersPoints.userLoadPoints.filter(isAboveXAxisMin),
		});
		setRequestsPoints({
			...requestsPoints,
			reqDurationPoints: requestsPoints.reqDurationPoints.filter(isAboveXAxisMin),
			reqPerSecPoints: requestsPoints.reqPerSecPoints.filter(isAboveXAxisMin),
		});
	}, 5 * 60 * 1000);
	const isAboveXAxisMin = (point: (number | undefined)[]): boolean => point[0] !== undefined && point[0] >= xAxisMin;

	return null;
};

export { ResultOverviewGraphsFetcher };

export const visibleForTesting = {
	mergeErrUsersPoints: mergeErrorsUsersPoints,
	mergeRunningRequestsPoints,
	mergeRequestsPoints,
	scheduleNextFetch,
};
