import { GridRowId } from '@mui/x-data-grid';

export const isSelected = (selection: GridRowId[], clickedId: GridRowId) => selection.includes(clickedId);

export const rightClickSelection = (
	selection: GridRowId[],
	clickedId: GridRowId,
	setSelection: (nextSelection: GridRowId[]) => void
) => {
	if (!isSelected(selection, clickedId)) {
		setSelection([clickedId]);
	}
};
