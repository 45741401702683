import i18n from 'i18next';
import { TrendPoint } from '@neoload/api';

type TrendStatistic = TrendPoint['statistic'];

export const mapToReadableStatisticName = (statistic: TrendStatistic) =>
	i18n.t(`test:trends.statistics.${statistic.replaceAll(' ', '').replaceAll('.', '').toLowerCase()}`);

export const mapToReadableStatisticUnit = (statistic: TrendStatistic) => {
	switch (statistic) {
		case 'ELEMENT_COUNT':
		case 'ERROR_COUNT': {
			return {
				short: '',
				long: '',
			};
		}
		case 'ELEMENTS_PER_SECOND': {
			return {
				short: i18n.t('test:trends.statisticsUnits.elementsPerSec.short'),
				long: i18n.t('test:trends.statisticsUnits.elementsPerSec.long'),
			};
		}
		case 'ERROR_RATE': {
			return {
				short: '%',
				long: '%',
			};
		}
		default: {
			return {
				short: i18n.t('test:trends.statisticsUnits.seconds.short'),
				long: i18n.t('test:trends.statisticsUnits.seconds.long'),
			};
		}
	}
};
