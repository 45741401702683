import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { darken, lighten, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { useColorScheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Unstable_Grid2';
import { ConfigurationFormData } from './types.d';

type ProjectConfigurationProps = {
	children: ReactNode;
	name: string;
	disabled?: boolean;
	actions?: ReactNode;
};

const TestConfiguration = ({ children, disabled, name, actions }: ProjectConfigurationProps) => {
	const theme = useTheme();
	const active = useWatch<ConfigurationFormData, 'dragActive'>({ name: 'dragActive' });

	const { mode } = useColorScheme();
	const activeColor =
		mode === 'light' ? lighten(theme.palette.primary.main, 0.96) : darken(theme.palette.primary.main, 0.96);
	const { t } = useTranslation(['test']);

	return (
		<Stack
			direction='column'
			sx={{
				width: '100%',
				backgroundColor: active ? activeColor : theme.palette.background.paper,
				padding: 2,
			}}
		>
			<Grid2 container sx={{ marginBottom: 1 }}>
				<Grid2 xs={4}>
					<Typography variant='subtitle1'>{name}</Typography>
				</Grid2>
				<Grid2 xs={4} textAlign='center'>
					{disabled && (
						<Typography
							role='alert'
							variant='caption'
							sx={{
								color: theme.palette.text.disabled,
							}}
						>
							{t('configuration.disabledText')}
						</Typography>
					)}
				</Grid2>
				<Grid2 xs={4}>
					{actions && (
						<Stack direction='row' justifyContent='flex-end' aria-label='test-configuration-actions'>
							{actions}
						</Stack>
					)}
				</Grid2>
			</Grid2>
			<Box aria-label='test-configuration-section'>{children}</Box>
		</Stack>
	);
};

export { TestConfiguration };
