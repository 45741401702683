import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SnackbarState = {
	id: string;
	severity?: 'info' | 'warning' | 'error' | 'success';
	text?: string | null;
	actionText?: string | null;
	actionCallback?: () => void | null;
	autoHideDuration?: number | null;
};

const initialSnackbarState: SnackbarState[] = [];

const snackbarSlice = createSlice({
	name: 'snackbar',
	initialState: initialSnackbarState,
	reducers: {
		addSnackbar: (state, action: PayloadAction<SnackbarState>) => {
			const foundAtIndex = state.findIndex((snackbar) => snackbar.id === action.payload.id);
			if (foundAtIndex >= 0) {
				state.splice(foundAtIndex, 1, action.payload);
			} else {
				state.push(action.payload);
			}
		},
		removeSnackbar: (state, action) => state.filter((s) => s.id !== action.payload),
		removeAllSnackbars: (_state, _action) => [],
	},
});

const { reducer: snackbarReducer, actions } = snackbarSlice;
export const { addSnackbar, removeSnackbar, removeAllSnackbars } = actions;
export { snackbarReducer };
