import { useTranslation } from 'react-i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { i18n } from '../../../../i18n';
import { Spinner } from '../../../common/spinner';
import { KpiTilesGrid } from '../../../common/kpi-tiles-grid';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import {
	useLazyGetV4ResultsByResultIdStatisticsQuery,
	WidgetDashboardTile,
	GetV4ResultsByResultIdStatisticsApiArg,
	GetV4ResultsByResultIdStatisticsApiResponse,
} from '@neoload/api';

type KpiFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

const f = (options: Intl.NumberFormatOptions = {}) =>
	new Intl.NumberFormat(i18n.language, { compactDisplay: 'short', ...options });
const secondUnit: Intl.NumberFormatOptions = { style: 'unit', unit: 'second', unitDisplay: 'narrow' };

export const _KpiFetcher = ({ shouldStartFetching, widgetTile }: KpiFetcherProps) => {
	const { t } = useTranslation();
	const [triggerGetResultStatistics] = useLazyGetV4ResultsByResultIdStatisticsQuery();

	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdStatisticsApiArg,
		GetV4ResultsByResultIdStatisticsApiResponse
	>(
		widgetTile,
		{
			resultId: widgetTile.resultId,
		},
		shouldStartFetching,
		triggerGetResultStatistics
	);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	if (widgetTile.filter.type !== 'KEY_METRICS') {
		return <DashboardTileErrorContent />;
	}

	switch (widgetTile.filter.elementType) {
		case 'TRANSACTION': {
			return (
				<KpiTilesGrid
					averageDuration={f(secondUnit).format(data.totalTransactionDurationAverage)}
					countPerSecond={f().format(data.totalTransactionCountPerSecond)}
					totalPassed={f().format(data.totalTransactionCountSuccess)}
					totalFailed={f().format(data.totalTransactionCountFailure)}
				/>
			);
		}
		case 'REQUEST': {
			return (
				<KpiTilesGrid
					averageDuration={f(secondUnit).format(data.totalRequestDurationAverage)}
					countPerSecond={f().format(data.totalRequestCountPerSecond)}
					totalPassed={f().format(data.totalRequestCountSuccess)}
					totalFailed={f().format(data.totalRequestCountFailure)}
				/>
			);
		}
		default: {
			return <DashboardTileErrorContent />;
		}
	}
};

export const VisibleForTesting = _KpiFetcher;
export const KpiFetcher = withLazyFetching(_KpiFetcher);
