import isUUID from 'validator/lib/isUUID';
import isMongoId from 'validator/lib/isMongoId';
import { NeoLoadError, NeoLoadErrorType } from '../errors/neoload-error';

/**
 * If `uuid` has not UUID format, throw a NeoLoadError so display the "Not Found" error page
 * @param uuid the string to check
 */
const checkUuid = (uuid: string) => {
	if (!validateUuid(uuid)) {
		const error: NeoLoadError = {
			type: NeoLoadErrorType.NotFound,
			customMessageI18nKey: 'badIdFormat',
			cause: new Error(
				`The following ID '${uuid}' does not match the UUID pattern /^[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$/`
			),
		};
		throw error;
	}
};

/**
 * If `objectId` has not ObjectId format, throw a NeoLoadError so display the "Not Found" error page
 * @param objectId the string to check
 */
const checkObjectId = (objectId: string) => {
	if (!validateObjectId(objectId)) {
		const error: NeoLoadError = {
			type: NeoLoadErrorType.NotFound,
			customMessageI18nKey: 'badIdFormat',
			cause: new Error(`The following ID '${objectId}' does not match the MongoId pattern /^[0-9a-fA-F]{24}$/`),
		};
		throw error;
	}
};

/**
 * Return true if `uuid` has UUID format
 * @param uuid the string to check
 */
const validateUuid = (uuid: string) => isUUID(uuid);

/**
 * Return true if `objectId` has ObjectId format
 * @param objectId the string to check
 */
const validateObjectId = (objectId: string) => isMongoId(objectId);

export { validateUuid, validateObjectId, checkUuid, checkObjectId };
