import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OpenConfirmModalAction, openModal } from '@neoload/api';

const useConfirmModal = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	return {
		openModal: useCallback((action: OpenConfirmModalAction) => dispatch(openModal(action)), [dispatch]),
		openDeleteConfirmModal: useCallback(
			(action: OpenConfirmModalAction) => {
				const act = { ...action };
				const { cancel = {}, confirm = {} } = action;
				act.cancel = {
					text: cancel.text ?? t('common:cancel'),
					color: cancel.color ?? 'info',
					icon: cancel.icon,
				};
				act.confirm = {
					text: confirm.text ?? t('common:delete'),
					color: confirm.color ?? 'error',
					icon: confirm.icon,
				};
				return dispatch(openModal(act));
			},
			[t, dispatch]
		),
	};
};

export { useConfirmModal };
