import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconCommentErrorOutlined from '@tricentis/aura/components/IconCommentErrorOutlined.js';
import IconCodeOutlined from '@tricentis/aura/components/IconCodeOutlined.js';
import NewReleasesOutlined from '@mui/icons-material/NewReleasesOutlined';
import IconStethoscopeOutlined from '@tricentis/aura/components/IconStethoscopeOutlined.js';
import { apiSwaggerUiUrl } from '@neoload/api';

type HelpMenuProps = {
	anchorEl?: HTMLElement | null;
	open?: boolean;
	handleClose: () => void;
};

const HelpMenu = ({ anchorEl = null, open = false, handleClose }: HelpMenuProps) => {
	const { t } = useTranslation(['menu', 'externalLinks']);

	return (
		<Menu
			data-testid='help-menu'
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
		>
			<MenuItem
				data-testid='help-menu-documentation'
				component={Link}
				href={t('externalLinks:documentation')}
				target='_blank'
			>
				<ListItemIcon>
					<IconStethoscopeOutlined fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('help.documentation')}</ListItemText>
			</MenuItem>
			<MenuItem data-testid='help-menu-api' component={Link} href={apiSwaggerUiUrl} target='_blank'>
				<ListItemIcon>
					<IconCodeOutlined fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('help.api')}</ListItemText>
			</MenuItem>
			<MenuItem data-testid='help-menu-support' component={Link} href={t('externalLinks:support')} target='_blank'>
				<ListItemIcon>
					<IconCommentErrorOutlined fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('help.support')}</ListItemText>
			</MenuItem>
			<MenuItem data-testid='help-menu-whats-new' component={Link} href={t('externalLinks:whatsNew')} target='_blank'>
				<ListItemIcon>
					<NewReleasesOutlined fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('help.whatsNew')}</ListItemText>
			</MenuItem>
		</Menu>
	);
};

export { HelpMenu };
