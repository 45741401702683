import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { UIEvent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetInitialValue, useGetTestResultData } from './search-result-combo-hooks';
import { TestResult } from '@neoload/api';
import { timeUtils } from '@neoload/utils';
import { useUrlSearchParams } from '@neoload/hooks';

export type SearchResultComboProps = {
	onResultSelected?: (result: TestResult) => void;
	initialResultId?: string;
	disabled?: boolean;
};

export const SearchResultCombo = ({ onResultSelected, initialResultId, disabled = false }: SearchResultComboProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['result']);
	const [pageNumber, setPageNumber] = useState(0);
	const [items, setItems] = useState<TestResult[]>([]);
	const [selectedResultId, setSelectedResultId] = useState<string>('');
	const [result, errorLoadResult, isInitialLoading] = useGetInitialValue(initialResultId);
	const [dataItems, total, error, isLoading] = useGetTestResultData(pageNumber);
	const [{ fromResult, tileToEdit }] = useUrlSearchParams('fromResult', 'tileToEdit');

	const hasMore = items.length < total;

	useEffect(() => {
		setSelectedResultId((previousSelected) => {
			if (initialResultId && dataItems.length > 0) {
				return initialResultId;
			} else if (dataItems.length > 0) {
				if (fromResult && (tileToEdit === 'SERIES' || tileToEdit === 'VALUES_COMPARISON' || tileToEdit === 'WIDGET')) {
					return fromResult;
				}
				return dataItems[0].id;
			}
			return previousSelected;
		});
	}, [initialResultId, dataItems, fromResult, tileToEdit]);

	useEffect(() => {
		if (dataItems && dataItems.length > 0) {
			setItems((previousItems) => [...previousItems, ...dataItems]);
		}
	}, [dataItems]);

	useEffect(() => {
		const testResult = items.find((item) => item.id === selectedResultId);
		if (testResult) {
			onResultSelected?.(testResult);
		}
	}, [selectedResultId, items, onResultSelected]);

	const handleChange = (event: SelectChangeEvent<string>) => {
		const testResult = items.find((item) => item.id === event.target.value);
		if (testResult) {
			setSelectedResultId(testResult.id);
			onResultSelected?.(testResult);
		}
	};

	const loadMoreItems = (event: UIEvent<HTMLElement>) => {
		const bottom = event.currentTarget.scrollHeight <= event.currentTarget.scrollTop + event.currentTarget.clientHeight;
		if (bottom && hasMore) {
			setPageNumber((pageNumber) => pageNumber + 1);
		}
	};

	if (error || errorLoadResult) {
		if (error) {
			console.error(t('testResultCombo.loadingError'), error);
		}
		if (errorLoadResult) {
			console.error(t('testResultCombo.loadingError'), errorLoadResult);
		}
	}

	return (
		<FormControl fullWidth variant='outlined'>
			<InputLabel id='test-result' required={true}>
				<Label isLoading={isLoading || isInitialLoading} />
			</InputLabel>
			{
				<Select
					// eslint-disable-next-line @typescript-eslint/naming-convention
					inputProps={{ 'data-testid': 'test-result-combo' }}
					error={!!error || !!errorLoadResult}
					label={<Label isLoading={isLoading || isInitialLoading} />}
					labelId='test-result'
					sx={{ height: theme.spacing(7) }}
					required={true}
					disabled={disabled}
					onChange={handleChange}
					value={selectedResultId}
					MenuProps={{
						// eslint-disable-next-line @typescript-eslint/naming-convention
						PaperProps: { onScroll: loadMoreItems },
						style: {
							maxHeight: 500,
						},
					}}
				>
					{result && items.map((item) => item.id).includes(result.id) && (
						<MenuItem key={`initial_test_result_${result.id}`} value={result.id} sx={{ display: 'none' }}>
							<TestResultItem testResult={result} />
						</MenuItem>
					)}
					{items.map((item) => (
						<MenuItem key={`test_result_${item.id}`} value={item.id}>
							<TestResultItem testResult={item} />
						</MenuItem>
					))}
				</Select>
			}
			{(!!error || !!errorLoadResult) && <FormHelperText error>{t('testResultCombo.loadingError')}</FormHelperText>}
		</FormControl>
	);
};

type TestResultItemProps = {
	testResult: TestResult;
};
const TestResultItem = ({ testResult }: TestResultItemProps) => (
	<Stack
		spacing={1}
		direction={'row'}
		sx={{
			flex: 1,
			alignItems: 'center',
		}}
	>
		<Box>
			<Stack>
				<Box>{testResult.name}</Box>
				{testResult.test && (
					<Box>
						<Typography
							variant='subtitle2'
							sx={{
								color: (theme) => theme.palette.text.secondary,
							}}
						>
							{testResult.test.name}
						</Typography>
					</Box>
				)}
			</Stack>
		</Box>
		<Box
			sx={{
				flex: 1,
				textAlign: 'right',
				overflow: 'hidden',
			}}
		>
			<Typography
				variant='subtitle2'
				sx={{
					lineHeight: 'inherit',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					color: (theme) => theme.palette.text.secondary,
				}}
			>
				{timeUtils.dateTimeAbsolute(testResult.startDate)}
			</Typography>
		</Box>
	</Stack>
);

const Label = ({ isLoading }: { isLoading: boolean }) => {
	const { t } = useTranslation(['result']);
	if (isLoading) {
		return (
			<Box sx={{ position: 'relative' }}>
				{t('testResultCombo.loading')} <CircularProgress size={'1em'} />
			</Box>
		);
	}
	return t('testResultCombo.testResult');
};
