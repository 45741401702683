import { useTranslation } from 'react-i18next';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';

export type DeleteTokenActionProps = {
	selectedAccessTokenIds: string[];
	onDeleteAccessTokens: (tokens: string[]) => void;
}

export const useDeleteTokenAction = ({
	selectedAccessTokenIds,
	onDeleteAccessTokens,
}: DeleteTokenActionProps): DatagridAction => {
	const { t } = useTranslation(['user']);

	const deleteToken: DatagridAction = {
		icon: <DeleteOutlined />,
		text: t('token.delete'),
		disabled: selectedAccessTokenIds.length === 0,
		color: 'error',
		action: () => {
			onDeleteAccessTokens(selectedAccessTokenIds);
		},
		singleItem: (token: string) => ({
			disabled: false,
			action: () => {
				onDeleteAccessTokens([token]);
			},
		}),
	};

	return deleteToken;
};
