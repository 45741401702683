import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import AddOutlined from '@mui/icons-material/AddOutlined';
import Stack from '@mui/material/Stack';
import { ComparisonRowListAccordion } from './row/comparison-row-list-accordion';
import { ComparisonColumnList } from './column/comparison-column-list';
import { ComparisonDifferenceSelector } from './table-settings/comparison-difference-selector';

export type ComparisonMainFormProps = {
	onAddRow: () => void;
	resultId?: string;
	onEditRow: (index: number) => void;
};
export const ComparisonMainForm = ({ onAddRow, resultId, onEditRow }: ComparisonMainFormProps) => {
	const { t } = useTranslation(['dashboard']);
	return (
		<>
			<Accordion defaultExpanded={true} elevation={0}>
				<AccordionSummary expandIcon={<GridExpandMoreIcon />}>{t('tile.edition.sections.comparison')}</AccordionSummary>
				<AccordionDetails>
					<Stack sx={{ gap: 2 }}>
						<Typography variant={'subtitle2'}>{t('valuesComparison.rows.sectionTitle')}</Typography>
						<Typography variant={'caption'}>{t('valuesComparison.rows.sectionDescription')}</Typography>
						<Stack sx={{ gap: 1 }}>
							<ComparisonRowListAccordion resultId={resultId ?? ''} onEditRow={onEditRow}/>
							<Button onClick={onAddRow} variant='outlined' startIcon={<AddOutlined />} data-testid={'add-row-button'}>
								{t('valuesComparison.rows.add')}
							</Button>
						</Stack>
						<ComparisonColumnList />
					</Stack>
				</AccordionDetails>
			</Accordion>
			<Accordion defaultExpanded={true} elevation={0}>
				<AccordionSummary expandIcon={<GridExpandMoreIcon />}>
					{t('tile.edition.sections.comparisonSettings')}
				</AccordionSummary>
				<AccordionDetails>
					<ComparisonDifferenceSelector />
				</AccordionDetails>
			</Accordion>
		</>
	);
};
