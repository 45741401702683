import { ReactNode } from 'react';
import { useGetMe } from '@neoload/hooks';
import { NeoLoadError, NeoLoadErrorType } from '@neoload/utils';

export type ProtectRouteProps = {
	permission: 'guest' | 'tester' | 'admin' | 'accountAdmin';
	children: ReactNode;
};
const ProtectedRoute = ({ permission, children }: ProtectRouteProps) => {
	const [{ hasAccountAdminPermissions, hasAdminPermissions, hasGuestPermissions, hasTesterPermissions }] = useGetMe();
	if (
		(permission === 'guest' && hasGuestPermissions) ||
		(permission === 'tester' && hasTesterPermissions) ||
		(permission === 'admin' && hasAdminPermissions) ||
		(permission === 'accountAdmin' && hasAccountAdminPermissions)
	) {
		return children;
	}
	const forbidden: NeoLoadError = {
		type: NeoLoadErrorType.Forbidden,
		cause: new Error('Forbidden'),
	};
	throw forbidden;
};

export { ProtectedRoute };
