import { ComponentPropsWithoutRef, forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { GridColumns, GridSortItem, useGridApiRef } from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../common/spinner';
import { dataGridStyle, numericOperators } from '../values-data-grid-common';
import { defaultGridProps, onColumnChange } from '../../../../common/datagrid';
import { ValuesDataGridFuncts } from '../result-tab-values';
import { Datagrid } from '../../../../common/data-grid/datagrid';
import { intervalLimitEmptyOverlay } from '../interval-limit-empty-overlay';
import { useGetV4ResultsByResultIdMonitorsValuesQuery } from '@neoload/api';
import { createNeoLoadError, defaultRowsPerPage, isIntervalLimitError } from '@neoload/utils';
import { useColumnsState, useUrlSearchParams } from '@neoload/hooks';

export type CounterDataGridProps = {
	resultId: string;
	setDataFetching: (isDataLoading: boolean) => void;
};

const columnsStateKey = 'VALUES_COUNTER_COLUMNS_STATE';
const initialState = {
	sorting: {
		sortModel: [{ field: 'name', sort: 'asc' } as GridSortItem],
	},
};

const CountersDataGrid = forwardRef(
	({ resultId, setDataFetching }: CounterDataGridProps, ref: Ref<ValuesDataGridFuncts | undefined>) => {
		const apiRef = useGridApiRef();
		const { t, i18n } = useTranslation(['result']);
		const [pageSize, setPageSize] = useState(defaultRowsPerPage);
		const formatter = new Intl.NumberFormat(i18n.language, { compactDisplay: 'short' });
		const [{ intervalId }] = useUrlSearchParams('intervalId');
		const {
			data: result,
			refetch,
			isFetching,
			isLoading,
			error,
		} = useGetV4ResultsByResultIdMonitorsValuesQuery({ resultId, intervalId });
		useImperativeHandle(ref, () => ({ refetch }));
		useEffect(() => {
			setDataFetching(isFetching);
		}, [isFetching, setDataFetching]);

		const intervalTooBigForFilteringError = isIntervalLimitError(error);

		const secondaryAction = [
			{
				children: <DownloadOutlined />,
				disabled: false,
				onClick: () => apiRef.current.exportDataAsCsv({ delimiter: ';' }),
				tooltipProps: {
					arrow: true,
					title: t('common:downloadCsv'),
				},
			},
		];
		const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
			toolbar: {
				displayColumnOptions: true,
				hideColumnsFromColumnOptions: ['__check__'],
				secondaryActions: secondaryAction,
				syncLocalStorage: {
					datagridId: 'neoloadValuesCounterDataGrid',
					isSyncEnabled: true,
				},
			},
		};
		const columns: GridColumns = [
			{ field: 'name', headerName: t('counters.name'), flex: 1, minWidth: 300 },
			{
				field: 'min',
				headerName: t('counters.min'),
				type: 'number',
				minWidth: 180,
				valueFormatter: ({ value }: { value: number }) => formatter.format(value),
				filterOperators: numericOperators,
			},
			{
				field: 'avg',
				headerName: t('counters.avg'),
				type: 'number',
				minWidth: 180,
				valueFormatter: ({ value }: { value: number }) => formatter.format(value),
				filterOperators: numericOperators,
			},
			{
				field: 'max',
				headerName: t('counters.max'),
				type: 'number',
				minWidth: 180,
				valueFormatter: ({ value }: { value: number }) => formatter.format(value),
				filterOperators: numericOperators,
			},
		];

		const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
			columnsStateKey,
			initialState,
			columns,
			apiRef
		);

		if (isLoading) {
			return <Spinner />;
		}
		if (!result && !intervalTooBigForFilteringError) {
			throw createNeoLoadError(error);
		}

		const rows = intervalTooBigForFilteringError ? [] : result?.items ?? [];

		return (
			<Datagrid
				{...defaultGridProps}
				{...onColumnChange(storeColumnState)}
				checkboxSelection={false}
				rows={rows}
				apiRef={apiRef}
				columns={updatedColumns}
				loading={isFetching}
				initialState={updatedInitialState}
				getRowId={(row) => row.id}
				pageSize={pageSize}
				density='compact'
				componentsProps={componentsProps}
				components={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					Toolbar: Toolbar,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					NoRowsOverlay: intervalLimitEmptyOverlay(
						intervalTooBigForFilteringError,
						t('counters.noIntervalFiltering.zeroStateTitle'),
						t('counters.noIntervalFiltering.zeroStateContent')
					),
				}}
				onPageSizeChange={(nextPageSize) => {
					setPageSize(nextPageSize);
				}}
				sx={{ ...dataGridStyle }}
			/>
		);
	}
);

export { CountersDataGrid };
