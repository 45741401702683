import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { IntervalForm } from './popper/interval-form';
import { PopperWrapper } from './popper/popper-wrapper';
import { IntervalEditionDragState } from '../result-overview-graphs';
import { IntervalPostRequest, usePostV4ResultsByResultIdIntervalsMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';
import { DEFAULT_INTERVAL_COLOR, extractIntervalIncompatibilities, IntervalIncompatibility } from '@neoload/utils';

const DEFAULT_COLOR = DEFAULT_INTERVAL_COLOR;

export type IntervalAddButtonProps = {
	resultId: string;
	resultDuration: string;
	resultEndDate?: string;
	startCreating: () => void;
	stopCreating: () => void;
	intervalDragState: IntervalEditionDragState;
	getIntervalRect: () => DOMRect | undefined;
	onOffsetsInputsEdited: (startOffset: string | undefined, endOffset: string | undefined) => void;
	onColorChanged: (hexColor: string) => void;
	disabled: boolean;
	chartContainer?: HTMLElement;
};

const getDefaultIntervalPostRequest = (name: string): IntervalPostRequest => ({
	name: name,
	startOffset: 'PT0S',
	endOffset: 'PT0S',
	color: DEFAULT_COLOR,
});

const getIntervalPostRequest = (
	intervalPostRequest: IntervalPostRequest,
	intervalDragState: IntervalEditionDragState
): IntervalPostRequest => ({
	...intervalPostRequest,
	...(intervalDragState.editedStartOffset && { startOffset: intervalDragState.editedStartOffset }),
	...(intervalDragState.editedEndOffset && { endOffset: intervalDragState.editedEndOffset }),
});

const IntervalAddButton = ({
	resultId,
	resultDuration,
	resultEndDate,
	getIntervalRect,
	startCreating,
	stopCreating,
	onOffsetsInputsEdited,
	intervalDragState,
	onColorChanged,
	disabled,
	chartContainer,
}: IntervalAddButtonProps) => {
	const { t } = useTranslation(['result']);
	const [intervalRect, setIntervalRect] = useState<DOMRect | undefined>(undefined);
	const [postInterval] = usePostV4ResultsByResultIdIntervalsMutation();
	const { showError } = useSetSnackbars();
	const [intervalPostRequest, setIntervalPostRequest] = useState<IntervalPostRequest>(
		getIntervalPostRequest(getDefaultIntervalPostRequest(t('overview.interval.defaultName')), intervalDragState)
	);

	useEffect(() => {
		setIntervalPostRequest((current) => getIntervalPostRequest(current, intervalDragState));
	}, [intervalDragState]);

	const handleClick = () => {
		startCreating();
		setIntervalRect(getIntervalRect());
	};

	const handleClose = () => {
		setIntervalRect(undefined);
		stopCreating();
	};

	const handleSubmit = (
		intervalToSubmit: IntervalPostRequest,
		ignoreIncompatibilities: boolean
	): Promise<IntervalIncompatibility[]> =>
		postInterval({
			resultId,
			intervalPostRequest: intervalToSubmit,
			ignoreIncompatibilities,
		})
			.unwrap()
			.then(
				(_interval) => handleClose(),
				(error) => extractIntervalIncompatibilities(error) ?? showError({ text: t('intervals.createFailed') })
			)
			.then((response) => (Array.isArray(response) ? response : []));

	const open = intervalRect !== undefined;

	return (
		<>
			<Button
				data-testid={'result-add-interval'}
				aria-haspopup='true'
				onClick={handleClick}
				variant={'outlined'}
				color={'primary'}
				size='small'
				startIcon={<AddOutlined />}
				disabled={disabled}
			>
				{t('overview.interval.addButtonLabel')}
			</Button>
			{open && (
				<PopperWrapper
					intervalRect={intervalRect}
					onClickAway={handleClose}
					open={open}
					chartContainer={chartContainer}
				>
					<IntervalForm
						interval={intervalPostRequest}
						resultDuration={resultDuration}
						resultEndDate={resultEndDate}
						onCancel={handleClose}
						onSubmit={handleSubmit}
						onOffsetsInputsEdited={onOffsetsInputsEdited}
						onColorChanged={onColorChanged}
					/>
				</PopperWrapper>
			)}
		</>
	);
};

export { IntervalAddButton };
