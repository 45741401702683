import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { darken, lighten, useColorScheme, useTheme } from '@mui/material';
import Brand from '@tricentis/aura/components/Brand.js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LoginErrorBoundary } from './login-error-boundary';
import login from '../../assets/login/login.svg';
import circle from '../../assets/login/circle.svg';
import rectangle from '../../assets/login/rectangle.svg';
import gear from '../../assets/login/gear.svg';

const LoginPanel = ({ onFail }: { onFail: () => void }) => {
	const theme = useTheme();
	const [panel, setPanel] = useState('');
	const { mode } = useColorScheme();

	const leftPanelSize = '30%';

	useEffect(() => {
		fetch('https://product-embeds.tricentis.com/wp-json/loginpanels/v2/random')
			.then((value) => value.json())
			.then((value) => {
				if (value.data.html) {
					setPanel(value.data.html);
				} else {
					onFail();
				}
			})
			.catch(() => onFail());
	});

	const getButton = () => {
		const returnValue: { title: string | null; link: string } = {
			title: '',
			link: '',
		};
		const container = document.createElement('div');
		container.innerHTML = panel;

		const a = container.querySelectorAll('a');

		if (a.length === 1) {
			returnValue.title = a[0].textContent;
			returnValue.link = a[0].href;
		}
		return returnValue;
	};
	const getFromPanel = (selector: string) => {
		const container = document.createElement('div');
		container.innerHTML = panel;

		const tag = container.querySelectorAll(selector);

		if (tag.length === 1) {
			return tag[0].textContent;
		}

		return '';
	};

	return (
		<LoginErrorBoundary onFail={onFail}>
			<Brand
				variant='mark'
				sx={{
					height: '48px',
					position: 'absolute',
					left: theme.spacing(4),
					top: theme.spacing(4),
					display: { xs: 'none', xl: 'block' },
				}}
			/>
			<Box
				component='img'
				src={rectangle}
				alt={''}
				sx={{
					width: '62px',
					position: 'absolute',
					left: `calc(${leftPanelSize} - 62px)`,
					top: theme.spacing(4),
					display: 'none',
				}}
			/>
			<Box
				component='img'
				src={circle}
				alt={''}
				sx={{
					position: 'absolute',
					left: `calc(${leftPanelSize} - 350px)`,
					bottom: 0,
					display: 'none',
				}}
			/>
			<Box component='img' src={gear} alt={''} sx={{ position: 'absolute', left: 0, top: '45%', display: 'none' }} />
			<Stack
				sx={{
					height: '100vh',
					backgroundColor:
						mode === 'light' ? lighten(theme.palette.info.main, 0.9) : darken(theme.palette.info.main, 0.9),
					width: leftPanelSize,
					display: { xs: 'none', xl: 'block' },
				}}
				direction='row'
			>
				<Stack id='welcome' justifyContent='center' alignItems='center' height='100%' flex={1}>
					<Box component='img' src={login} alt={''} sx={{ width: '470px', marginBottom: 4 }} />
					<Stack maxWidth='358px'>
						<Typography variant='subtitle1' color={theme.palette.primary.main}>
							{getFromPanel('#panel_tag')}
						</Typography>
						<Typography variant='h6' pt={1}>
							{getFromPanel('#panel_title')}
						</Typography>
						<Typography variant='body2' pt={1}>
							{getFromPanel('#panel_description')}
						</Typography>
						<Button
							component='a'
							href={getButton().link}
							variant='contained'
							sx={{ marginTop: 3, width: 'max-content' }}
							target='_blank'
						>
							{getButton().title}
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</LoginErrorBoundary>
	);
};

export { LoginPanel };
