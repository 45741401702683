import { DragEvent, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { useFormContext, useWatch } from 'react-hook-form';
import { darken, lighten, useTheme } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';
import { ConfigurationFormData } from '../types.d';
type DragDropFormProps = {
	children: ReactNode;
};

const DragDropForm = ({ children }: DragDropFormProps) => {
	const active = useWatch<ConfigurationFormData, 'dragActive'>({ name: 'dragActive' });
	const { setValue } = useFormContext<ConfigurationFormData>();
	const theme = useTheme();
	const handleDrag = function (event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		if (event.type === 'dragenter' || event.type === 'dragover') {
			setValue('dragActive', true);
		} else if (event.type === 'dragleave') {
			setValue('dragActive', false);
		}
	};
	const handleDrop = (event: DragEvent) => {
		event.preventDefault();
		event.stopPropagation();
		setValue('file', event.dataTransfer.files[0]);
		setValue('fileName', event.dataTransfer.files[0].name);
		setValue('dragActive', false);
	};

	const { mode } = useColorScheme();
	const activeColor =
		mode === 'light' ? lighten(theme.palette.primary.main, 0.96) : darken(theme.palette.primary.main, 0.96);

	return (
		<Box
			sx={{
				backgroundColor: active ? activeColor : '',
				opacity: '0.9',
				border: active ? `2px dashed ${theme.palette.primary.main}` : '2px solid transparent',
				borderRadius: '6px',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&>*': active ? { pointerEvents: 'none' } : '',
				display: 'flex',
				direction: 'column',
				height: '100%',
			}}
			onDragEnter={handleDrag}
			onDragLeave={handleDrag}
			onDragOver={handleDrag}
			onDrop={handleDrop}
		>
			{children}
		</Box>
	);
};

export { DragDropForm };
