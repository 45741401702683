import Stack from '@mui/material/Stack';
import { useFormContext } from 'react-hook-form';
import { ComparisonRowListAccordionItem } from './comparison-row-list-accordion-item';
import { ComparisonTileEditionFormFields } from '../../tile-edition-form';

type ComparisonRowListAccordionProps = {
	resultId: string;
	onEditRow: (index: number) => void;
};

export const ComparisonRowListAccordion = ({ resultId, onEditRow }: ComparisonRowListAccordionProps) => {
	const { watch } = useFormContext<ComparisonTileEditionFormFields>();
	const rows = watch('rows');
	return (
		<Stack spacing={1} maxWidth={'100%'}>
			{rows.map((row, index) => (
				<ComparisonRowListAccordionItem
					resultId={resultId}
					row={row}
					key={`${row.path.join('/')}${index}`}
					index={index}
					onEditRow={() => onEditRow(index)}
				/>
			))}
		</Stack>
	);
};
