import { generatePath } from 'react-router';

export const resultTabs = ['overview', 'values', 'events', 'slas', 'dashboards', 'logs'];
export type ResultTab = (typeof resultTabs)[number];

export const valuesCategories = ['transactions', 'pages', 'requests', 'actions', 'counters'];
export type ValuesCategory = (typeof valuesCategories)[number];

export type ResultGenericParams = {
	resultId: string;
	tab?: ResultTab;
	subTab?: ValuesCategory;
};

export type ResultValuesParams = {
	resultId: string;
	tab: 'values';
	subTab?: ValuesCategory;
};

const patterns = {
	base: '/results',
	byId: '/results/:resultId',
	byIdWithTab: '/results/:resultId/:tab',
	byIdWithTabWithSubTab: '/results/:resultId/:tab/:subTab?',
};

const resultRoutes = {
	patterns: patterns,
	base: patterns.base,
	result: (resultId: string) => generatePath(patterns.byId, { resultId: resultId }),
	overview: (resultId: string) => generatePath(patterns.byIdWithTab, { resultId: resultId, tab: 'overview' }),
	logs: (resultId: string) => generatePath(patterns.byIdWithTab, { resultId: resultId, tab: 'logs' }),
	dashboards: (resultId: string) => generatePath(patterns.byIdWithTab, { resultId: resultId, tab: 'dashboards' }),
	values: (resultId: string, category: ValuesCategory = 'transactions') =>
		generatePath(patterns.byIdWithTabWithSubTab, { resultId: resultId, tab: 'values', subTab: category }),
	generic: (resultId: string, tab: ResultTab = 'overview', subTab = '') =>
		generatePath(patterns.byIdWithTabWithSubTab, { resultId: resultId, tab: tab, subTab: subTab }),
};

export { resultRoutes as ResultRoutes };
