import dayjs, { UnitTypeShort } from 'dayjs';
import { timeUtils } from '@neoload/utils';

export type UnitFormatInfo = {
	dayjsUnit: UnitTypeShort;
	dayjsFormat: string;
	inputPatternFormat: string;
	inputPatternSeparator: string;
	dayjsPatternSeparator: string;
};

const daysInfo: UnitFormatInfo = {
	dayjsUnit: 'd',
	dayjsFormat: 'DD',
	inputPatternFormat: '##[d]',
	inputPatternSeparator: '',
	dayjsPatternSeparator: '-',
};
const hoursInfo: UnitFormatInfo = {
	dayjsUnit: 'h',
	dayjsFormat: 'HH',
	inputPatternFormat: '##',
	inputPatternSeparator: ' ',
	dayjsPatternSeparator: '-',
};
const minutesInfo: UnitFormatInfo = {
	dayjsUnit: 'm',
	dayjsFormat: 'mm',
	inputPatternFormat: '##',
	inputPatternSeparator: ':',
	dayjsPatternSeparator: '-',
};
const secondsInfo: UnitFormatInfo = {
	dayjsUnit: 's',
	dayjsFormat: 'ss',
	inputPatternFormat: '##',
	inputPatternSeparator: ':',
	dayjsPatternSeparator: '-',
};
const millisInfo: UnitFormatInfo = {
	dayjsUnit: 'ms',
	dayjsFormat: 'SSS',
	inputPatternFormat: '###',
	inputPatternSeparator: '.',
	dayjsPatternSeparator: '.',
};

const orderedUnitInfos: UnitFormatInfo[] = [daysInfo, hoursInfo, minutesInfo, secondsInfo, millisInfo];

const getUnitRange = (maxUnit: UnitFormatInfo, minUnit: UnitFormatInfo) =>
	orderedUnitInfos.slice(orderedUnitInfos.indexOf(maxUnit), orderedUnitInfos.indexOf(minUnit) + 1);

const formatWithSeparator = (
	maxUnit: UnitFormatInfo,
	minUnit: UnitFormatInfo,
	property: keyof UnitFormatInfo,
	separator: keyof UnitFormatInfo
) => {
	let format = '';
	for (const [index, unitInfo] of getUnitRange(maxUnit, minUnit).entries()) {
		if (index !== 0) {
			format = format + unitInfo[separator];
		}
		format = format + unitInfo[property];
	}
	return format;
};

const getInputFormat = (maxUnit: UnitFormatInfo, minUnit: UnitFormatInfo) =>
	formatWithSeparator(maxUnit, minUnit, 'inputPatternFormat', 'inputPatternSeparator');

const getPlaceHolder = (maxUnit: UnitFormatInfo, minUnit: UnitFormatInfo) =>
	getInputFormat(maxUnit, minUnit).replaceAll(/#/gi, '0');

const getDayjsFormat = (maxUnit: UnitFormatInfo, minUnit: UnitFormatInfo) =>
	formatWithSeparator(maxUnit, minUnit, 'dayjsFormat', 'dayjsPatternSeparator');

const getFormattedStringFromValue = (value: string | number, maxUnit: UnitFormatInfo, minUnit: UnitFormatInfo) =>
	timeUtils.parseDuration(value).format(getDayjsFormat(maxUnit, minUnit));

const getValueFromFormattedString = (formattedString: string, maxUnit: UnitFormatInfo, minUnit: UnitFormatInfo) => {
	let duration = dayjs.duration(0);
	const presentUnits: UnitFormatInfo[] = getUnitRange(maxUnit, minUnit);
	const valueStrings = formattedString.split(/[ .:]+/);
	for (const [index, valueString] of valueStrings.entries()) {
		duration = duration.add(dayjs.duration(Number.parseInt(valueString), presentUnits[index].dayjsUnit));
	}
	return duration;
};

const getMaxUnitFromDuration = (maxDuration: number | string): UnitFormatInfo => {
	const parsedDuration = timeUtils.parseDuration(maxDuration);
	for (const unitInfo of orderedUnitInfos) {
		if (parsedDuration.as(unitInfo.dayjsUnit) >= 1) {
			return unitInfo;
		}
	}
	return millisInfo;
};

const inputOffsetFormatUtils = {
	daysInfo,
	hoursInfo,
	minutesInfo,
	secondsInfo,
	millisInfo,
	getUnitRange,
	getInputFormat,
	getPlaceHolder,
	getDayjsFormat,
	getValueFromFormattedString,
	getMaxUnitFromDuration,
	getFormattedStringFromValue,
};

export { inputOffsetFormatUtils };
