import deepEqual from 'deep-equal';
import { useEffect, useRef, useState } from 'react';

const usePrevious = <Data>(list: Data[]) => {
	const ref = useRef<Data[]>([]);
	useEffect(() => {
		ref.current = list;
	});
	return ref.current;
};

export const useReloading = <Data>(list: Data[]) => {
	const previousList = usePrevious(list);
	const [reload, setReload] = useState<boolean>(false);
	useEffect(() => {
		if (deepEqual(previousList, list)) {
			setReload(false);
		} else {
			setReload(true);
		}
	}, [list, previousList, setReload]);
	return reload;
};
