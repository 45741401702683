import React, { CSSProperties, MouseEventHandler, ReactNode, TouchEventHandler, useMemo } from 'react';
import { DashboardTile } from '../tile/dashboard-tile';
import { matchAndReplaceWithI18N } from '../../dashboard-common';

/**
 * Wrapper component to encapsulate child elements.
 * Needed by react grid layout.
 * Don't modify any of these properties, otherwise, react-grid-layout behavior can stop working properly
 */
export type DashboardTileWrapperProps = {
	id: string;
	title: string;
	contentType?: string;
	children: ReactNode;
	style?: CSSProperties;
	// eslint-disable-next-line unicorn/no-keyword-prefix
	className?: string;
	isReadOnly: boolean;
	onDashboardTileDelete: (dashboardTileId: string, dashboardTileName: string) => void;
	onSwitchEdition: () => void;
	onMouseDown?: MouseEventHandler<HTMLLIElement>;
	onMouseUp?: MouseEventHandler<HTMLLIElement>;
	onTouchEnd?: TouchEventHandler<HTMLLIElement>;
	editionEnabled: boolean;
	editionHidden: boolean;
};

export const DashboardTileWrapper = React.forwardRef<HTMLLIElement, DashboardTileWrapperProps>(
	(
		{
			id,
			title,
			contentType,
			children,
			onDashboardTileDelete,
			onSwitchEdition,
			style,
			// eslint-disable-next-line unicorn/no-keyword-prefix
			className,
			isReadOnly,
			onMouseDown,
			onMouseUp,
			onTouchEnd,
			editionEnabled = true,
			editionHidden = false,
		},
		ref
	) => {
		const i18nTitle = useMemo(() => matchAndReplaceWithI18N(title), [title]);
		return (
			<li
				data-tile-id={id}
				key={id}
				aria-label={i18nTitle}
				style={{ ...style, listStyle: 'none' }}
				// eslint-disable-next-line unicorn/no-keyword-prefix
				className={className}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onTouchEnd={onTouchEnd}
				ref={ref}
			>
				<DashboardTile
					key={`tile-${id}`}
					title={i18nTitle}
					contentType={contentType}
					onDelete={() => onDashboardTileDelete(id, i18nTitle)}
					onSwitchEdition={onSwitchEdition}
					isReadOnly={isReadOnly}
					editionEnabled={editionEnabled}
					editionHidden={editionHidden}
				>
					{children}
				</DashboardTile>
			</li>
		);
	}
);
