import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import IconInfoOutlined from '@tricentis/aura/components/IconInfoOutlined.js';
import IconChevronDownOutlined from '@tricentis/aura/components/IconChevronDownOutlined.js';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { RefObject, useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { TrendsGraph, TrendsGraphSettings } from './trends';
import { mapToReadableStatisticName } from './trends/graph/trend-statistics';
import { Spinner } from '../../common/spinner';
import { ChartRef } from '../../common/chart';
import { i18n } from '../../../i18n';
import {
	DisplayTestResultsCount,
	TrendElementPage,
	TrendPage,
	TrendsConfiguration,
	TrendsConfigurationPatchRequest,
	useGetV4TestsByTestIdTrendsConfigurationQuery,
	useGetV4TestsByTestIdTrendsElementsQuery,
	useGetV4TestsByTestIdTrendsQuery,
	usePatchV4TestsByTestIdTrendsConfigurationMutation,
	usePutV4TestsByTestIdTrendsConfigurationMutation,
} from '@neoload/api';
import { buildTimestampedFilename, createNeoLoadError, downloadFile, jsonToCsv, timeUtils } from '@neoload/utils';
import { useSetSnackbars } from '@neoload/hooks';

type TestTrendsGraphSectionProps = {
	testId: string;
	testName: string;
	testWorkspaceId: string;
};

export const TestTrendsGraphSection = ({ testId, testName, testWorkspaceId }: TestTrendsGraphSectionProps) => {
	const { t } = useTranslation('test');
	const { showSuccess, showError } = useSetSnackbars();
	const {
		data: trendsPage,
		error: trendsError,
		isLoading: trendsLoading,
	} = useGetV4TestsByTestIdTrendsQuery({
		testId,
	});
	const {
		data: trendsConfiguration,
		error: trendsConfigurationError,
		isLoading: trendsConfigurationLoading,
	} = useGetV4TestsByTestIdTrendsConfigurationQuery({
		testId,
	});
	const {
		data: trendElementPage,
		error: trendElementsError,
		isLoading: trendElementsLoading,
	} = useGetV4TestsByTestIdTrendsElementsQuery({ testId });
	const [patchTrendsConfiguration] = usePatchV4TestsByTestIdTrendsConfigurationMutation();
	const [putTrendsConfiguration] = usePutV4TestsByTestIdTrendsConfigurationMutation();

	const ref = useRef<ChartRef>(null);

	const isLoading = trendsLoading || trendsConfigurationLoading || trendElementsLoading;
	if (isLoading) {
		return <Spinner />;
	}
	if (trendsError) {
		throw createNeoLoadError(trendsError);
	}
	if (trendsConfigurationError) {
		throw createNeoLoadError(trendsConfigurationError);
	}
	if (trendElementsError) {
		throw createNeoLoadError(trendElementsError);
	}
	if (!trendsPage || !trendsConfiguration || !trendElementPage) {
		throw createNeoLoadError();
	}

	const sendTrendsPatchConfigurationRequest = (trendsConfigurationPatchRequest: TrendsConfigurationPatchRequest) => {
		patchTrendsConfiguration({
			testId,
			trendsConfigurationPatchRequest,
		})
			.unwrap()
			.then(() => {
				showSuccess({
					text: t('trends.success.patchConfiguration'),
					id: 'TEST_TRENDS_UPDATE_SUCCESS',
				});
			})
			.catch((error) => {
				const errorLabel = t('trends.errors.patchConfiguration');
				showError({
					text: errorLabel,
				});
				console.error(errorLabel, error);
			});
	};

	const sendTrendsPutConfigurationRequest = (trendsConfiguration: TrendsConfiguration, dryRun: boolean) =>
		putTrendsConfiguration({
			testId,
			dryRun,
			trendsConfiguration,
		})
			.unwrap()
			.then((response) => {
				if (!dryRun) {
					showSuccess({
						text: t('trends.success.patchConfiguration'),
						id: 'TEST_TRENDS_UPDATE_SUCCESS',
					});
				}
				return response;
			})
			.catch((error) => {
				const errorLabel = t('trends.errors.patchConfiguration');
				showError({
					text: errorLabel,
				});
				console.error(errorLabel, error);
			});

	return (
		<Stack marginX={2} gap={2} marginTop={2}>
			<TrendsGraphHeader
				trendsConfiguration={trendsConfiguration}
				trendElementPage={trendElementPage}
				trendPage={trendsPage}
				sendTrendsPatchConfigurationRequest={sendTrendsPatchConfigurationRequest}
				sendTrendsPutConfigurationRequest={sendTrendsPutConfigurationRequest}
				testName={testName}
				testId={testId}
				testWorkspaceId={testWorkspaceId}
				chartRef={ref}
			/>
			<Stack flex={1} justifyContent={'center'} alignItems={'center'}>
				<TrendsGraph
					trendsPage={trendsPage}
					trendsConfiguration={trendsConfiguration}
					sendTrendsPatchConfigurationRequest={sendTrendsPatchConfigurationRequest}
					ref={ref}
				/>
			</Stack>
		</Stack>
	);
};

const LATEST_COUNTS: DisplayTestResultsCount[] = ['10', '20', '50'];

type TrendsGraphHeaderProps = {
	trendsConfiguration: TrendsConfiguration;
	trendElementPage: TrendElementPage;
	trendPage: TrendPage;
	sendTrendsPatchConfigurationRequest: (trendsConfigurationPatchRequest: TrendsConfigurationPatchRequest) => void;
	sendTrendsPutConfigurationRequest: (
		trendsConfiguration: TrendsConfiguration,
		dryRun: boolean
	) => Promise<TrendsConfiguration | void>;
	chartRef: RefObject<ChartRef>;
	testName: string;
	testId: string;
	testWorkspaceId: string;
};

const TrendsGraphHeader = ({
	trendsConfiguration,
	trendElementPage,
	trendPage,
	sendTrendsPatchConfigurationRequest,
	sendTrendsPutConfigurationRequest,
	testName,
	testId,
	testWorkspaceId,
	chartRef,
}: TrendsGraphHeaderProps) => {
	const { t } = useTranslation('test');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = !!anchorEl;

	const closeDownloadMenu = () => {
		setAnchorEl(null);
	};

	const openDownloadMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const downloadPng = () => {
		chartRef.current?.downloadPng(buildTimestampedFilename(testName), testName);
		closeDownloadMenu();
	};

	const downloadCsv = () => {
		const data = trendPageToCsvData(trendPage);
		const fileName = buildTimestampedFilename(testName) + '.csv';
		const fileType = 'text/csv;charset=utf-8';
		downloadFile({
			data,
			fileName,
			fileType,
		});
		closeDownloadMenu();
	};

	return (
		<Stack direction={'row'} alignItems={'center'}>
			<Stack direction={'row'} gap={1} flexBasis={'100%'} alignItems={'center'}>
				<Typography variant='subtitle1'>{t('trends.title')}</Typography>
				<Tooltip title={t('trends.description')} arrow={true}>
					<IconInfoOutlined sx={{ color: (theme) => theme.palette.action.active, fontSize: '1em' }} />
				</Tooltip>
			</Stack>
			<Stack direction={'row'} justifyContent={'center'}>
				<ToggleButtonGroup size={'small'} color='primary' value={trendsConfiguration.displayTestResultsCount}>
					{LATEST_COUNTS.map((count) => (
						<ToggleButton
							key={count}
							value={count}
							sx={{ padding: (theme) => theme.spacing(0, 1) }}
							disabled={trendsConfiguration.displayTestResultsCount === count}
							onClick={() => sendTrendsPatchConfigurationRequest({ displayTestResultsCount: count })}
						>
							{count}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Stack>
			<Stack direction={'row'} gap={1} flexBasis={'100%'} justifyContent={'flex-end'}>
				<Button
					aria-label={t('trends.downloads')}
					variant='outlined'
					size='small'
					startIcon={<FileDownloadOutlinedIcon />}
					endIcon={<IconChevronDownOutlined />}
					onClick={openDownloadMenu}
					disabled={trendElementPage.pageSize === 0}
				>
					{t('trends.downloads.title')}
				</Button>
				<Menu
					open={isMenuOpen}
					data-testid='trends-download-menu'
					anchorEl={anchorEl}
					onClose={closeDownloadMenu}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					MenuListProps={{ dense: true }}
				>
					<MenuItem onClick={() => downloadPng()}>
						<ListItemText primary={t('trends.downloads.png')} primaryTypographyProps={{ variant: 'caption' }} />
					</MenuItem>
					<MenuItem onClick={() => downloadCsv()}>
						<ListItemText primary={t('trends.downloads.csv')} primaryTypographyProps={{ variant: 'caption' }} />
					</MenuItem>
				</Menu>
				<TrendsGraphSettings
					trendsConfiguration={trendsConfiguration}
					trendElements={trendElementPage.items}
					sendTrendsPutConfigurationRequest={sendTrendsPutConfigurationRequest}
					testId={testId}
					testWorkspaceId={testWorkspaceId}
				/>
			</Stack>
		</Stack>
	);
};

const trendPageToCsvData = (trendPage: TrendPage) => {
	const json: object[] = [];
	const nameHeader = i18n.t('columns.name.title', {
		ns: 'result',
	});
	const startDateHeader = i18n.t('columns.startDate.title', {
		ns: 'result',
	});
	const statusHeader = i18n.t('columns.qualityStatus.title', {
		ns: 'result',
	});
	for (const item of trendPage.items) {
		let line: {
			[key: string]: string | number;
		} = {
			[nameHeader]: item.metadata.resultName,
			[startDateHeader]: timeUtils.dateTimeAbsolute(item.metadata.resultStartDate),
			[statusHeader]: item.metadata.resultQualityStatus,
		};
		for (const point of item.points) {
			const elementFullPath = point.element.fullPath;
			const elementName = elementFullPath.at(-1);
			const title = i18n.t('trends.graph.legend', {
				statistic: mapToReadableStatisticName(point.statistic),
				element: elementName,
				ns: 'test',
			});
			line = {
				...line,
				[title]: point.value,
			};
		}
		json.push(line);
	}
	return jsonToCsv(json);
};
