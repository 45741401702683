import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirmModal, useCrudBatchMessage } from '../modals';
import { Test, useDeleteV4TestsByTestIdMutation } from '@neoload/api';

const useDeleteTests = (redirectRoute?: string) => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const { t } = useTranslation(['test']);
	const { remove } = useCrudBatchMessage('test');
	const [deleteTest] = useDeleteV4TestsByTestIdMutation();
	const navigate = useNavigate();

	return useCallback(
		(testsToDelete: Pick<Test, 'id'>[]) => {
			const onDeleteTests = async (resultIds: string[]) => {
				await remove(resultIds, (testId) => deleteTest({ testId: testId }).unwrap());
				if (redirectRoute) {
					navigate(redirectRoute);
				}
			};
			const title = t('deleteSingleTestDialog.title', { count: testsToDelete.length });
			const content = t('deleteSingleTestDialog.label', { count: testsToDelete.length });

			openDeleteConfirmModal({
				title,
				content,
				handleConfirm: async () => onDeleteTests(testsToDelete.map((test) => test.id)),
			});
		},
		[deleteTest, navigate, openDeleteConfirmModal, redirectRoute, remove, t]
	);
};

export { useDeleteTests };
