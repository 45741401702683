const test = {
	description:
		'The Tests view helps you manage a test from creation to deletion. It includes operations like the execution of a test or its move to another workspace.',
	requiredName: `Test name is required.`,
	deleteConfirm: 'Deleting a test is permanent and cannot be undone',
	createConfirm: 'New test {{name}} created',
	configure: 'Configure',
	labelName: 'Test name',
	editTestName: 'Edit',
	placeholderDescription: 'Description',
	buttonCancel: 'Cancel',
	buttonConfirmCreate: 'Create',
	testNameAlreadyExist: 'A test with this name already exists',
	// eslint-disable-next-line unicorn/no-keyword-prefix
	newTest: 'New test {{number}}',
	testExecution: {
		summary: 'Test run overview',
		configuration: 'Test configuration',
		licenseAllocation: 'License allocation',
		project: 'Project',
		scenario: 'Scenario',
		duration: 'Test duration',
		durationUndetermined: 'Undetermined',
		maxDuration: 'Max. duration',
		virtualUsers: 'Virtual users',
		vuhEstimation: 'VUH estimation',
		vuhEstimationDescription:
			'The real VUH consumption is computed on the effective test duration and maximum number of VU',
		available: 'available',
		cloud: 'Cloud credits',
		cloudDescription: 'One credit per hour per Load Generator running in the NeoLoad Cloud Platform',
		resultInformation: 'Test result information',
		resultName: 'Test name',
		resultNameRequired: 'Test name is required',
		character: 'characters',
		description: 'Description',
		noDescription: '- no description -',
		cancelRun: 'Cancel',
		run: 'Run test',
		lg: 'LG',
		lg_one: 'LG',
		lg_other: 'LGs',
		zones: 'Zones',
		controllerZone: '(Controller zone)',
		autoReservation: 'Auto reservation',
		minDuration: 'Min.',
		warningRunTestWithoutValidLicense:
			'No valid license found. Test may proceed only if controller has adequate local license; otherwise, it will fail.',
		errors: {
			// error codes from API
			/* eslint-disable @typescript-eslint/naming-convention */
			MissingLg: 'Insufficient load generators matching controller version.',

			MissingLg_version: 'Insufficient load generators matching controller version {{version}}.',

			MissingController: 'No controller available in the selected zone.',

			IncompatibleVersion: 'Project not compatible with the available controller version.',

			IncompatibleVersion_version:
				'Project not compatible with the available controller version. Requires controller version {{version}} or higher.',

			NotEnoughCloudCredits: 'Insufficient cloud credits: your account lacks the credits needed for this test.',

			NotEnoughVuh: 'Insufficient VUH: your account lacks the VUH needed for this test.',
			ncpUnreachable:
				'Cloud platform unreachable: network timeout or service issue prevents starting the test with cloud LGs. Retry Later.',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		notEnoughDedicatedIp: {
			tooltip: {
				controller: 'No dedicated IP available to be assigned to the controller.',
				lg_one: 'Missing {{ips}} dedicated IP.',
				lg_other: 'Missing {{ips}} dedicated IPs.',
			},
			warning: {
				controller: 'Dedicated IP(s) available for LG(s) but not for the controller.',
				lg: 'Insufficient dedicated IP(s): ensure you have enough IP(s) for all LGs and possibly one for the controller.',
			},
		},
		sap: 'SAP:',
		web: 'Web:',
		webEdit: 'Edit Web vus',
		sapEdit: 'Edit SAP vus',
	},
	grid: {
		title: 'Tests',
		header: {
			name: 'Name',
			project: 'Project',
			lastRun: 'Last run',
			lastModifiedBy: 'Last modified by',
			lastModifiedDate: 'Last modified',
		},
		actions: {
			createTest: 'Create test',
			runTest: 'Run test',
			runTestReservation: 'Run with reservation',
			editionConfiguration: 'Configure',
			moveToWorkspace: 'Move to workspace',
			deleteTest: 'Delete',
			addEditDescription: {
				menuItemAdd: 'Add description',
				menuItemEdit: 'Edit description',
				dialogTitle: {
					edit: 'Edit description',
					add: 'Add description',
				},
				label: 'Description',
				button: {
					cancel: 'Cancel',
					save: 'Save',
				},
			},
		},
		run: {
			moreThanOneSelected: "Can't run more than one test",
			notConfigured: 'You must complete the test configurations before running the test.',
		},
		edit: {
			moreThanOneSelected: "Can't configure more than one test",
		},
	},
	configuration: {
		disabledText: 'Upload a project to continue with the configuration',
		project: {
			title: 'NeoLoad Project',
			separator: 'OR',
			export: {
				description:
					'Use the “Export and send to NeoLoad web” feature in the NeoLoadGUI to push the project here easy and fast.',
				title: 'NeoLoad export to web',
			},
			file: {
				title: 'File upload',
				description: 'Drop project from your local storage here, or',
				button: 'Select file',
				limit: 'ZIP, YAML (1 file up to {{size}})',
				sizeFile: {
					kb: '{{size}}KB',
					mb: '{{size}}MB',
					gb: '{{size}}GB',
				},
			},
			git: {
				title: 'Git fetch',
				description: 'Fetch the project from your Git repository',
				button: 'Clone from Git',
				modal: {
					title: 'Clone from Git',
					repository: 'Repository',
					reference: 'Branch',
					clone: 'Clone the project',
					loading: 'Loading...',
					noRepositories: 'No repositories',
					noReferences: 'No branches',
				},
			},
			download: 'Download NeoLoad GUI',
			project: 'Project',
			size: 'Size',
			uploaded: 'Uploaded',
			uploadedBy: 'Uploaded by',
			source: 'Source',
			sourceGit: 'Git',
			sourceLocal: 'Local storage',
		},
		scenarios: {
			types: {
				fromProject: 'From project',
				custom: 'Create custom',
			},
			title: 'Load scenario',
			label: 'Scenario',
			parameters: 'Parameters',
			virtualUsers: 'Virtual users:',
			vus: 'VUs',
			duration: 'Duration:',
			undetermined: 'undetermined',
			unsupported: 'Unsupported',
		},
		zones: {
			name: 'Zone name',
			lg: 'LG',
			controller: 'Controller',
			title: 'Load zones',
			types: {
				ncp: 'NeoLoad Cloud',
				static: 'Static',
				dynamic: 'Dynamic',
			},
			noZones: {
				ncp: 'You can add cloud zones through the Resources panel.',
				dynamic: "You don't have any dynamic zone created yet. Use the Resources panel to create at least one.",
			},
			ncp: {
				freeController: 'NeoLoad automatically provides at no cost the controller in the zone with the most LGs.',
			},
			dynamic: {
				noController: 'You can only use one dynamic zone.',
			},
		},
		advanced: {
			title: 'Advanced',
			label: 'Result name mask',
			helper: 'Define a name pattern for all future test results.',
			error: {
				required: 'The test result mask is required',
			},
		},
		snackbar: {
			configSuccess: 'Changes saved',
			configFail: 'Failed to update {{name}}',
			uploading: '{{name}} uploading',
			uploadSuccess: '{{name}} uploaded',
			uploadFail: '{{name}} upload failed: {{error}}',
			fileTooLarge: '{{name}} is too large. Max. file size: {{maxSize}}',
			fileExtensionsIncorrect: '{{name}} has an invalid extension. Valid extensions: {{extensions}}',
		},
		aria: {
			moreActions: 'more test actions',
			configuration: 'test configuration',
		},
		errors: {
			invalidFile: 'Invalid file',
			missingProjectContent: 'Missing project content',
			parsingFailure: 'Parsing failure',
			invalidProject: 'Invalid project',
		},
	},
	zero: {
		title: 'Create your first test',
		content:
			'A test is the basic unit that describes your testing scenario. \n' +
			'Create a test case to get started with your testing!',
		button: 'Create test',
	},
	zeroGuest: {
		title: 'There is no test here yet',
		content: '',
		button: 'Back home',
	},
	create: {
		success_one: 'Test {{name}} has been created',
		error_one: 'One test has not been created: {{cause}}',
	},
	delete: {
		success_one: 'Test {{name}} deleted',
		success_other: '{{count}} tests deleted',
		error_one: 'One test has not been deleted: {{cause}}',
		error_other: '{{count}} tests have not been deleted',
	},
	deleteSingleTestDialog: {
		title: 'Delete test?',
		label:
			"Deleting the test will permanently erase it from your data. It's not reversible, and you won't be able to retrieve it once removed.",
	},
	deleteMultipleTestDialog: {
		title: 'Delete {{testNumber}} tests?',
		label:
			"Deleting the tests will permanently erase them from your data. It's not reversible, and you won't be able to retrieve them once removed.",
	},
	testPage: {
		lastModified: 'Last modified:',
		date: '{{date}} by {{author}}',
	},
	messages: {
		updateNameSuccess: 'Test successfully renamed.',
		updateDescriptionSuccess: 'Description edited',
		deleteDescriptionSuccess: 'Description deleted',
		addDescriptionSuccess: 'Description added',
		updateNameError: 'Failed to rename test.',
	},
	validation: {
		name: {
			empty: 'Test name is required',
			tooLong: 'Test name must not exceed 80 characters',
		},
	},
	trends: {
		title: 'Test analysis',
		description: 'The test analysis graph provides an overview of how your test results key metrics are trending.',
		graph: {
			noData: 'No transaction data available',
			legend: '{{statistic}} of {{element}}',
		},
		success: {
			patchConfiguration: 'Configuration saved',
		},
		errors: {
			patchConfiguration: 'Failed to save configuration',
		},
		downloads: {
			title: 'Export',
			png: 'PNG',
			csv: 'CSV',
		},
		settings: {
			button: 'Settings',
			title: 'Test Analysis configuration',
			metrics: 'Metrics',
			element: 'Element',
			value: 'Value',
			statistic: 'Statistic',
			objectivesTitle: 'Objectives Mode',
			objective: 'Objective',
			referenceResult: 'Reference result',
			tolerance: 'Tolerance',
			shouldFailTestExecutionOnObjectiveExceeded:
				'Test Result status as "Failed" when one of the objectives is reached',
			cancel: 'Cancel',
			apply: 'Apply',
			objectiveMode: {
				static: 'Static',
				staticInfo: 'You manually set values for the metrics.',
				baseline: 'Baseline',
				baselineInfo:
					'Values for metrics are taken from a reference baseline Result you designate. You can specify a tolerance in %.',
				automated: 'Automated',
				automatedInfo:
					'NeoLoad computes statistical values for the metrics, based on the history of these metrics up to the latest 50 PASSED Results. You must specify a tolerance in standard deviation.',
			},
			actions: {
				addMetric: 'Add a new metric to the test analysis configuration',
				addMetricLimit: 'You cannot add more than {{max}} metrics to the test analysis configuration',
				removeMetric: 'Remove',
				removeMetricLimit: 'At least {{min}} metric is required',
			},
			errorMessages: {
				emptyObjective: 'Objective value is empty',
				positiveObjective: 'Objective value must be positive',
				noObjective: 'This element has no transaction for this result. NeoLoad cannot compute its objective value.',
				emptyTolerance: 'Tolerance value is empty',
				positiveTolerance: 'Tolerance value must be positive',
				noResultAvailable: 'No result available for this test',
				noResultSelected: 'No result selected',
			},
		},
		statistics: {
			/* eslint-disable @typescript-eslint/naming-convention */
			duration_percentile_99: 'Perc 99 duration',
			duration_percentile_95: 'Perc 95 duration',
			duration_percentile_90: 'Perc 90 duration',
			duration_percentile_50: 'Perc 50 duration',
			average_duration: 'Average duration',
			minimum_duration: 'Minimum duration',
			maximum_duration: 'Maximum duration',
			element_count: 'Sum count',
			error_count: 'Failure sum count',
			elements_per_second: 'Count per second',
			error_rate: 'Failure rate',
			minimum_ttfb: 'Min. TTFB',
			maximum_ttfb: 'Max. TTFB',
			average_ttfb: 'Avg. TTFB',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
		statisticsUnits: {
			elementsPerSec: {
				short: 'elt/s',
				long: 'Elements per second',
			},
			seconds: {
				short: 's',
				long: 'Seconds',
			},
		},
	},
	reservations: {
		currentReservations: 'Current reservations',
		remaining: 'remaining',
		autoReservation: 'Auto reservation',
		autoReservationText: 'Reservation will be created according to the test launch required resources.',
		lgs: 'LGs',
		vus: 'Vus',
	},
};

export { test };
