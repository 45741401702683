type CsvValueSeparator = ',' | ';';
type CsvLineSeparator = '\n';

const CHAR_TO_ESCAPE = '"';
const ESCAPED_CHAR = CHAR_TO_ESCAPE + CHAR_TO_ESCAPE;
const ESCAPE_REGEX = /"/g;

const csvEscape = (value: string): string => {
	if (!value) {
		return '';
	}
	return [
		CHAR_TO_ESCAPE,
		typeof value === 'string' ? value.replaceAll(ESCAPE_REGEX, ESCAPED_CHAR) : value,
		CHAR_TO_ESCAPE,
	].join('');
};

export const jsonToCsv = (
	json: object[],
	valueSeparator: CsvValueSeparator = ';',
	lineSeparator: CsvLineSeparator = '\n'
) => {
	if (json.length === 0) {
		return '';
	}
	const keys = json.flatMap((object) => Object.keys(object));
	if (keys.length === 0) {
		return '';
	}
	const headersAsSet = new Set<string>();
	for (const key of keys) {
		headersAsSet.add(key);
	}
	const headers = [...headersAsSet.values()];

	const lines = [];
	lines.push(headers.map(csvEscape).join(valueSeparator));

	for (const object of json) {
		const objectAsMap = new Map(Object.entries(object));
		const values = headers.map((header) => objectAsMap.get(header));
		lines.push(values.map(csvEscape).join(valueSeparator));
	}

	return lines.join(lineSeparator);
};
