import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useSetSnackbars } from '../snackbars/use-set-snackbars';

type Resource = { name: string } | void;
type Callback<T, R extends Resource> = (argument: T) => Promise<R>;

/**
 * This hook need specific locale structure for given translation domain :
 * <p>
 * 	create: {
 * 		success_one: '',
 * 		error_one: '',
 * 		error_one: '',
 * 		error_other: '',
 * 	},
 * 	update: {
 * 		success_one: '',
 * 		success_other: '',
 * 		error_one: '',
 * 		error_other: '',
 * 	},
 * 	delete: {
 * 		success_one: '',
 * 		success_other: '',
 * 		error_one: '',
 * 		error_other: '',
 * 	}
 * 	</p>
 * 	It can be used like :
 * 	<p> const { update, create, remove } = useCrudBatchMessage(['dashboard']); </p>
 * @param ns translation domain used for useTranslation
 * @param keyPrefix optional, prefix used to reach translation keys if they are nested
 * @param additionalI18nParams additional parameters used in your i18n string template
 */
const useCrudBatchMessage = (ns: string, keyPrefix?: string, additionalI18nParams?: { [key: string]: string }[]) => {
	const { showInfo, showError } = useSetSnackbars();
	const { t } = useTranslation(ns, { keyPrefix: keyPrefix });

	const batch = useCallback(
		(operationTranslationName: string) =>
			async <T, R extends Resource>(args: T[], callback: Callback<T, R>) => {
				const isError = (result: PromiseSettledResult<R>): result is PromiseRejectedResult => result.status === 'rejected';
				const isSuccess = (result: PromiseSettledResult<R>): result is PromiseFulfilledResult<R> => result.status === 'fulfilled';

				const promises = args.map((value) => callback(value));

				const all = await Promise.allSettled(promises);
				const errors = all.filter(isError).map((p) => p.reason);
				const successes = all.filter(isSuccess).map((p) => (p as PromiseFulfilledResult<R>).value);
				if (errors.length > 0) {
					const message = t(`${operationTranslationName}.error`, {
						count: errors.length,
						cause: JSON.stringify(errors[0]),
						...additionalI18nParams?.reduce((accumulator, current) => ({ ...accumulator, ...current }), {}),
					});
					showError({ text: message });
				}
				if (successes.length > 0) {
					const message = t(`${operationTranslationName}.success`, {
						count: successes.length,
						name: successes[0]?.name,
						...additionalI18nParams?.reduce((accumulator, current) => ({ ...accumulator, ...current }), {}),
					});
					showInfo({ text: message });
				}
			},
		[additionalI18nParams, showError, showInfo, t]
	);

	return {
		create: batch('create'),
		update: batch('update'),
		remove: batch('delete'),
	};
};

export { useCrudBatchMessage };
