import { useMemo } from 'react';
import { type Feature } from './features';
import { useGetV4MeFeaturesQuery } from '@neoload/api';

type FeatureStatus = { isLoading: true; isFeatureEnabled: undefined } | { isLoading: false; isFeatureEnabled: boolean };

const useFeatureEnabled = (featureName: Feature) => {
	const { data, isLoading } = useGetV4MeFeaturesQuery();

	const result: FeatureStatus = useMemo(() => {
		if (isLoading) {
			return {
				isLoading: true,
			};
		}
		const isEnabled = data?.enabledFeatures.includes(featureName) ?? false;
		return { isLoading: false, isFeatureEnabled: isEnabled };
	}, [isLoading, data, featureName]);

	return [result] as const;
};

export { useFeatureEnabled };
