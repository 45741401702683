import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useSetSnackbars, useGetMe } from '@neoload/hooks';
import { usePatchV4ResultsByResultIdMutation } from '@neoload/api';

export type ResultStatusDetailsProps = {
	resultId: string;
	resultDescription: string;
};

const ResultStatusDetails = ({ resultId, resultDescription }: ResultStatusDetailsProps) => {
	const { t } = useTranslation(['result']);
	const [{ hasTesterPermissions }] = useGetMe();
	const [loading, setLoading] = useState(false);
	const [draftDescription, setDraftDescription] = useState(resultDescription || '');
	const [editing, setEditing] = useState(false);
	const { showError } = useSetSnackbars();
	const [patchResult, patchResultResponse] = usePatchV4ResultsByResultIdMutation();
	const theme = useTheme();

	useEffect(() => {
		setLoading(patchResultResponse.isLoading);
		if (patchResultResponse.data) {
			setDraftDescription(patchResultResponse.data.description ?? '');
		}
		if (patchResultResponse.error) {
			showError({
				text: t('messages.failedToPatchDescription'),
			});
			setDraftDescription(resultDescription || '');
		}
	}, [patchResultResponse, showError, resultDescription, t]);

	useEffect(() => {
		setDraftDescription(resultDescription || '');
	}, [resultDescription]);

	const placeholder = (): string => {
		if (!hasTesterPermissions) {
			return t('overview.emptyDescription');
		}
		if (!editing) {
			return t('overview.clickToAddDescription');
		}
		return '';
	};

	return (
		<Grid item md sx={{ paddingX: 2, paddingTop: 2 }}>
			<Typography variant='subtitle1' gutterBottom data-testid={`result-status-details-title`}>
				{t('common:description')}
			</Typography>
			{editing ? (
				<TextareaAutosize
					defaultValue={draftDescription}
					style={{
						width: '100%',
						fontFamily: theme.typography.caption.fontFamily,
						fontSize: theme.typography.caption.fontSize,
						color: theme.typography.caption.color,
						lineHeight: theme.typography.caption.lineHeight,
						fontWeight: theme.typography.caption.fontWeight,
						letterSpacing: theme.typography.caption.letterSpacing,
						resize: 'none',
					}}
					ref={(ref: HTMLTextAreaElement | null) => {
						if (ref !== null) {
							ref.focus();
						}
					}}
					maxRows={5.5}
					minRows={5.5}
					onBlur={(event) => {
						const { value: description = '' } = event.target;
						setEditing(false);
						if (description !== null && (draftDescription || '') !== description) {
							setLoading(true);
							setDraftDescription(description);
							patchResult({
								resultId,
								testResultInput: { description },
							});
						}
					}}
				/>
			) : (
				<Typography
					variant='caption'
					contentEditable={hasTesterPermissions && !loading}
					data-testid={`result-status-details-description`}
					sx={loading || (!editing && !resultDescription) ? { opacity: 0.5 } : {}}
					paragraph
					suppressContentEditableWarning
					maxHeight={99}
					overflow={'auto'}
					textOverflow={'ellipsis'}
					onFocus={() => {
						setEditing(true);
					}}
					margin={0}
					style={{ overflowWrap: 'anywhere' }}
					whiteSpace={'pre-wrap'}
				>
					{draftDescription || placeholder()}
				</Typography>
			)}
		</Grid>
	);
};

export { ResultStatusDetails };
