import React, { CSSProperties } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material';

const ReactGridLayout = WidthProvider(RGL);

export type HomeTileProps = {
	title: string;
	link?: string;
	header: boolean;
	children: React.ReactNode;
	cssClassName?: string;
	style?: CSSProperties;
};
type Coordinates = {
	x: number;
	y: number;
	w: number;
	h: number;
};

export type HomeGridProps = {
	tiles: (HomeTileProps & Coordinates)[];
};
const HomeTile = React.forwardRef<HTMLLIElement, HomeTileProps>(
	({ style, cssClassName, header, title, link, children }, ref) => {
		const { t } = useTranslation(['home']);
		const theme = useTheme();

		return (
			<ListItem
				sx={{
					backgroundColor: theme.palette.background.paper,
					borderRadius: '4px',
					boxShadow: theme.shadows['1'],
					padding: '0',
				}}
				aria-label={title}
				key={title}
				ref={ref}
				className={cssClassName}
				style={style}
			>
				<Stack sx={{ height: '100%', width: '100%' }}>
					{header && (
						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}
							paddingTop={(theme) => theme.spacing(2)}
							paddingX={(theme) => theme.spacing(2)}
						>
							<Typography title={title} variant='subtitle1'>
								{title}
							</Typography>
							{link && (
								<Link to={link}>
									<Button variant={'text'}>{t('grid.tile.header.viewAll')}</Button>
								</Link>
							)}
						</Stack>
					)}
					<Box sx={{ height: '100%' }}>{children}</Box>
				</Stack>
			</ListItem>
		);
	}
);

export const HomeGrid = ({ tiles }: HomeGridProps) => {
	const theme = useTheme();
	return (
		<Paper
			sx={{
				background: 'none',
				boxShadow: 'none',
			}}
			elevation={0}
		>
			<ReactGridLayout
				cols={3}
				autoSize={true}
				rowHeight={300}
				margin={[16, 16]}
				isResizable={false}
				containerPadding={[0, 0]}
				useCSSTransforms={false}
				style={{
					marginBottom: theme.spacing(4),
				}}
			>
				{tiles.map((tile) => (
					<HomeTile key={tile.title} {...tile} data-grid={{ x: tile.x, y: tile.y, w: tile.w, h: tile.h }} />
				))}
			</ReactGridLayout>
		</Paper>
	);
};
