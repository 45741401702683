import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialWorkspaceState } from './model';
import { UserWorkspace } from '../../nlw-api-v4';

export type WorkspaceState = {
	workspaces: UserWorkspace[];
	selectedWorkspace: UserWorkspace;
};

const workspaceSlice = createSlice({
	name: 'workspace',
	initialState: initialWorkspaceState,
	reducers: {
		setSelectedWorkspace: (state, action: PayloadAction<UserWorkspace>) => {
			state.selectedWorkspace = action.payload;
		},
		setWorkspaces: (state, action: PayloadAction<UserWorkspace[]>) => {
			state.workspaces = action.payload;
		},
	},
});

const { reducer: workspaceReducer, actions } = workspaceSlice;
export const { setSelectedWorkspace, setWorkspaces } = actions;
export { workspaceReducer, workspaceSlice };
