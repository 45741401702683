import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalTestExecution } from './modal-test-execution';
import {
	OpenConfirmModalAction,
	openModal,
	TestExecutionInput,
	usePostV4TestExecutionsMutation,
	closeModal,
} from '@neoload/api';
import { CommonRoutes } from '@neoload/utils';
import { useSetSnackbars } from '@neoload/hooks';

const useOpenTestExecutionModal = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['test']);
	const navigate = useNavigate();
	const { showError } = useSetSnackbars();
	const [testExecution] = usePostV4TestExecutionsMutation();

	return {
		openTestExecutionModal: useCallback(
			(testId: string) => {
				const act: OpenConfirmModalAction = {
					title: t('testExecution.summary'),
					content: (valueUpdater, setConfirmable, close) => (
						<ModalTestExecution
							testId={testId}
							valueUpdater={valueUpdater}
							setConfirmable={setConfirmable}
							close={close}
						/>
					),
					width: 750,
					confirm: {
						text: t('testExecution.run'),
					},
					canConfirm: false,
					handleConfirm: async (fields) => {
						const { testId, name, description, webVu, sapVu, duration, reservationId } = fields as TestExecutionInput;
						if (testId) {
							const d = await (reservationId
								? testExecution({
										dryRun: false,
										testExecutionInput: {
											testId: testId,
											name: name,
											description: description,
											reservationId: reservationId,
										},
								  }).unwrap()
								: testExecution({
										dryRun: false,
										testExecutionInput: {
											testId: testId,
											name: name,
											description: description,
											webVu: webVu,
											sapVu: sapVu,
											duration: duration,
										},
								  }).unwrap());
							navigate(CommonRoutes.results.logs(d.id), { state: { fromTestExecution: true } });
						} else {
							showError({
								text: t('common:errors.otherError.content'),
								id: 'error-run-test',
							});
							dispatch(closeModal());
							throw new Error('missing fields');
						}
					},
				};

				return dispatch(openModal(act));
			},
			[t, dispatch, testExecution, navigate, showError]
		),
	};
};

export { useOpenTestExecutionModal };
