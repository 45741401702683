import { TFunction } from 'i18next';
import themeOptionsDataGrid from '@tricentis/aura/constants/themeOptionsDataGrid.js';
import { GridRowHeightParams } from '@mui/x-data-grid';
import { CommonRoutes } from '../routes/commons';
import { SettingsRoutes } from '../routes/settings';
import { ElementComparisonRow } from '@neoload/api';

export type DocumentTitleModel = {
	path: string;
	title: string;
};

export const productName = 'NeoLoad';

export const smallRowsPerPageOption = 50;
export const mediumRowsPerPageOption = 100;
export const largeRowsPerPageOption = 200;
export const defaultRowsPerPage = smallRowsPerPageOption;
export const defaultRowsPerPageOptions = [smallRowsPerPageOption, mediumRowsPerPageOption, largeRowsPerPageOption];

export const RAW_DATA_RETENTION_DURATION_IN_DAYS = 30;

export const DEFAULT_INTERVAL_COLOR = '#096FED';

const applicationsTitleList = (t: TFunction): DocumentTitleModel[] => [
	{
		path: CommonRoutes.tests.patterns.base,
		title: t('tests'),
	},
	{
		path: CommonRoutes.results.patterns.base,
		title: t('results'),
	},
	{
		path: CommonRoutes.dashboards.patterns.base,
		title: t('dashboards'),
	},
	{
		path: SettingsRoutes.generalSettings,
		title: t('settings'),
	},
	{
		path: SettingsRoutes.workspaces,
		title: t('workspaces'),
	},
	{
		path: SettingsRoutes.users,
		title: t('users'),
	},
	{
		path: SettingsRoutes.subscription,
		title: t('subscription'),
	},
	{
		path: SettingsRoutes.infrastructureProviders,
		title: t('infrastructureProviders'),
	},
	{
		path: CommonRoutes.home.patterns.base,
		title: t('home'),
	},
];

type ElementType = ElementComparisonRow['elementType'];

const allXxxElements: {
	id: string;
	name: string;
	type: ElementType;
}[] = [
	{
		id: 'all-transactions',
		name: 'All Transactions',
		type: 'TRANSACTION',
	},
	{
		id: 'all-pages',
		name: 'All Pages',
		type: 'PAGE',
	},
	{
		id: 'all-requests',
		name: 'All Requests',
		type: 'REQUEST',
	},
	{
		id: 'all-actions',
		name: 'All Actions',
		type: 'ACTION',
	},
];

const sharedContainersRootElementName = 'Shared Containers';

const getAllXxxIdByElementType = (elementType: ElementType): string | undefined =>
	allXxxElements.find((element) => element.type === elementType)?.id;

const getAllXxxId = (elementName?: string): string | undefined =>
	allXxxElements.find((element) => element.name === elementName)?.id;

const isAnAllXxxElement = (path: string[]): boolean => {
	const elementName = path.at(-1);
	return allXxxElements.some((element) => element.name === elementName);
};

const getRowHeight = (densityFactor: number): number => {
	const gridRowHeightParams: GridRowHeightParams = {
		densityFactor: densityFactor,
		id: '',
		model: {},
	};

	return themeOptionsDataGrid.components.MuiDataGrid.defaultProps.getRowHeight(gridRowHeightParams);
};

const defaultGridHeaderHeight = 56;

export {
	applicationsTitleList,
	allXxxElements,
	ElementType,
	getAllXxxId,
	getAllXxxIdByElementType,
	isAnAllXxxElement,
	sharedContainersRootElementName,
	defaultGridHeaderHeight,
	getRowHeight,
};
