import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { VusLicenseAllocation } from './license-allocation/vus-license-allocation';
import { DurationLicenseAllocation } from './license-allocation/duration-license-allocation';
import { TestExecutionReservation } from './test-execution-reservation';
import { gap } from '../constant';

const TestExecutionLicenseAllocation = () => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();

	return (
		<Stack useFlexGap flexDirection='column' gap={gap(theme)} sx={{ alignItems: 'flex-start', width: '50%' }}>
			<Stack sx={{ width: '100%' }} useFlexGap flexDirection='row' justifyContent='space-between'>
				<Typography variant='subtitle1'>{t('test:testExecution:licenseAllocation')}</Typography>
				<TestExecutionReservation />
			</Stack>
			<Stack useFlexGap flexDirection='column'>
				<Stack useFlexGap marginBottom='12px' gap={theme.spacing(4)} flexDirection='row'>
					<DurationLicenseAllocation />
				</Stack>
				<Stack useFlexGap sx={{ width: '100%' }}>
					<Stack useFlexGap gap={theme.spacing(4)} flexDirection='row'>
						<Typography variant='subtitle2' width='100px'>
							{t('test:testExecution:virtualUsers')}
						</Typography>
						<Box>
							<VusLicenseAllocation typeVu='WEB' />
							<VusLicenseAllocation typeVu='SAP' />
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export { TestExecutionLicenseAllocation };
