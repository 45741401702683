export const USER_STATUS = {
	PENDING: 'user:userStatus.pending', // eslint-disable-line @typescript-eslint/naming-convention
	ACTIVE: 'user:userStatus.active', // eslint-disable-line @typescript-eslint/naming-convention
} as const;
export type UserStatus = keyof typeof USER_STATUS;

export const USER_ROLE = {
	NONE: 'user:userRole.none', // eslint-disable-line @typescript-eslint/naming-convention
	GUEST: 'user:userRole.guest', // eslint-disable-line @typescript-eslint/naming-convention
	TESTER: 'user:userRole.tester', // eslint-disable-line @typescript-eslint/naming-convention
	NEOLOAD_ADMIN: 'user:userRole.neoloadAdmin', // eslint-disable-line @typescript-eslint/naming-convention
	ACCOUNT_ADMIN: 'user:userRole.accountAdmin', // eslint-disable-line @typescript-eslint/naming-convention
} as const;
export type UserRole = keyof typeof USER_ROLE;
