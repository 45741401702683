import { useFormContext } from 'react-hook-form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TileEditionFormFields } from './tile-edition-form';
import { SearchResultCombo } from '../../../../results/search-result-combo';
import { DashboardFilter, TestResult, WidgetDashboardTile } from '@neoload/api';
import { useUrlSearchParams } from '@neoload/hooks';

export type DashboardTileProps = {
	dashboardTile: WidgetDashboardTile;
};

type FilterWithPresetProps = {
	group: string;
	type: string;
	filter: DashboardFilter;
	visualization: 'SUMMARY' | 'TABLE' | 'COUNTER' | 'TEXT' | 'LINE_CHART' | 'PIE_CHART' | 'CATEGORY';
};

const mappingFilterWithPreset: FilterWithPresetProps[] = [
	{ group: 'groups.testResultInfo', type: 'types.summary', filter: { type: 'EMPTY' }, visualization: 'SUMMARY' },
	{
		group: 'groups.errors',
		type: 'types.topByLg',
		filter: { type: 'ERRORS_COUNT', aggregationType: 'LG_NAME', sort: '-count', pageSize: 10, pageNumber: 0 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.errors',
		type: 'types.topByElement',
		filter: { type: 'ERRORS_COUNT', aggregationType: 'ELEMENT_ID', sort: '-count', pageSize: 10, pageNumber: 0 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.errors',
		type: 'types.topByCode',
		filter: { type: 'ERRORS_COUNT', aggregationType: 'CODE', sort: '-count', pageSize: 10, pageNumber: 0 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.slaAlerts',
		type: 'types.top',
		filter: {
			type: 'SLA_PER_INTERVAL',
			sort: '-failPercentage',
			pageSize: 10,
			filter: { elementType: [], slaStatus: ['WARN', 'FAILED'] },
		},
		visualization: 'TABLE',
	},
	{
		group: 'groups.transactions',
		type: 'types.keyMetrics',
		filter: { type: 'KEY_METRICS', elementType: 'TRANSACTION' },
		visualization: 'COUNTER',
	},
	{
		group: 'groups.transactions',
		type: 'types.topAverageDuration',
		filter: { type: 'ELEMENTS_VALUES', elementType: 'TRANSACTION', sort: '-averageDuration', pageSize: 10 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.transactions',
		type: 'types.topMaximumDuration',
		filter: { type: 'ELEMENTS_VALUES', elementType: 'TRANSACTION', sort: '-maximumDuration', pageSize: 10 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.pages',
		type: 'types.topAverageDuration',
		filter: { type: 'ELEMENTS_VALUES', elementType: 'PAGE', sort: '-averageDuration', pageSize: 10 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.pages',
		type: 'types.topMaximumDuration',
		filter: { type: 'ELEMENTS_VALUES', elementType: 'PAGE', sort: '-maximumDuration', pageSize: 10 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.requests',
		type: 'types.keyMetrics',
		filter: { type: 'KEY_METRICS', elementType: 'REQUEST' },
		visualization: 'COUNTER',
	},
	{
		group: 'groups.requests',
		type: 'types.topAverageDuration',
		filter: { type: 'ELEMENTS_VALUES', elementType: 'REQUEST', sort: '-averageDuration', pageSize: 10 },
		visualization: 'TABLE',
	},
	{
		group: 'groups.requests',
		type: 'types.topMaximumDuration',
		filter: { type: 'ELEMENTS_VALUES', elementType: 'REQUEST', sort: '-maximumDuration', pageSize: 10 },
		visualization: 'TABLE',
	},
];

const getGroupAndTypeByFilter = (filter: DashboardFilter) =>
	mappingFilterWithPreset.find((preset) => {
		for (const key in preset.filter) {
			if (preset.filter[key as keyof typeof preset.filter] !== filter[key as keyof typeof filter]) {
				return false;
			}
		}
		return true;
	});

export const TileDataAccordion = ({ dashboardTile }: DashboardTileProps) => {
	const { t } = useTranslation(['dashboard'], { keyPrefix: 'tile.edition' });
	const { setValue } = useFormContext<TileEditionFormFields>();
	const isEdition = dashboardTile.id !== '';
	const [{ fromResult }] = useUrlSearchParams('fromResult');

	const handleSelectResult = (result: TestResult) => {
		setValue('resultId', result.id);
		setValue('resultName', result.name);
		setValue('testId', result.test?.id);
		setValue('testName', result.test?.name);
	};

	const handleChange = (_event: object, value: FilterWithPresetProps | null) => {
		if (value) {
			setValue('visualization', value.visualization);
			setValue('filter', value.filter);
		}
	};

	return (
		<Accordion defaultExpanded={true} elevation={0}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('sections.data')}</AccordionSummary>
			<AccordionDetails>
				<SearchResultCombo
					onResultSelected={(result: TestResult) => handleSelectResult(result)}
					initialResultId={dashboardTile.resultId || fromResult}
				/>
				<Autocomplete
					id='grouped-preset'
					fullWidth={true}
					options={mappingFilterWithPreset}
					disabled={isEdition}
					defaultValue={getGroupAndTypeByFilter(dashboardTile.filter)}
					onChange={handleChange}
					isOptionEqualToValue={(option, value) => option.group === value.group && option.type === value.type}
					groupBy={(element) => t('fields.presets.' + element.group)}
					getOptionLabel={(element) =>
						`${t('fields.presets.' + element.group)} - ${t('fields.presets.' + element.type)}`
					}
					sx={{ marginTop: '50px' }}
					renderInput={(params) => <TextField {...params} label={t('fields.presets.title')} required={true} />}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
