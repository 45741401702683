/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-sequences */
/**
 * This code is copy/pasted from Gainsight.
 * It downloads a JS and add a function named "aptrinsic" to the window.
 * See [Gainsight documentation](https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK)
 */
function initGainsightCompanion(n: any, t: any, a: string, e: string, co: any) {
	const i = 'aptrinsic';
	(n[i] =
		n[i] ||
		function () {
			(n[i].q = n[i].q || []).push(arguments);
		}),
		(n[i].p = e);
	n[i].c = co;
	const r = t.createElement('script');
	(r.async = !0), (r.src = a + '?a=' + e);
	const c = t.querySelectorAll('script')[0];
	c.parentNode.insertBefore(r, c);
}
/* eslint-enable */

const initCompanion = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, unicorn/no-typeof-undefined
	if (typeof (window as any).aptrinsic === 'undefined') {
		const environmentKey = import.meta.env.VITE_GAINSIGHT_ENVIRONMENT_KEY;
		initGainsightCompanion(window, document, 'https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js', environmentKey, {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			usePOST: true,
		});
	}
};

/**
 * Send an operation to Gainsight
 *
 * @param operation The action to send to gainsight. It may exist other opertations than those listed here, look into Gainsight doc.
 * Example: "identify", "reset", "remove", "set", "setOnce", "track"
 * @param args The parameters of the action, if needed
 */
const callCompanion = (
	operation: 'identify' | 'reset' | 'remove' | 'set' | 'setOnce' | 'track',
	...args: unknown[]
) => {
	if (Object.prototype.hasOwnProperty.call(window, 'aptrinsic')) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).aptrinsic(operation, ...args);
	}
};

export { initCompanion, callCompanion };
