import { useCallback } from 'react';
import { useCrudBatchMessage } from '../modals';
import { usePatchV4ResultsByResultIdMutation } from '@neoload/api';

const useLockResults = () => {
	const { update: batchLock } = useCrudBatchMessage('result', 'lock');
	const { update: batchUnlock } = useCrudBatchMessage('result', 'unlock');
	const [updateTestResults] = usePatchV4ResultsByResultIdMutation();

	return useCallback(
		(resultIds: string[], locked: boolean) => {
			const doUpdate = locked ? batchLock : batchUnlock;

			doUpdate(resultIds, (resultId) =>
				updateTestResults({
					resultId: resultId,
					testResultInput: {
						locked: locked,
					},
				}).unwrap()
			);
		},
		[batchLock, batchUnlock, updateTestResults]
	);
};

export { useLockResults };
