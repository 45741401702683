import DOMPurify from 'dompurify';
import { useTheme } from '@mui/material';

export type HtmlAreaProps = {
	html: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	'data-testid'?: string;
};

const HtmlArea = ({ html, 'data-testid': dataTestId }: HtmlAreaProps) => {
	const theme = useTheme();
	return (
		<div
			data-testid={dataTestId}
			dangerouslySetInnerHTML={{
				__html: DOMPurify.sanitize(html),
			}}
			style={{ width: '100%', padding: 8, color: theme.palette.text.primary, wordWrap: 'break-word' }}
		/>
	);
};

export { HtmlArea };
