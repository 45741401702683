import { generatePath } from 'react-router';

const patterns = {
	base: '/tests',
	byId: '/tests/:testId',
	byIdConfigure: '/tests/:testId/configure',
};

const testRoutes = {
	patterns: patterns,
	base: patterns.base,
	test: (testId: string) => generatePath(patterns.byId, { testId: testId }),
	testConfigure: (testId: string) => generatePath(patterns.byIdConfigure, { testId: testId }),
};

export type TestGenericParams = {
	testId: string;
};
export { testRoutes as TestRoutes };
