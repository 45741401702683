import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { GitRepositoryAutocomplete } from './git/git-repository-autocomplete';
import { GitReferenceAutocomplete } from './git/git-reference-autocomplete';
import { GitCloneForm } from './git/types';
import { usePostV4CheckoutsMutation } from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

type ModalGitProps = {
	close: () => void;
	testId: string;
	configuredRepositoryId?: string;
};

const ModalGit = ({ close, testId, configuredRepositoryId }: ModalGitProps) => {
	const { t } = useTranslation(['test']);
	const [cloneRepository] = usePostV4CheckoutsMutation();
	const [canClone, setCanClone] = useState(false);
	const [loading, setLoading] = useState(false);
	const { showError, showInfo } = useSetSnackbars();

	const { watch, getValues, ...methods } = useForm<GitCloneForm>({
		mode: 'onChange',
		values: {
			repositoryId: '',
			repositoryName: '',
			reference: '',
			configuredRepositoryId: configuredRepositoryId,
		},
	});

	watch((values) => {
		if (values.repositoryId && values.reference) {
			setCanClone(true);
		} else {
			setCanClone(false);
		}
	});

	const executeAndClose = () => {
		const reference = getValues('reference');
		const repositoryId = getValues('repositoryId');
		const repositoryName = getValues('repositoryName');

		setLoading(true);
		const inputs = {
			checkoutInput: {
				reference,
				testId: testId,
				scmRepositoryId: repositoryId,
			},
		};
		cloneRepository(inputs)
			.unwrap()
			.then(() => {
				showInfo({
					text: t('configuration.snackbar.uploading', { name: `${repositoryName}/${reference}` }),
					id: 'upload',
				});
			})
			.catch((_) => {
				showError({
					text: t('configuration.snackbar.uploadFail', {
						name: `${repositoryName}/${reference}`,
						error: '',
					}),
					id: 'upload',
				});
			})
			.finally(() => {
				close();
				setLoading(false);
			});
	};

	return (
		<Dialog open={true} maxWidth={false} onClose={() => close()}>
			<FormProvider {...methods} watch={watch} getValues={getValues}>
				<DialogTitle>{t('configuration.project.git.modal.title')}</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
							marginTop: 1,
							width: '500px',
						}}
					>
						<GitRepositoryAutocomplete />
						<GitReferenceAutocomplete />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color='info' onClick={() => close()} data-trackingid='git-clone-button-cancel' disabled={loading}>
						{t('test:buttonCancel')}
					</Button>
					<Button
						variant='contained'
						color='info'
						disabled={!canClone || loading}
						onClick={() => executeAndClose()}
						data-trackingid='git-clone-button-confirm'
						startIcon={loading ? <CircularProgress size={24.5} color='inherit' /> : ''}
					>
						{t('configuration.project.git.modal.clone')}
					</Button>
				</DialogActions>
			</FormProvider>
		</Dialog>
	);
};

export { ModalGit };
