import { createBrowserRouter } from 'react-router-dom';
import { DefaultLayout } from './layouts/default-layout';
import { SettingsLayout } from './layouts/settings-layout';
import { LoginLayout } from './layouts/login-layout';
import { TestsPage } from './pages/tests-page';
import { UsersPage } from './pages/users-page';
import { HomePage } from './pages/home-page';
import { ProfilePage } from './pages/profile-page';
import { UserLayout } from './layouts/user-layout';
import { GitRepoPage } from './pages/git-repo-page';
import { AccessTokensPage } from './pages/access-tokens-page';
import { ResultPage } from './pages/result-page';
import { DashboardsPage } from './pages/dashboards-page';
import { DashboardPage } from './pages/dashboard-page';
import { ResultListPage } from './pages/result-list-page';
import { TestPage } from './pages/test-page';
import { ProtectedRoute } from './utils/protected-route/protected-route';
import { ErrorPage, GlobalErrorPage } from '@neoload/ui';
import { CommonRoutes } from '@neoload/utils';

const routes = createBrowserRouter([
	{
		path: CommonRoutes.login.patterns.base,
		element: <LoginLayout />,
	},
	{
		path: CommonRoutes.settings.patterns.base,
		element: <SettingsLayout />,
		errorElement: <GlobalErrorPage />,
		children: [
			{
				path: CommonRoutes.settings.patterns.generalSettings,
				element: (
					<ProtectedRoute permission='admin'>
						<div>Settings</div>
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.users,
				element: (
					<ProtectedRoute permission='tester'>
						<UsersPage />
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.workspaces,
				element: (
					<ProtectedRoute permission='admin'>
						<div>workspaces</div>
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.infrastructureProviders,
				element: (
					<ProtectedRoute permission='admin'>
						<div>infrastructure-providers</div>
					</ProtectedRoute>
				),
			},
			{
				path: CommonRoutes.settings.patterns.subscription,
				element: (
					<ProtectedRoute permission='tester'>
						<div>subscription</div>
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: CommonRoutes.user.patterns.base,
		element: <UserLayout />,
		errorElement: <GlobalErrorPage />,
		children: [
			{
				path: CommonRoutes.user.patterns.accessTokens,
				element: <AccessTokensPage />,
			},
			{
				path: CommonRoutes.user.patterns.profile,
				element: <ProfilePage />,
			},
			{
				path: CommonRoutes.user.patterns.gitRepositories,
				element: (
					<ProtectedRoute permission='tester'>
						<GitRepoPage />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: CommonRoutes.home.patterns.base,
		element: <DefaultLayout />,
		errorElement: <GlobalErrorPage />,
		children: [
			{
				errorElement: <ErrorPage />,
				children: [
					{ index: true, element: <HomePage /> },
					{
						path: CommonRoutes.tests.patterns.base,
						children: [
							{
								index: true,
								element: <TestsPage />,
							},
							{
								path: CommonRoutes.tests.patterns.byId,
								element: <TestPage />,
							},
							{
								path: CommonRoutes.tests.patterns.byIdConfigure,
								element: (
									<ProtectedRoute permission='tester'>
										<TestPage configure />
									</ProtectedRoute>
								),
							},
						],
					},
					{
						path: CommonRoutes.dashboards.patterns.base,
						element: <DashboardsPage />,
					},
					{
						path: CommonRoutes.dashboards.patterns.byId,
						element: <DashboardPage />,
					},
					{
						path: CommonRoutes.results.patterns.base,
						children: [
							{
								index: true,
								element: <ResultListPage />,
							},
							{
								path: CommonRoutes.results.patterns.byId,
								element: <ResultPage />,
							},
							{
								path: CommonRoutes.results.patterns.byIdWithTab,
								element: <ResultPage />,
							},
							{
								path: CommonRoutes.results.patterns.byIdWithTabWithSubTab,
								element: <ResultPage />,
							},
						],
					},
				],
			},
		],
	},
]);

export { routes };
