import './event-drawer.css';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { Resizable } from 'react-resizable';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { EventDrawerContent } from './event-drawer-content';

import { Event } from '@neoload/api';

export type EventDrawerProps = {
	resultId: string;
	event: Event;
	onClose: () => void;
	showScreenShot: boolean;
};

const EventDrawer = ({ resultId, event, onClose, showScreenShot }: EventDrawerProps) => {
	const { t } = useTranslation(['result']);
	const [width, setWidth] = useState(600);

	return (
		<Resizable
			axis='x'
			width={width}
			onResize={(_event, { size }) => setWidth(size.width)}
			handle={<span className='resize-event-details-handle' />}
			resizeHandles={['w']}
			minConstraints={[250, 0]}
		>
			<Grid
				container
				direction='column'
				sx={(theme) => ({
					width: width,
					height: '100%',
					borderLeft: `2px solid ${theme.palette.divider}`,
					flexWrap: 'nowrap',
				})}
			>
				<Grid container padding={2} paddingBottom={1} justifyContent='space-between' alignItems='center'>
					<Typography variant='h6'>{t('events.details.title')}</Typography>
					<Tooltip arrow title={t('common:close')}>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</Grid>
				<EventDrawerContent resultId={resultId} event={event} showScreenShot={showScreenShot} />
			</Grid>
		</Resizable>
	);
};

export { EventDrawer };
