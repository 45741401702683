const patterns = {
	base: '/',
};

const homeRoutes = {
	patterns: patterns,
	base: patterns.base,
};

export { homeRoutes as HomeRoutes };
