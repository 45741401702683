import { ValuesFilter } from './values-filter';
import { ResultContext } from '@neoload/api';

export type ValuesFiltersProps = {
	context: ResultContext;
};

const ValuesFilters = ({ context }: ValuesFiltersProps) => (
	<>
		{Object.keys(context).map((key) => (
			<ValuesFilter key={key} title={`filters.${key}`} options={context[key as keyof ResultContext]} paramName={key} />
		))}
	</>
);

export { ValuesFilters };
