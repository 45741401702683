const DECIMAL_UNIT_SYSTEM_TABLE = [
	{
		factor: 3,
		prefix: 'kilo',
		symbol: 'k',
	},
	{
		factor: 6,
		prefix: 'mega',
		symbol: 'M',
	},
	{
		factor: 9,
		prefix: 'giga',
		symbol: 'G',
	},
	{
		factor: 12,
		prefix: 'tera',
		symbol: 'T',
	},
	{
		factor: 15,
		prefix: 'peta',
		symbol: 'P',
	},
	{
		factor: 18,
		prefix: 'exa',
		symbol: 'E',
	},
	{
		factor: 21,
		prefix: 'zetta',
		symbol: 'Z',
	},
	{
		factor: 24,
		prefix: 'yotta',
		symbol: 'Y',
	},
] as const;

const BINARY_UNIT_SYSTEM_TABLE = [
	{
		factor: 10,
		prefix: 'kibi',
		symbol: 'Ki',
	},
	{
		factor: 20,
		prefix: 'mebi',
		symbol: 'Mi',
	},
	{
		factor: 30,
		prefix: 'gigi',
		symbol: 'Gi',
	},
	{
		factor: 40,
		prefix: 'tebi',
		symbol: 'Ti',
	},
	{
		factor: 50,
		prefix: 'pebi',
		symbol: 'Pi',
	},
	{
		factor: 60,
		prefix: 'exbi',
		symbol: 'Ei',
	},
	{
		factor: 70,
		prefix: 'zebi',
		symbol: 'Zi',
	},
	{
		factor: 80,
		prefix: 'yobi',
		symbol: 'Yi',
	},
] as const;

const UNIT_SUFFIX = {
	byte: 'B',
	bytePerSecond: 'B/s',
	bit: 'b',
	bitPerSecond: 'b/s',
};

const UNIT_SYSTEMS = {
	binary: {
		base: 2,
	},
	decimal: {
		base: 10,
	},
} as const;

export type UnitSystemName = Uppercase<keyof typeof UNIT_SYSTEMS>;
type UnitSystemBase = (typeof UNIT_SYSTEMS)[Lowercase<UnitSystemName>]['base'];
type DecimalUnit = (typeof DECIMAL_UNIT_SYSTEM_TABLE)[number];
type BinaryUnit = (typeof BINARY_UNIT_SYSTEM_TABLE)[number];
type Unit = BinaryUnit | DecimalUnit;
export type UnitSuffix = keyof typeof UNIT_SUFFIX;

const findNearestUnitMultiple = (factor: number, unitSystemName: UnitSystemName) => {
	const unitTable: readonly Unit[] = unitSystemName === 'BINARY' ? BINARY_UNIT_SYSTEM_TABLE : DECIMAL_UNIT_SYSTEM_TABLE;
	for (const multiple of [...unitTable].reverse()) {
		if (multiple && factor >= multiple.factor) {
			return multiple;
		}
	}
	return null;
};

const isBinaryUnit = (unit: Unit): unit is BinaryUnit =>
	BINARY_UNIT_SYSTEM_TABLE.some(
		(binaryUnit) =>
			binaryUnit.prefix === unit.prefix && binaryUnit.symbol === unit.symbol && binaryUnit.factor === unit.factor
	);

/**
 * Converts bytes into bits
 */
export const bytesToBits = (bytes: number) => bytes * 8;

/**
 * Convert a number using a unit system (decimal or binary). The nearest multiple will be used.
 * i.e : in DECIMAL system, 1000000 => 1 mega
 * i.e : in BINARY system, 1024 => 1 kibi
 */
export const convertValue = (value: number, unitSystemName: UnitSystemName = 'DECIMAL') => {
	let unit: Unit | null;
	let base: UnitSystemBase;
	if (unitSystemName === 'BINARY') {
		const log = Math.log2(value);
		unit = findNearestUnitMultiple(log, 'BINARY');
		base = UNIT_SYSTEMS['binary'].base;
	} else {
		const log = Math.log10(value);
		unit = findNearestUnitMultiple(log, 'DECIMAL');
		base = UNIT_SYSTEMS['decimal'].base;
	}
	return doConvertValue(value, base, unit);
};

/**
 * Convert a number a unit system (decimal or binary). The @param unit will be used as the conversion unit.
 * i.e : in DECIMAL system, 1000000 (k) => 1000 kilo
 * i.e : in BINARY system, 1024 (Mi) => 0.0009765625 mebi
 */
export const convertValueWithUnit = (value: number, unit: Unit) => {
	const base: UnitSystemBase = isBinaryUnit(unit) ? UNIT_SYSTEMS['binary'].base : UNIT_SYSTEMS['decimal'].base;
	return doConvertValue(value, base, unit);
};

const doConvertValue = (value: number, base: UnitSystemBase, unit: Unit | null) => {
	const convertedValue = unit ? value / Math.pow(base, unit.factor) : value;
	return [convertedValue, unit] as const;
};

export const valueToString = (
	value: number,
	unitSuffix: UnitSuffix,
	unitSystemName: UnitSystemName = 'DECIMAL',
	toFixedDecimal = 1
): string => {
	const [convertedValue, unit] = convertValue(value, unitSystemName);
	return `${convertedValue.toFixed(toFixedDecimal)} ${unit?.symbol ?? ''}${UNIT_SUFFIX[unitSuffix]}`;
};
