import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import React from 'react';
import { TextFieldProps } from '@mui/material/TextField';

export type ButtonDescription = {
	text?: string;
	color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	icon?: React.ReactNode;
};

export type FormValue = Record<string, string | number>;

type Fields = Partial<TextFieldProps> | (Partial<TextFieldProps> & { max: number; requiredHelperText?: string });

export type ValueUpdaterFunction = (updatedValue: FormValue) => void;
export type SetConfirmableFunction = (confirmable: boolean) => void;
export type CloseFunction = () => void;
type ContentType =
	| string
	| Fields[]
	| ((
			valueUpdater: ValueUpdaterFunction,
			setConfirmable: SetConfirmableFunction,
			close: CloseFunction
	  ) => React.ReactNode);

export type ConfirmModalState = {
	title?: string;
	content?: ContentType;
	open: boolean;
	cancel: ButtonDescription;
	confirm: ButtonDescription;
	handleConfirm?: (values: FormValue) => Promise<unknown>;
	handleCancel?: (values: FormValue) => Promise<unknown>;
	validate?: (values: FormValue) => boolean;
	defaultValue?: FormValue;
	canConfirm?: boolean;
	width?: number;
};

const initialModalState: ConfirmModalState = {
	open: false,
	cancel: {},
	confirm: {},
};

export type OpenConfirmModalAction = {
	title?: string;
	content?: ContentType;
	cancel?: ButtonDescription;
	confirm?: ButtonDescription;
	handleConfirm?: (values: FormValue) => Promise<void>;
	handleCancel?: (values: FormValue) => Promise<void>;
	validate?: (values: FormValue) => boolean;
	defaultValue?: FormValue;
	canConfirm?: boolean;
	width?: number;
};
const modalSlice = createSlice({
	name: 'modal',
	initialState: initialModalState,

	reducers: {
		openModal: (state, payload: PayloadAction<OpenConfirmModalAction>) => {
			const {
				title,
				content,
				cancel = {
					text: t('common:cancel'),
					color: 'info',
				},
				confirm = {
					text: t('common:delete'),
					color: 'error',
				},
				handleCancel,
				handleConfirm,
				validate,
				defaultValue,
				width,
				canConfirm,
			} = payload.payload;
			state.open = true;
			state.title = title;
			//FIXME ts error is it ok to ignore ?
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.content = content;
			state.cancel = cancel;
			state.confirm = confirm;
			state.handleCancel = handleCancel;
			state.handleConfirm = handleConfirm;
			state.validate = validate;
			state.defaultValue = defaultValue;
			state.width = width;
			state.canConfirm = canConfirm;
		},
		closeModal: (state) => {
			state.open = false;
		},
		canConfirmModal: (state) => {
			state.canConfirm = true;
		},
		cannotConfirmModal: (state) => {
			state.canConfirm = false;
		},
	},
});

const { reducer: modalReducer, actions } = modalSlice;
export const { openModal, closeModal, canConfirmModal, cannotConfirmModal } = actions;
export { modalReducer };
