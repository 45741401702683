import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GridSelectionModel } from '@mui/x-data-grid/models';
import { TestsListRow } from './tests-data-grid';
import { DatagridAction } from '../../common/data-grid/actions/data-grid-actions';
import { Test, useDeleteV4TestsByTestIdMutation } from '@neoload/api';
import { CommonRoutes } from '@neoload/utils';
import { useConfirmModal, useCrudBatchMessage } from '@neoload/hooks';

const useGetTestsDataGridAction = (
	runTest: (test: Test) => void,
	selectedIds: GridSelectionModel,
	selectedTests: Test[]
): DatagridAction[] => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const { remove } = useCrudBatchMessage('test');
	const [deleteTest] = useDeleteV4TestsByTestIdMutation();
	const navigate = useNavigate();
	const { t } = useTranslation(['test']);

	const editConfiguration = (test: Test) => {
		navigate(CommonRoutes.tests.testConfigure(test.id));
	};

	const deleteRows = async (testsToDelete: string[]) => {
		await remove(testsToDelete, (testId) => deleteTest({ testId: testId }).unwrap());
	};

	const openConfirmationTestDialog = (test: { name: string; id: string }) => {
		const title = t('test:deleteSingleTestDialog.title');
		const content = t('test:deleteSingleTestDialog.label', { test: test.name });
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => deleteRows([test.id]),
		});
	};

	const openDeleteAllDialog = () => {
		const title = t('test:deleteMultipleTestDialog.title', {
			testNumber: selectedIds.length.toString(),
		});
		const content = t('test:deleteMultipleTestDialog.label', {
			testNumber: selectedIds.length.toString(),
		});
		openDeleteConfirmModal({
			title,
			content,
			handleConfirm: () => deleteRows(selectedIds.map((x) => x as string)),
		});
	};

	const shouldDisabledRun = () => selectedTests.length !== 1 || selectedTests.some((test) => !test.isConfigured);
	const getRunTooltip = () => {
		if (selectedTests.length !== 1) {
			return t('grid.run.moreThanOneSelected');
		}

		if (selectedTests.some((test) => !test.isConfigured)) {
			return t('grid.run.notConfigured');
		}

		return t('grid.actions.runTest');
	};

	const shouldDisabledEdit = () => selectedTests.length !== 1;
	const getEditTooltip = () => {
		if (shouldDisabledEdit()) {
			return t('grid.edit.moreThanOneSelected');
		}

		return t('grid.actions.editionConfiguration');
	};

	return [
		{
			icon: <PlayCircleOutlineIcon />,
			text: t('grid.actions.runTest'),
			disabled: shouldDisabledRun(),
			tooltip: getRunTooltip(),
			action: () => {
				if (selectedIds.length === 1) {
					const testToRun = selectedTests.find((test) => test.id === selectedIds[0]);
					if (testToRun) {
						runTest(testToRun);
					}
				}
			},
			singleItem: (row: Test) => ({
				action: () => {
					runTest(row);
				},
				disabled: !row.isConfigured,
			}),
		},
		{
			icon: <SettingsOutlinedIcon />,
			text: t('grid.actions.editionConfiguration'),
			disabled: shouldDisabledEdit(),
			tooltip: getEditTooltip(),
			action: () => {
				if (selectedIds.length === 1) {
					const testToEdit = selectedTests.find((test) => test.id === selectedIds[0]);
					if (testToEdit) {
						editConfiguration(testToEdit);
					}
				}
			},
			singleItem: (row: Test) => ({
				action: () => {
					editConfiguration(row);
				},
			}),
		},
		{
			icon: <DeleteOutlined />,
			text: t('grid.actions.deleteTest'),
			color: 'error',
			action: () => {
				if (selectedIds.length === 1) {
					const testToDelete = selectedTests.find((test) => test.id === selectedIds[0]);
					if (testToDelete) {
						openConfirmationTestDialog(testToDelete);
					}
				} else {
					openDeleteAllDialog();
				}
			},
			singleItem: (row: TestsListRow) => ({
				disabled: false,
				action: () => {
					openConfirmationTestDialog(row);
				},
			}),
		},
	];
};

export { useGetTestsDataGridAction };
