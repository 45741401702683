const formatNumberToString = (language: string, value?: number) => {
	const formatter = new Intl.NumberFormat(language, { minimumFractionDigits: 3, maximumFractionDigits: 3 });

	return value === undefined || Number.isNaN(value) ? '' : formatter.format(value);
};

const formatIntegerToString = (language: string, value?: number) => {
	const integerFormatter = new Intl.NumberFormat(language, { compactDisplay: 'short' });

	return value === undefined || Number.isNaN(value) ? '' : integerFormatter.format(value);
};

export const numberUtils = {
	formatNumberToString,
	formatIntegerToString,
};
