import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

export type TestExecutionZoneControllerProps = {
	defaultController: boolean;
};

const TestExecutionZoneController = ({ defaultController }: TestExecutionZoneControllerProps) => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();

	if (defaultController) {
		return (
			<Typography variant='body2' color={theme.palette.text.secondary}>
				{t('testExecution.controllerZone')}
			</Typography>
		);
	}

	return <Box />;
};

export { TestExecutionZoneController };
