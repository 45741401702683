import { useTranslation } from 'react-i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { Spinner } from '../../../common/spinner';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { SummaryTilesGrid } from '../../../common/summary-tiles-grid';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import {
	GetV4ResultsByResultIdApiArg,
	GetV4ResultsByResultIdApiResponse,
	useLazyGetV4ResultsByResultIdQuery,
	WidgetDashboardTile,
} from '@neoload/api';
import { timeUtils } from '@neoload/utils';

type SummaryFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

export const _SummaryFetcher = ({ shouldStartFetching, widgetTile }: SummaryFetcherProps) => {
	const { t } = useTranslation(['dashboard']);
	const [triggerGetResult] = useLazyGetV4ResultsByResultIdQuery();

	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdApiArg,
		GetV4ResultsByResultIdApiResponse
	>(
		widgetTile,
		{
			resultId: widgetTile.resultId,
		},
		shouldStartFetching,
		triggerGetResult
	);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	return (
		<SummaryTilesGrid
			result={{ id: data.id, name: data.name }}
			test={data.test?.name ? { id: data.test.id, name: data.test.name } : undefined}
			status={data}
			startDate={timeUtils.dateTimeAbsolute(data.startDate)}
			endDate={timeUtils.dateTimeAbsolute(data.endDate ?? null)}
			duration={timeUtils.durationSeconds(data.duration)}
			project={data.project}
			scenario={data.scenario}
		/>
	);
};

export const VisibleForTesting = _SummaryFetcher;
export const SummaryFetcher = withLazyFetching(_SummaryFetcher);
