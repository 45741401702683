import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import PlaylistRemoveOutlined from '@mui/icons-material/PlaylistRemoveOutlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@tricentis/aura/components/Tooltip.js';

type DashboardHeaderMoreMenuProps = {
	isActionsVisible: boolean;
	onDuplicateDashboard: () => Promise<void>;
	onDeleteDashboard: () => Promise<void>;
	deleteTilesEnabled?: boolean;
	onClearTiles?: () => Promise<void>;
};

export const DashboardHeaderMoreMenu = ({
	isActionsVisible,
	deleteTilesEnabled = true,
	onDuplicateDashboard,
	onClearTiles,
	onDeleteDashboard,
}: DashboardHeaderMoreMenuProps) => {
	const { t } = useTranslation(['dashboard']);
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpen = !!anchorEl;

	const onMoreMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMoreMenuClose = () => {
		setAnchorEl(null);
	};

	const onDuplicateDashboardClick = () => {
		handleMoreMenuClose();
		onDuplicateDashboard();
	};

	const onClearTilesClick = () => {
		handleMoreMenuClose();
		if (onClearTiles) {
			onClearTiles();
		}
	};
	const onDeleteDashboardClick = () => {
		handleMoreMenuClose();
		onDeleteDashboard();
	};

	return (
		<>
			<Tooltip arrow title={t('header.more.button')}>
				<IconButton onClick={onMoreMenuButtonClick}>
					<MoreVertOutlined />
				</IconButton>
			</Tooltip>
			<Menu open={isMenuOpen} anchorEl={anchorEl} onClose={handleMoreMenuClose}>
				<MenuItem onClick={onDuplicateDashboardClick}>
					<ListItemIcon>
						<FileCopyOutlined sx={{ color: theme.palette.action.active }} />
					</ListItemIcon>
					<ListItemText>{t('common:duplicate')}</ListItemText>
				</MenuItem>
				{isActionsVisible && [
					<MenuItem
						key={'clear-tile-button-key'}
						onClick={onClearTilesClick}
						sx={{ display: deleteTilesEnabled ? '' : 'none' }}
					>
						<ListItemIcon>
							<PlaylistRemoveOutlined color='error' />
						</ListItemIcon>
						<ListItemText sx={{ color: theme.palette.error.main }}>{t('tile.clear.button')}</ListItemText>
					</MenuItem>,
					<MenuItem key={'delete-dashboard-button-key'} onClick={onDeleteDashboardClick}>
						<ListItemIcon>
							<DeleteOutlined color='error' />
						</ListItemIcon>
						<ListItemText sx={{ color: theme.palette.error.main }}>{t('deleteButton')}</ListItemText>
					</MenuItem>,
				]}
			</Menu>
		</>
	);
};
