import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

const apiFileBaseUrl = import.meta.env.VITE_FILE_API_BASE_URL;

export const emptySplitFileApiV4 = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: apiFileBaseUrl,
		credentials: 'include',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		paramsSerializer: (params: Record<string, any>) => queryString.stringify(params),
	}),
	reducerPath: 'fileApiV4',
	keepUnusedDataFor: 10,
	endpoints: () => ({}),
});
