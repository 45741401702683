import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PlayCircleOutlined from '@mui/icons-material/PlayCircleOutlined';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { ConfigurationFormData } from '.';
import { TestHeaderMenu } from './test-header-menu';
import { useOpenTestExecutionModal } from '../test-execution';
import { getTranslationKeyToValidatedName } from '../../common/input/input-validation';
import { CommonRoutes, timeUtils } from '@neoload/utils';
import { PatchTestInput, Test } from '@neoload/api';
import { useGetMe, useSetSnackbars } from '@neoload/hooks';

export type TestHeaderProps = {
	patchTest: (patchTestInput: PatchTestInput) => Promise<Test | void>;
	isConfigurePage: boolean;
	description?: string;
};
const TestHeader = ({ patchTest, isConfigurePage, description }: TestHeaderProps) => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();
	const navigate = useNavigate();
	const test = useWatch<ConfigurationFormData, 'test'>({ name: 'test' });
	const { showInfo, showError } = useSetSnackbars();
	const { openTestExecutionModal } = useOpenTestExecutionModal();
	const [{ hasTesterPermissions }] = useGetMe();

	const onEditTestName = (name: string) => {
		patchTest({ name })
			.then(() => {
				showInfo({ text: t('messages.updateNameSuccess') });
			})
			.catch((_error) => {
				console.error(_error);
				showError({ text: t('messages.updateNameError') });
			});
	};

	const openRunModal = () => {
		openTestExecutionModal(test.id);
	};

	return (
		<Grid item xs='auto' sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
			<Box sx={{ maxWidth: '1000px' }}>
				<SectionTitle
					onClickBack={(_event) => {
						if (isConfigurePage) {
							navigate(CommonRoutes.tests.test(test.id));
						} else {
							navigate(CommonRoutes.tests.base);
						}
					}}
					title={test.name}
					editable={hasTesterPermissions}
					description={description}
					label={t('test:labelName')}
					slotProps={{
						input: {
							onApply: (updatedData: Record<string, string>) => {
								const updatedName = updatedData.source;
								if (updatedName === test.name) {
									return;
								}
								onEditTestName(updatedName);
							},
							validationRules: [
								(updatedName: string) => {
									const translationKey = getTranslationKeyToValidatedName(updatedName);
									return translationKey ? t(translationKey) : false;
								},
							],
							tooltipTitle: t('editTestName'),
						},
					}}
				/>
			</Box>
			{hasTesterPermissions && (
				<Grid container justifyContent='flex-end' gap={theme.spacing(2)}>
					{!isConfigurePage && (
						<Box display='flex' alignItems='center'>
							<Typography variant='subtitle2' color={theme.palette.text.secondary} marginRight={theme.spacing(1)}>
								{t('testPage.lastModified')}
							</Typography>
							<Tooltip
								arrow
								title={t('testPage.date', {
									date: timeUtils.dateTimeAbsolute(test.updatedAt),
									author: test.userModifierName,
								})}
							>
								<Typography data-testid='lastModified' variant='subtitle2'>
									{timeUtils.dateRelative(test.updatedAt)}
								</Typography>
							</Tooltip>
						</Box>
					)}
					<Stack direction={'row'} gap={theme.spacing(1)}>
						<Button
							variant='outlined'
							sx={{ display: isConfigurePage ? 'none' : '' }}
							onClick={() => navigate(CommonRoutes.tests.testConfigure(test.id))}
						>
							<Typography>{t('configure')}</Typography>
						</Button>
						<Tooltip arrow title={test.isConfigured ? '' : t('grid.run.notConfigured')}>
							<div>
								<Button
									sx={{ height: '100%' }}
									variant='contained'
									onClick={openRunModal}
									disabled={!test.isConfigured}
									startIcon={<PlayCircleOutlined />}
								>
									<Typography>{t('grid.actions.runTest')}</Typography>
								</Button>
							</div>
						</Tooltip>
						<TestHeaderMenu id={test.id} patchTest={patchTest} description={description} />
					</Stack>
				</Grid>
			)}
		</Grid>
	);
};

export { TestHeader };
