import { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import DefaultContentLayout from '@tricentis/aura/components/DefaultContentLayout.js';
import NavRail from '@tricentis/aura/components/NavRail.js';
import { Theme, useTheme } from '@mui/material/styles';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import IconPlaylistsOutlined from '@tricentis/aura/components/IconPlaylistsOutlined.js';
import ChecklistOutlined from '@mui/icons-material/ChecklistOutlined';
import { AppBar, ApplicationLoader, DocumentTitleHandler, Modal, StackedSnackbars } from '@neoload/ui';
import { CommonRoutes } from '@neoload/utils';
import { decideCurrentWorkspace, useGetV4MePreferencesQuery } from '@neoload/api';
import { useGetMe, useSetCurrentWorkspace } from '@neoload/hooks';

const Layout = () => {
	const { t } = useTranslation(['menu']);
	const theme = useTheme();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const vPaddingContentLayout = theme.spacing(3);

	function isDrawerOpen(isOpen: boolean) {
		setOpen(isOpen);
	}

	const isHomePage = location.pathname === '/';
	const menuItems: ComponentPropsWithoutRef<typeof NavRail>['items'] = [
		{
			id: 'home',
			text: t('home'),
			icon: <HomeOutlinedIcon data-testid='home-page' />,
			selected: isHomePage,
			onClick: () => setOpen(false),
			slotProps: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				ListItemButton: {
					to: CommonRoutes.home.base,
					component: Link,
				},
			},
		},
		{
			id: 'test',
			text: t('tests'),
			icon: <ChecklistOutlined data-testid='tests-list-page' />,
			onClick: () => setOpen(false),
			selected: location.pathname.startsWith('/tests'),
			slotProps: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				ListItemButton: {
					to: CommonRoutes.tests.base,
					component: Link,
				},
			},
		},
		{
			id: 'results',
			text: t('results'),
			icon: <IconPlaylistsOutlined />,
			onClick: () => setOpen(false),
			selected: location.pathname.startsWith('/results'),
			slotProps: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				ListItemButton: {
					to: CommonRoutes.results.base,
					component: Link,
				},
			},
		},
		{
			id: 'dashboards',
			text: t('dashboards'),
			icon: <DashboardOutlinedIcon />,
			onClick: () => setOpen(false),
			selected: location.pathname.startsWith('/dashboards'),
			slotProps: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				ListItemButton: {
					to: CommonRoutes.dashboards.base,
					component: Link,
				},
			},
		},
	];

	return (
		<>
			<AppBar />
			<Box
				sx={{
					width: '100%',
					display: 'grid',
					gridTemplateColumns: '1fr',
					gridTemplateRows: '39px 1fr',
					rowGap: '1px',
					height: '100%',
					backgroundColor: theme.palette.background.default,
				}}
			>
				<NavRail
					open={open}
					clipped
					onToggle={isDrawerOpen}
					items={menuItems}
					toggleTooltips={{ expand: t('common:expand'), collapse: t('common:collapse') }}
				/>
				<Box
					sx={{
						paddingLeft: '40px',
						paddingTop: '4px',
						overflow: 'hidden',
					}}
				>
					<Box sx={{ paddingLeft: '20px' }} onClick={() => setOpen(false)}>
						<DefaultContentLayout
							cardSx={buildCardSx(theme, isHomePage, vPaddingContentLayout)}
							contentSx={{
								padding: '0 !important',
								height: `calc(100vh - ${theme.constants.appBar.height.xl}px - ${vPaddingContentLayout} + 4px)`,
								color: 'text.primary',
							}}
							preset={{ height: 'workspace' }}
						>
							<Outlet />
						</DefaultContentLayout>
					</Box>
				</Box>
			</Box>
			<DocumentTitleHandler />
			<Modal />
			<StackedSnackbars />
		</>
	);
};

const DefaultLayout = () => {
	const setCurrentWorkspace = useSetCurrentWorkspace();
	const [{ isLoaded, workspaces }] = useGetMe();
	const { data: preferences, isLoading: isPreferencesLoading } = useGetV4MePreferencesQuery();
	const isAppReady = isLoaded;
	const isPreferencesLoaded = !isPreferencesLoading;

	useEffect(() => {
		if (isLoaded && isPreferencesLoaded) {
			const currentWorkspace = decideCurrentWorkspace(workspaces, preferences);
			setCurrentWorkspace(currentWorkspace);
		}
	}, [isLoaded, workspaces, setCurrentWorkspace, preferences, isPreferencesLoading, isPreferencesLoaded]);

	return isAppReady ? <Layout /> : <ApplicationLoader />;
};

export { DefaultLayout };

const buildCardSx = (theme: Theme, isHomePage: boolean, vPaddingContentLayout: string) => {
	const homeCardSx = {
		background: 'none',
		boxShadow: 'none',
	};

	const defaultCardSx = {
		height: `calc(100vh - ${theme.constants.appBar.height.xl}px - ${vPaddingContentLayout} + 4px)`,
		overflowY: 'auto',
	};

	const cardSx = isHomePage
		? {
				...defaultCardSx,
				...homeCardSx,
		  }
		: defaultCardSx;
	return cardSx;
};
