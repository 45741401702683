import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DashboardsDataGrid } from '@neoload/ui';
import {
	DashboardInput,
	PatchV4DashboardsByDashboardIdApiArg,
	useDeleteV4DashboardsByDashboardIdMutation,
	usePatchV4DashboardsByDashboardIdMutation,
	usePostV4DashboardsByDashboardIdDuplicationMutation,
	usePostV4DashboardsMutation,
} from '@neoload/api';
import { useCrudBatchMessage, useCurrentWorkspace, useSetSnackbars } from '@neoload/hooks';
import { CommonRoutes } from '@neoload/utils';

const DashboardsPage = () => {
	const [currentWorkspace] = useCurrentWorkspace();
	const { t } = useTranslation(['dashboard']);
	const navigate = useNavigate();
	const { showError, showInfo } = useSetSnackbars();
	const { update, create, remove } = useCrudBatchMessage('dashboard');
	const [createDashboard] = usePostV4DashboardsMutation();
	const [updateDashboard] = usePatchV4DashboardsByDashboardIdMutation();
	const [deleteDashboard] = useDeleteV4DashboardsByDashboardIdMutation();
	const [duplicateDashboard] = usePostV4DashboardsByDashboardIdDuplicationMutation();

	const onDashboardsUpdate = async (patches: PatchV4DashboardsByDashboardIdApiArg[]) => {
		await update(patches, (patch) => updateDashboard(patch).unwrap());
	};

	const onDashboardCreate = async (dashboardInput: DashboardInput) => {
		dashboardInput.workspaceId = currentWorkspace.id;
		await create([dashboardInput], (input) =>
			createDashboard({ dashboardInput: input })
				.unwrap()
				.then((dashboard) => {
					navigate(CommonRoutes.dashboards.dashboard(dashboard.id));
				})
		);
	};

	const onDashboardDuplicate = async (dashboardId: string) => {
		duplicateDashboard({ dashboardId, dashboardDuplicateRequest: {} })
			.unwrap()
			.then(() => showInfo({ text: t('messages.duplicateSuccess') }))
			.catch((_error) => {
				console.error('error on onDuplicateDashboard', _error);
				showError({ text: t('messages.duplicateError') });
			});
	};

	const deleteDashboards = async (dashboardIds: string[]) => {
		await remove(dashboardIds, (dashboardId) => deleteDashboard({ dashboardId: dashboardId }).unwrap());
	};

	return (
		<DashboardsDataGrid
			onDashboardCreate={onDashboardCreate}
			onDashboardDuplicate={onDashboardDuplicate}
			onDashboardsUpdate={onDashboardsUpdate}
			onDeleteDashboards={deleteDashboards}
		/>
	);
};

export { DashboardsPage };
