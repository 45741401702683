import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import AddOutlined from '@mui/icons-material/AddOutlined';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { InfoIcon } from '@storybook/icons';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useMemo } from 'react';
import { findNextColor } from './color-generator';
import { AUTOMATED_TOLERANCE_VALUES, TrendsConfigurationForm } from './trends-graph-settings';
import { mapToReadableStatisticUnit } from '../graph/trend-statistics';
import {
	BaselineTrendsConfiguration,
	TrendElement,
	useGetV4ResultsQuery,
	UserPathElementValueStatistic,
} from '@neoload/api';

const STATISTICS: UserPathElementValueStatistic[] = [
	'DURATION_PERCENTILE_99',
	'DURATION_PERCENTILE_95',
	'DURATION_PERCENTILE_90',
	'DURATION_PERCENTILE_50',
	'MINIMUM_DURATION',
	'AVERAGE_DURATION',
	'MAXIMUM_DURATION',
	'ELEMENT_COUNT',
	'ERROR_COUNT',
	'ELEMENTS_PER_SECOND',
	'ERROR_RATE',
	'MINIMUM_TTFB',
	'AVERAGE_TTFB',
	'MAXIMUM_TTFB',
];

type TrendsGraphSettingsFormProps = {
	trendElements: TrendElement[];
	testId: string;
	testWorkspaceId: string;
};

const METRICS_LENGTH = {
	min: 1,
	max: 10,
};

export const TrendsGraphSettingsForm = ({ trendElements, testId, testWorkspaceId }: TrendsGraphSettingsFormProps) => {
	const { t } = useTranslation('test');

	const {
		control,
		setValue,
		setError,
		watch,
		register,
		formState: { errors },
	} = useFormContext<TrendsConfigurationForm>();

	const metrics = watch('metrics');
	const objectiveMode = watch('objectiveMode');

	const {
		data: resultPage,
		isLoading: isResultPageLoading,
		isError: isResultPageError,
	} = useGetV4ResultsQuery(
		{
			workspaceId: testWorkspaceId,
			testIds: [testId],
		},
		{
			skip: objectiveMode !== 'BASELINE',
		}
	);

	useEffect(() => {
		if (objectiveMode === 'BASELINE' && !isResultPageLoading && (isResultPageError || (resultPage?.total ?? 0) <= 0)) {
			setError('resultId', {
				type: 'required',
				message: t('trends.settings.errorMessages.noResultAvailable'),
			});
		}
	}, [isResultPageError, isResultPageLoading, objectiveMode, resultPage?.total, setError, t]);

	const sortedTrendElements = useMemo(
		() =>
			trendElements
				.filter(
					(trendElement, index, array) =>
						array.findIndex((element) => element.fullPath === trendElement.fullPath) === index
				)
				.toSorted((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())),
		[trendElements]
	);

	const addButtonTitle =
		metrics.length >= METRICS_LENGTH.max
			? t('trends.settings.actions.addMetricLimit', {
					max: METRICS_LENGTH.max,
			  })
			: t('trends.settings.actions.addMetric');

	const removeButtonTitle =
		metrics.length <= METRICS_LENGTH.min
			? t('trends.settings.actions.removeMetricLimit', {
					min: METRICS_LENGTH.min,
			  })
			: t('trends.settings.actions.removeMetric');

	return (
		<>
			<Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant='subtitle1'>{t('trends.settings.metrics')}</Typography>
				<Tooltip title={addButtonTitle} arrow>
					<Box component={'span'}>
						<IconButton
							aria-label={addButtonTitle}
							onClick={() => {
								setValue('metrics', [
									...metrics,
									{
										elementId: sortedTrendElements.at(0)?.id ?? '',
										elementFullPath: sortedTrendElements.at(0)?.fullPath ?? [],
										statistic: STATISTICS[0],
										visible: true,
										color: findNextColor(metrics) ?? '#000000',
										objective: false,
									},
								]);
							}}
							disabled={metrics.length >= METRICS_LENGTH.max}
						>
							<AddOutlined />
						</IconButton>
					</Box>
				</Tooltip>
			</Stack>
			<Divider />
			<Stack
				component={'ol'}
				aria-label={t('trends.settings.metrics')}
				pt={2}
				mt={0}
				pl={0}
				sx={{ overflowY: 'scroll', height: '60%' }}
			>
				{metrics.map((metric, index) => (
					<ListItem key={metric.elementFullPath + metric.statistic + index} sx={{ paddingX: 0 }}>
						<Stack width={'100%'}>
							<Stack direction={'row'}>
								<Stack direction={'row'} gap={3} flex={1}>
									<FormControl sx={{ width: '33%' }} required>
										<InputLabel id={`metrics-${index}-element`}>{t('trends.settings.element')}</InputLabel>
										<Select
											label={t('trends.settings.element')}
											labelId={`metrics-${index}-element`}
											{...register(`metrics.${index}.elementId`, {
												required: true,
												onChange: (event) => {
													const trendElement = trendElements.find(
														(element) => event.target.value === element.fullPath.join('')
													);
													if (trendElement) {
														setValue(`metrics.${index}`, {
															...metric,
															elementFullPath: trendElement.fullPath,
															elementId: trendElement.id,
														});
													}
												},
											})}
											value={metric.elementFullPath.join('')}
											sx={{ height: (theme) => theme.spacing(7) }}
										>
											{sortedTrendElements.map((trendElement) => (
												<MenuItem key={trendElement.id} value={trendElement.fullPath.join('')}>
													<TrendElementItem trendElement={trendElement} />
												</MenuItem>
											))}
										</Select>
									</FormControl>

									<FormControl sx={{ width: '33%', padding: '0px', height: 'fit-content' }} required>
										<InputLabel id={`metrics-${index}-statistic`}>{t('trends.settings.statistic')}</InputLabel>
										<Select
											label={t('trends.settings.element')}
											labelId={`metrics-${index}-statistic`}
											value={metric.statistic}
											{...register(`metrics.${index}.statistic`, {
												required: true,
											})}
										>
											{STATISTICS.map((statistic) => (
												<MenuItem key={statistic} value={statistic}>
													{t(`common:statistics.userPathElementValueStatistics.${statistic}`)}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<FormControlLabel
										id={`metrics-${index}-objective`}
										label={t('trends.settings.objective')}
										sx={{ marginRight: '0px' }}
										control={
											<Controller
												name={`metrics.${index}.objective`}
												control={control}
												rules={{ deps: [`metrics.${index}.objectiveValue`] }}
												render={({ field }) => <Checkbox {...field} checked={field.value} />}
											/>
										}
									/>
									<TextField
										type={'number'}
										label={t('trends.settings.value')}
										sx={{ width: '20%' }}
										required={metric.objective}
										{...register(`metrics.${index}.objectiveValue`, {
											valueAsNumber: true,
											required: {
												value: metric.objective && objectiveMode === 'STATIC',
												message: t('trends.settings.errorMessages.emptyObjective'),
											},
											min: { value: 0, message: t('trends.settings.errorMessages.positiveObjective') },
										})}
										error={metric.objective && !!errors.metrics?.at?.(index)?.objectiveValue}
										InputProps={{
											inputProps: { min: 0, step: '0.001' },
											disabled: objectiveMode !== 'STATIC' || !metric.objective,
											endAdornment: (
												<InputAdornment position='end'>
													{mapToReadableStatisticUnit(metric.statistic).short}
												</InputAdornment>
											),
										}}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Stack>

								<Box p={1} alignSelf={'center'}>
									<Tooltip title={removeButtonTitle} arrow>
										<Box component={'span'}>
											<IconButton
												aria-label={removeButtonTitle}
												onClick={() => {
													setValue(
														'metrics',
														metrics.filter((thatMetric) => thatMetric !== metric)
													);
												}}
												disabled={metrics.length <= METRICS_LENGTH.min}
											>
												<DeleteOutlined />
											</IconButton>
										</Box>
									</Tooltip>
								</Box>
							</Stack>
							{metric.objective && errors.metrics?.at?.(index)?.objectiveValue?.message && (
								<Box color={'red'} marginTop={'10px'} fontSize={'small'}>
									{errors.metrics.at(index)?.objectiveValue?.message}
								</Box>
							)}
						</Stack>
					</ListItem>
				))}
			</Stack>
			<Stack marginTop={'20px'}>
				<Typography variant='subtitle1' marginBottom={'10px'}>
					{t('trends.settings.objectivesTitle')}
				</Typography>
				<Divider />
				<FormControl sx={{ marginTop: '5px' }} required>
					<Controller
						control={control}
						name='objectiveMode'
						rules={{
							required: true,
							deps: ['metrics'],
						}}
						render={({ field }) => (
							<RadioGroup {...field} row={true} sx={{ gap: '30px' }}>
								{[
									{
										value: 'STATIC',
										labelI18n: 'trends.settings.objectiveMode.static',
										tooltipI18n: 'trends.settings.objectiveMode.staticInfo',
									},
									{
										value: 'BASELINE',
										labelI18n: 'trends.settings.objectiveMode.baseline',
										tooltipI18n: 'trends.settings.objectiveMode.baselineInfo',
									},
									{
										value: 'AUTOMATED',
										labelI18n: 'trends.settings.objectiveMode.automated',
										tooltipI18n: 'trends.settings.objectiveMode.automatedInfo',
									},
								].map((radio) => (
									<Stack key={radio.value} direction={'row'} alignItems={'center'}>
										<FormControlLabel
											label={t(radio.labelI18n)}
											sx={{ marginRight: '5px' }}
											control={<Radio value={radio.value} checked={field.value === radio.value} />}
										/>
										<Tooltip arrow title={t(radio.tooltipI18n)}>
											<InfoIcon />
										</Tooltip>
									</Stack>
								))}
							</RadioGroup>
						)}
					/>

					{objectiveMode === 'BASELINE' && (
						<Stack>
							<Stack direction={'row'} gap={3} marginTop={'16px'} alignItems={'baseline'}>
								<FormControl sx={{ width: '50%' }} required={true}>
									<InputLabel id='baseline-reference-result'>{t('trends.settings.referenceResult')}</InputLabel>
									<Controller
										control={control}
										name='resultId'
										rules={{
											required: true,
											shouldUnregister: true,
										}}
										render={({ field }) => (
											<Select
												label={t('trends.settings.referenceResult')}
												labelId='baseline-reference-result'
												required={true}
												{...field}
												error={!!(errors as FieldErrors<BaselineTrendsConfiguration>)?.resultId?.message}
											>
												{resultPage?.items.map((testResult) => (
													<MenuItem key={testResult.id} value={testResult.id}>
														{testResult.name}
													</MenuItem>
												))}
											</Select>
										)}
									/>
									{!!(errors as FieldErrors<BaselineTrendsConfiguration>)?.resultId?.message && (
										<Box color={'red'} marginTop={'10px'} fontSize={'small'}>
											{(errors as FieldErrors<BaselineTrendsConfiguration>)?.resultId?.message}
										</Box>
									)}
								</FormControl>
								<TextField
									type={'number'}
									label={t('trends.settings.tolerance')}
									sx={{ width: '20%' }}
									required={true}
									{...register('baselineTolerance', {
										valueAsNumber: true,
										required: { value: true, message: t('trends.settings.errorMessages.emptyTolerance') },
										shouldUnregister: true,
										min: { value: 0, message: t('trends.settings.errorMessages.positiveTolerance') },
									})}
									defaultValue={0}
									error={!!(errors as FieldErrors<BaselineTrendsConfiguration>)?.tolerance}
									InputProps={{
										inputProps: { min: 0 },
										endAdornment: <InputAdornment position='end'>%</InputAdornment>,
									}}
								/>
							</Stack>
							{!!(errors as FieldErrors<BaselineTrendsConfiguration>)?.tolerance && (
								<Box color={'red'} marginTop={'10px'} fontSize={'small'}>
									{(errors as FieldErrors<BaselineTrendsConfiguration>)?.tolerance?.message}
								</Box>
							)}
						</Stack>
					)}
					{objectiveMode === 'AUTOMATED' && (
						<FormControl sx={{ width: '20%', marginTop: '16px' }} required={true}>
							<InputLabel id='automated-tolerance'>{t('trends.settings.tolerance')}</InputLabel>
							<Controller
								control={control}
								name='automatedTolerance'
								rules={{
									required: true,
									shouldUnregister: true,
								}}
								render={({ field }) => (
									<Select
										label={t('trends.settings.tolerance')}
										labelId='automated-tolerance'
										required={true}
										{...field}
									>
										{AUTOMATED_TOLERANCE_VALUES.map((tolerance) => (
											<MenuItem key={tolerance} value={tolerance}>
												{'μ +/-' + tolerance + 'σ'}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
					)}
				</FormControl>
				<Divider sx={{ marginTop: (theme) => theme.spacing(2) }} />
				<FormControlLabel
					label={t('trends.settings.shouldFailTestExecutionOnObjectiveExceeded')}
					sx={{ marginTop: (theme) => theme.spacing(1) }}
					control={
						<Controller
							name={`shouldFailTestExecutionOnObjectiveExceeded`}
							control={control}
							render={({ field }) => <Checkbox {...field} checked={field.value} />}
						/>
					}
				/>
			</Stack>
		</>
	);
};

type TrendElementItemProps = {
	trendElement: TrendElement;
};

const TrendElementItem = ({ trendElement }: TrendElementItemProps) => (
	<Stack
		direction={'row'}
		sx={{
			flex: 1,
			alignItems: 'center',
		}}
	>
		<Stack>
			<Box>{trendElement.name}</Box>
			<Box>
				<Typography
					variant='subtitle2'
					sx={{
						color: 'text.secondary',
					}}
				>
					{trendElement.fullPath.slice(0, -1).join('/')}
				</Typography>
			</Box>
		</Stack>
	</Stack>
);
