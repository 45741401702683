import { useTranslation } from 'react-i18next';
import PowerIcon from '@mui/icons-material/Power';
import { DatagridAction } from '../../../common/data-grid/actions/data-grid-actions';
import { useCurrentWorkspace } from '@neoload/hooks';

export type ConfigureNlguiActionProps = {
	publicApiUrl: string | undefined;
	selectedAccessTokenIds: string[];
	isGuest: boolean;
}

export const useConfigureNlguiAction = ({
	publicApiUrl,
	selectedAccessTokenIds,
	isGuest,
}: ConfigureNlguiActionProps): DatagridAction => {
	const [workspace] = useCurrentWorkspace();
	const { t } = useTranslation(['user']);

	const onConfigureNlgui = (token: string) => {
		const url = new URL('neoload://v1/autoconfigure-controller');
		url.searchParams.append('nlweb-api-url', publicApiUrl ?? '');
		url.searchParams.append('user-token', token);
		url.searchParams.append('session-id', 'c3f48e6c-f011-4323-a49b-03bffe802044');
		url.searchParams.append('call-id', 'c3f48e6c-f011-4323-a49b-03bffe802044');
		if (workspace.id) {
			url.searchParams.append('group-id', workspace.id);
		}
		window.open(url, '_self');
	};

	const configureNlgui = {
		icon: <PowerIcon />,
		text: t('token.configureNlG'),
		disabled: selectedAccessTokenIds.length !== 1,
		hidden: isGuest,
		action: () => {
			if (selectedAccessTokenIds.length === 1) {
				onConfigureNlgui(selectedAccessTokenIds[0]);
			}
		},
		singleItem: (token: string) => ({
			disabled: false,
			action: () => {
				onConfigureNlgui(token);
			},
		}),
	};

	return configureNlgui;
};
