import { DataGridProProps } from '@mui/x-data-grid-pro';
import { defaultRowsPerPageOptions } from '@neoload/utils';

type DefaultGridPropsType = Pick<
	DataGridProProps,
	| 'sortingOrder'
	| 'pagination'
	| 'disableColumnMenu'
	| 'experimentalFeatures'
	| 'rowsPerPageOptions'
	| 'checkboxSelection'
	| 'disableSelectionOnClick'
	| 'paginationMode'
	| 'onColumnVisibilityModelChange'
	| 'onColumnOrderChange'
	| 'onColumnWidthChange'
>;
export const defaultGridProps: DefaultGridPropsType = {
	sortingOrder: ['desc', 'asc'],
	pagination: true,
	disableColumnMenu: true,
	// eslint-disable-next-line unicorn/no-keyword-prefix
	experimentalFeatures: { newEditingApi: true },
	rowsPerPageOptions: defaultRowsPerPageOptions,
	checkboxSelection: true,
	disableSelectionOnClick: true,
	paginationMode: 'client',
};

export const onColumnChange = (onChange: () => void): DefaultGridPropsType => ({
	onColumnVisibilityModelChange: onChange,
	onColumnOrderChange: onChange,
	onColumnWidthChange: onChange,
});
