const ssoLoginUrl = import.meta.env.VITE_SSO_LOGIN_URL;
const resetPasswordUrl = import.meta.env.VITE_RESET_PASSWORD_URL;

const buildSsoLoginUrlWithReturnParameter = (currentUrl: string) => {
	const url = new URL(ssoLoginUrl);
	url.searchParams.append('returnUrl', currentUrl);
	return url.toString();
};

export { buildSsoLoginUrlWithReturnParameter, resetPasswordUrl };
