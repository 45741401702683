import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { DeepPartial, useFieldArray, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { ComparisonColumnListItem } from './comparison-column-item';
import { ComparisonTileEditionFormFields } from '../../tile-edition-form';

const ComparisonColumnList = () => {
	const { t } = useTranslation(['dashboard']);

	const [lastAddedIndex, setLastAddedIndex] = useState<number | undefined>(undefined);

	const { control, getValues, setValue } = useFormContext<DeepPartial<ComparisonTileEditionFormFields>>();

	const { fields, append } = useFieldArray({
		control,
		name: 'columns',
	});

	const addColumn = () => {
		const lastColumn = getValues('columns')?.at(-1);
		append({
			resultId: lastColumn?.resultId,
			visible: true,
		});
		const columns = getValues('columns');
		if (columns) {
			setLastAddedIndex(columns.length - 1);
		}
	};

	const removeColumn = (index: number) => {
		const columns = getValues('columns');
		setValue('columns', columns?.toSpliced(index, 1));
	};

	return (
		<>
			<Stack spacing={2} maxWidth={'100%'}>
				<Typography variant={'subtitle2'}>{t('valuesComparison.columns.sectionTitle')}</Typography>
				<Typography variant={'caption'}>{t('valuesComparison.columns.sectionDescription')}</Typography>
			</Stack>
			<Stack spacing={1} maxWidth={'100%'}>
				{fields.map((field, index) => (
					<ComparisonColumnListItem
						key={field.id}
						index={index}
						initAsOpened={lastAddedIndex === index}
						removeColumn={removeColumn}
					/>
				))}
				<Button startIcon={<AddOutlined />} variant='outlined' onClick={() => addColumn()}>
					{t('valuesComparison.columns.add')}
				</Button>
			</Stack>
		</>
	);
};

export { ComparisonColumnList };
