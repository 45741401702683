import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import { useGetMe, useCurrentWorkspace, useCrudBatchMessage } from '@neoload/hooks';
import { Test, useGetV4TestsQuery, usePatchV4ResultsByResultIdMutation } from '@neoload/api';

type ResultMoveDialogProps = {
	isOpen: boolean;
	onClose: () => void;
	movingResultIds: string[];
};

type FormProps = {
	workspace: string;
	test: string;
};

const ResultMoveDialog = ({ isOpen, onClose, movingResultIds }: ResultMoveDialogProps) => {
	const { t } = useTranslation(['result']);
	const title = t('actions.moveTestResults.title', { count: movingResultIds.length });
	const label = t('actions.moveTestResults.label', { count: movingResultIds.length });
	const dashboardWarning = t('actions.moveTestResults.dashboardWarning');
	const targetWorkspace = t('actions.moveTestResults.targetWorkspace');
	const targetTest = t('actions.moveTestResults.targetTest');
	const move = t('actions.moveTestResults.move');
	const moveAnyway = t('actions.moveTestResults.moveAnyway');
	const cancel = t('common:cancel');
	const pageSize = 200;

	const [{ workspaces = [] }] = useGetMe();
	const [{ id: workspaceId, name: workspaceName }] = useCurrentWorkspace();
	const [updateTestResults] = usePatchV4ResultsByResultIdMutation();

	const [loading, setLoading] = useState(false);
	const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceId);

	const [tests, setTests] = useState<Test[]>([]);
	const [page, setPage] = useState(0);

	const { data: testPage } = useGetV4TestsQuery(
		{
			workspaceId: selectedWorkspace,
			pageNumber: page,
			pageSize,
		},
		{ skip: !isOpen }
	);

	const defaultValues: FormProps = {
		workspace: workspaceName,
		test: '',
	};

	const [selectedTest, setSelectedTest] = useState(defaultValues.test);

	const selectedName = tests.find((test) => test.id === selectedTest)?.name;
	const { update } = useCrudBatchMessage(
		'result',
		'actions.moveTestResults',
		selectedName ? [{ testName: selectedName }] : []
	);

	const handleWorkspaceChange = useCallback(
		(nextWorkspaceId: string) => {
			if (nextWorkspaceId !== selectedWorkspace) {
				setSelectedWorkspace(nextWorkspaceId);
				setSelectedTest('');
				setTests([]);
				setPage(0);
			}
		},
		[selectedWorkspace]
	);

	function close() {
		handleWorkspaceChange(workspaceId);
		onClose();
	}

	const moveTest = async (workspace: string, test: string) => {
		setLoading(true);
		await update(movingResultIds, (resultId) =>
			updateTestResults({ resultId, testResultInput: { workspaceId: workspace, testId: test } }).unwrap()
		);
		onClose();
		handleWorkspaceChange(workspaceId);
		setLoading(false);
	};

	useEffect(() => {
		if (testPage) {
			setTests((t) => [...t, ...testPage.items]);
			if (testPage.items.length >= pageSize) {
				setPage((p) => p + 1);
			}
		}
	}, [testPage]);

	useEffect(() => {
		handleWorkspaceChange(workspaceId);
	}, [handleWorkspaceChange, workspaceId]);

	return (
		<Dialog open={isOpen} fullWidth={true} data-testid={'move-dialog'}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
					}}
				>
					{label}
					<Box>
						<FormControl fullWidth required>
							<InputLabel>{targetWorkspace}</InputLabel>
							<Select label={targetWorkspace} defaultValue={defaultValues.workspace}>
								{workspaces.map((workspace) => (
									<MenuItem
										key={workspace.id}
										value={workspace.name}
										onClick={() => handleWorkspaceChange(workspace.id)}
									>
										{workspace.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box>
						<FormControl fullWidth required>
							<InputLabel>{targetTest}</InputLabel>
							<Select label={targetTest} defaultValue={''}>
								{tests.map((test) => (
									<MenuItem key={test.id} value={test.name} onClick={() => setSelectedTest(test.id)}>
										{test.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					{workspaceId !== selectedWorkspace && (
						<Alert sx={{ display: 'flex', alignItems: 'center' }} severity={'warning'}>
							{dashboardWarning}
						</Alert>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close()} color='primary' disabled={loading}>
					{cancel}
				</Button>
				<Button
					onClick={() => moveTest(selectedWorkspace, selectedTest)}
					variant='contained'
					disabled={loading || selectedTest === ''}
					sx={{ textTransform: 'initial' }}
					startIcon={loading && <CircularProgress size={24.5} color='inherit' />}
				>
					{workspaceId !== selectedWorkspace && moveAnyway}
					{workspaceId === selectedWorkspace && move}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { ResultMoveDialog };
