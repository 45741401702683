import ToggleButton from '@mui/material/ToggleButton';
import { useTranslation } from 'react-i18next';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export type TimeWindow = 'last10' | 'last30' | 'start';

export type TimeWindowButtonsProps = {
	value: TimeWindow;
	onUpdateValue: (window: TimeWindow) => void;
	disabled: boolean;
};

const allTimeWindows: TimeWindow[] = ['last10', 'last30', 'start'];

const TimeWindowButtons = ({ value, onUpdateValue, disabled }: TimeWindowButtonsProps) => {
	const { t } = useTranslation(['result']);

	return (
		<ToggleButtonGroup
			exclusive
			size='small'
			color='primary'
			sx={{ width: '100%', justifyContent: 'center', marginTop: 2 }}
			onChange={(_event, nextValue) => onUpdateValue(nextValue)}
		>
			{allTimeWindows.map((window) => (
				<ToggleButton
					value={window}
					disabled={disabled}
					key={window}
					sx={(theme) => ({ padding: `${theme.spacing(1)} ${theme.spacing(2)}` })}
					selected={window === value}
				>
					{t(`overview.charts.timeWindow.${window}`)}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
};

export { TimeWindowButtons };
