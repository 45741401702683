import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useWatch } from 'react-hook-form';
import { TestExecutionZoneLg } from './test-execution-zone-lg';
import { TestExecutionZoneIcon } from './test-execution-zone-icon';
import { TestExecutionZoneController } from './test-execution-zone-controller';
import { TestExecutionForm } from '../../types';
import { hasControllerError } from '../../test-execution-helpers';

export type TestExecutionZoneProps = {
	zoneName: string;
	nbLgsZone: number;
	defaultController: boolean;
	nbLgsRequired: number;
	missingDedicatedIps?: number;
};

const TestExecutionZone = (testExecutionZoneProps: TestExecutionZoneProps) => {
	const theme = useTheme();
	const errors = useWatch<TestExecutionForm, 'errors'>({ name: 'errors' });
	const controllerError = testExecutionZoneProps.defaultController && hasControllerError(errors);

	if (controllerError) {
		return (
			<Alert
				icon={false}
				sx={{
					marginBottom: 1,
					border: 'none',
					paddingY: theme.spacing(0.5),
					'& .MuiAlert-message': {
						flex: '1',
						paddingY: theme.spacing(0.5),
					},
				}}
				severity='error'
			>
				<TestExecutionZoneRow {...testExecutionZoneProps} controllerError={controllerError} />
			</Alert>
		);
	}

	return (
		<Box
			sx={{
				paddingY: 1,
				paddingX: 2,
			}}
		>
			<TestExecutionZoneRow {...testExecutionZoneProps} controllerError={controllerError} />
		</Box>
	);
};

const TestExecutionZoneRow = (testExecutionZoneProps: TestExecutionZoneProps & { controllerError: boolean }) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: 'minmax(50px, 4fr) 2fr 3fr 1fr',
				alignItems: 'center',
				gridRowGap: theme.spacing(1),
				overflow: 'auto',
			}}
		>
			<Tooltip title={testExecutionZoneProps.zoneName} arrow>
				<Typography variant='body2' noWrap={true}>
					{testExecutionZoneProps.zoneName}
				</Typography>
			</Tooltip>
			<TestExecutionZoneLg
				nbLgsZone={testExecutionZoneProps.nbLgsZone}
				nbLgsRequired={testExecutionZoneProps.nbLgsRequired}
			/>
			<TestExecutionZoneController defaultController={testExecutionZoneProps.defaultController} />
			<TestExecutionZoneIcon
				defaultController={testExecutionZoneProps.defaultController}
				missingDedicatedIps={testExecutionZoneProps.missingDedicatedIps}
				controllerError={testExecutionZoneProps.controllerError}
			/>
		</Box>
	);
};

export { TestExecutionZone };
