import { GridOverlay } from '@mui/x-data-grid-pro';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import { useTranslation } from 'react-i18next';
import DataGridNoResults from '../../assets/DataGridNoResults.svg';

const NoResultsOverlay = () => {
	const { t } = useTranslation(['common']);
	return (
		<GridOverlay>
			<ZeroState
				containerSx={{ backgroundColor: 'transparent' }}
				illustration={<img src={DataGridNoResults} alt={t('datagrid.noContent.title')} />}
				title={t('datagrid.noContent.title')}
			>
				{t('datagrid.noContent.content', { modifier: t('datagrid.noContent.search') })}
			</ZeroState>
		</GridOverlay>
	);
};

export { NoResultsOverlay };
