import { generatePath } from 'react-router';

const patterns = {
	base: '/starting',
	byId: '/starting/:testExecutionId',
};

const startingRoutes = {
	patterns: patterns,
	base: patterns.base,
	starting: (testExecutionId: string) => generatePath(patterns.byId, { testExecutionId: testExecutionId }),
};

export { startingRoutes as StartingRoutes };
