import TaskAltOutlined from '@mui/icons-material/TaskAltOutlined';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { ComponentProps } from 'react';
import { Sla } from '@neoload/utils';

export type SlaBadgeProps = {
	sla?: Sla;
	size?: ComponentProps<typeof Typography>['variant'];
};

export const SlaBadge = ({ sla, size }: SlaBadgeProps) => {
	const theme = useTheme();
	let fontSize: string | number | undefined = undefined;
	if (size) {
		fontSize = size === 'inherit' ? size : theme.typography[size].fontSize;
	}

	switch (sla) {
		case 'SUCCESS': {
			return <TaskAltOutlined sx={{ fontSize }} color='success' />;
		}
		case 'WARN': {
			return <WarningAmberOutlined sx={(theme) => ({ color: theme.palette.warning.light, fontSize })} />;
		}
		case 'FAILED': {
			return <CancelOutlined sx={{ fontSize }} color='error' />;
		}
		default: {
			return null;
		}
	}
};
