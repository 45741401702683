import { GridOverlay } from '@mui/x-data-grid-pro';
import ZeroState from '@tricentis/aura/components/ZeroState.js';
import { useTranslation } from 'react-i18next';
import DataGridNoResults from '../../assets/DataGridNoResults.svg';

type NoRowsOverlayProps = {
	customTitle?: string;
	customContent?: string;
};

const NoRowsOverlay = ({ customTitle, customContent }: NoRowsOverlayProps) => {
	const { t } = useTranslation(['common']);
	return (
		<GridOverlay sx={{ backgroundColor: 'transparent' }}>
			<ZeroState
				containerSx={{ maxHeight: '100%', paddingY: (theme) => theme.spacing(2) }}
				illustration={<img src={DataGridNoResults} alt={t('datagrid.noContent.title')} />}
				title={customTitle ?? t('datagrid.noContent.title')}
			>
				{customContent ?? t('datagrid.noContent.content', { modifier: t('datagrid.noContent.filter') })}
			</ZeroState>
		</GridOverlay>
	);
};

export { NoRowsOverlay };
