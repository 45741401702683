import { useState, useRef, ComponentType } from 'react';
import { useDebounce, useIntersection } from 'react-use';

export type WithLazyFetchingProps = {
	shouldStartFetching: boolean;
};

export function withLazyFetching<T>(Component: ComponentType<T & WithLazyFetchingProps>) {
	return (props: T) => {
		const [shouldStartFetching, setShouldStartFetching] = useState(false);
		const intersectionRef = useRef(null);
		const intersection = useIntersection(intersectionRef, {
			root: null,
			rootMargin: '0px',
			threshold: 0.4,
		});

		useDebounce(
			() => {
				if (intersection?.isIntersecting && !shouldStartFetching) {
					setShouldStartFetching(true);
				}
			},
			500,
			[intersection, shouldStartFetching]
		);

		return (
			<div ref={intersectionRef} style={{ height: '100%' }}>
				<Component {...props} shouldStartFetching={shouldStartFetching} />
			</div>
		);
	};
}
