import { useEffect, useRef, useState } from 'react';
import { TypedLazyQueryTrigger } from '@reduxjs/toolkit/query/react';
import { TestResult } from '@neoload/api';

type ResultIdParam = { resultId: string };
type TreeResponse<T> = { rootElements?: T[] };

export const useTreeDataWhenTestResultChange = <Response,>(
	testResult: TestResult | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	trigger: TypedLazyQueryTrigger<TreeResponse<Response>, ResultIdParam, any>
): [Response[], boolean, string | undefined] => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [items, setItems] = useState<Response[]>([]);
	const lastPromise = useRef<Promise<Response[]> | undefined>();

	useEffect(() => {
		setItems([]);
		if (!testResult) {
			return;
		}
		const fetchData = async (testResult: TestResult): Promise<Response[]> => {
			const { data = { rootElements: [] }, isError, error } = await trigger({ resultId: testResult.id }, true);
			if (isError) {
				throw new Error(error.toString());
			}
			const { rootElements: elements = [] } = data;
			return elements;
		};
		setIsLoading(true);
		setError(undefined);

		const currentCall = fetchData(testResult);
		currentCall
			.then((elements) => {
				if (lastPromise.current === currentCall) {
					setItems(elements ?? []);
				}
			})
			.catch((_error) => {
				if (lastPromise.current === currentCall) {
					setError(_error);
				}
			})
			.finally(() => {
				if (lastPromise.current === currentCall) {
					setIsLoading(false);
				}
			});
		lastPromise.current = currentCall;
	}, [testResult, trigger]);

	return [items, isLoading, error];
};
