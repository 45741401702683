/* eslint-disable react-compiler/react-compiler */
// TODO - Fix react-compiler
import { useEffect, useState } from 'react';
import { useGetWithRetry } from './use-get-with-retry';
import { useCurrentWorkspace } from '../workspaces/use-current-workspace';
import { useSetCurrentWorkspaceId } from '../workspaces/use-set-current-workspace-id';
import { useGetV4ResultsByResultIdQuery } from '@neoload/api';
import { shouldResultDoPolling } from '@neoload/utils';

/**
 * Fetches the result and switches to its workspace if needed.
 * If the result is running, polls with 2 seconds interval
 * Calls useGetWithRetry hook, with up to 8 tries if we need to wait for result
 * @param resultId the result to query's id
 * @param waitResult whether we should wait for result's presence. This is needed
 * when redirected to result page immediately after launching a test. Otherwise should
 * be false
 * @returns
 */
const useGetResult = (resultId: string, waitResult: boolean) => {
	const [{ id }] = useCurrentWorkspace();
	const [pollingInterval, setPollingInterval] = useState(0);
	const { response, waiting } = useGetWithRetry(
		useGetV4ResultsByResultIdQuery,
		{ resultId },
		waitResult ? 8 : 0,
		1000,
		{ pollingInterval: pollingInterval }
	);

	const setCurrentWorkspaceId = useSetCurrentWorkspaceId();
	const { data: result } = response;

	useEffect(() => {
		if (result && result.workspaceId !== id) {
			setCurrentWorkspaceId(result.workspaceId);
		}
	}, [result, id, setCurrentWorkspaceId]);

	useEffect(() => {
		const nextPollingInterval = !waiting && shouldResultDoPolling(result) ? 2000 : 0;
		if (pollingInterval !== nextPollingInterval) {
			setPollingInterval(nextPollingInterval);
		}
	}, [result, pollingInterval, waiting]);

	return {
		response: response,
		waiting: waiting,
	};
};

export { useGetResult };
