import { TreeNode } from './statistic-tree';
import { StyledTreeItem as TreeItem } from './styled-tree-item';

export const StatisticTreeItem = <T extends object>({ id, label, children }: TreeNode<T>) => (
	<TreeItem nodeId={id} label={label} sx={{ padding: (theme) => theme.spacing(0.6) }}>
		{children.map((child: TreeNode<T>) => (
			<StatisticTreeItem key={child.id} {...child} />
		))}
	</TreeItem>
);
