const patterns = {
	base: '/login',
};

const loginRoutes = {
	patterns: patterns,
	base: patterns.base,
};

export { loginRoutes as LoginRoutes };
