import { TFunction } from 'i18next';

const getReadableSize = (t: TFunction, size: number, fractionDigits = 0) => {
	const kb = 1000;
	const mb = kb * 1000;
	const gb = mb * 1000;

	if (size >= gb) {
		return `${t('configuration.project.file.sizeFile.gb', { size: (size / gb).toFixed(fractionDigits) })}`;
	}

	if (size >= mb) {
		return `${t('configuration.project.file.sizeFile.mb', { size: (size / mb).toFixed(fractionDigits) })}`;
	}

	return `${t('configuration.project.file.sizeFile.kb', { size: (size / kb).toFixed(fractionDigits) })}`;
};

const allowedFileUploadExtension = new Set(['zip', 'yml', 'yaml']);

const allowedFileUploadExtensionString = [...allowedFileUploadExtension].map((v) => '.' + v).join(', ');

export { getReadableSize, allowedFileUploadExtension, allowedFileUploadExtensionString };
