import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import IconButton from '@mui/material/IconButton';
import { TestExecutionForm } from '../../types.d';
import { timeUtils } from '@neoload/utils';

const parseIntSafely = (value: string) => {
	const number = Number.parseInt(value);
	if (number < 0 || Number.isNaN(number)) {
		return 0;
	}
	return number;
};

const DurationLicenseAllocation = () => {
	const { t } = useTranslation(['test']);
	const theme = useTheme();
	const [edit, setEdit] = useState(false);
	const { setValue } = useFormContext<TestExecutionForm>();
	const duration = useWatch<TestExecutionForm, 'resources.duration'>({ name: 'resources.duration' });
	const errorsInFirstCall = useWatch<TestExecutionForm, 'errorsInFirstCall'>({ name: 'errorsInFirstCall' });
	const scenarioDuration = useWatch<TestExecutionForm, 'test.duration'>({ name: 'test.duration' });
	const reservation = useWatch<TestExecutionForm, 'reservation'>({ name: 'reservation' });
	const reservationId = useWatch<TestExecutionForm, 'resources.reservationId'>({ name: 'resources.reservationId' });
	const durationString = dayjs.duration(duration).asMinutes().toString();
	const [localValue, setLocalValue] = useState(durationString);

	useEffect(() => {
		setLocalValue(durationString);
	}, [durationString]);

	const calMinDuration = () =>
		reservation.reservationModeStatus === 'RESERVATION_MODE_ENABLED'
			? dayjs.duration(scenarioDuration).add(20, 'minute').toISOString()
			: dayjs.duration(scenarioDuration).add(5, 'minute').toISOString();

	const verifyValueUpperMinDuration = (value: number) => {
		const minValue = dayjs.duration(calMinDuration()).asMinutes();
		if (value < minValue) {
			return minValue;
		}
		return value;
	};

	const disallowedEdition = errorsInFirstCall || reservationId !== undefined;

	return (
		<>
			<Typography variant='subtitle2' width='100px'>
				{t('test:testExecution:maxDuration')}
			</Typography>
			<Stack useFlexGap flexDirection='row' gap={theme.spacing(1)} alignItems='center'>
				{edit ? (
					<>
						<TextField
							size='small'
							placeholder={durationString}
							type='number'
							sx={{ width: '120px' }}
							onChange={(event) => setLocalValue(parseIntSafely(event.currentTarget.value).toString())}
							onBlur={(event) => {
								setValue(
									'resources.duration',
									dayjs.duration(verifyValueUpperMinDuration(Number(event.currentTarget.value)), 'm').toISOString()
								);
								setEdit(false);
							}}
							autoFocus={true}
							value={localValue}
							InputProps={{
								endAdornment: <InputAdornment position='end'>{t('common:duration.minutes.small')}</InputAdornment>,
							}}
						/>
						<Typography color='text.secondary' variant='body2'>
							{t('test:testExecution:minDuration')} {timeUtils.durationFull(calMinDuration())}
						</Typography>
					</>
				) : (
					<>
						<Typography
							variant='body2'
							color='text.primary'
							data-testid='testMaxDuration'
							onClick={() => {
								disallowedEdition ? setEdit(false) : setEdit(true);
							}}
						>
							{timeUtils.durationFull(duration)}
						</Typography>
						{disallowedEdition ? (
							''
						) : (
							<Tooltip arrow title={t('common:edit')} sx={{ width: 4, height: 4 }}>
								<IconButton
									onClick={() => {
										setEdit(true);
									}}
								>
									<EditOutlined color='action' />
								</IconButton>
							</Tooltip>
						)}
					</>
				)}
			</Stack>
		</>
	);
};

export { DurationLicenseAllocation };
