import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Brand from '@tricentis/aura/components/Brand.js';
import { useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { buildSsoLoginUrlWithReturnParameter, resetPasswordUrl } from '@neoload/utils';
import { Account, CreateSessionRequest } from '@neoload/api';

type InitializingAccount =
	| {
			initializing: true;
			accountName: string;
	  }
	| {
			initializing: false;
	  };

export type LoginProps = {
	postSession: (data: CreateSessionRequest) => void;
	accounts: Account[];
	alert?: boolean;
	initializingAccount: InitializingAccount;
};

const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
	event.preventDefault();
};

const Login = ({ postSession, accounts, alert = false, initializingAccount }: LoginProps) => {
	const { t } = useTranslation(['login']);
	const theme = useTheme();

	const [localAlert, setLocalAlert] = useState(alert);

	const [showPassword, setShowPassword] = useState(false);

	const [connectionState, setConnectionState] = useState({
		email: '',
		password: '',
		buttonDisabled: true,
	});

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	function login(accountId?: string) {
		postSession({
			login: connectionState.email,
			password: connectionState.password,
			accountId,
		});
	}

	useEffect(() => {
		setLocalAlert(alert);
	}, [alert]);

	useEffect(() => {
		if (connectionState.email !== '' && connectionState.password !== '') {
			setConnectionState((previousState) => ({
				...previousState,
				buttonDisabled: false,
			}));
		} else {
			setConnectionState((previousState) => ({
				...previousState,
				buttonDisabled: true,
			}));
		}
	}, [connectionState.email, connectionState.password]);

	const handleChange = (element: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = element.target;
		setConnectionState((previousState) => ({
			...previousState,
			[id]: value,
		}));
		setLocalAlert(false);
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Enter' && !connectionState.buttonDisabled) {
			login();
		}
	};

	return (
		<>
			<Stack justifyContent='space-between'>
				<Stack width='469px' alignItems='center' flex='1' justifyContent='center'>
					<Brand variant='full' sx={{ width: '118px' }} />
					<Typography variant='h6' sx={{ paddingTop: 6 }}>
						{t('login:signInTo')}
					</Typography>
					<TextField
						sx={{
							marginTop: 4,
						}}
						id='email'
						color='primary'
						label={t('login:email')}
						size='medium'
						value={connectionState.email}
						onChange={handleChange}
						onKeyDown={handleKeyPress}
						data-testid='login-input'
						required
						fullWidth
					/>
					<TextField
						sx={{
							marginTop: 4,
							[`.MuiFormHelperText-root`]: {
								alignSelf: 'end',
							},
						}}
						id='password'
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label={t('login:aria.togglePasswordVisibility')}
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						color='primary'
						label={t('login:password')}
						size='medium'
						value={connectionState.password}
						onChange={handleChange}
						onKeyDown={handleKeyPress}
						data-testid='password-input'
						required
						fullWidth
						helperText={
							<Link href={resetPasswordUrl} underline='hover'>
								{t('login:forgotPassword')}
							</Link>
						}
					/>
					{localAlert && (
						<Alert
							sx={{
								marginTop: theme.spacing(1.5),
								width: '100%',
							}}
							severity='error'
						>
							{t('login:invalidCredentials')}
						</Alert>
					)}
					<Button
						onClick={() => login()}
						sx={{
							marginTop: 3,
						}}
						color='primary'
						fullWidth
						variant='contained'
						disabled={connectionState.buttonDisabled}
						data-testid='login-button'
						size='large'
						aria-label={t('login:login')}
					>
						{t('login:login')}
					</Button>
					<Link
						sx={{
							alignSelf: 'center',
							marginTop: 3,
						}}
						color='primary'
						variant='body2'
						underline='hover'
						href={buildSsoLoginUrlWithReturnParameter(window.location.href)}
					>
						{t('login:sso')}
					</Link>
				</Stack>
				<Stack direction='row' justifyContent='space-evenly' mb={3}>
					<Link color={theme.palette.secondary.light} variant='body2' underline='hover' href={t('login:legal.link')}>
						{t('login:legal.text')}
					</Link>
					<Link color={theme.palette.secondary.light} variant='body2' underline='hover' href={t('login:privacy.link')}>
						{t('login:privacy.text')}
					</Link>
					<Link
						color={theme.palette.secondary.light}
						variant='body2'
						underline='hover'
						href={t('login:tricentis.link')}
					>
						{t('login:tricentis.text')}
					</Link>
				</Stack>
			</Stack>
			{(accounts.length > 0 || initializingAccount.initializing) && (
				<DialogBox accounts={accounts} login={login} initializatingAccount={initializingAccount} />
			)}
		</>
	);
};

function sortAccountByName() {
	return (a: Account, b: Account) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0);
}

const DialogBox = ({
	accounts,
	login,
	initializatingAccount,
}: {
	accounts: Account[];
	login: (accountId: string) => void;
	initializatingAccount: InitializingAccount;
}) => {
	const { t } = useTranslation(['login']);
	return (
		<Dialog open>
			<DialogTitle>{t('login:accountSelectionTitle')}</DialogTitle>
			<DialogContent>
				{accounts.length > 0 && (
					<Stack spacing={2} paddingTop={3} paddingBottom={1}>
						{accounts.toSorted(sortAccountByName()).map((account: { id: string; name: string }) => (
							<Button
								sx={{
									textTransform: 'none',
								}}
								color='primary'
								variant='outlined'
								value={account.id}
								key={account.id}
								onClick={() => login(account.id)}
								data-testid={`account-login-button-${account.id}`}
							>
								{account.name}
							</Button>
						))}
					</Stack>
				)}
				{initializatingAccount.initializing && (
					<Stack direction={'row'} spacing={1} alignItems={'center'} marginY={1}>
						<CircularProgress size={'1em'} />
						<Typography variant='body2'>
							{t('login:initializingAccount', {
								accountName: initializatingAccount.accountName,
							})}
						</Typography>
					</Stack>
				)}
			</DialogContent>
		</Dialog>
	);
};

export { Login };
