import { ComponentPropsWithoutRef, forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { GridPinnedRowsProp, GridSortItem, useGridApiRef } from '@mui/x-data-grid-pro';
import Toolbar from '@tricentis/aura/components/Toolbar.js';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { ValuesDataGridColumns } from './values-data-grid-columns';
import { defaultGridProps, onColumnChange } from '../../../../common/datagrid';
import { Spinner } from '../../../../common/spinner';
import { dataGridStyle } from '../values-data-grid-common';
import { ValuesDataGridFuncts } from '../result-tab-values';
import { Datagrid } from '../../../../common/data-grid/datagrid';
import { intervalLimitEmptyOverlay } from '../interval-limit-empty-overlay';
import { ResultElementValue, useGetV4ResultsByResultIdElementsValuesQuery } from '@neoload/api';
import { createNeoLoadError, defaultRowsPerPage, isIntervalLimitError } from '@neoload/utils';
import { useUrlSearchParams, useColumnsState } from '@neoload/hooks';

export type Category = 'actions' | 'pages' | 'requests' | 'transactions';

export type ValuesDataGridProps = {
	category: Category;
	resultId: string;
	setDataFetching: (isDataLoading: boolean) => void;
};

const columnsStateKey = 'VALUES_ELEMENTS_COLUMNS_STATE';
const initialState = {
	columns: {
		columnVisibilityModel: {
			errorRate: false,
			minTtfb: false,
			avgTtfb: false,
			maxTtfb: false,
		},
	},
	sorting: {
		sortModel: [{ field: 'averageDuration', sort: 'desc' } as GridSortItem],
	},
};

const toElementType = (category: Category) => {
	switch (category) {
		case 'actions': {
			return 'ACTION';
		}
		case 'transactions': {
			return 'TRANSACTION';
		}
		case 'pages': {
			return 'PAGE';
		}
		case 'requests': {
			return 'REQUEST';
		}
	}
};

const ValuesDataGrid = forwardRef(
	({ category, resultId, setDataFetching }: ValuesDataGridProps, ref: Ref<ValuesDataGridFuncts | undefined>) => {
		const apiRef = useGridApiRef();
		const { t } = useTranslation(['result']);
		const [{ zones: zone, userPaths: userPath, populations: population, intervalId }] = useUrlSearchParams(
			'zones',
			'userPaths',
			'populations',
			'intervalId'
		);
		const [pageSize, setPageSize] = useState(defaultRowsPerPage);
		const {
			data: result,
			isFetching,
			isLoading,
			refetch,
			error,
		} = useGetV4ResultsByResultIdElementsValuesQuery({
			resultId,
			intervalId,
			elementType: toElementType(category),
			zone,
			userPath,
			population,
			pageSize: 0,
		});

		const columns = ValuesDataGridColumns(category, result?.items ?? []);

		useImperativeHandle(ref, () => ({ refetch }));
		useEffect(() => {
			setDataFetching(isFetching);
		}, [isFetching, setDataFetching]);

		const resultTooBigForIntervalFilteringError = isIntervalLimitError(error);

		const { updatedInitialState, updatedColumns, storeColumnState } = useColumnsState(
			columnsStateKey,
			initialState,
			columns,
			apiRef
		);

		if (isLoading) {
			return <Spinner />;
		}
		if (!result && !isFetching && !resultTooBigForIntervalFilteringError) {
			throw createNeoLoadError(error);
		}
		const total = result?.totalValue
			? ({ ...result.totalValue, name: t(`values.all${category}`) } as ResultElementValue)
			: undefined;

		const secondaryAction = [
			{
				children: <DownloadOutlined />,
				disabled: false,
				onClick: () => apiRef.current.exportDataAsCsv({ delimiter: ';' }),
				tooltipProps: {
					arrow: true,
					title: t('common:downloadCsv'),
				},
			},
		];
		const componentsProps: { toolbar: ComponentPropsWithoutRef<typeof Toolbar> } = {
			toolbar: {
				displayColumnOptions: true,
				hideColumnsFromColumnOptions: ['__check__'],
				secondaryActions: secondaryAction,
				syncLocalStorage: {
					datagridId: 'neoloadValuesElementsDataGrid',
					isSyncEnabled: true,
				},
			},
		};

		const pinnedRows: GridPinnedRowsProp = {
			top: [total ?? {}],
		};

		const rows = resultTooBigForIntervalFilteringError ? [] : result?.items ?? [];

		return (
			<Grid container direction='column' sx={{ height: '100%' }}>
				<Grid item sx={{ flexGrow: 1 }}>
					<Datagrid
						loading={isFetching}
						{...defaultGridProps}
						{...onColumnChange(storeColumnState)}
						checkboxSelection={false}
						pinnedRows={total && pinnedRows}
						experimentalFeatures={{ rowPinning: true }}
						rows={rows}
						getRowId={(row) => `${row.id}${row.userPath ?? ''}`}
						apiRef={apiRef}
						columns={updatedColumns}
						initialState={updatedInitialState}
						pageSize={pageSize}
						density='compact'
						components={{
							// eslint-disable-next-line @typescript-eslint/naming-convention
							Toolbar,
							// eslint-disable-next-line @typescript-eslint/naming-convention
							NoRowsOverlay: intervalLimitEmptyOverlay(
								resultTooBigForIntervalFilteringError,
								t('values.noIntervalFiltering.zeroStateTitle'),
								t('values.noIntervalFiltering.zeroStateContent')
							),
						}}
						componentsProps={componentsProps}
						onPageSizeChange={(nextPageSize) => {
							setPageSize(nextPageSize);
						}}
						sx={{ ...dataGridStyle }}
					/>
				</Grid>
			</Grid>
		);
	}
);

export { ValuesDataGrid };
