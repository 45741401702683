import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftOutlined from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { TFunction } from 'i18next';
import { EventTabContentDetails, EventTabContentRequestResponseProps } from './event-tab-content-details';
import { ComponentTabPanel } from '../../../../../layout/component-tab-panel/component-tab-panel';
import {
	ErrorEventWithDetails,
	RequestOrResponseDetails,
	useGetV4ResultsByResultIdEventsContentsAndContentIdQuery,
} from '@neoload/api';
import { useSetSnackbars } from '@neoload/hooks';

export type EventTabContentProps = {
	event: ErrorEventWithDetails;
	resultId: string;
};

type ContentCategory = 'REQUEST' | 'RESPONSE';

const CONTENT_TAB_DEFINITION = {
	/* eslint-disable @typescript-eslint/naming-convention */
	REQUEST: { tabId: 0, dataTestId: 'event-content-request-btn', i18nKey: 'events.content.request' },
	RESPONSE: { tabId: 1, dataTestId: 'event-content-response-btn', i18nKey: 'events.content.response' },
	/* eslint-enable @typescript-eslint/naming-convention */
} as const;

type MatchingDetails = {
	detail?: RequestOrResponseDetails;
	firstOccurenceDetail?: RequestOrResponseDetails;
};

const getMatchingDetails = (event: ErrorEventWithDetails, current: boolean, type: ContentCategory): MatchingDetails => {
	if (current) {
		return type === 'REQUEST'
			? { detail: event.currentRequest, firstOccurenceDetail: event.firstIterationCurrentRequest }
			: { detail: event.currentResponse, firstOccurenceDetail: event.firstIterationCurrentResponse };
	} else if (type === 'REQUEST') {
		return { detail: event.previousRequest, firstOccurenceDetail: event.firstIterationPreviousRequest };
	} else {
		return { detail: event.previousResponse, firstOccurenceDetail: event.firstIterationPreviousResponse };
	}
};

const getMatchingDetailsProperty = (matchingDetail: MatchingDetails, property: keyof RequestOrResponseDetails) =>
	matchingDetail.detail?.[property] ?? matchingDetail.firstOccurenceDetail?.[property];

const isPropertyFromFirstOccurence = (matchingDetail: MatchingDetails, property: keyof RequestOrResponseDetails) =>
	matchingDetail.detail?.[property] === undefined && matchingDetail.firstOccurenceDetail?.[property] !== undefined;

const getWarningMessage = (matchingDetail: MatchingDetails, t: TFunction) => {
	const propertiesMessages: string[] = [];
	const headersFromFirstOccurence = isPropertyFromFirstOccurence(matchingDetail, 'headers');
	if (headersFromFirstOccurence) {
		propertiesMessages.push(t('events.content.headers'));
	}
	if (isPropertyFromFirstOccurence(matchingDetail, 'statusLine')) {
		propertiesMessages.push(t('events.content.statusLine'));
	}
	if (isPropertyFromFirstOccurence(matchingDetail, 'contentId')) {
		propertiesMessages.push(t('events.content.content'));
	}
	if (propertiesMessages.length === 0) {
		return undefined;
	}
	const joinedProperties = propertiesMessages.join(t('events.content.and'));
	const isPlural = headersFromFirstOccurence || propertiesMessages.length > 1;
	return t('events.content.warnPropertiesFromFirstOccurence', { count: isPlural ? 2 : 1, propety: joinedProperties });
};

const isDataStored = (detail: RequestOrResponseDetails | undefined): boolean =>
	!!detail?.headers || !!detail?.contentId;

const EventTabContent = ({ event, resultId }: EventTabContentProps) => {
	const { t } = useTranslation(['result']);
	const { showError } = useSetSnackbars();
	const [selectedTab, setSelectedTab] = useState<ContentCategory>('RESPONSE');
	const [current, setCurrent] = useState(true);

	const matchingDetails = getMatchingDetails(event, current, selectedTab);
	const contentId = getMatchingDetailsProperty(matchingDetails, 'contentId') ?? '';

	const {
		data: content,
		error,
		isLoading,
		isFetching,
	} = useGetV4ResultsByResultIdEventsContentsAndContentIdQuery(
		{
			contentId: encodeURIComponent(contentId),
			resultId,
		},
		{ skip: contentId === '' }
	);

	const hasPrevious = isDataStored(event.previousRequest);

	useEffect(() => {
		if (!hasPrevious && !current) {
			setCurrent(true);
		}
	}, [current, hasPrevious]);

	useEffect(() => {
		if (error) {
			showError({ text: t('events.content.showError') });
		}
	}, [error, showError, t]);

	const getContentDetailProps = (): EventTabContentRequestResponseProps => ({
		contentId,
		content: contentId === '' ? undefined : content,
		headers: getMatchingDetailsProperty(matchingDetails, 'headers'),
		statusLine: getMatchingDetailsProperty(matchingDetails, 'statusLine'),
		isContentLoading: isLoading || isFetching,
		warnMessage: getWarningMessage(matchingDetails, t),
	});

	return (
		<>
			<Grid
				item
				sx={() => ({
					marginTop: -2,
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
				})}
			>
				{hasPrevious && (
					<Grid
						item
						sx={() => ({
							alignItems: 'center',
							display: 'flex',
							gap: 2,
						})}
					>
						<Tooltip arrow title={t('events.content.previous')}>
							<IconButton
								data-testid='event-error-content-previous'
								onClick={() => setCurrent(false)}
								disabled={!current}
							>
								<ChevronLeftOutlined />
							</IconButton>
						</Tooltip>
						<Typography>{current ? t('events.content.current') : t('events.content.previous')}</Typography>
						<Tooltip arrow title={t('events.content.current')}>
							<IconButton data-testid='event-error-content-next' onClick={() => setCurrent(true)} disabled={current}>
								<ChevronRightOutlined />
							</IconButton>
						</Tooltip>
					</Grid>
				)}
			</Grid>
			{hasPrevious && <Divider />}
			<Grid
				item
				sx={() => ({
					marginY: 2,
					textAlign: 'center',
				})}
			>
				<ToggleButtonGroup
					onChange={(_event, nextValue) => setSelectedTab(nextValue)}
					exclusive
					color='primary'
					size='small'
				>
					{Object.entries(CONTENT_TAB_DEFINITION).map(([k, v]) => (
						<ToggleButton
							key={k}
							value={k}
							data-testid={v.dataTestId}
							sx={(theme) => ({ padding: `${theme.spacing(1)} ${theme.spacing(2)}` })}
							selected={k === selectedTab}
						>
							{t(v.i18nKey)}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Grid>
			<Grid>
				<ComponentTabPanel value={CONTENT_TAB_DEFINITION[selectedTab].tabId} index={0}>
					<Grid item>
						<EventTabContentDetails {...getContentDetailProps()} />
					</Grid>
				</ComponentTabPanel>
				<ComponentTabPanel value={CONTENT_TAB_DEFINITION[selectedTab].tabId} index={1}>
					<Grid item>
						<EventTabContentDetails {...{ ...getContentDetailProps(), response: true }} />
					</Grid>
				</ComponentTabPanel>
			</Grid>
		</>
	);
};

export { EventTabContent };
