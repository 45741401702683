import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { EditorState, LexicalEditor } from 'lexical';
import { TRANSFORMERS } from '@lexical/markdown';
import { CSSProperties } from 'react';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { $generateHtmlFromNodes } from '@lexical/html';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { Theme, useTheme } from '@mui/material';
import { ToolbarPlugin } from './toolbar-plugin';
import { useLoadInitialContent } from './use-load-initial-content-plugin';

const editorConfig: InitialConfigType = {
	namespace: 'Editor',
	theme: {},
	onError(error) {
		throw error;
	},
	nodes: [
		HeadingNode,
		ListNode,
		ListItemNode,
		QuoteNode,
		CodeNode,
		CodeHighlightNode,
		AutoLinkNode,
		LinkNode,
		HorizontalRuleNode,
	],
};

const buildContentStyle = (theme: Theme): CSSProperties => ({
	minHeight: '150px',
	resize: 'none',
	color: theme.palette.text.primary,
	caretColor: theme.palette.text.primary,
	fontSize: '15px',
	position: 'relative',
	tabSize: '1',
	outline: '0',
	padding: '15px 10px',
});

const buildContainerStyle = (theme: Theme): CSSProperties => ({
	margin: '20px auto 20px auto',
	borderRadius: '2px',
	color: theme.palette.text.primary,
	caretColor: theme.palette.text.primary,
	position: 'relative',
	lineHeight: '20px',
	fontWeight: 400,
	textAlign: 'left',
	height: '100%',
	borderTopLeftRadius: '10px',
	borderTopRightRadius: '10px',
});

const URL_MATCHER = /((https?:\/\/(www\.)?)|(www\.))[\w#%+.:=@~-]{1,256}\.[\d()A-Za-z]{1,6}\b([\w#%&()+./:=?@~-]*)/;

const MATCHERS = [
	(text: string) => {
		const match = URL_MATCHER.exec(text);
		if (match === null) {
			return null;
		}
		const fullMatch = match[0];
		return {
			index: match.index,
			length: fullMatch.length,
			text: fullMatch,
			url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
			attributes: { rel: 'noreferrer', target: '_blank' },
		};
	},
];

type RichTextEditorProps = {
	name: string;
	onChange: (updatedValue: string) => void;
	value?: string;
	placeholder?: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	'data-testid'?: string;
};

const InternalLexicalComposer = (props: {
	name: string;
	value?: string;
	placeholder: string | undefined;
	onChange: (editorState: EditorState, editor: LexicalEditor, _tags: Set<string>) => void;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	'data-testid'?: string;
}) => {
	useLoadInitialContent({ value: props.value });
	const theme = useTheme();
	return (
		<div style={buildContainerStyle(theme)}>
			<ToolbarPlugin />
			<div
				style={{
					background: theme.palette.background.paper,
					position: 'relative',
					lineHeight: '2em',
					overflowY: 'auto',
					height: 'calc(100% - 40px)',
				}}
			>
				<RichTextPlugin
					contentEditable={
						<ContentEditable
							data-testid={props['data-testid']}
							name={props.name}
							rows={5}
							style={buildContentStyle(theme)}
						/>
					}
					placeholder={
						<div
							style={{
								position: 'absolute',
								top: '30px',
								left: '10px',
								pointerEvents: 'none',
								color: theme.palette.text.primary,
							}}
						>
							{props.placeholder}
						</div>
					}
					ErrorBoundary={LexicalErrorBoundary}
				/>
				<HistoryPlugin />
				<AutoFocusPlugin />
				<ListPlugin />
				<LinkPlugin />
				<HorizontalRulePlugin />
				<OnChangePlugin onChange={props.onChange} />
				<AutoLinkPlugin matchers={MATCHERS} />
				<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
			</div>
		</div>
	);
};

const RichTextEditor = ({ name, onChange, value, placeholder, 'data-testid': dataTestId }: RichTextEditorProps) => {
	const internalOnChange = (editorState: EditorState, editor: LexicalEditor, _tags: Set<string>) => {
		editorState.read(() => {
			onChange($generateHtmlFromNodes(editor));
		});
	};

	return (
		<LexicalComposer initialConfig={editorConfig}>
			<InternalLexicalComposer
				data-testid={dataTestId}
				name={name}
				value={value}
				placeholder={placeholder}
				onChange={internalOnChange}
			/>
		</LexicalComposer>
	);
};

export { RichTextEditor };
