import { useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { TestExecutionScenario } from './test-execution-scenario';
import { TestExecutionZones } from './zones/test-execution-zones';
import { TestExecutionCloud, TestExecutionVuh } from './test-execution-ncp';
import { TestExecutionForm } from '../types.d';

const TestExecutionConfiguration = () => {
	const shouldDisplayVuhSection =
		useWatch<TestExecutionForm, 'resources.vuh.needed'>({ name: 'resources.vuh.needed' }) > 0;
	const shouldDisplayCloudCreditSection =
		useWatch<TestExecutionForm, 'resources.cloudCredits.needed'>({ name: 'resources.cloudCredits.needed' }) > 0;

	return (
		<Stack direction='column' sx={{ width: '100%', lineHeight: '1.25rem' }}>
			<TestExecutionScenario />
			{shouldDisplayVuhSection && <TestExecutionVuh />}
			<TestExecutionZones />
			{shouldDisplayCloudCreditSection && <TestExecutionCloud />}
		</Stack>
	);
};

export { TestExecutionConfiguration };
