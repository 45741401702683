import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
	$createParagraphNode,
	$getSelection,
	$isElementNode,
	$isRangeSelection,
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	ElementFormatType,
	FORMAT_ELEMENT_COMMAND,
	FORMAT_TEXT_COMMAND,
	LexicalEditor,
	REDO_COMMAND,
	SELECTION_CHANGE_COMMAND,
	TextNode,
	UNDO_COMMAND,
} from 'lexical';
import { $setBlocksType } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
	REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from '@lexical/rich-text';
import { $createCodeNode } from '@lexical/code';
import IconButton from '@mui/material/IconButton';
import FormatAlignLeftOutlined from '@mui/icons-material/FormatAlignLeftOutlined';
import FormatAlignRightOutlined from '@mui/icons-material/FormatAlignRightOutlined';
import FormatAlignCenterOutlined from '@mui/icons-material/FormatAlignCenterOutlined';
import FormatAlignJustifyOutlined from '@mui/icons-material/FormatAlignJustifyOutlined';
import Divider from '@mui/material/Divider';
import RedoOutlined from '@mui/icons-material/RedoOutlined';
import UndoOutlined from '@mui/icons-material/UndoOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import FormatItalicOutlined from '@mui/icons-material/FormatItalicOutlined';
import FormatUnderlinedOutlined from '@mui/icons-material/FormatUnderlinedOutlined';
import FormatBoldOutlined from '@mui/icons-material/FormatBoldOutlined';
import HorizontalRuleOutlined from '@mui/icons-material/HorizontalRuleOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CodeOutlined from '@mui/icons-material/CodeOutlined';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';
import { SelectChangeEvent } from '@mui/material';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { useTranslation } from 'react-i18next';
import { getSelectedNode } from './get-selected-node';

const LowPriority = 1;

const supportedBlockTypes = ['paragraph', 'quote', 'code', 'h1', 'h2', 'h3', 'h4', 'h5', 'ul', 'ol'] as const;
type SupportedBlockTypesType = (typeof supportedBlockTypes)[number];

type SupportedElementFormatType = Extract<ElementFormatType, 'left' | 'center' | 'right' | 'justify'>;

type FormatTypeToIconType = {
	[K in SupportedElementFormatType]: ReactNode;
};

const formatTypeToIcon: FormatTypeToIconType = {
	left: <FormatAlignLeftOutlined />,
	center: <FormatAlignCenterOutlined />,
	right: <FormatAlignRightOutlined />,
	justify: <FormatAlignJustifyOutlined />,
};

const BlockOptionsDropdownList = ({
	editor,
	blockType,
}: {
	editor: LexicalEditor;
	blockType: SupportedBlockTypesType;
}) => {
	const formatParagraph = () => {
		if (blockType !== 'paragraph') {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					$setBlocksType(selection, () => $createParagraphNode());
				}
			});
		}
	};

	const formatHeading = (headingType: Extract<SupportedBlockTypesType, 'h1' | 'h2' | 'h3' | 'h4' | 'h5'>) => {
		if (blockType !== headingType) {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					$setBlocksType(selection, () => $createHeadingNode(headingType));
				}
			});
		}
	};

	const formatBulletList = () => {
		if (blockType === 'ul') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-expect-error
			editor.dispatchCommand(REMOVE_LIST_COMMAND);
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-expect-error
			editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
		}
	};

	const formatNumberedList = () => {
		if (blockType === 'ol') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-expect-error
			editor.dispatchCommand(REMOVE_LIST_COMMAND);
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-expect-error
			editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
		}
	};

	const formatQuote = () => {
		if (blockType !== 'quote') {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					$setBlocksType(selection, () => $createQuoteNode());
				}
			});
		}
	};

	const formatCode = () => {
		if (blockType !== 'code') {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					$setBlocksType(selection, () => $createCodeNode());
				}
			});
		}
	};

	function handleChange(event: SelectChangeEvent<SupportedBlockTypesType>): void {
		const value = event.target.value as SupportedBlockTypesType;
		switch (value) {
			case 'ul': {
				formatBulletList();
				break;
			}
			case 'code': {
				formatCode();
				break;
			}
			case 'h1':
			case 'h2':
			case 'h3':
			case 'h4':
			case 'h5': {
				formatHeading(value);
				break;
			}
			case 'ol': {
				formatNumberedList();
				break;
			}
			case 'paragraph': {
				formatParagraph();
				break;
			}
			case 'quote': {
				formatQuote();
				break;
			}
		}
	}
	const { t: translate } = useTranslation(['common']);
	return (
		<FormControl sx={{ minWidth: 120 }} size='small'>
			<Select<SupportedBlockTypesType> value={blockType} onChange={handleChange}>
				{supportedBlockTypes.map((t) => (
					<MenuItem key={t} value={t}>
						{translate('richTextEditor.' + t)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const ToolbarPlugin = () => {
	const [editor] = useLexicalComposerContext();
	const [canUndo, setCanUndo] = useState(false);
	const [canRedo, setCanRedo] = useState(false);
	const [blockType, setBlockType] = useState<SupportedBlockTypesType>('paragraph');
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);
	const [isCode, setIsCode] = useState(false);
	const [elementFormat, setElementFormat] = useState<SupportedElementFormatType>('left');
	const { t } = useTranslation(['common']);
	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if (!$isRangeSelection(selection)) {
			return;
		}
		const anchorNode = selection.anchor.getNode();
		const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
		const elementKey = element.getKey();
		if (editor.getElementByKey(elementKey) !== null) {
			if ($isListNode(element)) {
				const parentList = $getNearestNodeOfType(anchorNode, ListNode);
				const type = parentList ? parentList.getTag() : element.getTag();
				setBlockType(type);
			} else {
				const type = $isHeadingNode(element) ? element.getTag() : element.getType();
				setBlockType(type);
			}
		}
		setIsBold(selection.hasFormat('bold'));
		setIsItalic(selection.hasFormat('italic'));
		setIsUnderline(selection.hasFormat('underline'));
		setIsCode(selection.hasFormat('code'));

		const node = getSelectedNode(selection);

		const formatType: SupportedElementFormatType = $isElementNode(node)
			? node?.getFormatType()
			: node.getParent()?.getFormatType();
		setElementFormat(formatType || 'left');
	}, [editor]);
	const UNDERLINE_STYLE = 'text-decoration: underline';
	useEffect(
		() =>
			mergeRegister(
				editor.registerUpdateListener(({ editorState }) => {
					editorState.read(() => {
						updateToolbar();
					});
				}),
				editor.registerCommand(
					SELECTION_CHANGE_COMMAND,
					(_payload, _newEditor) => {
						updateToolbar();
						return false;
					},
					LowPriority
				),
				editor.registerCommand(
					CAN_UNDO_COMMAND,
					(payload) => {
						setCanUndo(payload);
						return false;
					},
					LowPriority
				),
				editor.registerCommand(
					CAN_REDO_COMMAND,
					(payload) => {
						setCanRedo(payload);
						return false;
					},
					LowPriority
				),
				editor.registerNodeTransform(TextNode, (textNode) => {
					const style = textNode.getStyle();
					if (textNode.hasFormat('underline') && !style.includes(UNDERLINE_STYLE)) {
						textNode.setStyle(`${style}${UNDERLINE_STYLE}`);
					} else if (!textNode.hasFormat('underline') && style.includes(UNDERLINE_STYLE)) {
						textNode.setStyle(style.replace(UNDERLINE_STYLE, ''));
					}
				})
			),
		[editor, updateToolbar]
	);

	return (
		<Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
			<ButtonGroup size='small'>
				<IconButton
					disabled={!canUndo}
					onClick={() => {
						//@ts-expect-error - API is wrong
						editor.dispatchCommand(UNDO_COMMAND);
					}}
					aria-label={t('richTextEditor.undo')}
					data-testid='undo-button'
				>
					<UndoOutlined />
				</IconButton>
				<IconButton
					disabled={!canRedo}
					onClick={() => {
						//@ts-expect-error - API is wrong
						editor.dispatchCommand(REDO_COMMAND);
					}}
					aria-label={t('richTextEditor.redo')}
				>
					<RedoOutlined />
				</IconButton>
			</ButtonGroup>
			{supportedBlockTypes.includes(blockType) && <BlockOptionsDropdownList editor={editor} blockType={blockType} />}
			<ToggleButtonGroup size='small'>
				<ToggleButton
					onClick={() => {
						editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
					}}
					selected={isBold}
					aria-label={t('richTextEditor.bold')}
					value='bold'
				>
					<FormatBoldOutlined />
				</ToggleButton>
				<ToggleButton
					onClick={() => {
						editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
					}}
					selected={isItalic}
					aria-label={t('richTextEditor.italics')}
					value='italic'
				>
					<FormatItalicOutlined />
				</ToggleButton>
				<ToggleButton
					onClick={() => {
						editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
					}}
					selected={isUnderline}
					aria-label={t('richTextEditor.underline')}
					value='underline'
				>
					<FormatUnderlinedOutlined />
				</ToggleButton>
				<ToggleButton
					onClick={() => {
						editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
					}}
					aria-label={t('richTextEditor.horizontalRule')}
					value='horizontal-rule'
				>
					<HorizontalRuleOutlined />
				</ToggleButton>
				<ToggleButton
					onClick={() => {
						editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
					}}
					selected={isCode}
					aria-label={t('richTextEditor.insertCode')}
					value='code'
				>
					<CodeOutlined />
				</ToggleButton>
			</ToggleButtonGroup>
			<ToggleButtonGroup size='small' value={elementFormat} defaultValue={'left'}>
				{Object.entries(formatTypeToIcon).map(([key, icon]) => (
					<ToggleButton
						key={key}
						value={key}
						onClick={() => {
							editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, key as SupportedElementFormatType);
						}}
						aria-label={t('richTextEditor.' + key)}
						data-testid={key}
					>
						{icon}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</Stack>
	);
};

export { ToolbarPlugin };
