import React, { useState, useEffect } from 'react';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
 
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';

type HiddenInputProps = {
	inputData: string;
	display?: boolean;
	selfManaged?: boolean;
	'data-testid'?: string; // eslint-disable-line @typescript-eslint/naming-convention
};

const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
	event.preventDefault();
};

const HiddenInput = ({ inputData, display = false, selfManaged = false, ...other }: HiddenInputProps) => {
	const [showData, setShowData] = useState(display);
	const handleClickShowPassword = () => setShowData((show) => !show);

	useEffect(() => {
		setShowData(display);
	}, [display]);

	return (
		<Input
			type={showData ? 'text' : 'password'}
			value={inputData}
			disableUnderline={true}
			sx={{ flexGrow: 1 }}
			endAdornment={
				selfManaged && (
					<InputAdornment position='end'>
						<IconButton
							sx={{ flexGrow: 0 }}
							aria-label='toggle visibility'
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{showData ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
						</IconButton>
					</InputAdornment>
				)
			}
			{...other}
		/>
	);
};

export { HiddenInput };
