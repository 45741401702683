import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import IconWarningOutlined from '@tricentis/aura/components/IconWarningOutlined.js';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export type TestExecutionZoneIconProps = {
	defaultController: boolean;
	missingDedicatedIps?: number;
	controllerError: boolean;
};

const TestExecutionZoneIcon = ({
	defaultController,
	missingDedicatedIps,
	controllerError,
}: TestExecutionZoneIconProps) => {
	const theme = useTheme();
	const { t } = useTranslation(['test']);

	const getWarningTooltip = () => {
		if (defaultController && missingDedicatedIps === 1) {
			return t('testExecution.notEnoughDedicatedIp.tooltip.controller');
		}
		return t('testExecution.notEnoughDedicatedIp.tooltip.lg', { ips: missingDedicatedIps, count: missingDedicatedIps });
	};

	if (controllerError) {
		return <ErrorOutlineIcon sx={{ color: theme.palette.error.main }} role='alert' />;
	}

	if (missingDedicatedIps) {
		return (
			<Box
				sx={{
					paddingRight: 1,
					display: 'flex',
					justifyContent: 'end',
					alignItems: 'center',
				}}
			>
				<Tooltip arrow title={getWarningTooltip()}>
					<IconWarningOutlined sx={{ color: theme.palette.warning.main }} role='alert' />
				</Tooltip>
			</Box>
		);
	}
	return <Box />;
};

export { TestExecutionZoneIcon };
