import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SectionTitle from '@tricentis/aura/components/SectionTitle.js';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import { useTheme } from '@mui/material/styles';
import { getTranslationKeyToValidateDashboardName } from './dashboard-common';
import { DashboardHeaderMoreMenu } from './dashboard-header-more-menu';
import { TileCreationPopover } from './tiles/creation/tile-creation-popover';
import { useGetMe, useUrlSearchParams } from '@neoload/hooks';
import { CommonRoutes } from '@neoload/utils';

export type DashboardHeaderProps = {
	name: string;
	isShared: boolean;
	ownerId: string;
	deleteTilesEnabled?: boolean;
	onDuplicateDashboard: () => Promise<void>;
	onEditDashboardName: (name: string) => void;
	onChangeDashboardShareStatus: (isShared: boolean) => void;
	onDeleteDashboard: () => Promise<void>;
	onClearTiles: () => Promise<void>;
};
const DashboardHeader = ({
	name,
	isShared,
	ownerId,
	deleteTilesEnabled = true,
	onEditDashboardName,
	onDuplicateDashboard,
	onChangeDashboardShareStatus,
	onDeleteDashboard,
	onClearTiles,
}: DashboardHeaderProps) => {
	const { t } = useTranslation(['dashboard']);
	const theme = useTheme();
	const navigate = useNavigate();
	const styleFlexCenter = { display: 'flex', alignItems: 'center' };
	const [currentUser] = useGetMe();
	const [{ fromResult: resultId }] = useUrlSearchParams('fromResult');

	const isGuestAndNotOwner = ownerId !== currentUser.id && currentUser.role === 'GUEST';

	const baseHeaderProps = { deleteTilesEnabled, onDuplicateDashboard, onDeleteDashboard };
	const headerProps = { ...baseHeaderProps, onClearTiles };
	return (
		<Grid item xs='auto' sx={styleFlexCenter}>
			<Box sx={{ maxWidth: '1000px' }}>
				<SectionTitle
					onClickBack={(_event) => {
						if (resultId) {
							navigate(CommonRoutes.results.dashboards(resultId));
						} else {
							navigate(CommonRoutes.dashboards.base);
						}
					}}
					title={name}
					label={t('header.name')}
					editable={!isGuestAndNotOwner}
					slotProps={{
						input: {
							onApply: (updatedData: Record<string, string>) => {
								const updatedName = updatedData.source;
								if (updatedName === name) {
									return;
								}
								onEditDashboardName(updatedName);
							},
							validationRules: [
								(updatedName: string) => {
									const translationKey = getTranslationKeyToValidateDashboardName(updatedName);
									return translationKey ? t(translationKey) : false;
								},
							],
						},
					}}
				/>
			</Box>
			<Grid container justifyContent='flex-end' columnSpacing={theme.spacing(1)} alignItems='center'>
				<Grid item>
					{ownerId === currentUser.id && (
						<Button
							variant={'outlined'}
							size='medium'
							onClick={() => onChangeDashboardShareStatus(!isShared)}
							startIcon={<ShareOutlined />}
						>
							{isShared ? t('common:unshare') : t('common:share')}
						</Button>
					)}
				</Grid>
				{!isGuestAndNotOwner && (
					<Grid item>
						<TileCreationPopover />
					</Grid>
				)}
				<Grid item>
					<DashboardHeaderMoreMenu {...headerProps} isActionsVisible={!isGuestAndNotOwner} />
				</Grid>
			</Grid>
		</Grid>
	);
};
export { DashboardHeader };
