import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

type ScenarioRadioGroupProps = {
	disabled: boolean;
};

export enum ScenarioTypes {
	FromProject = 'fromProject',
	Custom = `custom`,
}

const ScenarioRadioGroup = ({ disabled = false }: ScenarioRadioGroupProps) => {
	const { t } = useTranslation(['test']);

	return (
		<Controller
			name='scenario.type'
			rules={{ required: true }}
			render={({ field }) => (
				<RadioGroup
					{...field}
					row
					sx={{
						marginBottom: 2,
						marginLeft: 2,
					}}
				>
					<FormControlLabel
						value={ScenarioTypes.Custom}
						control={<Radio />}
						label={t('configuration.scenarios.types.custom')}
						disabled={disabled}
					/>
					<FormControlLabel
						value={ScenarioTypes.FromProject}
						control={<Radio />}
						label={t('configuration.scenarios.types.fromProject')}
						disabled={disabled}
					/>
				</RadioGroup>
			)}
		/>
	);
};

export { ScenarioRadioGroup };
