import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useConfirmModal, useCrudBatchMessage } from '../modals';
import { useDeleteV4ResultsByResultIdIntervalsAndIntervalIdMutation } from '@neoload/api';

/**
 * Provide a function that opens the delete Interval modal, and when confirm do API call to delete the selected intervals
 *
 * @returns the delete function
 */
const useDeleteIntervals = () => {
	const { openDeleteConfirmModal } = useConfirmModal();
	const { t } = useTranslation(['result']);
	const { remove } = useCrudBatchMessage('result', 'intervals');
	const [deleteInterval] = useDeleteV4ResultsByResultIdIntervalsAndIntervalIdMutation();

	return useCallback(
		(intervalsToDelete: string[], resultId: string) => {
			const onDeleteIntervals = async (intervalIds: string[], resultId: string) => {
				await remove(intervalIds, (intervalId) =>
					deleteInterval({ intervalId: intervalId, resultId: resultId }).unwrap()
				);
			};
			const title = t('intervals.delete.title', { count: intervalsToDelete.length });
			const content = t('intervals.delete.content', { count: intervalsToDelete.length });

			openDeleteConfirmModal({
				title,
				content,
				handleConfirm: async () => onDeleteIntervals(intervalsToDelete, resultId),
			});
		},
		[deleteInterval, openDeleteConfirmModal, remove, t]
	);
};

export { useDeleteIntervals };
