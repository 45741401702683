import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AccountOutlined from '@mui/icons-material/AccountCircleOutlined';
import { CommonRoutes, callCompanion } from '@neoload/utils';
import { nlwApiV4, store, useDeleteV4SessionsMutation } from '@neoload/api';
import { useGetMe } from '@neoload/hooks';

type AccountMenuProps = {
	anchorEl?: HTMLElement | null;
	open?: boolean;
	handleClose: () => void;
};

const AccountMenu = ({ anchorEl = null, open = false, handleClose }: AccountMenuProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation('menu');
	const [deleteSessionMutation, postDeleteSessionMutation] = useDeleteV4SessionsMutation();
	const theme = useTheme();

	useEffect(() => {
		if (postDeleteSessionMutation.isSuccess) {
			store.dispatch(nlwApiV4.util.resetApiState());
			navigate(CommonRoutes.login.base);
		}
	}, [postDeleteSessionMutation.isSuccess, navigate]);

	const [{ email, firstName, lastName }] = useGetMe();

	const logout = () => {
		deleteSessionMutation();
		callCompanion('reset');
	};

	return (
		<Menu
			id='basic-menu'
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
			data-testid='account-menu'
			sx={{
				zIndex: 9_999_999,
			}}
		>
			<MenuItem data-testid='account-user' style={{ pointerEvents: 'none' }}>
				<ListItemText>
					<Typography variant='body1'>{firstName + ' ' + lastName}</Typography>
					<Typography variant='body2' color={theme.palette.text.secondary}>
						{email}
					</Typography>
				</ListItemText>
			</MenuItem>
			<Divider />
			<MenuItem data-testid='account-menu-profile' component={Link} to={CommonRoutes.user.accessTokens}>
				<ListItemIcon>
					<AccountOutlined fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('menu:profile')}</ListItemText>
			</MenuItem>
			<MenuItem onClick={logout} data-testid='account-menu-logout'>
				<ListItemIcon>
					<LogoutIcon fontSize='small' />
				</ListItemIcon>
				<ListItemText>{t('menu:logout')}</ListItemText>
			</MenuItem>
		</Menu>
	);
};

export { AccountMenu };
