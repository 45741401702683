import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ValuesFilter } from './values/values-tab/values-filter';
import { getRenderValue, listCellRenderer } from './search-interval-combobox/interval-combobox-utils';
import { Interval } from '@neoload/api';

export type IntervalFilterProps = {
	intervals: Interval[];
	resultDuration: string;
};

const IntervalFilter = ({ intervals, resultDuration }: IntervalFilterProps) => {
	const { t } = useTranslation(['result']);
	const theme = useTheme();
	return (
		<ValuesFilter
			key={'intervals'}
			title={'filters.intervals'}
			options={intervals.map((interval) => interval.id)}
			paramName={'intervalId'}
			listCellRenderer={listCellRenderer(intervals, t, theme, resultDuration)}
			renderValue={getRenderValue(intervals, t)}
		/>
	);
};

export { IntervalFilter };
