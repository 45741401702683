const common = {
	ok: 'OK',
	delete: 'Delete',
	remove: 'Remove',
	share: 'Share',
	unshare: 'Unshare',
	add: 'Add',
	decrease: 'Decrease',
	increase: 'Increase',
	refresh: 'Refresh',
	duplicate: 'Duplicate',
	cancel: 'Cancel',
	edit: 'Edit',
	save: 'Save',
	saveAnyway: 'Save anyway',
	close: 'Close',
	users: 'Users',
	downloadCsv: 'Export to CSV',
	apply: 'Apply',
	description: 'Description',
	value: 'Value',
	expand: 'Expand',
	collapse: 'Collapse',
	title: 'Title',
	name: 'Name',
	errors: {
		forbidden: {
			altText: 'Error Forbidden',
			title: '403: Ay! Forbidden access',
			content: 'Sorry, you seem to be trying to view a page with restricted access.',
		},
		notFound: {
			altText: 'Error Not Found',
			title: '404: Ay! Page not found',
			content: "Sorry, the page you are trying to view either doesn't exist, or is unavailable at the moment.",
		},
		serverError: {
			altText: 'Server Error',
			title: '{{httpCode}}: Ay! Unknown error',
			content:
				"Sorry, we don't know what happened. Try to reload this page. If the problem persists, please contact us.",
		},
		serverNotReached: {
			title: 'Ay! Unable to contact the server',
			content:
				"Sorry, we don't know what happened. Check your internet connection then try to reload this page. If the problem persists, please contact us.",
		},
		otherError: {
			title: 'Ay! Unexpected Error',
			content: "Sorry, we don't know what happened. Try again. If the problem persists, please contact us.",
		},
		customMessages: {
			badIdFormat: 'Mistyped address? Please check the URL.',
		},
		buttons: {
			home: 'Back home',
			reload: 'Reload page',
		},
		workInProgress: {
			title: 'Feature under development',
			content: "We're working on this feature. Stay tuned for updates in a future version.",
		},
	},
	datagrid: {
		noContent: {
			title: 'Ay! No results',
			content:
				'This {{modifier}} did not find anything. Check if you entered the value correctly. Alternatively, try to modify the {{modifier}} settings.',
			filter: 'filter',
			search: 'search',
		},
	},
	chart: {
		noData: 'No data to display',
		loading: 'Waiting for data...',
	},
	zeroState: {
		noResultsFound: 'No results found',
	},
	kpi: {
		avgDuration: 'Avg. duration',
		elementsPerSec: 'Element/s',
		passed: 'Passed',
		failed: 'Failed',
		warning: 'Warning',
	},
	summary: {
		result: 'Result',
		status: 'Status',
		startDate: 'Start date',
		endDate: 'End date',
		duration: 'Duration',
		test: 'Test',
		project: 'Project',
		scenario: 'Scenario',
	},
	time: {
		day_one: '{{count}} day',
		day_other: '{{count}} days',
	},
	input: {
		characters: 'characters',
		required: 'Value is required'
	},
	colorPicker: {
		title: 'Change color: {{color}}',
	},
	richTextEditor: {
		undo: 'Undo',
		redo: 'Redo',
		code: 'Code Block',
		h1: 'H1 Heading',
		h2: 'H2 Heading',
		h3: 'H3 Heading',
		h4: 'H4 Heading',
		h5: 'H5 Heading',
		ol: 'Numbered List',
		paragraph: 'Normal',
		quote: 'Quote',
		ul: 'Bulleted List',
		bold: 'Format Bold',
		italics: 'Format Italics',
		underline: 'Format Underline',
		horizontalRule: 'Horizontal Rule',
		insertCode: 'Insert Code',
		left: 'Left align',
		center: 'Center align',
		right: 'Right align',
		justify: 'Justify align',
	},
	goBack: 'Go back',
	duration: {
		minutes: {
			small: 'm',
		},
	},
	statistics: {
		userPathElementValueStatistics: {
			/* eslint-disable @typescript-eslint/naming-convention */
			DURATION_PERCENTILE_99: 'Duration percentile 99th',
			DURATION_PERCENTILE_95: 'Duration percentile 95th',
			DURATION_PERCENTILE_90: 'Duration percentile 90th',
			DURATION_PERCENTILE_50: 'Duration percentile 50th',
			MINIMUM_DURATION: 'Minimum duration',
			AVERAGE_DURATION: 'Average duration',
			MAXIMUM_DURATION: 'Maximum duration',
			ELEMENT_COUNT: 'Element count',
			ELEMENTS_PER_SECOND: 'Elements per second',
			ERROR_COUNT: 'Error count',
			ERROR_RATE: 'Error rate',
			MINIMUM_TTFB: 'Minimum TTFB',
			AVERAGE_TTFB: 'Average TTFB',
			MAXIMUM_TTFB: 'Maximum TTFB',
			/* eslint-enable @typescript-eslint/naming-convention */
		},
	},
	allTransactions: 'All transactions',
};
export { common };
