import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

export type LimitedSizeTextFieldProps = {
	max: number;
	requiredHelperText?: string;
	'data-testid'?: string; // eslint-disable-line @typescript-eslint/naming-convention
} & TextFieldProps;

const LimitedSizeTextField = ({ max, helperText, requiredHelperText, ...other }: LimitedSizeTextFieldProps) => {
	const { t } = useTranslation(['common']);

	const localValue = (other.value as string) || '';
	let helper = helperText || `${localValue.length}/${max} ${t('input.characters')}`;

	if (other.required && localValue.length === 0) {
		helper = requiredHelperText ?? t('input.required');
	}

	return <TextField InputProps={{ inputProps: { maxLength: max } }} helperText={helper} {...other} />;
};

export { LimitedSizeTextField };
