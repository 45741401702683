import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { useFormContext, useWatch } from 'react-hook-form';
import { TestExecutionTestConfig } from './test-execution-test-config';
import { TestExecutionLicenseAllocation } from './test-execution-license-allocation';
import { TestExecutionErrorTypes, TestExecutionForm } from '../types.d';

const TestExecutionScenario = () => {
	const theme = useTheme();
	const { getValues } = useFormContext<TestExecutionForm>();
	const errors = useWatch<TestExecutionForm, 'errors'>({ name: 'errors' });
	const reservationId = useWatch<TestExecutionForm, 'resources.reservationId'>({ name: 'resources.reservationId' });
	const errorScenario = errors.filter((errors) => errors.type === TestExecutionErrorTypes.Scenario);
	const displayLicenseAllocation = !(
		!reservationId &&
		getValues('resources.zoneType') === 'STATIC' &&
		getValues('resources.webVu.needed') === 0 &&
		getValues('resources.sapVu.needed') === 0 &&
		errors.length === 0
	);

	return (
		<Box>
			<Stack useFlexGap gap={theme.spacing(3)} flexDirection='row' width='100%'>
				<TestExecutionTestConfig errorScenario={errorScenario.length > 0} />
				{displayLicenseAllocation && (
					<>
						<Divider orientation='vertical' sx={{ height: 'auto' }} />
						<TestExecutionLicenseAllocation />
					</>
				)}
			</Stack>
			{errorScenario.length > 0 && (
				<Alert sx={{ marginTop: 2 }} severity='error'>
					<Typography variant='body2'>{errorScenario[0].sentence}</Typography>
				</Alert>
			)}
		</Box>
	);
};

export { TestExecutionScenario };
