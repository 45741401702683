import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import { KeyboardEvent } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { MonitorComparisonRow, UserPathElementStatistic, UserPathElementValueStatistic } from '@neoload/api';
export type MonitorComparisonStatistic = MonitorComparisonRow['statistic'];
export type Statistic = UserPathElementStatistic | UserPathElementValueStatistic | MonitorComparisonStatistic;

export const StatsSelectionList = <T extends Statistic>({
	multiple = false,
	statisticPrefix,
	availablesStatistics,
	value,
	onUpdate,
}: {
	multiple?: boolean;
	statisticPrefix: string;
	availablesStatistics: T[];
	value: T[];
	onUpdate: (values: T[]) => void;
}) => {
	const { t } = useTranslation(['dashboard']);

	const onKeyDown = (event: KeyboardEvent) => {
		if (value.length === 0 || multiple) {
			return;
		}
		const index = availablesStatistics.indexOf(value[0]);

		if (event.code === 'ArrowUp' && index !== 0) {
			onUpdate([availablesStatistics[index - 1]]);
		}
		if (event.code === 'ArrowDown' && index < availablesStatistics.length - 1) {
			onUpdate([availablesStatistics[index + 1]]);
		}
	};
	const onToggle = (stat: T) => {
		if (multiple) {
			onUpdate(value.includes(stat) ? value.filter((s) => s !== stat) : [...value, stat]);
		} else {
			onUpdate([stat]);
		}
	};
	if (availablesStatistics.length === 0) {
		return null;
	}
	return (
		<List
			aria-label='Statistics'
			dense={true}
			sx={{
				width: '100%',
			}}
			onKeyDown={onKeyDown}
		>
			{availablesStatistics.map((stat: T) => (
				<ListItemButton
					disableRipple
					key={stat}
					selected={value.includes(stat)}
					onClick={(_) => {
						onToggle(stat);
					}}
					data-value={stat}
				>
					<ListItemText primary={t(`${statisticPrefix}.${stat}`)} />
				</ListItemButton>
			))}
		</List>
	);
};
