import IconButton from '@mui/material/IconButton';
import Tooltip from '@tricentis/aura/components/Tooltip.js';
import { DatagridAction, toSingleItemAction } from './data-grid-actions';

export type DatagridActionCellProps = {
	actions: DatagridAction[];
	rowData: unknown;
}
const ActionsCell = ({ actions, rowData }: DatagridActionCellProps) => (
	<div>
		{actions.map((actionProps) => {
			const { icon, text, disabled, action, sx, hidden } = toSingleItemAction(actionProps, rowData);
			if (hidden) {
				return null;
			}
			return (
				<Tooltip key={text} title={text}>
					<IconButton
						disabled={disabled}
						sx={sx}
						aria-label={text}
						onClick={() => {
							action();
						}}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
							event.preventDefault();
						}}
					>
						{icon}
					</IconButton>
				</Tooltip>
			);
		})}
	</div>
);

export { ActionsCell };
