import { useTranslation } from 'react-i18next';
import { TilesGrid, TilesGridCell, TileSize } from './tiles-grid';

export type KpiTilesGridProps = {
	averageDuration: string;
	countPerSecond: string;
	totalPassed: string;
	totalFailed: string;
};

const getTileSize = (columnRatio: number, rowRatio: number, index: number): TileSize => {
	if (columnRatio === rowRatio) {
		return 'halfWidth';
	}
	if (columnRatio < rowRatio) {
		return 'fullWidth';
	}
	if (index === 0) {
		return 'fullWidth';
	}
	return 'thirdWidth';
};

export const KpiTilesGrid = ({ averageDuration, countPerSecond, totalPassed, totalFailed }: KpiTilesGridProps) => {
	const { t } = useTranslation(['common']);
	const cells: TilesGridCell[] = [
		{
			uid: '1',
			value: averageDuration,
			label: t('kpi.avgDuration'),
			highlight: true,
		},
		{
			uid: '2',
			value: countPerSecond,
			label: t('kpi.elementsPerSec'),
		},
		{
			uid: '3',
			typographyProps: { color: 'success' },
			value: totalPassed,
			label: t('kpi.passed'),
		},
		{
			uid: '4',
			typographyProps: { color: 'error' },
			value: totalFailed,
			label: t('kpi.failed'),
		},
	];
	return <TilesGrid cells={cells} getTileSize={getTileSize} />;
};
