import { ErrorsByCodeFetcher } from './errors-by-code-fetcher';
import { ErrorsByLgFetcher } from './errors-by-lg-fetcher';
import { SlasPerIntervalFetcher } from './slas-per-interval-fetcher';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { TopDurationFetcher } from './top-duration-fetcher';
import { ErrorsByElementFetcher } from './errors-by-element-fetcher';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { WidgetDashboardTile } from '@neoload/api';

type TableFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

export const _TableFetcher = ({ widgetTile }: TableFetcherProps) => {
	switch (widgetTile.filter.type) {
		case 'ELEMENTS_VALUES': {
			return <TopDurationFetcher widgetTile={widgetTile} />;
		}
		case 'ERRORS_COUNT': {
			switch (widgetTile.filter.aggregationType) {
				case 'CODE': {
					return <ErrorsByCodeFetcher widgetTile={widgetTile} />;
				}
				case 'LG_NAME': {
					return <ErrorsByLgFetcher widgetTile={widgetTile} />;
				}
				case 'ELEMENT_ID': {
					return <ErrorsByElementFetcher widgetTile={widgetTile} />;
				}
				default: {
					return <DashboardTileErrorContent />;
				}
			}
		}
		case 'SLA_PER_INTERVAL': {
			return <SlasPerIntervalFetcher widgetTile={widgetTile} />;
		}
		default: {
			return <DashboardTileErrorContent />;
		}
	}
};

export const TableFetcher = withLazyFetching(_TableFetcher);
