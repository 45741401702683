import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { AppBar, ApplicationLoader, DocumentTitleHandler, Modal, StackedSnackbars } from '@neoload/ui';
import { HomeRoutes } from '@neoload/utils';
import { useGetMe } from '@neoload/hooks';

export type MenuItem = {
	title: string;
	icon: React.JSX.Element;
	link: string;
	enabled?: boolean;
};

type AbstractSettingsLayoutProps = {
	title: string;
	menuItems: MenuItem[];
};

const Layout = ({ title, menuItems = [] }: AbstractSettingsLayoutProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const { t } = useTranslation(['common']);

	const outerLayoutHeight = `calc(100vh - ${theme.constants.appBar.height.xl}px)`;
	//prettier-ignore
	const innerLayoutHeight = `calc(100vh - ${theme.constants.appBar.height.xl}px - ${theme.spacing(6)})`;
	return (
		<>
			<AppBar withWorkspaceSelector={false} />
			<Box
				sx={{
					paddingTop: '40px',
					width: '100%',
					display: 'grid',
					gridTemplateColumns: '1fr',
					gridTemplateRows: '39px 1fr',
					rowGap: '1px',
					height: '100vh',
					backgroundColor: theme.palette.background.default,
				}}
			>
				<Box
					sx={{
						height: outerLayoutHeight,
						padding: theme.spacing(3),
					}}
				>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'minmax(256px, max-content) 1fr',
							gap: '16px',
							height: innerLayoutHeight,
						}}
					>
						<Paper>
							<Box>
								<Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 1.5 }}>
									<Tooltip title={t('goBack')} arrow>
										<IconButton
											sx={{ p: 1 }}
											onClick={(_event) => (location.key === 'default' ? navigate(HomeRoutes.base) : navigate(-1))}
										>
											<ArrowBackOutlined />
										</IconButton>
									</Tooltip>
									<Typography variant='subtitle1'>{title}</Typography>
								</Box>
								<Divider />
								<List>
									{menuItems
										.filter((value) => value.enabled === undefined || value.enabled)
										.map(({ title, icon, link }) => {
											const isActive = link !== '' && location.pathname.endsWith(link);
											return (
												<ListItem key={title} disablePadding sx={{ display: 'block' }}>
													<ListItemButton
														selected={isActive}
														sx={{
															minHeight: 48,
															justifyContent: 'initial',
															px: 2.5,
															...(isActive && {
																backgroundColor: 'red',
															}),
														}}
														onClick={link === '' ? undefined : () => navigate(link, { replace: true })}
													>
														<ListItemIcon
															sx={{
																minWidth: 0,
																mr: 'auto',
																justifyContent: 'center',
																...(isActive && { color: theme.palette.primary.main }),
																paddingRight: '10px',
															}}
														>
															{icon}
														</ListItemIcon>
														<ListItemText
															primary={title}
															sx={{ ...(isActive && { color: theme.palette.primary.main }) }}
														/>
													</ListItemButton>
												</ListItem>
											);
										})}
								</List>
							</Box>
						</Paper>
						<Paper>
							<Box sx={{ height: innerLayoutHeight }}>
								<Outlet />
							</Box>
						</Paper>
					</Box>
				</Box>
			</Box>
			<DocumentTitleHandler />
			<Modal />
			<StackedSnackbars />
		</>
	);
};

export const AbstractSettingsLayout = (props: AbstractSettingsLayoutProps) => {
	const [{ isLoaded }] = useGetMe();
	return isLoaded ? <Layout {...props} /> : <ApplicationLoader />;
};
