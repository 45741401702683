import Divider from '@mui/material/Divider';
import { FormProvider, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import { AddSeriesElementSelector } from './add-series-element-selector';
import { MonitorComparisonStatistic, Statistic } from './statistics';
import { SearchResultCombo } from '../../../../results/search-result-combo';
import { ComparisonSerieDefaultValue } from '../form/comparison/row/use-find-in-tree';
import { MonitorElement, TestResult, useGetV4ResultsByResultIdQuery, UserPathElement } from '@neoload/api';
import { useUrlSearchParams } from '@neoload/hooks';

export type UserPathFormData<T extends Statistic> = {
	type: 'USERPATH';
	testResult: TestResult;
	userPathElement: UserPathElement;
	userPath: string[];
	userPathId: string;
	stats: T[];
	rootElementType: UserPathElement['type'];
};

export type MonitorFormData = {
	type: 'MONITOR';
	testResult: TestResult;
	monitor: MonitorElement & { path: string[] };
	comparisonStat?: MonitorComparisonStatistic;
};

export type AddSeriesFormData<T extends Statistic> = Partial<UserPathFormData<T>> | Partial<MonitorFormData>;
export type SeriesData<T extends Statistic> = Required<UserPathFormData<T>> | Required<MonitorFormData>;

export type AddSeriesFormProps<T extends Statistic> = {
	onApply?: (data: SeriesData<T>) => void;
	onCancel?: () => void;
	multiple?: boolean;
	comparison?: boolean;
	resultId?: string;
	defaultValue?: ComparisonSerieDefaultValue<T>;
};
export const AddSeriesForm = <T extends Statistic>({
	onApply,
	onCancel,
	multiple,
	comparison,
	resultId = '',
	defaultValue,
}: AddSeriesFormProps<T>) => {
	const { handleSubmit, watch, setValue, ...methods } = useForm<AddSeriesFormData<T>>({
		mode: 'onChange',
		...(defaultValue ? { values: defaultValue } : {}),
	});
	const { t } = useTranslation(['dashboard']);
	const [{ fromResult }] = useUrlSearchParams('fromResult');
	const { data: result } = useGetV4ResultsByResultIdQuery({ resultId }, { skip: !resultId });
	const [wTestResult, wUserPathElement, wStats, wType, wMonitor, wComparisonStat] = watch([
		'testResult',
		'userPathElement',
		'stats',
		'type',
		'monitor',
		'comparisonStat',
	]);

	const canApply = () =>
		(wType === 'USERPATH' && wTestResult && wUserPathElement && wStats?.[0]) ||
		(wType === 'MONITOR' && wMonitor && wMonitor.drawable && (!comparison || wComparisonStat));

	const toSeriesData = <T extends Statistic>(formData: AddSeriesFormData<T>): SeriesData<T> => {
		const { type, testResult } = formData;
		if (type === 'USERPATH' && testResult) {
			const { userPathElement, userPathId, stats, userPath, rootElementType } = formData;
			if (!userPathElement || !stats || !rootElementType) {
				throw new Error('Form data not valid');
			}
			return {
				type: type,
				testResult: testResult,
				userPathElement: userPathElement,
				userPath: userPath ?? [],
				userPathId: userPathId ?? userPathElement.id,
				rootElementType: rootElementType,
				stats: stats,
			};
		} else if (type === 'MONITOR' && testResult) {
			const { monitor, comparisonStat } = formData;
			if (!monitor) {
				throw new Error('Form data not valid');
			}
			return {
				type: type,
				testResult: testResult,
				monitor: monitor,
				comparisonStat: comparisonStat ?? 'MIN',
			};
		} else {
			throw new Error('Form data not valid');
		}
	};

	const _onResultSelected = useCallback(
		(testResult: TestResult) => {
			setValue('testResult', testResult);
		},
		[setValue]
	);
	useEffect(() => {
		if (result) {
			setValue('testResult', result);
		}
	}, [setValue, result]);

	return (
		<form
			aria-label={t('tile.edition.addSeries.accessibleTitle')}
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
			onSubmit={handleSubmit((form) => {
				onApply?.(toSeriesData(form));
			})}
			onReset={() => onCancel?.()}
		>
			<FormProvider {...methods} setValue={setValue} handleSubmit={handleSubmit} watch={watch}>
				<Stack height={'100%'}>
					<Stack
						sx={{
							flex: 1,
							overflow: 'hidden',
						}}
					>
						<Box padding={2}>
							<SearchResultCombo
								onResultSelected={_onResultSelected}
								initialResultId={resultId || fromResult}
								disabled={!!resultId}
							/>
						</Box>
						<Divider />
						<AddSeriesElementSelector comparison={comparison} multiple={multiple} defaultValue={defaultValue} />
					</Stack>
					<Stack direction={'row'} spacing={1} padding={2}>
						<Button type='submit' data-testid='add-serie-apply' variant='contained' disabled={!canApply()}>
							{t('common:apply')} {wStats && wStats.length > 1 && `(${wStats.length})`}
						</Button>
						<Button type='reset' data-testid='add-serie-cancel' color='primary'>
							{t('common:cancel')}
						</Button>
					</Stack>
				</Stack>
			</FormProvider>
		</form>
	);
};
