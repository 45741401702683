import { GridValueFormatterParams } from '@mui/x-data-grid';
import { timeUtils } from '@neoload/utils';

const getDateValue = (params: GridValueFormatterParams<unknown>): string => {
	const value = params.value;
	if (value === undefined) {
		return '';
	}
	if (value === null || typeof value === 'number' || typeof value === 'string' || value instanceof Date) {
		return timeUtils.dateTimeAbsolute(value);
	} else {
		throw new Error(`Invalid date rendering for column ${params.field}.`);
	}
};

export { getDateValue };
