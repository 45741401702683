import { Location, matchRoutes } from 'react-router';
import { ResultRoutes } from '../routes/results';
import { DashboardRoutes } from '../routes/dashboards';
import { TestRoutes } from '../routes/tests';

/**
 * define if the app must redirect to Home from current location when switching workspace
 * @param location current url
 */
export const shouldRedirectToHome = (location: Partial<Location> | string) => {
	const matchedRoutes = matchRoutes(
		[
			{ path: ResultRoutes.patterns.byId },
			{ path: ResultRoutes.patterns.byIdWithTab },
			{ path: ResultRoutes.patterns.byIdWithTabWithSubTab },
			{ path: DashboardRoutes.patterns.byId },
			{ path: TestRoutes.patterns.byId },
			{ path: TestRoutes.patterns.byIdConfigure },
		],
		location
	);
	return matchedRoutes && matchRoutes.length > 0;
};
