import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { useMeasure } from 'react-use';
import { useTheme } from '@mui/material/styles';

type TextTilesGridCell = {
	value: string | number;
	renderValue?: never;
	typographyProps?: Pick<TypographyProps, 'color'>;
};

type ComponentTilesGridCell = {
	value?: never;
	renderValue: () => React.ReactNode;
	typographyProps?: never;
};

export type BaseTilesGridCell = {
	uid: string;
	label: string;
	highlight?: boolean;
};

export type TilesGridCell = BaseTilesGridCell & (TextTilesGridCell | ComponentTilesGridCell);

export type TilesGridProps = {
	cells: TilesGridCell[];
	getTileSize: (index: number, width: number, height: number) => TileSize;
};

const styles = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

const Cell = ({ value, label, highlight = false, renderValue, fontSize }: TilesGridCell & { fontSize: number }) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: theme.palette.background.default,
				color: 'inherit',
				borderRadius: '4px',
			}}
			data-tile-grid-cell
		>
			<Box
				sx={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
				}}
			>
				<Typography
					{...styles}
					variant='body2'
					color='inherit'
					fontSize={fontSize - 1}
					whiteSpace={'nowrap'}
					textAlign={'center'}
					fontWeight={'auto'}
					data-tile-grid-label
				>
					{label}
				</Typography>

				{renderValue ? (
					<Box fontSize={fontSize} {...styles} whiteSpace={'nowrap'} textAlign={'center'}>
						{renderValue()}
					</Box>
				) : (
					<Typography
						{...styles}
						variant='body2'
						color='text.primary'
						fontSize={fontSize}
						whiteSpace={'nowrap'}
						textAlign={'center'}
						fontWeight={highlight ? 'bold' : 'auto'}
						data-tile-grid-value
					>
						{value}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
const getFontSize = (width: number): number => {
	if (width < 350) {
		return 12;
	}
	if (width < 650) {
		return 14;
	}
	return 16;
};

const totalCellCount = 6;

const columnsPerCell = {
	fullWidth: totalCellCount,
	halfWidth: totalCellCount / 2,
	thirdWidth: totalCellCount / 3,
};

export type TileSize = keyof typeof columnsPerCell;

export const TilesGrid = ({ cells, getTileSize }: TilesGridProps) => {
	const [ref, { width, height }] = useMeasure<HTMLDivElement>();
	return (
		<Grid ref={ref} container columns={totalCellCount} rowGap={1} columnSpacing={1} height={'100%'} paddingX={'16px'}>
			{cells.map((cell, index) => {
				const columnRatio = Math.floor(width / 450);
				const rowRatio = Math.floor(height / 200);
				const nCols = columnsPerCell[getTileSize(columnRatio, rowRatio, index)];
				return (
					<Grid item xs={nCols} key={cell.uid}>
						<Cell {...cell} fontSize={getFontSize(width)} />
					</Grid>
				);
			})}
		</Grid>
	);
};
