import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import {
	DashboardHeader,
	DashboardTilesArea,
	findNextPosition,
	NewSeriesFormFields,
	SeriesToEditFormFields,
	Spinner,
	TileEditionFormFields,
	TileLayout,
} from '@neoload/ui';
import { checkObjectId, CommonRoutes, createNeoLoadError, DashboardGenericParams } from '@neoload/utils';
import {
	DashboardPatchRequest,
	DashboardSeries,
	DashboardSeriesPostRequest,
	DashboardTile,
	SeriesDashboardTile,
	useDeleteV4DashboardsByDashboardIdMutation,
	useDeleteV4DashboardsByDashboardIdTilesAndDashboardTileIdMutation,
	useDeleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation,
	useDeleteV4DashboardsByDashboardIdTilesMutation,
	useGetV4DashboardsByDashboardIdQuery,
	useGetV4DashboardsByDashboardIdTilesQuery,
	usePatchV4DashboardsByDashboardIdMutation,
	usePatchV4DashboardsByDashboardIdTilesAndDashboardTileIdMutation,
	usePatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation,
	usePatchV4DashboardsByDashboardIdTilesMutation,
	usePostV4DashboardsByDashboardIdDuplicationMutation,
	usePostV4DashboardsByDashboardIdTilesAndTileIdSeriesMutation,
	usePostV4DashboardsByDashboardIdTilesMutation,
} from '@neoload/api';
import { useConfirmModal, usePushDocumentTitleHandler, useSetSnackbars, useUrlSearchParams } from '@neoload/hooks';

type ShareStatus = Exclude<DashboardPatchRequest['shareStatus'], 'SHARED_READ'>;

const DashboardPage = () => {
	const { dashboardId = '' } = useParams<DashboardGenericParams>();
	checkObjectId(dashboardId);
	const navigate = useNavigate();
	const { data: dashboard, error: dashboardError, isLoading } = useGetV4DashboardsByDashboardIdQuery({ dashboardId });
	const {
		data: tilesData = { items: [] },
		error: tilesLoadError,
		isLoading: isTilesLoading,
	} = useGetV4DashboardsByDashboardIdTilesQuery({ dashboardId });

	const { t } = useTranslation(['dashboard']);
	const { showError, showInfo } = useSetSnackbars();
	const [{ scrollToTile: currentTileIdParam }, setUrlSearchParams] = useUrlSearchParams('scrollToTile', 'tileToEdit');
	const [tileIdScrolled, setTileIdScrolled] = useState<string>('');
	const { openDeleteConfirmModal } = useConfirmModal();
	const [updateDashboard] = usePatchV4DashboardsByDashboardIdMutation();
	const [deleteDashboard] = useDeleteV4DashboardsByDashboardIdMutation();
	const [duplicateDashboard] = usePostV4DashboardsByDashboardIdDuplicationMutation();
	const [deleteDashboardTile] = useDeleteV4DashboardsByDashboardIdTilesAndDashboardTileIdMutation();
	const [deleteAllDashboardTiles] = useDeleteV4DashboardsByDashboardIdTilesMutation();
	const [updateDashboardLayout] = usePatchV4DashboardsByDashboardIdTilesMutation();
	const [updateDashboardTileSeries] = usePatchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation();
	const [deleteDashboardTileSeries] = useDeleteV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesIdMutation();
	const [updateDashboardTile] = usePatchV4DashboardsByDashboardIdTilesAndDashboardTileIdMutation();
	const [createDashboardTileSeries] = usePostV4DashboardsByDashboardIdTilesAndTileIdSeriesMutation();
	const [createDashboardTile] = usePostV4DashboardsByDashboardIdTilesMutation();

	useEffect(() => {
		if (currentTileIdParam && tileIdScrolled !== currentTileIdParam) {
			const element = document.querySelector('[data-tile-id="' + currentTileIdParam + '"]');
			if (element) {
				element.scrollIntoView({ behavior: 'instant' });
				setTileIdScrolled(currentTileIdParam);
			}
		}
	}, [currentTileIdParam, tileIdScrolled, tilesData.items]);

	const onEditDashboardName = (name: string) => {
		updateDashboard({ dashboardId, dashboardPatchRequest: { name: name } })
			.unwrap()
			.then(() => showInfo({ text: t('messages.updateNameSuccess') }))
			.catch((error: Response) => {
				console.error('error on onEditDashboardName', error);
				showError({ text: error.status === 409 ? t('validation.name.alreadyExists') : t('messages.updateNameError') });
			});
	};

	const onDuplicateDashboard = async () => {
		await duplicateDashboard({ dashboardId, dashboardDuplicateRequest: {} })
			.unwrap()
			.then((dashboardDuplicated) => {
				showInfo({ text: t('messages.duplicateSuccess') });
				navigate(CommonRoutes.dashboards.dashboard(dashboardDuplicated.id));
			})
			.catch((_error) => {
				console.error('error on onDuplicateDashboard', _error);
				showError({ text: t('messages.duplicateError') });
			});
	};
	const onChangeDashboardShareStatus = (isShared: boolean) => {
		const shareStatus: ShareStatus = isShared ? 'SHARED_WRITE' : 'UNSHARED';
		updateDashboard({ dashboardId, dashboardPatchRequest: { shareStatus: shareStatus } })
			.unwrap()
			.then(() => {
				isShared
					? showInfo({ text: t('messages.updateShareStatusSuccess') })
					: showInfo({ text: t('messages.updateUnshareStatusSuccess') });
			})
			.catch((error: unknown) => {
				console.error('error on onChangeDashboardShareStatus', error);
				showError({ text: t('messages.updateShareStatusError') });
			});
	};

	const onDeleteDashboard = async () => {
		try {
			openDeleteConfirmModal({
				title: t('dashboard:deleteSingleDashboardDialog.title'),
				content: t('dashboard:deleteSingleDashboardDialog.label'),
				handleConfirm: async () => {
					await deleteDashboard({ dashboardId: dashboardId });
					showInfo({ text: t('messages.deleteSuccess') });
					navigate(CommonRoutes.dashboards.base);
				},
			});
		} catch (error) {
			console.error('error on onDeleteDashboard', error);
			showError({ text: t('messages.deleteError') });
		}
	};

	const onDashboardTileDelete = async (dashboardTileId: string, _dashboardTileName: string) => {
		try {
			openDeleteConfirmModal({
				title: t('tile.delete.confirm.title'),
				content: t('tile.delete.confirm.content'),
				confirm: { text: t('common:remove') },
				handleConfirm: async () => {
					await deleteDashboardTile({
						dashboardId,
						dashboardTileId,
					});
					showInfo({ text: t('tile.delete.success') });
				},
			});
		} catch (error) {
			console.error('error on onDashboardTileDelete', error);
			showError({ text: t('tile.delete.error') });
		}
	};

	const onClearTiles = async () => {
		try {
			openDeleteConfirmModal({
				title: t('tile.clear.confirm.title'),
				content: t('tile.clear.confirm.content'),
				confirm: { text: t('common:remove') },
				handleConfirm: async () => {
					await deleteAllDashboardTiles({ dashboardId });
					showInfo({ text: t('tile.clear.success') });
				},
			});
		} catch (error) {
			console.error('error on onClearTiles', error);
			showError({ text: t('tile.clear.error') });
		}
	};

	usePushDocumentTitleHandler(dashboard?.name);

	if (isLoading || isTilesLoading) {
		return <Spinner />;
	}
	if (dashboardError || tilesLoadError) {
		throw createNeoLoadError(dashboardError);
	}
	if (!dashboard || !tilesData) {
		throw createNeoLoadError();
	}

	const onLayoutChange = async (items: TileLayout[]) => {
		const dashboardTileItems: { x: number; width: number; y: number; id: string; height: number }[] = items.map(
			(item) => ({
				id: item.id,
				x: item.x,
				y: item.y,
				width: item.width,
				height: item.height,
			})
		);
		await updateDashboardLayout({ dashboardId, dashboardTilesPatchAllRequest: { items: dashboardTileItems } });
	};

	const onSubmitEditionForm = async (formFields: TileEditionFormFields, tileInEdition: DashboardTile) => {
		let tileId = tileInEdition.id;
		let isCreated = false;
		if (tileId === '') {
			const [x, y] = findNextPosition(tilesData.items, tileInEdition);
			const createdTile = await createDashboardTile({
				dashboardId,
				dashboardTilesPostOneRequest: {
					...tileInEdition,
					x,
					y,
				},
			});
			if (createdTile.data) {
				tileId = createdTile.data.id;
				isCreated = true;
			}
		}
		let result;
		switch (formFields.type) {
			case 'SERIES': {
				result = saveSeries(tileId, formFields.series, (tileInEdition as SeriesDashboardTile).series).then(() =>
					updateDashboardTile({
						dashboardId: dashboardId,
						dashboardTileId: tileId,
						dashboardTilesPatchOneRequest: { title: formFields.title },
					}).unwrap()
				);
				break;
			}
			case 'TEXT': {
				result = updateDashboardTile({
					dashboardId,
					dashboardTileId: tileId,
					dashboardTilesPatchOneRequest: {
						type: 'TEXT',
						title: formFields.title,
						text: formFields.text,
					},
				}).unwrap();
				break;
			}
			case 'VALUES_COMPARISON': {
				result = updateDashboardTile({
					dashboardId,
					dashboardTileId: tileId,
					dashboardTilesPatchOneRequest: {
						type: 'VALUES_COMPARISON',
						title: formFields.title,
						columns: formFields.columns,
						rows: formFields.rows,
						differenceType: formFields.differenceType,
					},
				}).unwrap();
				break;
			}
			case 'WIDGET': {
				result = updateDashboardTile({
					dashboardId,
					dashboardTileId: tileId,
					dashboardTilesPatchOneRequest: {
						title: formFields.title,
						type: formFields.type,
						filter: formFields.filter,
						resultId: formFields.resultId,
						resultName: formFields.resultName,
						visualization: formFields.visualization,
						testId: formFields.testId,
						testName: formFields.testName,
					},
				}).unwrap();
				break;
			}
			default: {
				result = Promise.resolve();
			}
		}
		result
			.then(() => {
				showInfo({ text: t(isCreated ? 'tile.create.success' : 'tile.edition.success') });
				if (isCreated) {
					setUrlSearchParams({
						tileToEdit: null,
						scrollToTile: tileId,
					});
				}
			})
			.catch(() => {
				showError({ text: t(isCreated ? 'tile.create.error' : 'tile.edition.error') });
			});
	};

	const saveSeries = async (
		tileId: string,
		formSeriesList: SeriesToEditFormFields[],
		existingSeriesList: DashboardSeries[]
	) => {
		const existingSeriesIds = new Set(existingSeriesList.map(({ id }) => id));
		const deleteSeriesRequests = formSeriesList
			.filter((formSeries) => formSeries.deleteOnSubmit && existingSeriesIds.has(formSeries.id))
			.map((formSeries) => ({
				dashboardId,
				seriesId: formSeries.id,
				tileId,
			}));
		const updateSeriesRequests = formSeriesList
			.filter((formSeries) => !formSeries.deleteOnSubmit && existingSeriesIds.has(formSeries.id))
			.map((formSeries) => ({
				dashboardId,
				seriesId: formSeries.id,
				tileId,
				dashboardSeriesPatchRequest: { color: formSeries.color, visible: formSeries.visible },
			}));
		const createSeriesRequests = formSeriesList
			.filter((formSeries) => !formSeries.deleteOnSubmit && !existingSeriesIds.has(formSeries.id))
			.map((formSeries) => {
				const series = formSeries as NewSeriesFormFields;
				return {
					dashboardId,
					tileId,
					dashboardSeriesPostRequest: {
						color: series.color,
						filter: series.filter,
						legend: series.legend,
						resultId: series.resultId,
						resultName: series.resultName,
						statistic: series.statistic,
						visible: series.visible,
					} as DashboardSeriesPostRequest,
				};
			});
		return Promise.all([
			updateSeriesRequests.map((params) => updateDashboardTileSeries(params).unwrap()),
			createSeriesRequests.map((params) => createDashboardTileSeries(params).unwrap()),
			deleteSeriesRequests.map((params) => deleteDashboardTileSeries(params).unwrap()),
		]);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<Box sx={{ m: 2 }}>
				<DashboardHeader
					deleteTilesEnabled={tilesData.items.length > 0}
					name={dashboard.name}
					isShared={dashboard.shareStatus !== 'UNSHARED'}
					ownerId={dashboard.ownerId}
					onDuplicateDashboard={onDuplicateDashboard}
					onEditDashboardName={onEditDashboardName}
					onChangeDashboardShareStatus={onChangeDashboardShareStatus}
					onDeleteDashboard={onDeleteDashboard}
					onClearTiles={onClearTiles}
				/>
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					overflowY: 'auto',
					backgroundColor: (theme) => theme.palette.other.navigationBackground,
				}}
			>
				<DashboardTilesArea
					dashboardId={dashboard.id}
					ownerId={dashboard.ownerId}
					tilesData={tilesData}
					onLayoutChange={onLayoutChange}
					onDashboardTileDelete={onDashboardTileDelete}
					onSubmitEditionForm={onSubmitEditionForm}
					reportBenchId={dashboard.benchId}
				/>
			</Box>
		</Box>
	);
};

export { DashboardPage };
