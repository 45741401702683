import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import CreditCardOutlined from '@mui/icons-material/CreditCardOutlined';
import { useTranslation } from 'react-i18next';
import { AbstractSettingsLayout, MenuItem } from './abstract-settings-layout';
import { useGetMe } from '@neoload/hooks';
import { SettingsRoutes } from '@neoload/utils';

const SettingsLayout = () => {
	const { t } = useTranslation(['menu']);
	const [userInfo] = useGetMe();
	const title = t('settings');

	const menuItems: MenuItem[] = [
		{
			title: t('general'),
			icon: <SettingsOutlinedIcon />,
			link: SettingsRoutes.generalSettings,
			enabled: false,
		},
		{
			title: t('users'),
			icon: <GroupOutlinedIcon />,
			link: SettingsRoutes.users,
			enabled: userInfo.hasAdminPermissions,
		},
		{
			title: t('workspaces'),
			icon: <FolderCopyOutlinedIcon />,
			link: SettingsRoutes.workspaces,
			enabled: false,
		},
		{
			title: t('infrastructureProviders'),
			icon: <CloudOutlinedIcon />,
			link: SettingsRoutes.infrastructureProviders,
			enabled: false,
		},
		{
			title: t('subscription'),
			icon: <CreditCardOutlined />,
			link: SettingsRoutes.subscription,
			enabled: false,
		},
	].filter((item) => item.enabled);

	return <AbstractSettingsLayout title={title} menuItems={menuItems} />;
};

export { SettingsLayout };
