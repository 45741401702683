import { useTranslation } from 'react-i18next';
import { withLazyFetching, WithLazyFetchingProps } from './with-lazy-fetching';
import { useWidgetDataFetcher } from './hooks/use-widget-data-fetcher';
import { DashboardTileErrorContent } from '../tiles/dashboard-tile-error-content';
import { Spinner } from '../../../common/spinner';
import { Cell, Column, TableGrid } from '../../../common/table-grid';
import { DashboardTileUnloadedContent } from '../tiles/dashboard-tile-unloaded-content';
import {
	DashboardSlaFilter,
	GetV4ResultsByResultIdSlasApiArg,
	GetV4ResultsByResultIdSlasApiResponse,
	SlaPerInterval,
	SlaThresholdCondition,
	useLazyGetV4ResultsByResultIdSlasQuery,
	WidgetDashboardTile,
} from '@neoload/api';

type SlasPerIntervalFetcherProps = {
	widgetTile: WidgetDashboardTile;
} & WithLazyFetchingProps;

const round = (value: number) => Math.round(value * 1000) / 1000;

const getDuration = (sla: SlaPerInterval) => {
	if (sla.status === 'FAILED') {
		return round(sla.failPercentage * 100);
	}
	if (sla.status === 'WARN') {
		return round(sla.warnPercentage * 100);
	}
	return 0;
};

const getOrder = (severity?: 'WARNING' | 'CRITICAL') => {
	if (!severity) {
		return 2;
	}
	return severity === 'CRITICAL' ? 0 : 1;
};

const getValue = (condition: SlaThresholdCondition) => {
	switch (condition.operator) {
		case 'GREATER_THAN': {
			return '>=' + condition.min;
		}
		case 'LESS_THAN': {
			return '=<' + condition.max;
		}
		default: {
			return '';
		}
	}
};

const buildThresholdCondition = (conditions: SlaThresholdCondition[]) => {
	const sorted = [...conditions].sort((a, b) => getOrder(a.severity) - getOrder(b.severity));
	if (sorted.length > 0) {
		return getValue(sorted[0]);
	}
	return '';
};

const _SlasPerIntervalFetcher = ({ shouldStartFetching, widgetTile }: SlasPerIntervalFetcherProps) => {
	const { t } = useTranslation(['dashboard']);
	const [querySlasPerInterval] = useLazyGetV4ResultsByResultIdSlasQuery();

	const filter = widgetTile.filter as DashboardSlaFilter;
	const resultId = widgetTile.resultId;
	const { pageSize, sort } = filter;
	const { slaStatus, elementType } = filter.filter;

	const [data, error, loadingState] = useWidgetDataFetcher<
		GetV4ResultsByResultIdSlasApiArg,
		GetV4ResultsByResultIdSlasApiResponse
	>(
		widgetTile,
		{ resultId, pageSize, sort, slaStatus, elementType, slaType: 'PER_TIME_INTERVAL' },
		shouldStartFetching,
		querySlasPerInterval
	);

	if (loadingState === 'UNLOADED') {
		return <DashboardTileUnloadedContent />;
	}
	if (loadingState === 'LOADING') {
		return <Spinner />;
	}
	if (error || !data) {
		if (error) {
			console.error(
				t('widget.error.global', {
					tileId: widgetTile.id,
				}),
				error
			);
		}
		return <DashboardTileErrorContent />;
	}

	const columns: Column[] = [
		{ label: t('sla.columns.name.userPath') },
		{ label: t('sla.columns.name.status'), align: 'center' },
		{ label: t('sla.columns.name.element') },
		{ label: t('sla.columns.name.parent') },
		{ label: t('sla.columns.name.name') },
		{ label: t('sla.columns.name.threshold'), align: 'right' },
		{ label: t('sla.columns.name.duration'), align: 'right' },
	];

	function slaToTableRow(slaPerInterval: SlaPerInterval): Cell[] {
		return [
			{ text: slaPerInterval.userPathName },
			{ type: 'status', status: slaPerInterval.status },
			{ text: slaPerInterval.elementName },
			{ text: slaPerInterval.parentName },
			{ text: t('sla.threshold.' + slaPerInterval.slaThreshold.identifier) },
			{ text: buildThresholdCondition(slaPerInterval.slaThreshold.conditions) },
			{ text: getDuration(slaPerInterval) + '%' },
		];
	}

	const values = data.items.map((item) => slaToTableRow(item as SlaPerInterval));

	return <TableGrid columns={columns} values={values} />;
};

export const SlasPerIntervalFetcher = withLazyFetching(_SlasPerIntervalFetcher);
