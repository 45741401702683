import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import './index.css';
import { Provider } from 'react-redux';
import {
	Experimental_CssVarsProvider as CssVarsProvider,
	experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import muiXLicenseKey from '@tricentis/aura/constants/muiXLicenseKey.js';
import themeOptions from '@tricentis/aura/constants/themeOptions.js';
import themeOptionsDataGrid from '@tricentis/aura/constants/themeOptionsDataGrid.js';
import { App } from './app';
import { store } from '@neoload/api';
import './i18n';
import { initCompanion } from '@neoload/utils';

LicenseInfo.setLicenseKey(muiXLicenseKey);
initCompanion();

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
const theme = extendTheme(themeOptions, themeOptionsDataGrid);
root.render(
	<React.StrictMode>
		<CssVarsProvider theme={theme}>
			<CssBaseline>
				<Provider store={store}>
					<App />
				</Provider>
			</CssBaseline>
		</CssVarsProvider>
	</React.StrictMode>
);
