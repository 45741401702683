import { TestResult } from '@neoload/api';

export const isStarting = (result: Pick<TestResult, 'status'>) => {
	switch (result.status) {
		case 'INIT':
		case 'STARTING': {
			return true;
		}
		default: {
			return false;
		}
	}
};

export const isAlive = (result: Pick<TestResult, 'status'>) => {
	switch (result.status) {
		case 'RUNNING':
		case 'INIT':
		case 'STARTING': {
			return true;
		}
		default: {
			return false;
		}
	}
};

type Status = Pick<TestResult, 'status'>['status'];
export const RUNNING_STATUSES: Status[] = ['RUNNING'];
export const isRunning = (result: Pick<TestResult, 'status'>) => RUNNING_STATUSES.includes(result.status);

export const isComputing = (result: Pick<TestResult, 'qualityStatus'>) => result.qualityStatus === 'COMPUTING';

export const shouldResultDoPolling = (result?: Pick<TestResult, 'status' | 'qualityStatus'>): boolean =>
	result !== undefined && (isAlive(result) || isComputing(result));

export const hasNotStarted = (result: Pick<TestResult, 'status' | 'terminationReason'>) =>
	result.status === 'TERMINATED' &&
	(result.terminationReason === 'FAILED_TO_START' || result.terminationReason === 'CANCELLED');

export const isStoppable = (result: Pick<TestResult, 'status'>, initializationStep: number) =>
	isRunning(result) || (isStarting(result) && initializationStep < 5);
