import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useFormContext, useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { ConfigurationFormData } from '../types';
import { TestConfiguration } from '../test-configuration';

const AdvancedConfiguration = () => {
	const { t } = useTranslation(['test']);
	const {
		register,
		formState: { errors },
	} = useFormContext<ConfigurationFormData>();
	const disabled = useWatch<ConfigurationFormData, 'configurationDisabled'>({ name: 'configurationDisabled' });

	return (
		<TestConfiguration name={t('configuration.advanced.title')} disabled={disabled}>
			<Stack direction='row' spacing={2} marginTop={1}>
				<TextField
					{...register('testResultMask', {
						required: {
							value: true,
							message: t('configuration.advanced.error.required'),
						},
					})}
					label={t('configuration.advanced.label')}
					disabled={disabled}
					required={true}
					helperText={errors?.testResultMask?.message ?? t('configuration.advanced.helper')}
					error={!!errors.testResultMask}
				/>
			</Stack>
		</TestConfiguration>
	);
};

export { AdvancedConfiguration };
