import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';
import { Spinner } from '../../../../../../common/spinner';
import { useTreeDataWhenTestResultChange } from '../tree-data-hooks';
import { AddSeriesFormData } from '../add-series-form';
import { SplitPane } from '../split-pane';
import { MonitorComparisonStatistic, Statistic, StatsSelectionList } from '../statistics';
import { ComparisonSerieDefaultValue } from '../../form/comparison/row/use-find-in-tree';
import { findNode, StatisticTree, TreeNode, TreeNodeSearchResult } from '../../statistic-tree/statistic-tree';
import { MonitorElement, TestResult, useLazyGetV4ResultsByResultIdMonitorsQuery } from '@neoload/api';

const useGetMonitorsWhenTestResultChange = (
	testResult: TestResult | undefined
): [MonitorElement[], boolean, string | undefined] => {
	const [triggerGetMonitorsByResultId] = useLazyGetV4ResultsByResultIdMonitorsQuery();
	return useTreeDataWhenTestResultChange<MonitorElement>(testResult, triggerGetMonitorsByResultId);
};
const toTreeNode = (monitor: MonitorElement): TreeNode<MonitorElement> => ({
	id: monitor.id,
	label: monitor.name,
	data: monitor,
	children: monitor.children.map(toTreeNode),
});

export type MonitorTreeProps = {
	comparison?: boolean;
	defaultValue?: ComparisonSerieDefaultValue<Statistic>;
};

export const MonitorTree: React.FC<MonitorTreeProps> = ({ comparison, defaultValue }) => {
	const { watch, setValue } = useFormContext<AddSeriesFormData<MonitorComparisonStatistic>>();
	const [testResult, comparisonStat, monitor] = watch(['testResult', 'comparisonStat', 'monitor']);
	const [monitors, isLoading, error] = useGetMonitorsWhenTestResultChange(testResult);
	const { t } = useTranslation(['dashboard'], { keyPrefix: 'tile.edition.addSeries' });
	const selection = defaultValue ? { nodeId: defaultValue?.nodeId, expandedIds: defaultValue?.expandedIds } : undefined;
	const [monitorNodes, setMonitorNodes] = useState<TreeNode<MonitorElement>[]>(monitors.map(toTreeNode));
	useEffect(() => {
		setMonitorNodes(monitors.map(toTreeNode));
	}, [monitors, setMonitorNodes]);
	useEffect(() => {
		if (monitorNodes.length > 0 && (!defaultValue || !findNode(monitorNodes, defaultValue.nodeId))) {
			setValue('monitor', undefined);
		}
	}, [defaultValue, monitorNodes, setValue]);

	const handleNodeSelect = ({ node, path }: TreeNodeSearchResult<MonitorElement>) => {
		setValue('monitor', { ...node, path: [...path].map((monitor) => monitor.name) });
		setValue('comparisonStat', undefined);
		setValue('type', 'MONITOR');
	};
	if (isLoading) {
		return <Spinner />;
	}
	if (error) {
		console.error(t('errorLoadingData'), error);
	}
	return (
		<SplitPane proportions={[0.8, 0.2]}>
			<StatisticTree<MonitorElement>
				rootNodes={monitorNodes}
				defaultValue={selection}
				onChange={handleNodeSelect}
				label={t('monitorTree.label')}
			/>
			<>
				{error && (
					<Box sx={{ textAlign: 'center' }}>
						<FormHelperText error>{t('errorLoadingData')}</FormHelperText>
					</Box>
				)}
				{comparison && (
					<StatsSelectionList
						availablesStatistics={monitor && monitor.drawable ? ['MIN', 'MAX', 'AVG'] : []}
						statisticPrefix='statistics'
						onUpdate={(updatedValue: MonitorComparisonStatistic[]) => setValue('comparisonStat', updatedValue[0])}
						value={comparisonStat ? [comparisonStat] : []}
					/>
				)}
			</>
		</SplitPane>
	);
};
