import { MutableRefObject, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CachedOutlined from '@mui/icons-material/CachedOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { BaseQueryFn, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';
import { Category, ValuesDataGrid } from './values-tab/values-data-grid';
import { CountersDataGrid } from './counter-tab/counters-data-grid';
import { ValuesFilters } from './values-tab/values-filters';
import { ComponentTabPanel } from '../../../layout/component-tab-panel/component-tab-panel';
import { IntervalFilter } from '../interval-filter';
import { ResultRoutes, ResultValuesParams, valuesCategories, ValuesCategory } from '@neoload/utils';
import { Interval, ResultContext } from '@neoload/api';
import { UpdateUrlProps, useUrlSearchParams } from '@neoload/hooks';

const urlKeys = ['populations', 'userPaths', 'zones', 'intervalId'] as const;
type UrlKeysType = typeof urlKeys;

// 0 => STM (transactions, pages, requests and actions)
// 1 => IM (counters)
type SelectedGrid = 0 | 1;

export type ResultTabValuesProps = {
	intervals: Interval[];
	context: ResultContext;
	isRunning: boolean;
	resultDuration: string;
};
export type ValuesDataGridFuncts = {
	refetch: () => QueryActionCreatorResult<QueryDefinition<unknown, BaseQueryFn, string, unknown>>;
};

export const isQueryNotAvailable = (
	key: UrlKeysType[number],
	availables: string[],
	actualSearchParams: { [K in UrlKeysType[number]]?: string | undefined }
) => actualSearchParams[key] && !availables.includes(actualSearchParams[key] ?? '');

const ResultTabValuesInternal = ({ context, isRunning, intervals, resultDuration }: ResultTabValuesProps) => {
	const navigate = useNavigate();
	const theme = useTheme();

	const { t } = useTranslation(['result']);
	const { resultId = '', subTab = 'transactions' } = useParams<ResultValuesParams>();
	const selectedGrid: SelectedGrid = subTab === 'counters' ? 1 : 0;
	const [searchParams, setSearchParams, fullUrlParams] = useUrlSearchParams(...urlKeys);
	const [isDataFetching, setIsDataFetching] = useState<boolean>(false);
	const valuesDatagrid: MutableRefObject<ValuesDataGridFuncts | undefined> = useRef();
	const counterDatagrid: MutableRefObject<ValuesDataGridFuncts | undefined> = useRef();

	useEffect(() => {
		if (!valuesCategories.includes(subTab)) {
			navigate(ResultRoutes.values(resultId));
		}
	});

	useEffect(() => {
		const update: UpdateUrlProps<UrlKeysType> = {};
		const fieldsToCheckUrl: [UrlKeysType[number], string[]][] = [
			['populations', context.populations],
			['userPaths', context.userPaths],
			['zones', context.zones],
			['intervalId', intervals.map(({ id }) => id)],
		];
		for (const [key, availables] of fieldsToCheckUrl) {
			if (isQueryNotAvailable(key, availables, searchParams)) {
				update[key] = null;
			}
		}
		setSearchParams(update);
	}, [searchParams, context, intervals, setSearchParams]);

	const refetch = useCallback(() => {
		if (selectedGrid === 1 && counterDatagrid.current) {
			counterDatagrid.current.refetch();
		}
		if (selectedGrid === 0 && valuesDatagrid.current) {
			valuesDatagrid.current.refetch();
		}
	}, [selectedGrid]);

	useEffect(() => {
		if (isRunning) {
			refetch();
		}
	}, [subTab, selectedGrid, isRunning, refetch]);

	const onChange = (_event: React.SyntheticEvent, value: ValuesCategory) => {
		navigate({
			pathname: ResultRoutes.values(resultId, value),
			search: fullUrlParams.toString(),
		});
	};

	return (
		<Grid container direction='column' sx={{ height: '100%' }} alignItems='center' justifyContent='center'>
			<Grid
				item
				alignItems='center'
				display='flex'
				justifyContent='space-between'
				width='100%'
				sx={{
					paddingTop: theme.spacing(1),
					paddingLeft: theme.spacing(2),
					paddingRight: theme.spacing(2),
				}}
			>
				<ToggleButtonGroup
					color='primary'
					onChange={onChange}
					exclusive
					size='small'
					sx={{
						marginTop: theme.spacing(1),
						marginBottom: theme.spacing(1),
					}}
				>
					{valuesCategories.map((tab) => (
						<ToggleButton
							key={tab}
							value={tab}
							data-testid={`${tab}-tab`}
							sx={(theme) => ({ padding: `${theme.spacing(1)} ${theme.spacing(2)}` })}
							selected={subTab === tab}
						>
							{t(`tabs.${tab}`)}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				{isRunning && (
					<Button
						variant='outlined'
						color='primary'
						data-testid='refresh-values-or-counter'
						disabled={isDataFetching}
						onClick={() => refetch()}
						startIcon={isDataFetching ? <CircularProgress size={20} color='inherit' /> : <CachedOutlined />}
						sx={{
							marginLeft: '20px',
							marginRight: 'auto',
							textTransform: 'uppercase',
						}}
					>
						{t('commons:refresh')}
					</Button>
				)}
				<Grid
					container
					display='flex'
					justifySelf='flex-end'
					justifyContent='flex-end'
					flexDirection='row'
					flexWrap='nowrap'
					maxWidth='60%'
				>
					<IntervalFilter intervals={intervals} resultDuration={resultDuration} />
					{selectedGrid === 0 && <ValuesFilters context={context} />}
				</Grid>
			</Grid>
			<Grid item sx={{ flexGrow: 1, width: '100%' }}>
				<ComponentTabPanel value={selectedGrid} index={0} data-testid='values-panel' sx={{ height: '100%' }}>
					<ValuesDataGrid
						ref={valuesDatagrid}
						setDataFetching={setIsDataFetching}
						category={subTab as Category}
						resultId={resultId}
					/>
				</ComponentTabPanel>
				<ComponentTabPanel value={selectedGrid} index={1} data-testid='counters-panel' sx={{ height: '100%' }}>
					<CountersDataGrid ref={counterDatagrid} setDataFetching={setIsDataFetching} resultId={resultId} />
				</ComponentTabPanel>
			</Grid>
		</Grid>
	);
};

export const ResultTabValues = memo(ResultTabValuesInternal);
