import { TFunction } from 'i18next';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Interval } from '@neoload/api';
import { timeUtils } from '@neoload/utils';

const getInterval = (intervalId: string, intervals: Interval[]) =>
	intervals.find((interval) => interval.id === intervalId);

export const listCellRenderer =
	(intervals: Interval[], t: TFunction, theme: Theme, resultDuration?: string) => (intervalId: string) => {
		const interval: Interval | undefined = getInterval(intervalId, intervals);
		return intervalComboboxCellRenderer(interval, t, resultDuration, theme);
	};

export const getRenderValue = (intervals: Interval[], t: TFunction) => (intervalId: string) => {
	const interval = getInterval(intervalId, intervals);
	return interval ? interval.name : t('filters.none');
};

const getDisplayedRange = (interval?: Interval, resultDuration?: string) => {
	if (interval) {
		return timeUtils.rangeTimeSeconds(interval.startOffset, interval.endOffset);
	}
	return resultDuration ? timeUtils.rangeTimeSeconds(0, resultDuration) : undefined;
};

const intervalComboboxCellRenderer = (
	interval: Interval | undefined,
	t: TFunction,
	resultDuration: string | undefined,
	theme: Theme
) => (
	<Grid sx={{ display: 'flex', flexDirection: 'column' }}>
		<Grid sx={{ fontSize: theme.typography.body1.fontSize }}>{interval ? interval.name : t('filters.none')}</Grid>
		<Grid sx={{ fontSize: theme.typography.body3.fontSize }}>{getDisplayedRange(interval, resultDuration)}</Grid>
	</Grid>
);
