import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useGetV4MeQuery, CurrentUser, UserRole } from '@neoload/api';
import { HomeRoutes, SettingsRoutes, UserRoutes } from '@neoload/utils';

const accountAdminRoles: UserRole[] = ['ACCOUNT_ADMIN'];
const adminRoles: UserRole[] = ['NEOLOAD_ADMIN', ...accountAdminRoles];
const testerRoles: UserRole[] = ['TESTER', ...adminRoles];
// eslint-disable-next-line unicorn/prefer-set-has
const guestRoles: UserRole[] = ['GUEST', ...testerRoles];

type LoadingCurrentUser = {
	isLoading: true;
	isLoaded: false;
	hasAccountAdminPermissions: false;
	hasAdminPermissions: false;
	hasTesterPermissions: false;
	hasGuestPermissions: false;
	isAccountAdmin: false;
	isAdmin: false;
	isTester: false;
	isGuest: false;
} & Partial<CurrentUser> &
	Pick<CurrentUser, 'workspaces'>;

type LoadedCurrentUser = {
	isLoading: false;
	isLoaded: true;
	hasAccountAdminPermissions: boolean;
	hasAdminPermissions: boolean;
	hasTesterPermissions: boolean;
	hasGuestPermissions: boolean;
	isAccountAdmin: boolean;
	isAdmin: boolean;
	isTester: boolean;
	isGuest: boolean;
} & CurrentUser;

type Me = LoadingCurrentUser | LoadedCurrentUser;

const useGetMe = () => {
	const { data, refetch } = useGetV4MeQuery();
	const currentUser: Me = useMemo(() => {
		if (data) {
			const { role } = data;
			const workspaces = [...data.workspaces];
			workspaces.sort((a, b) => a.name.localeCompare(b.name));
			return {
				isLoaded: true,
				isLoading: false,
				hasAccountAdminPermissions: accountAdminRoles.includes(role),
				hasAdminPermissions: adminRoles.includes(role),
				hasTesterPermissions: testerRoles.includes(role),
				hasGuestPermissions: guestRoles.includes(role),
				isAccountAdmin: role === 'ACCOUNT_ADMIN',
				isAdmin: role === 'NEOLOAD_ADMIN',
				isTester: role === 'TESTER',
				isGuest: role === 'GUEST',
				...data,
				workspaces: workspaces,
			};
		}
		return {
			isLoaded: false,
			isLoading: true,
			hasAccountAdminPermissions: false,
			hasAdminPermissions: false,
			hasTesterPermissions: false,
			hasGuestPermissions: false,
			isAccountAdmin: false,
			isAdmin: false,
			isTester: false,
			isGuest: false,
			workspaces: [],
		};
	}, [data]);

	useRedirectWhenNoWorkspaceAssigned(currentUser);

	return [currentUser, refetch] as const;
};

export { useGetMe };

export const NO_WORKSPACE_ASSIGNED_REDIRECTION_KEY = 'no-workspace-assigned-redirection';

const useRedirectWhenNoWorkspaceAssigned = (currentUser: Me) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	useEffect(() => {
		if (currentUserHasNoAssignedWorkspace(currentUser) && locationRequiresWorkspace(pathname)) {
			navigate(`${HomeRoutes.base}?${NO_WORKSPACE_ASSIGNED_REDIRECTION_KEY}`);
		}
	}, [navigate, currentUser, pathname]);
};

const currentUserHasNoAssignedWorkspace = (currentUser: Me) =>
	currentUser.isLoaded && currentUser.workspaces.length <= 0;

const locationRequiresWorkspace = (pathname: string) =>
	![SettingsRoutes.base, UserRoutes.base].some((base) => pathname.startsWith(base));
