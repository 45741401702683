import { generatePath } from 'react-router';

const patterns = {
	base: '/dashboards',
	byId: '/dashboards/:dashboardId',
};

const dashboardRoutes = {
	patterns: patterns,
	base: patterns.base,
	dashboard: (dashboardId: string) => generatePath(patterns.byId, { dashboardId: dashboardId }),
};

export type DashboardGenericParams = {
	dashboardId: string;
};

export { dashboardRoutes as DashboardRoutes };
