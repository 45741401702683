import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { EventTabDetailItem } from './event-tab-details-item';
import { EventTabDetailsError } from './event-tab-details-error';
import { EventTabDetailsObjective } from './event-tab-details-objective';
import { EventTabDetailsCustom } from './event-tab-details-custom';
import { EventCodeRenderer } from '../../event-code-renderer';
import { getDisplayedDuration } from '../event-detail-util';
import { EventWithDetails } from '@neoload/api';
import { isErrorEvent } from '@neoload/utils';

export type EventTabDetailsProps = {
	event: EventWithDetails;
	isLoading: boolean;
};

const getPopulationName = (event: EventWithDetails) =>
	event.type === 'ERROR' || event.type === 'ALERT' ? event.details.populationName : undefined;

const getZoneId = (event: EventWithDetails) =>
	event.type === 'ERROR' || event.type === 'ALERT' ? event.details.zoneId : undefined;

const getUserPathName = (event: EventWithDetails) => {
	if (event.type === 'ERROR') {
		return event.details.userPathId;
	}
	if (event.type === 'ALERT') {
		return event.details.vuName ?? event.details.userPathId;
	}
	return undefined;
};

const getMonitoredMachine = (event: EventWithDetails) =>
	event.type === 'ALERT' ? event.details.monitoredMachine : undefined;

const getMonitor = (event: EventWithDetails) => (event.type === 'ALERT' ? event.details.monitor : undefined);

const EventTabDetails = ({ event, isLoading }: EventTabDetailsProps) => {
	const { t } = useTranslation(['result']);
	const eventTime = getDisplayedDuration(event.offset, event.duration, event.type, t('events.pending'));
	const { fullName, code, source } = event;

	return (
		<>
			<EventTabDetailItem title={t('events.details.name')} value={fullName} />
			<EventTabDetailItem title={t('events.details.time')} value={eventTime} />
			<EventTabDetailItem
				title={t('events.details.code')}
				value={code ? <EventCodeRenderer eventCode={code} /> : undefined}
			/>
			<EventTabDetailItem title={t('events.details.source')} value={source} />
			<Divider sx={{ marginBottom: 2 }} />
			{isLoading && [1, 2, 3, 4].map((n) => <EventTabDetailItem displayLoading key={n} title={''} value={''} />)}
			<EventTabDetailItem value={getPopulationName(event)} title={t(`events.details.populationName`)} />
			<EventTabDetailItem value={getZoneId(event)} title={t(`events.details.zone`)} />
			<EventTabDetailItem value={getUserPathName(event)} title={t(`events.details.userPathId`)} />
			{isErrorEvent(event) && <EventTabDetailsError event={event} />}
			{event.type === 'OBJECTIVE' && <EventTabDetailsObjective event={event} />}
			{event.type === 'CUSTOM' && <EventTabDetailsCustom event={event} />}
			<EventTabDetailItem value={getMonitoredMachine(event)} title={t(`events.details.monitoredMachine`)} />
			<EventTabDetailItem value={getMonitor(event)} title={t(`events.details.monitor`)} />
		</>
	);
};

export { EventTabDetails };
