import ZeroState from '@tricentis/aura/components/ZeroState.js';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { ButtonProps } from '@mui/material/Button';
import { Key } from 'react';
import { TileCreationPopover } from './tiles/creation/tile-creation-popover';
import TestData from '../../../assets/TestData.svg';

type ButtonPropsWithKey = ButtonProps & { key: Key };

const DashboardEmptyState = () => {
	const { t } = useTranslation(['dashboard']);

	const actions: ButtonPropsWithKey[] = [
		{
			key: 'zero-button',
			variant: 'contained',
			children: (
				<>
					<AddIcon sx={{ marginRight: 1 }} />
					{t('dashboard:zero:button')}
				</>
			),
			sx: { textTransform: 'none' },
			onClick: () => {
				alert('Not implemented');
			},
		},
	];

	const title = t('dashboard:zero:title');

	return (
		<ZeroState
			illustration={<img src={TestData} alt={title} />}
			title={title}
			actions={actions.map(({ children, key, ...others }) => (
				<TileCreationPopover key={key} {...others} />
			))}
			containerSx={{ backgroundColor: 'transparent' }}
		>
			<Typography variant='body2'>{t('dashboard:zero:content')}</Typography>
		</ZeroState>
	);
};

export { DashboardEmptyState };
