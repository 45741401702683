/* eslint-disable @typescript-eslint/naming-convention */
const dashboardLegacyKeys = {
	'DashboardPage.all.node.Requests': '<all requests>',
	'DashboardPage.all.node.Pages': '<all pages>',
	'DashboardPage.all.node.Transactions': '<all transactions>',
	'DashboardPage.all.node.Actions': '<all actions>',

	//Statistics
	'DashboardPage.statistic.USER_LOAD': 'User Load',
	'DashboardPage.statistic.AVG_DUR': 'Average duration',
	'DashboardPage.statistic.MAX_DUR': 'Maximum duration',
	'DashboardPage.statistic.MIN_DUR': 'Minimum duration',
	'DashboardPage.statistic.SCATTER_DUR': 'Scatter duration',
	'DashboardPage.statistic.PERCENTILES_DURATION': 'Percentile duration',
	'DashboardPage.statistic.HITS': 'Elements/s',
	'DashboardPage.statistic.THROUGHPUT': 'Throughput',
	'DashboardPage.statistic.AVG_TTFB': 'Average TTFB',
	'DashboardPage.statistic.MAX_TTFB': 'Maximum TTFB',
	'DashboardPage.statistic.MIN_TTFB': 'Minimum TTFB',
	'DashboardPage.statistic.ERRORS': 'Errors',
	'DashboardPage.statistic.ERRORS_PER_SECOND': 'Errors/s',
	'DashboardPage.statistic.ERRORS_RATE': 'Errors rate',

	//Widgets
	//Full name
	'Dashboard.widgets.TEST_RESULT_SUMMARY.full': 'Summary',
	'Dashboard.widgets.TOP_ERRORS_BY_CODE.full': 'Top errors by code',
	'Dashboard.widgets.TOP_ERRORS_BY_LG.full': 'Top errors by LG',
	'Dashboard.widgets.TOP_ERRORS_BY_ELEMENT.full': 'Top errors by element',
	'Dashboard.widgets.TOP_ERRORS_BY_CODE_AS_PIE.full': 'Top errors by code',
	'Dashboard.widgets.TOP_ERRORS_BY_LG_AS_PIE.full': 'Top errors by LG',
	'Dashboard.widgets.TOP_SLA_ALERTS.full': 'Top SLA alerts',
	'Dashboard.widgets.TRANSACTION_KEY_METRICS.full': 'Transactions key metrics',
	'Dashboard.widgets.TOP_TRANSACTION_AVG.full': 'Top transactions average duration',
	'Dashboard.widgets.TOP_TRANSACTION_MAX.full': 'Top transactions maximum duration',
	'Dashboard.widgets.TOP_PAGE_AVG.full': 'Top pages average duration',
	'Dashboard.widgets.TOP_PAGE_MAX.full': 'Top pages maximum duration',
	'Dashboard.widgets.REQUEST_KEY_METRICS.full': 'Requests key metrics',
	'Dashboard.widgets.TOP_REQUEST_AVG.full': 'Top requests average duration',
	'Dashboard.widgets.TOP_REQUEST_MAX.full': 'Top requests maximum duration',

	//Short name
	'Dashboard.widgets.TEST_RESULT_INFO': 'Test Result info',
	'Dashboard.widgets.ERRORS': 'Errors',
	'Dashboard.widgets.SLA_ALERTS': 'SLA alerts',
	'Dashboard.widgets.TRANSACTIONS': 'Transactions',
	'Dashboard.widgets.PAGES': 'Pages',
	'Dashboard.widgets.REQUESTS': 'Requests',
	'Dashboard.widgets.TEST_RESULT_SUMMARY': 'Summary',
	'Dashboard.widgets.TOP_ERRORS_BY_CODE': 'Top by code',
	'Dashboard.widgets.TOP_ERRORS_BY_LG': 'Top by LG',
	'Dashboard.widgets.TOP_ERRORS_BY_ELEMENT': 'Top by element',
	'Dashboard.widgets.TOP_ERRORS_BY_CODE_AS_PIE': 'Top by code',
	'Dashboard.widgets.TOP_ERRORS_BY_LG_AS_PIE': 'Top by LG',
	'Dashboard.widgets.TOP_SLA_ALERTS': 'Top',
	'Dashboard.widgets.TRANSACTION_KEY_METRICS': 'Key metrics',
	'Dashboard.widgets.TOP_TRANSACTION_AVG': 'Top average duration',
	'Dashboard.widgets.TOP_TRANSACTION_MAX': 'Top maximum duration',
	'Dashboard.widgets.TOP_PAGE_AVG': 'Top average duration',
	'Dashboard.widgets.TOP_PAGE_MAX': 'Top maximum duration',
	'Dashboard.widgets.REQUEST_KEY_METRICS': 'Key metrics',
	'Dashboard.widgets.TOP_REQUEST_AVG': 'Top average duration',
	'Dashboard.widgets.TOP_REQUEST_MAX': 'Top maximum duration',

	'DashboardPage.units.full.second': 'Second',
	'DashboardPage.units.short.second': 's',
	'DashboardPage.units.full.megabit_per_second': 'megabit per second',
	'DashboardPage.units.short.megabit_per_second': 'Mbps',
	'DashboardPage.units.full.percentage': '%',
	'DashboardPage.units.short.percentage': '%',
	'DashboardPage.units.full.perthousand': '',
	'DashboardPage.units.short.perthousand': '',
	'DashboardPage.units.full.megabytes': 'Megabytes',
	'DashboardPage.units.short.megabytes': 'MB',
	'DashboardPage.units.full.errors_per_second': 'Errors per second',
	'DashboardPage.units.short.errors_per_second': 'Err/s',
	'DashboardPage.units.full.errors': 'Errors',
	'DashboardPage.units.short.errors': 'Err',
	'DashboardPage.units.full.elements': 'e',
	'DashboardPage.units.short.elements': 'Elements',
	'DashboardPage.units.full.elements_per_second': 'Elements per second',
	'DashboardPage.units.short.elements_per_second': 'e/s',
	'DashboardPage.units.full.empty': '',
	'DashboardPage.units.short.empty': '',
};

export { dashboardLegacyKeys };
