// eslint-disable-next-line no-restricted-imports
import styled from '@mui/material/styles/styled';
import { TreeItem, TreeItemProps } from '@mui/x-tree-view';
export const StyledTreeItem = styled(TreeItem)<TreeItemProps>(({ theme }) => ({
	'& .MuiTreeItem-content .MuiTreeItem-label': {
		fontSize: theme.typography.body2.fontSize,
	},
	// eslint-disable-next-line @typescript-eslint/naming-convention
	'&.MuiTreeItem-root': {
		padding: 0,
	},
	'& .MuiTreeItem-content': {
		padding: 6,
	},
}));
