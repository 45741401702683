import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { SlaBadge } from '../../../common/sla-badge';
import { Sla } from '@neoload/utils';

type EventCode = {
	status?: Sla;
	text: string;
};

type EventCodeRendererProps = {
	eventCode: string;
};

export const EventCodeRenderer = ({ eventCode }: EventCodeRendererProps) => {
	const { t } = useTranslation(['result']);

	const stringToEventCode = (alertCode: string): EventCode => {
		switch (alertCode) {
			case 'SLA w': {
				return { status: 'WARN', text: t('events.sla') };
			}
			case 'SLA c': {
				return { status: 'FAILED', text: t('events.sla') };
			}
			case 'Objective p': {
				return { status: 'SUCCESS', text: t('events.objective') };
			}
			case 'Objective f': {
				return { status: 'FAILED', text: t('events.objective') };
			}
			case 'Monitoring w': {
				return { status: 'WARN', text: t('events.monitoring') };
			}
			case 'Monitoring c': {
				return { status: 'FAILED', text: t('events.monitoring') };
			}
			default: {
				return { text: alertCode };
			}
		}
	};

	const code: EventCode = stringToEventCode(eventCode);

	return (
		<Typography variant='body2' sx={(theme) => ({ display: 'flex', alignItems: 'center', gap: theme.spacing(1) })}>
			<SlaBadge sla={code.status} />
			{code.text}
		</Typography>
	);
};
