import { useState } from 'react';
import Box from '@mui/material/Box';
import { SlaBadgeWithPercentage } from './sla-badge-with-percentage';
import { SlaValuePopover } from './sla-value-popover';
import { SlaPerInterval } from '@neoload/api';

export type SlasPerIntervalValueProps = {
	slaPerInterval: SlaPerInterval;
};

const SlasPerIntervalValue = ({ slaPerInterval }: SlasPerIntervalValueProps) => {
	const { slaThreshold, failPercentage: error, warnPercentage: warn } = slaPerInterval;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const onMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onMouseLeave = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Box
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			data-testid='perTimeIntervalValueCell'
			sx={{ display: 'flex' }}
		>
			{error > 0 && <SlaBadgeWithPercentage status='FAILED' value={error} />}
			{warn > 0 && <SlaBadgeWithPercentage status='WARN' value={warn} />}
			<SlaValuePopover slaThreshHold={slaThreshold} anchorEl={anchorEl} open={open} />
		</Box>
	);
};

export { SlasPerIntervalValue };
