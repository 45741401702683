import i18n from 'i18next';
import { SlaThreshold, SlaThresholdCondition } from '@neoload/api';

export type SlaIdentifier = SlaThreshold['identifier'];
export type SlaOperator = SlaThresholdCondition['operator'];

/** Ordered list of SLA Status */
const slaStatuses = ['SUCCESS', 'WARN', 'FAILED'] as const;
export type Sla = (typeof slaStatuses)[number];

export type SlaConditionSeverity = SlaThresholdCondition['severity'];

const f = () =>
	new Intl.NumberFormat(i18n.language, {
		compactDisplay: 'short',
		minimumFractionDigits: 0,
		maximumFractionDigits: 3,
	});

const getSeverityfromStatus = (status: Sla): SlaConditionSeverity | null => {
	switch (status) {
		case 'SUCCESS': {
			return null;
		}
		case 'WARN': {
			return 'WARNING';
		}
		case 'FAILED': {
			return 'CRITICAL';
		}
	}
};

const getI18nKey = (status: Sla): string => {
	switch (status) {
		case 'SUCCESS': {
			return 'kpi.passed';
		}
		case 'WARN': {
			return 'kpi.warning';
		}
		case 'FAILED': {
			return 'kpi.failed';
		}
	}
};

const getUnit = (identifier: SlaIdentifier) => {
	switch (identifier) {
		case 'AVERAGE_REQUEST_RESPONSE_TIME':
		case 'AVERAGE_PAGE_RESPONSE_TIME':
		case 'AVERAGE_CONTAINER_RESPONSE_TIME':
		case 'PERCENTILE_CONTAINER_RESPONSE_TIME':
		case 'AVERAGE_RESPONSE_TIME': {
			return i18n.t('result:slas.units.second');
		}
		case 'AVERAGE_THROUGHPUT_PER_SECOND': {
			return i18n.t('result:slas.units.megabitsPerSecond');
		}
		case 'TOTAL_THROUGHPUT': {
			return i18n.t('result:slas.units.megabytes');
		}
		default: {
			return null;
		}
	}
};

const getAppliedSlaCondition = (threshHold: SlaThreshold, status: Sla): SlaThresholdCondition | undefined => {
	const severity = getSeverityfromStatus(status);
	return severity ? getSlaConditionBySeverity(threshHold, severity) : undefined;
};

const getSlaConditionBySeverity = (
	threshHold: SlaThreshold,
	severity: SlaConditionSeverity
): SlaThresholdCondition | undefined => threshHold.conditions.find((condition) => condition.severity === severity);

const formatValue = (value: number, identifier: SlaIdentifier) => {
	const unit = getUnit(identifier);
	return unit ? f().format(value) + ' ' + unit : f().format(value);
};

const formatDifference = (threshHold: SlaThreshold, status: Sla, value: number) => {
	const condition = getAppliedSlaCondition(threshHold, status);
	if (condition?.min === undefined) {
		return null;
	}
	switch (condition.operator) {
		case 'LESS_THAN': {
			return '-' + formatValue(condition.min - value, threshHold.identifier);
		}
		case 'GREATER_THAN': {
			return '+' + formatValue(value - condition.min, threshHold.identifier);
		}
		case 'EQUALS':
		case 'BETWEEN': {
			return null;
		}
	}
};

const formatConditions = (value: number, operator: SlaOperator, identifier: SlaIdentifier) => {
	switch (operator) {
		case 'LESS_THAN': {
			return '<= ' + formatValue(value, identifier);
		}
		case 'GREATER_THAN': {
			return '>= ' + formatValue(value, identifier);
		}
		case 'EQUALS': {
			return '= ' + formatValue(value, identifier);
		}
	}
	return null;
};

const slaComparator = (sla1: string, sla2: string) => {
	const statuses = [undefined, 'SUCCESS', 'WARN', 'FAILED'];
	return statuses.indexOf(sla1) - statuses.indexOf(sla2);
};

const slaUtils = {
	slaStatuses,
	formatValue,
	formatConditions,
	formatDifference,
	getSlaConditionBySeverity,
	slaComparator,
	getI18nKey,
};

export { slaUtils };
