import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { SlasPerIntervalValue } from './slas-per-interval-value';
import { SlaRow } from './slas-data-grid';
import { SlaGlobalPerRunValue } from './sla-global-per-run-value';
import { slaIdentifierTranslations } from '../../slas/sla-by-identifier';
import { SlaStatus } from '../../slas/sla-status';
import { SlaThreshold } from '@neoload/api';
import { Sla, slaUtils, toSentenceCase } from '@neoload/utils';

function SlasDatagridColumns() {
	const { t, i18n } = useTranslation(['result']);
	const intFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 0 });

	const columns: GridColDef<SlaRow>[] = [
		{
			field: 'status',
			headerName: t('slas.status'),
			minWidth: 205,
			renderCell: ({ row }) =>
				row.rowCategory === 'TREE'
					? slaUtils.slaStatuses.map(
							(sla: Sla) =>
								row.statuses[sla] > 0 && (
									<SlaStatus variant='body2' key={sla} status={sla} label={intFormatter.format(row.statuses[sla])} />
								)
					  )
					: row.status && <SlaStatus variant='body2' status={row.status} />,
			sortable: true,
			sortComparator: slaUtils.slaComparator,
			type: 'singleSelect',
			valueOptions: [
				{ value: 'FAILED', label: 'Failed' },
				{ value: 'SUCCESS', label: 'Passed' },
				{ value: 'WARN', label: 'Warning' },
			],
		},
		{
			field: 'slaThreshold',
			headerName: t('slas.identifier'),
			hideable: false,
			minWidth: 225,
			flex: 0.1,
			valueGetter: ({ value }: { value?: SlaThreshold }): string =>
				value ? t(`slas.identifiers.${slaIdentifierTranslations[value.identifier]}`) : '',
			filterable: false,
		},
		{
			field: 'userPathName',
			headerName: t('slas.userPath'),
			minWidth: 180,
			flex: 0.1,
			filterable: false,
		},
		{
			field: 'elementName',
			headerName: t('slas.element'),
			minWidth: 180,
			flex: 0.2,
			filterable: false,
		},
		{
			field: 'elementType',
			headerName: t('slas.elementType'),
			valueGetter: ({ value, row }) =>
				row.rowCategory !== 'GLOBAL' && typeof value === 'string' ? toSentenceCase(value) : null,
			width: 150,
			type: 'singleSelect',
			valueOptions: ['Page', 'Request', 'Transaction'],
		},
		{
			field: 'parentName',
			headerName: t('slas.parent'),
			minWidth: 180,
			flex: 0.1,
			filterable: false,
		},
		{
			field: 'value',
			headerName: t('slas.value'),
			headerAlign: 'right',
			align: 'right',
			width: 200,
			valueGetter: ({ row }) => {
				if ('PER_RUN' === row.rowCategory || 'GLOBAL' === row.rowCategory) {
					return row.value;
				}
				if (row.rowCategory === 'PER_TIME_INTERVAL') {
					return row.failPercentage * 10_000 + row.warnPercentage;
				}
				return 0;
			},
			renderCell: (params: GridRenderCellParams<unknown, SlaRow, unknown>) => {
				if ('PER_RUN' === params.row.rowCategory || 'GLOBAL' === params.row.rowCategory) {
					return <SlaGlobalPerRunValue slaPerRun={params.row} />;
				}
				return params.row.rowCategory === 'PER_TIME_INTERVAL' && <SlasPerIntervalValue slaPerInterval={params.row} />;
			},
			filterable: false,
		},
	];
	return columns;
}

export { SlasDatagridColumns };
