import { TrendsConfigurationMetric } from '@neoload/api';

const TRENDS_SERIES_COLORS = [
	'#89c0de',
	'#d2b067',
	'#58b737',
	'#1aba9f',
	'#ba4f1a',
	'#ba1a35',
	'#1a85ba',
	'#8092ed',
	'#35ba1a',
	'#b37400',
	'#ff33cc',
	'#c23265',
	'#993300',
];

export const findNextColor = (metrics: TrendsConfigurationMetric[]) => {
	const colors = new Set(metrics.map(({ color }) => color));
	return TRENDS_SERIES_COLORS.find((trendsSeriesColor) => !colors.has(trendsSeriesColor));
};
