import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGetMe } from '../user/use-get-me';
import { setSelectedWorkspace } from '@neoload/api';

const useSetCurrentWorkspaceId = () => {
	const [{ workspaces = [] }] = useGetMe();
	const dispatch = useDispatch();
	return useCallback(
		(id: string) => {
			const targetWorkspace = workspaces.find((w) => w.id === id);
			if (targetWorkspace) {
				return dispatch(
					setSelectedWorkspace({
						id: targetWorkspace.id,
						name: targetWorkspace.name,
						enabledForAllUsers: targetWorkspace.enabledForAllUsers,
					})
				);
			} else {
				throw new Error('You do not have access to this workspace');
			}
		},
		[dispatch, workspaces]
	);
};

export { useSetCurrentWorkspaceId };
