import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import deepmerge, { Options } from 'deepmerge';
import { DeepPartial } from 'react-hook-form';
import { TileEditionForm, TileEditionFormFields } from './tile-edition-form';
import { DashboardTile, DashboardTileContent } from '../..';
import { matchAndReplaceWithI18N } from '../../../dashboard-common';
import { DashboardTile as DashboardTileApiModel } from '@neoload/api';

const noopFunction = () => {
	// NOOP
};

const tileHeight = 200;
type TileEditionDialogProps = {
	dashboardId: string;
	tile: DashboardTileApiModel;
	onSubmit: (formFields?: TileEditionFormFields) => void;
	reportBenchId?: string;
};

export const TileEditionDialog = ({ dashboardId, tile, reportBenchId, onSubmit }: TileEditionDialogProps) => {
	const { t } = useTranslation(['dashboard']);
	const [preview, setPreview] = useState<DashboardTileApiModel>(tile);
	const open = true;

	const onFormValueChanged = useCallback(
		(values: DeepPartial<TileEditionFormFields>) => {
			const updatedPreview = tile.type === 'SERIES' ? merge(tile, values) : Object.assign({ ...tile }, values);
			setPreview(updatedPreview);
		},
		[tile]
	);

	return (
		<>
			<Dialog
				open={open}
				aria-label={t('tile.edition.dialog.name')}
				aria-hidden={!open}
				sx={{ width: '67%' }}
				fullWidth
				maxWidth='xl'
				hideBackdrop
			>
				<DialogContent sx={{ height: `${tileHeight + 100}px`, padding: '0' }}>
					<DashboardTile
						title={matchAndReplaceWithI18N(preview.title)}
						isReadOnly={true}
						onSwitchEdition={noopFunction}
						onDelete={noopFunction}
						children={<DashboardTileContent dashboardId={dashboardId} tile={preview} isReadOnly={true} />}
					/>
				</DialogContent>
			</Dialog>
			<Drawer
				anchor='right'
				open={open}
				PaperProps={{
					sx: { paddingTop: '48px', width: '33%', backgroundImage: 'none' },
				}}
			>
				<TileEditionForm
					tile={tile}
					onSubmit={onSubmit}
					onCancel={onSubmit}
					reportBenchId={reportBenchId}
					onFormValuesChange={onFormValueChanged}
				/>
			</Drawer>
		</>
	);
};

const merge = (tile: DashboardTileApiModel, values: DeepPartial<TileEditionFormFields>) =>
	deepmerge<DashboardTileApiModel, DeepPartial<TileEditionFormFields>>(tile, values, {
		arrayMerge: combineMerge,
	});

const combineMerge: Options['arrayMerge'] = (target, source, options) => {
	const destination = [...target];
	for (const [index, item] of source.entries()) {
		if (item['deleteOnSubmit'] === true) {
			destination[index] = null;
		} else if (options && destination[index] === undefined) {
			destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
		} else if (options?.isMergeableObject(item)) {
			destination[index] = deepmerge(target[index], item, options);
		} else if (!target.includes(item)) {
			destination.push(item);
		}
	}
	return destination.filter((item) => !!item);
};
