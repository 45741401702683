import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigurationForm } from './configuration-form';
import { ConfigurationFormData } from './types.d';
import { TestGenericParams, validateUuid } from '@neoload/utils';
import { usePushDocumentTitleHandler, useSetSnackbars } from '@neoload/hooks';
import { PatchTestInput, Test, usePatchV4TestsByTestIdMutation } from '@neoload/api';

type ConfigurePageProps = {
	testInfos: ConfigurationFormData;
};

const ConfigurePage = ({ testInfos }: ConfigurePageProps) => {
	const { testId = '' } = useParams<TestGenericParams>();
	validateUuid(testId);
	const { t } = useTranslation(['test']);
	const { showError, showInfo } = useSetSnackbars();
	const [patchTest] = usePatchV4TestsByTestIdMutation();

	usePushDocumentTitleHandler(testInfos.test.name);

	const patch = async (patchTestInput: PatchTestInput): Promise<Test | void> =>
		await patchTest({ testId: testId, patchTestInput: patchTestInput })
			.unwrap()
			.then((test) => {
				if (patchTestInput.name) {
					showInfo({ text: t('messages.updateNameSuccess') });
				} else {
					showInfo({
						text: t('configuration.snackbar.configSuccess'),
						id: 'config_success',
						autoHideDuration: 2000,
					});
				}

				return test;
			})
			.catch((_error) => {
				if (patchTestInput.name) {
					showError({ text: t('messages.updateNameError') });
				} else {
					showError({ text: t('configuration.snackbar.configFail', { name: testInfos.test.name }), id: 'config_fail' });
				}
			});

	return <ConfigurationForm patchTest={patch} defaultValues={testInfos} />;
};

export { ConfigurePage };
