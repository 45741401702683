import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTest, CreateTestModal, TestsDataGrid, useOpenTestExecutionModal } from '@neoload/ui';
import { Test, usePostV4TestsMutation } from '@neoload/api';
import { createNeoLoadError } from '@neoload/utils';
import { useSetSnackbars, useUrlSearchParams } from '@neoload/hooks';

const TestsPage = () => {
	const { t } = useTranslation(['test', 'common']);
	const [createTest] = usePostV4TestsMutation();
	const [{ createTest: createTestParameter }, setUrlSearchParams] = useUrlSearchParams('createTest');
	const [testId, setTestId] = useState<string>('');

	const { showInfo } = useSetSnackbars();
	const { openTestExecutionModal } = useOpenTestExecutionModal();

	const [createModal, setCreateModal] = useState({
		isOpen: false,
		errorMessage: '',
	});

	useEffect(() => {
		if (createTestParameter === 'true') {
			setCreateModal((previousState) => ({ ...previousState, isOpen: true }));
		}
		if (testId !== '') {
			openTestExecutionModal(testId);
			setTestId('');
		}
	}, [testId, createTestParameter, openTestExecutionModal]);

	const openCreateModal = () => {
		setUrlSearchParams({ createTest: 'true' });
	};
	const closeCreateModal = () => {
		setCreateModal({ isOpen: false, errorMessage: '' });
		setUrlSearchParams({ createTest: null });
	};

	const runTestCallback = (test: Test) => {
		if (test.id === testId) {
			openTestExecutionModal(testId);
			setTestId('');
		} else {
			setTestId(test.id);
		}
	};

	const createTestCallback = (createTestValue: CreateTest) => {
		setCreateModal((previousState) => ({ ...previousState, errorMessage: '' }));
		createTest({ testInput: createTestValue })
			.then((d) => {
				if (d.error && 'status' in d.error && d.error.status === 409) {
					const message = t('test:testNameAlreadyExist');
					setCreateModal((previousState) => ({ ...previousState, errorMessage: message }));
				} else {
					if (d.data && 'name' in d.data) {
						showInfo({
							text: t('createConfirm', { name: d.data.name }),
						});
					}
					closeCreateModal();
				}
			})
			.catch((error) => {
				throw createNeoLoadError(error);
			});
	};

	return (
		<>
			<TestsDataGrid runTest={runTestCallback} openCreateModal={openCreateModal} />
			<CreateTestModal
				handleClose={closeCreateModal}
				handleConfirm={createTestCallback}
				open={createModal.isOpen}
				errorMessage={createModal.errorMessage}
			/>
		</>
	);
};

export { TestsPage };
