import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { TestResult, useGetV4ResultsByResultIdLogsQuery } from '@neoload/api';
import { useGetWithRetry } from '@neoload/hooks';
import { shouldResultDoPolling } from '@neoload/utils';

const useGetLogs = (result: Pick<TestResult, 'id' | 'status' | 'qualityStatus'>) => {
	const { id } = result;
	const [pollingInterval, setPollingInterval] = useState(0);
	const location = useLocation();
	const fromTestExecution = location.state?.fromTestExecution;
	const { response, waiting } = useGetWithRetry(
		// TODO react-compiler
		// eslint-disable-next-line react-compiler/react-compiler
		useGetV4ResultsByResultIdLogsQuery,
		{ resultId: id },
		fromTestExecution ? 8 : 0,
		1000,
		{ pollingInterval: pollingInterval }
	);

	const { data: logs } = response;

	useEffect(() => {
		const nextPollingInterval = shouldResultDoPolling(result) ? 2000 : 0;
		if (pollingInterval !== nextPollingInterval) {
			setPollingInterval(nextPollingInterval);
		}
	}, [logs, pollingInterval, result]);
	return { response, waiting };
};
export { useGetLogs };
