import { memo, Fragment } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { StatisticsBox, StatisticsBoxProps } from './statistics-box';
import { TestResultStatistics, useGetV4ResultsByResultIdStatisticsQuery } from '@neoload/api';
import { bytesToBits, valueToString, timeUtils } from '@neoload/utils';

export type StatisticsLayoutProps = {
	resultId: string;
	isRunning: boolean;
	statisticsSamplingInterval?: string;
	isStarting: boolean;
};

function buildStatistics(
	t: TFunction,
	f: (options?: object) => Intl.NumberFormat,
	data: TestResultStatistics
): StatisticsBoxProps[] {
	const {
		totalRequestCountSuccess,
		totalRequestCountFailure,
		totalRequestDurationAverage,
		totalRequestCountPerSecond,
		totalTransactionCountSuccess,
		totalTransactionCountFailure,
		totalTransactionDurationAverage,
		totalTransactionCountPerSecond,
		totalIterationCountSuccess,
		totalIterationCountFailure,
		totalGlobalDownloadedBytes,
		totalGlobalDownloadedBytesPerSecond,
	} = data;

	return [
		{
			title: t('overview.statistics.transactions'),
			left: [
				{
					name: t('overview.statistics.transactionsPerSecond'),
					statisticKey: 'totalTransactionCountPerSecond',
					value: f().format(totalTransactionCountPerSecond),
					dataReady: true,
				},
				{
					name: t('overview.statistics.averageTime'),
					statisticKey: 'totalTransactionDurationAverage',
					value: f({ style: 'unit', unit: 'second', unitDisplay: 'narrow' }).format(totalTransactionDurationAverage),
					dataReady: true,
				},
			],
			right: [
				{
					name: t('overview.statistics.passed'),
					statisticKey: 'totalTransactionCountSuccess',
					value: f().format(totalTransactionCountSuccess),
					dataReady: true,
					color: 'success.main',
				},
				{
					name: t('overview.statistics.failed'),
					statisticKey: 'totalTransactionCountFailure',
					value: f().format(totalTransactionCountFailure),
					dataReady: true,
					color: 'error.main',
				},
			],
		},
		{
			title: t('overview.statistics.requests'),
			left: [
				{
					name: t('overview.statistics.requestsPerSecond'),
					statisticKey: 'totalRequestCountPerSecond',
					value: f().format(totalRequestCountPerSecond),
					dataReady: true,
				},
				{
					name: t('overview.statistics.averageTime'),
					statisticKey: 'totalRequestDurationAverage',
					value: f({ style: 'unit', unit: 'second', unitDisplay: 'narrow' }).format(totalRequestDurationAverage),
					dataReady: true,
				},
			],
			right: [
				{
					name: t('overview.statistics.passed'),
					statisticKey: 'totalRequestCountSuccess',
					value: f().format(totalRequestCountSuccess),
					dataReady: true,
					color: 'success.main',
				},
				{
					name: t('overview.statistics.failed'),
					statisticKey: 'totalRequestCountFailure',
					value: f().format(totalRequestCountFailure),
					dataReady: true,
					color: 'error.main',
				},
			],
		},
		{
			title: t('overview.statistics.throughput'),
			left: [
				{
					name: t('overview.statistics.throughputPerSecond'),
					statisticKey: 'totalGlobalDownloadedBytesPerSecond',
					value: valueToString(bytesToBits(totalGlobalDownloadedBytesPerSecond), 'bitPerSecond', 'BINARY'),
					dataReady: true,
				},
				{
					name: t('overview.statistics.totalThroughput'),
					statisticKey: 'totalGlobalDownloadedBytes',
					value: valueToString(totalGlobalDownloadedBytes, 'byte', 'BINARY'),
					dataReady: true,
				},
			],
		},
		{
			title: t('overview.statistics.vuIterations'),
			left: [
				{
					name: t('overview.statistics.passed'),
					statisticKey: 'totalIterationCountSuccess',
					value: f().format(totalIterationCountSuccess),
					dataReady: true,
					color: 'success.main',
				},
				{
					name: t('overview.statistics.failed'),
					statisticKey: 'totalIterationCountFailure',
					value: f().format(totalIterationCountFailure),
					dataReady: true,
					color: 'error.main',
				},
			],
		},
	];
}

const isDataReady = (
	loading: boolean,
	fetching: boolean,
	myData: TestResultStatistics | undefined,
	isRunning: boolean,
	isStarting: boolean
): myData is TestResultStatistics => !(isStarting || loading || (fetching && !isRunning) || !myData);

function buildLoadingStatistics(t: TFunction): StatisticsBoxProps[] {
	return [
		{
			title: t('overview.statistics.transactions'),
			left: [
				{
					statisticKey: 'totalTransactionCountPerSecond',
					dataReady: false,
				},
				{
					statisticKey: 'totalTransactionDurationAverage',
					dataReady: false,
				},
			],
			right: [
				{
					statisticKey: 'totalTransactionCountSuccess',
					dataReady: false,
				},
				{
					statisticKey: 'totalTransactionCountFailure',
					dataReady: false,
				},
			],
		},
		{
			title: t('overview.statistics.requests'),
			left: [
				{
					statisticKey: 'totalRequestCountPerSecond',
					dataReady: false,
				},
				{
					statisticKey: 'totalRequestDurationAverage',
					dataReady: false,
				},
			],
			right: [
				{
					statisticKey: 'totalRequestCountSuccess',
					dataReady: false,
				},
				{
					statisticKey: 'totalRequestCountFailure',
					dataReady: false,
				},
			],
		},
		{
			title: t('overview.statistics.throughput'),
			left: [
				{
					statisticKey: 'totalGlobalDownloadedBytes',
					dataReady: false,
				},
				{
					statisticKey: 'totalGlobalDownloadedBytesPerSecond',
					dataReady: false,
				},
			],
		},
		{
			title: t('overview.statistics.vuIterations'),
			left: [
				{
					statisticKey: 'totalIterationCountSuccess',
					dataReady: false,
				},
				{
					statisticKey: 'totalIterationCountFailure',
					dataReady: false,
				},
			],
		},
	];
}

const StatisticsLayoutInternal = ({
	resultId,
	isRunning,
	statisticsSamplingInterval,
	isStarting,
}: StatisticsLayoutProps) => {
	const { t, i18n } = useTranslation(['result']);
	const f = (options: object = {}) => new Intl.NumberFormat(i18n.language, { compactDisplay: 'short', ...options });
	const { data, isLoading, isFetching } = useGetV4ResultsByResultIdStatisticsQuery(
		{
			resultId,
		},
		{
			pollingInterval:
				isRunning && statisticsSamplingInterval ? timeUtils.asMilliseconds(statisticsSamplingInterval) : 0,
			skip: isStarting,
		}
	);

	const statistics: StatisticsBoxProps[] = isDataReady(isLoading, isFetching, data, isRunning, isStarting)
		? buildStatistics(t, f, data)
		: buildLoadingStatistics(t);

	return (
		<>
			{statistics.map((s) => (
				<Fragment key={s.title}>
					<Divider orientation='vertical' flexItem sx={{ marginTop: 2 }} />
					<Grid item md={s.right ? 3 : 2} sx={{ paddingX: 2, paddingTop: 2 }} data-testid={s.title}>
						<StatisticsBox {...s} />
					</Grid>
				</Fragment>
			))}
		</>
	);
};

export const StatisticsLayout = memo(StatisticsLayoutInternal);

export const visibleForTesting = {
	isDataReady,
};
