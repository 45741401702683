import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import DriveFileMoveOutlined from '@mui/icons-material/DriveFileMoveOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { ResultMoveDialog } from './move/result-move-dialog';
import { getDeleteToolTip } from './tooltip-utils';
import { ResultStatusData } from '../../data-grid/run-chip';
import { useDeleteResults } from '@neoload/hooks';
import { isAlive, isDeletable, ResultRoutes } from '@neoload/utils';
import { TestResult } from '@neoload/api';

export type ResultHeaderMenuProps = {
	result: ResultStatusData & Pick<TestResult, 'id' | 'locked' | 'name'>;
};

const ResultHeaderMenu = ({ result }: ResultHeaderMenuProps) => {
	const { t } = useTranslation(['result']);
	const deleteResults = useDeleteResults(ResultRoutes.base);

	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(menuAnchorEl);

	const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false);

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMoveClick = () => {
		setMoveDialogOpen(true);
	};

	const handleCloseMenu = () => {
		setMenuAnchorEl(null);
	};

	const handleMoveClose = () => setMoveDialogOpen(false);

	const openDeleteModal = () => {
		handleCloseMenu();
		deleteResults([result]);
	};

	return (
		<>
			<Tooltip arrow title={t('header.menu.tooltip')}>
				<IconButton
					onClick={handleMenuClick}
					data-testid={'result-header-menu-button'}
					aria-label={t('header.menu.button')}
				>
					<MoreVertOutlined />
				</IconButton>
			</Tooltip>
			<Menu
				open={menuOpen}
				anchorEl={menuAnchorEl}
				onClose={handleCloseMenu}
				data-testid={'result-header-menu'}
				sx={{ marginTop: 1 }}
			>
				<MenuItem onClick={handleMoveClick} disabled={isAlive(result)} data-testid={'move-result'}>
					<ListItemIcon>
						<DriveFileMoveOutlined />
					</ListItemIcon>
					<ListItemText>{t('actions.moveTestResults.title', { count: 1 })}</ListItemText>
				</MenuItem>
				<Tooltip arrow title={!isDeletable(result) && getDeleteToolTip(result.locked, isAlive(result), 1, t)}>
					<Grid>
						<MenuItem
							sx={(theme) => ({ color: theme.palette.error.main })}
							onClick={() => openDeleteModal()}
							disabled={!isDeletable(result)}
							data-testid={'delete-result'}
						>
							<Grid display='flex' flexDirection='row'>
								<ListItemIcon>
									<DeleteOutlined sx={(theme) => ({ color: theme.palette.error.main })} />
								</ListItemIcon>
								<ListItemText>{t('header.menu.delete')}</ListItemText>
							</Grid>
						</MenuItem>
					</Grid>
				</Tooltip>
			</Menu>
			<ResultMoveDialog isOpen={moveDialogOpen} onClose={handleMoveClose} movingResultIds={[result.id]} />
		</>
	);
};

export { ResultHeaderMenu };
