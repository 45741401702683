 
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { SlaBadge } from '../../common/sla-badge';
import { Sla, slaUtils } from '@neoload/utils';

export type SlaStatusProps = {
	status?: Sla;
	label?: string;
	variant?: ComponentProps<typeof Typography>['variant'];
	iconVariant?: ComponentProps<typeof Typography>['variant'];
	labelSx?: SxProps<Theme>;
};
export const SlaStatus = ({ status, label, variant, iconVariant, labelSx }: SlaStatusProps) => {
	const { t } = useTranslation('common');

	return (
		<Box sx={{ alignItems: 'center', height: '100%', display: 'flex', justifyContent: 'flex-start' }}>
			<SlaBadge sla={status} size={iconVariant} />
			<Typography
				data-testid='sla-badge-label'
				component='span'
				variant={variant}
				sx={{
					marginLeft: 0.5,
					marginRight: 2,
					textAlign: 'left',
					...labelSx,
				}}
			>
				{label ?? (status ? t(slaUtils.getI18nKey(status)) : '')}
			</Typography>
		</Box>
	);
};
