import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import { useTranslation } from 'react-i18next';
import { AbstractSettingsLayout, MenuItem } from './abstract-settings-layout';
import { UserRoutes } from '@neoload/utils';

const UserLayout = () => {
	const { t } = useTranslation(['menu']);

	const title = t('profile');

	const menuItems: MenuItem[] = [
		{
			title: t('accessTokens'),
			icon: <KeyOutlinedIcon />,
			link: UserRoutes.accessTokens,
			enabled: true,
		},
		{
			title: t('profile'),
			icon: <AccountCircleOutlinedIcon />,
			link: UserRoutes.profile,
			enabled: false,
		},
		{
			title: t('gitRepositories'),
			icon: <BookOutlinedIcon />,
			link: UserRoutes.gitRepositories,
			enabled: false,
		},
	];

	return <AbstractSettingsLayout title={title} menuItems={menuItems} />;
};

export { UserLayout };
