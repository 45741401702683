import { createContext } from 'react';
import { TestResult } from '@neoload/api';

type TestResultContext =
	| {
			testResult: TestResult | null;
	  }
	| undefined;

export const TestResultContext = createContext<TestResultContext>(undefined);
