import { nlwApiV4 } from './nlw-api-v4';

const TAGS = [
	'ElementPercentiles',
	'ElementTimeseries',
	'Features',
	'Dashboard',
	'DashboardTiles',
	'Interval',
	'Me',
	'MonitorElement',
	'MonitorTimeseries',
	'Preferences',
	'ResultTimeseries',
	'Session',
	'TestResult',
	'Tests',
	'Token',
	'UserPathElement',
	'Users',
	'Values',
	'Trends',
	'TrendsConfiguration',
];

const enhancedApiV4 = nlwApiV4.enhanceEndpoints({
	// add your tags here
	addTagTypes: TAGS,
	endpoints: {
		getV4Me: {
			providesTags: (result, _error, _arg) => [{ type: 'Me', id: result?.id }, 'Session'],
		},
		getV4MePreferences: { providesTags: ['Preferences'] },
		patchV4MePreferences: { invalidatesTags: ['Preferences'] },
		getV4MeTokens: { providesTags: ['Token'] },
		postV4MeTokens: { invalidatesTags: ['Token'] },
		deleteV4MeTokensByToken: { invalidatesTags: ['Token'] },
		getV4UsersByUserIdWorkspaces: {
			providesTags: ['Session'],
		},
		postV4Sessions: { invalidatesTags: TAGS },
		deleteV4Sessions: { invalidatesTags: TAGS },
		getV4Results: { providesTags: ['TestResult'] },
		getV4ResultsByResultId: { providesTags: ['TestResult'] },
		patchV4ResultsByResultId: { invalidatesTags: ['TestResult'] },
		deleteV4ResultsByResultId: { invalidatesTags: ['TestResult'] },
		getV4ResultsByResultIdIntervals: { providesTags: ['Interval'] },
		postV4ResultsByResultIdIntervals: { invalidatesTags: ['Interval'] },
		deleteV4ResultsByResultIdIntervalsAndIntervalId: { invalidatesTags: ['Interval'] },
		patchV4ResultsByResultIdIntervalsAndIntervalId: { invalidatesTags: ['Interval'] },
		getV4Tests: { providesTags: ['Tests'] },
		postV4Tests: { invalidatesTags: (_, error) => (error ? [] : ['Tests']) },
		deleteV4TestsByTestId: { invalidatesTags: ['Tests'] },
		patchV4TestsByTestId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'Tests',
					id: arg.testId,
				},
			],
		},
		getV4TestsByTestId: { providesTags: (_result, _error, arg) => [{ type: 'Tests', id: arg.testId }] },
		getV4Dashboards: { providesTags: ['Dashboard'] },
		postV4Dashboards: { invalidatesTags: ['Dashboard'] },
		getV4DashboardsByDashboardId: {
			providesTags: (result, _error, _arg) =>
				result ? [{ type: 'Dashboard', id: result.id }, 'Dashboard'] : ['Dashboard'],
		},
		patchV4DashboardsByDashboardId: { invalidatesTags: ['Dashboard'] },
		deleteV4DashboardsByDashboardId: { invalidatesTags: ['Dashboard'] },
		postV4DashboardsByDashboardIdDuplication: { invalidatesTags: ['Dashboard'] },
		getV4DashboardsByDashboardIdTiles: {
			providesTags: (_result, _error, arg) => [
				{ type: 'DashboardTiles', id: getDashboardTilesByDashboardIdKey(arg.dashboardId) },
			],
		},
		deleteV4DashboardsByDashboardIdTilesAndDashboardTileId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		patchV4DashboardsByDashboardIdTiles: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		deleteV4DashboardsByDashboardIdTiles: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		patchV4DashboardsByDashboardIdTilesAndTileIdSeriesSeriesId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		getV4ResultsByResultIdElementsValues: { providesTags: ['Values'] },
		getV4Users: { providesTags: ['Users'] },
		postV4Users: { invalidatesTags: ['Users'] },
		patchV4UsersByUserId: { invalidatesTags: ['Users'] },
		deleteV4UsersByUserId: { invalidatesTags: ['Users'] },
		postV4UsersByUserIdWorkspaces: {
			invalidatesTags: (_result, _error, arg) => ['Users', { type: 'Me', id: arg.userId }],
		},
		deleteV4UsersByUserIdWorkspacesAndWorkspaceId: {
			invalidatesTags: (_result, _error, arg) => ['Users', { type: 'Me', id: arg.userId }],
		},
		getV4ResultsByResultIdTimeseries: {
			providesTags: (result, _error, arg) => {
				if (arg.fixedWindowDuration || arg.requestToken || result?.nextRequestToken) {
					return []; // Do not cache partial series yet
				}
				return arg.series.map((serie) => ({
					type: 'ResultTimeseries',
					id: getResultTimeseriesKey(arg.resultId, serie, arg.intervalId),
				}));
			},
		},
		getV4ResultsByResultIdElementsAndElementIdTimeseries: {
			providesTags: (result, _error, arg) => {
				if (arg.requestToken || result?.nextRequestToken) {
					return []; // Do not cache partial series yet
				}
				return arg.statistics.map((statistic) => ({
					type: 'ElementTimeseries',
					id: getElementTimeseriesKey(arg.resultId, arg.elementId, statistic, arg.userPathId, arg.intervalId),
				}));
			},
		},
		getV4ResultsByResultIdMonitorsAndMonitorIdTimeseries: {
			providesTags: (result, _error, arg) => {
				if (arg.requestToken || result?.nextRequestToken) {
					return []; // Do not cache partial series yet
				}
				return [
					{
						type: 'MonitorTimeseries',
						id: getMonitorTimeseriesKey(arg.resultId, arg.monitorId, arg.intervalId),
					},
				];
			},
		},
		getV4ResultsByResultIdElements: {
			providesTags: ['UserPathElement'],
		},
		getV4ResultsByResultIdMonitors: {
			providesTags: ['MonitorElement'],
		},
		getV4ResultsByResultIdElementsAndElementIdPercentiles: {
			providesTags: (_result, _error, arg) => [
				{
					type: 'ElementPercentiles',
					id: getElementPercentilesKey(arg.resultId, arg.elementId, arg.userPathId, arg.intervalId),
				},
			],
		},
		patchV4DashboardsByDashboardIdTilesAndDashboardTileId: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		postV4DashboardsByDashboardIdTilesAndTileIdSeries: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'DashboardTiles',
					id: getDashboardTilesByDashboardIdKey(arg.dashboardId),
				},
			],
		},
		getV4MeFeatures: { providesTags: ['Features'] },
		getV4TestsByTestIdTrends: {
			providesTags: (_result, _error, arg) => [
				{
					type: 'Trends',
					id: arg.testId,
				},
			],
		},
		getV4TestsByTestIdTrendsConfiguration: {
			providesTags: (_result, _error, arg) => [
				{
					type: 'TrendsConfiguration',
					id: arg.testId,
				},
			],
		},
		patchV4TestsByTestIdTrendsConfiguration: {
			invalidatesTags: (_result, _error, arg) => [
				{
					type: 'Trends',
					id: arg.testId,
				},
				{
					type: 'TrendsConfiguration',
					id: arg.testId,
				},
			],
		},
		putV4TestsByTestIdTrendsConfiguration: {
			invalidatesTags: (_result, _error, arg) => {
				if (arg.dryRun === true) {
					return [];
				}
				return [
					{
						type: 'Trends',
						id: arg.testId,
					},
					{
						type: 'TrendsConfiguration',
						id: arg.testId,
					},
				];
			},
		},
	},
});

export const {
	useLazyGetV4ResultsByResultIdElementsAndElementIdTimeseriesQuery,
	useLazyGetV4ResultsByResultIdMonitorsAndMonitorIdTimeseriesQuery,
	useLazyGetV4ResultsByResultIdQuery,
	useLazyGetV4ResultsByResultIdStatisticsQuery,
	useLazyGetV4ResultsByResultIdElementsAndElementIdPercentilesQuery,
	useLazyGetV4ResultsByResultIdElementsValuesQuery,
	useLazyGetV4ResultsByResultIdEventsErrorsQuery,
	useLazyGetV4ResultsByResultIdSlasQuery,
	useLazyGetV4ResultsByResultIdElementsQuery,
	useLazyGetV4ResultsByResultIdMonitorsQuery,
	useLazyGetV4TestsByTestIdTrendsConfigurationQuery,
	useLazyGetV4ScmRepositoriesByRepositoryIdReferencesQuery,
	useLazyGetV4ResultsByResultIdMonitorsValuesQuery,
} = enhancedApiV4;

const getDashboardTilesByDashboardIdKey = (dashboardId: string) => `TILES-OF-DASHBOARD-${dashboardId}`;

const getResultTimeseriesKey = (resultId: string, serie: string, intervalId?: string) =>
	JSON.stringify([resultId, serie, intervalId]);

const getElementTimeseriesKey = (
	resultId: string,
	elementId: string,
	statistic: string,
	userPathId?: string,
	intervalId?: string
) => JSON.stringify([resultId, elementId, statistic, userPathId, intervalId]);

const getMonitorTimeseriesKey = (resultId: string, monitorId: string, intervalId?: string) =>
	JSON.stringify([resultId, monitorId, intervalId]);

const getElementPercentilesKey = (resultId: string, elementId: string, userPathId?: string, intervalId?: string) =>
	JSON.stringify([resultId, elementId, userPathId, intervalId]);
