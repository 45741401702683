import sanitize from 'sanitize-filename';

/**
 * @param suffix a suffix to characterize the file name
 * @returns a sanitized and timestamped string to use as a filename in the format yyyyMMdd_hhmmss_{@param suffix}
 */
export const buildTimestampedFilename = (suffix: string) => {
	const currentDate = new Date();
	const year = currentDate.getFullYear().toString().padStart(4, '0');
	const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
	const date = currentDate.getDate().toString().padStart(2, '0');
	const hours = currentDate.getHours().toString().padStart(2, '0');
	const minutes = currentDate.getMinutes().toString().padStart(2, '0');
	const seconds = currentDate.getSeconds().toString().padStart(2, '0');

	const timestamp = `${year}${month}${date}_${hours}${minutes}${seconds}`;
	const sanitizedSuffix = sanitize(suffix, {
		replacement: '_',
	});

	return `${timestamp}_${sanitizedSuffix}`;
};
