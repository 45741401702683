import { Chart, TooltipFormatterCallbackFunction, TooltipFormatterContextObject } from 'highcharts';
import { useEffect, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';

const generateTooltipId = (chartId: number) => `highcharts-custom-tooltip-${chartId}`;

type CustomChartTooltipProps = {
	chart: Chart | null;
	children(formatterContext: TooltipFormatterContextObject): ReactNode;
};

export const CustomChartTooltip = ({ chart, children }: CustomChartTooltipProps) => {
	const [context, setContext] = useState<TooltipFormatterContextObject | null>(null);

	useEffect(() => {
		if (chart) {
			const formatter: TooltipFormatterCallbackFunction = function () {
				setContext(this ?? null);
				return `<div id="${generateTooltipId(chart.index)}" role="tooltip"></div>`;
			};

			chart.update({
				tooltip: {
					formatter,
					style: {
						opacity: 0,
					},
				},
			});
		}
	}, [chart]);

	const node = chart && document.querySelector(`#${generateTooltipId(chart.index)}`);
	return node && context ? ReactDOM.createPortal(children(context), node) : null;
};
