import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ZoneRow } from './zone-row';
import { ConfigurationFormData, ConfigurationZoneFormData } from '../types.d';
import { Zone } from '@neoload/api';

const filterZones = (zones: ConfigurationZoneFormData[], zoneTypeSelected: Zone['type']) =>
	zones.filter((value) => value.type === zoneTypeSelected);

const Zones = () => {
	const { t } = useTranslation(['test']);
	const zoneTypeSelected = useWatch<ConfigurationFormData, 'zones.type'>({ name: 'zones.type' });
	const { setValue } = useFormContext();
	const controllerSelected = useWatch<ConfigurationFormData, 'zones.controller'>({ name: 'zones.controller' });
	const zones = useWatch<ConfigurationFormData, 'zones.items'>({ name: 'zones.items' });
	const [previous, setPrevious] = useState<{
		zones: ConfigurationZoneFormData[];
		controller: string;
		zoneType: Zone['type'] | '';
	}>({ controller: '', zoneType: '', zones: [] });

	const handleZoneTypeChange = useCallback(() => {
		for (let index = 0, max = zones.length; index < max; index++) {
			setValue(`zones.items.${index}.number`, 0);
		}
		const filteredZones = filterZones(zones, zoneTypeSelected);
		if (filteredZones.length > 0) {
			setValue('zones.controller', filteredZones[0].zoneId);
		}
		if (filteredZones.length === 1) {
			const z = filteredZones[0];
			z.number = 1;
			const index = zones.findIndex((value) => value.zoneId === filteredZones[0].zoneId);
			setValue(`zones.items.${index}.number`, 1);
		}
	}, [setValue, zoneTypeSelected, zones]);

	const handleControllerChange = useCallback(() => {
		if (zoneTypeSelected === 'DYNAMIC') {
			for (const [index, { zoneId }] of zones.entries()) {
				if (zoneId === controllerSelected) {
					setValue(`zones.items.${index}.number`, 1);
				} else {
					setValue(`zones.items.${index}.number`, 0);
				}
			}
		}
	}, [controllerSelected, setValue, zoneTypeSelected, zones]);

	const handleZoneChange = useCallback(() => {
		if (zoneTypeSelected === 'NCP') {
			const filteredZones = filterZones(zones, zoneTypeSelected);
			if (filteredZones.length > 0) {
				const max = filteredZones.toSorted((a, b) => b.number - a.number)[0];
				if (controllerSelected !== max.zoneId) {
					setValue('zones.controller', max.zoneId);
				}
			}
		}
	}, [controllerSelected, setValue, zoneTypeSelected, zones]);

	useEffectOnce(() => {
		if (previous.controller !== '' && previous.controller !== controllerSelected) {
			handleControllerChange();
		}
	});

	useEffect(() => {
		if (previous.controller !== '' && previous.controller !== controllerSelected) {
			handleControllerChange();
		}

		if (previous.zoneType !== '' && previous.zoneType !== zoneTypeSelected) {
			handleZoneTypeChange();
		}

		if (previous.zones.length > 0) {
			handleZoneChange();
		}

		setPrevious(() => ({
			zoneType: zoneTypeSelected,
			controller: controllerSelected,
			zones: zones,
		}));
	}, [
		zoneTypeSelected,
		controllerSelected,
		zones,
		previous.controller,
		previous.zoneType,
		previous.zones.length,
		setValue,
		handleZoneTypeChange,
		handleControllerChange,
		handleZoneChange,
	]);

	const filteredZones = filterZones(zones, zoneTypeSelected);

	if (filteredZones.length === 0) {
		const noZoneMessage = zoneTypeSelected === 'DYNAMIC' ? t('configuration.zones.noZones.dynamic') : t('configuration.zones.noZones.ncp');
		return (
			<Box>
				<Typography variant='body1'>{noZoneMessage}</Typography>
			</Box>
		);
	}

	return (
		<Stack gap={1} useFlexGap sx={{ minWidth: '500px', maxWidth: 'max-content' }}>
			<Stack direction='row'>
				<Typography variant='caption' sx={{ flex: '1', marginLeft: 2 }}>
					{t('configuration.zones.name')}
				</Typography>
				<Typography variant='caption' sx={{ width: '96px' }}>
					{t('configuration.zones.lg')}
				</Typography>
				<Typography variant='caption' sx={{ width: '70px' }}>
					{t('configuration.zones.controller')}
				</Typography>
			</Stack>
			{zones.map(
				(zone, index) =>
					zone.type === zoneTypeSelected && <ZoneRow index={index} key={zone.zoneId} zone={zone} disabledRemoved />
			)}
		</Stack>
	);
};

export { Zones };
