/* eslint-disable @typescript-eslint/naming-convention */
import type { SlaThreshold } from '@neoload/api';

export const slaIdentifierTranslations: Record<SlaThreshold['identifier'], string> = {
	AVERAGE_REQUEST_RESPONSE_TIME: 'averageRequestResponseTime',
	AVERAGE_PAGE_RESPONSE_TIME: 'averagePageResponseTime',
	AVERAGE_CONTAINER_RESPONSE_TIME: 'averageContainerResponseTime',
	PERCENTILE_CONTAINER_RESPONSE_TIME: 'percentileContainerResponseTime',
	AVERAGE_HITS_PER_SECOND: 'averageHitsPerSecond',
	AVERAGE_THROUGHPUT_PER_SECOND: 'averageThroughputPerSecond',
	TOTAL_ERRORS: 'totalErrors',
	TOTAL_HITS: 'totalHits',
	TOTAL_THROUGHPUT: 'totalThroughput',
	AVERAGE_RESPONSE_TIME: 'averageResponseTime',
	ERRORS_PER_SECOND: 'errorsPerSecond',
	ERROR_PERCENTILE: 'errorPercentile',
};
