import { useGridApiRef } from '@mui/x-data-grid-pro';
import { GridColumns } from '@mui/x-data-grid/models';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useIntervalsDatagridColumns } from './intervals-datagrid-columns';
import { Datagrid } from '../../../../../common/data-grid/datagrid';
import { defaultGridProps, onColumnChange } from '../../../../../common/datagrid';
import { dataGridStyle } from '../../../values/values-data-grid-common';
import { Interval, IntervalPage } from '@neoload/api';
import { useColumnsState } from '@neoload/hooks';
import { defaultGridHeaderHeight, getRowHeight } from '@neoload/utils';
import './intervals-data-grid.css';

const intervalsColumnsKey = 'INTERVALS_COLUMNS_STATE';

export type IntervalsDataGridProps = {
	intervals: IntervalPage;
	resultId: string;
};

const IntervalsDataGrid = ({ intervals, resultId }: IntervalsDataGridProps) => {
	const { t } = useTranslation(['result']);
	const columns: GridColumns<Interval> = useIntervalsDatagridColumns(resultId);
	const apiRef = useGridApiRef();

	// We need to compute the exact grid height to avoid pagination or scrolling
	const gridSize = defaultGridHeaderHeight + getRowHeight(1) * intervals.total;

	const initialState = {};

	const { updatedColumns, updatedInitialState, storeColumnState } = useColumnsState(
		intervalsColumnsKey,
		initialState,
		columns,
		apiRef
	);
	return (
		<>
			<Grid item marginLeft={2} marginBottom={2}>
				<Typography variant={'subtitle1'}>{t('intervals.gridHeader')}</Typography>
			</Grid>
			<Grid item>
				<Box sx={{ height: gridSize + 'px' }}>
					<Datagrid
						apiRef={apiRef}
						{...defaultGridProps}
						{...onColumnChange(storeColumnState)}
						rows={intervals.items}
						rowCount={intervals.total}
						columns={updatedColumns}
						initialState={updatedInitialState}
						pageSize={intervals.total}
						sx={{ ...dataGridStyle }}
						hideFooter={true}
						density={'standard'}
						getRowClassName={(params) => `interval-grid-${params.row.hidden ? 'hidden' : 'shown'}-row`}
					/>
				</Box>
			</Grid>
		</>
	);
};

export { IntervalsDataGrid };
