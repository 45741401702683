import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getTranslationKeyToValidateDashboardName } from '../dashboard-common';
import { useCurrentWorkspace } from '@neoload/hooks';
import { DashboardInput } from '@neoload/api';

type FormProps = {
	label: {
		value: string;
		error?: string;
		touched?: true;
	};
	touched?: true;
};
const emptyForm = (): FormProps => ({
	label: { value: '' },
});

type DashboardCreateDialogProps = {
	isOpen: boolean;
	onClose: (okButton: boolean, dashboardInput?: DashboardInput) => void;
};
const DashboardCreateDialog = ({ isOpen, onClose }: DashboardCreateDialogProps) => {
	const { t } = useTranslation(['dashboard']);

	const [form, setForm] = useState<FormProps>(emptyForm());
	const [currentWorkspace] = useCurrentWorkspace();

	const getLabelError = (name: string): string | undefined => {
		const translationKey = getTranslationKeyToValidateDashboardName(name);
		return translationKey ? t(translationKey) : undefined;
	};

	const onLabelChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;
		setForm({
			...form,
			label: {
				value,
				error: getLabelError(value),
				touched: true,
			},
			touched: true,
		});
	};

	const isFormValid = () => !getLabelError(form.label.value);

	const cancel = () => {
		onClose(false);
		setForm(emptyForm());
	};

	const create = () => {
		const dashboardInput: DashboardInput = { name: form.label.value, workspaceId: currentWorkspace.id };
		onClose(true, dashboardInput);
		setForm(emptyForm());
	};

	const onPressEnter = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			create();
		}
	};

	return (
		<Dialog open={isOpen}>
			<DialogTitle>{t('createDialog.title')}</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3,
						marginTop: 1,
						width: 500,
					}}
				>
					<FormControl onKeyDown={onPressEnter} fullWidth required>
						<TextField
							name='label'
							required
							placeholder={t('createDialog.label.placeholder') ?? ''}
							label={t('createDialog.label.label')}
							error={!!form.label.error}
							helperText={form.label.error}
							onChange={onLabelChange}
							data-testid='dashboard.createDialog.label'
						/>
					</FormControl>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancel} color='primary'>
					{t('common:cancel')}
				</Button>
				<Button onClick={create} disabled={!isFormValid()} variant='contained' data-testid='dashboard.createDialog.ok'>
					{t('createDialog.okButton')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { DashboardCreateDialog };
