import { createContext } from 'react';
import { TableData } from './download-values-comparison';

type TableDownloadContextType = {
	setDataToDownload: (dataToDownload: TableData) => void;
};

export const CsvDownloadContext = createContext<TableDownloadContextType>({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setDataToDownload: (_dataToDownload: TableData) => {},
});
