import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $getRoot, $isTextNode } from 'lexical';
import { useRef } from 'react';

type LoadInitialContentProps = { value?: string };

export const useLoadInitialContent = ({ value }: LoadInitialContentProps) => {
	const [editor] = useLexicalComposerContext();
	const hasBeenCalledRef = useRef(false);

	if (!hasBeenCalledRef.current && value) {
		editor.update(() => {
			const parser = new DOMParser();
			const dom = parser.parseFromString(value, 'text/html');
			const nodes = $generateNodesFromDOM(editor, dom);
			const root = $getRoot();
			root.clear();
			for (const n of nodes) {
				if ($isTextNode(n)) {
					const paragraphNode = $createParagraphNode();
					paragraphNode.append(n);
					root.append(paragraphNode);
				} else {
					root.append(n);
				}
			}	
			root.selectEnd();
		});
		hasBeenCalledRef.current = true;
	}
};
