import { DeepPartial, DefaultValues, FieldNamesMarkedBoolean, FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { ReactNode, useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { TileDetailsAccordion } from './tile-details-accordion';
import { TileTextAccordion } from './tile-text-accordion';
import { SeriesForm } from './series/series-form';
import { TileDataAccordion } from './tile-data-accordion';
import { ComparisonForm } from './comparison/comparison-form';
import { matchAndReplaceWithI18N } from '../../../dashboard-common';
import {
	DashboardFilter,
	DashboardSeries,
	DashboardTile,
	TextDashboardTile,
	ValuesComparisonDashboardTile,
	WidgetDashboardTile,
} from '@neoload/api';

type CommonTileEditionFormFields = {
	title: string;
};

export type ExistingSeriesFormFields = {
	id: string;
	visible: boolean;
	color: string;
	deleteOnSubmit: boolean;
};
export type NewSeriesFormFields = ExistingSeriesFormFields & {
	legend?: DashboardSeries['legend'];
	resultId?: DashboardSeries['resultId'];
	resultName?: DashboardSeries['resultName'];
	filter?: DashboardSeries['filter'];
	statistic?: DashboardSeries['statistic'];
	color: string;
};

export type SeriesToEditFormFields = ExistingSeriesFormFields | NewSeriesFormFields;

export type SeriesToAddFormFields =
	| {
			elementId: string;
			userPathId?: string;
			type: 'USER_PATH';
	  }
	| {
			monitorId: string;
			type: 'MONITOR';
	  };

export type SeriesTileEditionFormFields = {
	type: 'SERIES';
	series: SeriesToEditFormFields[];
} & CommonTileEditionFormFields;

export type ComparisonTileEditionFormFields = {
	type: 'VALUES_COMPARISON';
} & CommonTileEditionFormFields &
	Pick<ValuesComparisonDashboardTile, 'columns' | 'differenceType' | 'rows'>;

type TextTileEditionFormFields = {
	type: 'TEXT';
	text: string;
} & CommonTileEditionFormFields;

type WidgetTileEditionFormFields = {
	type: 'WIDGET';
	filter: DashboardFilter;
	resultId: string;
	resultName: string;
	visualization: 'LINE_CHART' | 'TABLE' | 'TEXT' | 'PIE_CHART' | 'COUNTER' | 'SUMMARY' | 'CATEGORY';
	testId?: string;
	testName?: string;
} & CommonTileEditionFormFields;

export type TileEditionFormFields =
	| SeriesTileEditionFormFields
	| TextTileEditionFormFields
	| WidgetTileEditionFormFields
	| ComparisonTileEditionFormFields;

export type TileEditionFormProps = {
	tile: DashboardTile;
	onSubmit: (formFields: TileEditionFormFields) => void;
	onCancel: () => void;
	onFormValuesChange: (formFields: DeepPartial<TileEditionFormFields>) => void;
	reportBenchId?: string;
};

export type TileEditionFormBaseProps<T extends TileEditionFormFields> = {
	onSubmit: (formFields: T) => void;
	onCancel: () => void;
	onFormValuesChange: (formFields: DeepPartial<T>) => void;
	onAddSeriesClick?: () => void;
};

export const TileEditionForm = ({ tile, reportBenchId, ...rest }: TileEditionFormProps) => {
	if (tile.type === 'SERIES') {
		return <SeriesForm tile={tile} {...rest} />;
	}
	if (tile.type === 'TEXT') {
		return <TextForm tile={tile} {...rest} />;
	}
	if (tile.type === 'VALUES_COMPARISON') {
		return <ComparisonForm tile={tile} reportBenchId={reportBenchId} {...rest} />;
	}
	if (tile.type === 'WIDGET') {
		return <WidgetForm tile={tile} {...rest} />;
	}
	throw new Error('Tile type not recognized');
};

export const TextForm = ({
	tile,
	...rest
}: { tile: TextDashboardTile } & TileEditionFormBaseProps<TextTileEditionFormFields>) => (
	<FormTemplate
		{...rest}
		defaultValues={{
			title: matchAndReplaceWithI18N(tile.title),
			type: 'TEXT',
			text: tile.text,
		}}
		mainForm={<TileTextAccordion />}
		hasTitle={false}
	/>
);

export const WidgetForm = ({
	tile,
	...rest
}: { tile: WidgetDashboardTile } & TileEditionFormBaseProps<WidgetTileEditionFormFields>) => {
	const widget = useMemo(() => <TileDataAccordion dashboardTile={tile} />, [tile]);
	return (
		<FormTemplate
			{...rest}
			defaultValues={{
				title: matchAndReplaceWithI18N(tile.title),
				type: 'WIDGET',
				filter: tile.filter,
				resultId: tile.resultId,
				resultName: tile.resultName,
				visualization: tile.visualization,
				testId: tile.testId,
				testName: tile.testName,
			}}
			mainForm={widget}
		/>
	);
};

export type FormTemplateProps<T extends TileEditionFormFields> = {
	defaultValues: DefaultValues<T>;
	hasTitle?: boolean;
	mainForm: ReactNode;
	onSubmit: (formFields: T, dirtyValues: Partial<Readonly<FieldNamesMarkedBoolean<T>>>) => void;
	onCancel: () => void;
	onFormValuesChange: (formFields: DeepPartial<T>, dirtyValues: Partial<Readonly<FieldNamesMarkedBoolean<T>>>) => void;
	onAddSeriesClick?: () => void;
	canApply?: (form: T) => boolean;
};

export const FormTemplate = <T extends TileEditionFormFields>({
	defaultValues,
	hasTitle = true,
	onSubmit,
	onCancel,
	onFormValuesChange,
	canApply: canApplyHandler,
	mainForm,
}: FormTemplateProps<T>) => {
	const { t } = useTranslation(['dashboard']);
	const { handleSubmit, formState, watch, ...methods } = useForm<T>({
		mode: 'onChange',
		defaultValues,
	});
	const { dirtyFields, isValid } = formState;

	useEffect(() => {
		const subscription = watch((values) => {
			onFormValuesChange(values, dirtyFields);
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [watch, onFormValuesChange, dirtyFields]);

	const canApply = (form: T) => (canApplyHandler ? canApplyHandler(form) : true);
	return (
		<>
			<Typography
				variant='h6'
				sx={{ paddingX: (theme) => theme.spacing(2), position: 'absolute', top: (theme) => theme.spacing(1) }}
			>
				{t('tile.edition.headerTitle')}
			</Typography>
			<FormProvider {...methods} handleSubmit={handleSubmit} formState={formState} watch={watch}>
				<form
					aria-label={t('tile.edition.accessibleTitle')}
					onSubmit={handleSubmit((form) => {
						onSubmit(form, dirtyFields);
					})}
					onReset={() => {
						onCancel();
					}}
					style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
				>
					<Stack>
						{hasTitle && <TileDetailsAccordion />}
						<Divider />
						{mainForm}
						<Divider />
					</Stack>
					<Stack direction={'row'} spacing={1} padding={2} style={{ marginTop: 'auto' }}>
						<Button type='submit' disabled={!isValid || !canApply(methods.getValues())} variant='contained'>
							{t('common:apply')}
						</Button>
						<Button type='reset' color='primary'>
							{t('common:cancel')}
						</Button>
					</Stack>
				</form>
			</FormProvider>
		</>
	);
};
