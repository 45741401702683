/* eslint-disable react-compiler/react-compiler */
// TODO - Fix react-compiler
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SlaPerInterval, SlaPerRun, TestResult, useGetV4ResultsByResultIdSlasQuery } from '@neoload/api';

type AllowedSlaTypes = 'PER_RUN' | 'PER_TIME_INTERVAL';

type RealSlaType<T extends AllowedSlaTypes> = T extends 'PER_RUN'
	? SlaPerRun
	: T extends 'PER_TIME_INTERVAL'
	? SlaPerInterval
	: never;

type SlasResponses<T extends AllowedSlaTypes> = {
	isLoading: boolean;
	isFetching: boolean;
	error?: FetchBaseQueryError | SerializedError;
	data:
		| {
				items: RealSlaType<T>[];
				total: number;
				pageNumber?: number;
				pageSize?: number;
				statuses: Record<'FAILED' | 'WARN' | 'SUCCESS', number>;
		  }
		| undefined;
};

const splitByStatus = (statuses: string[]): Record<'FAILED' | 'WARN' | 'SUCCESS', number> => ({
	FAILED: statuses.filter((a) => a === 'FAILED').length,
	WARN: statuses.filter((a) => a === 'WARN').length,
	SUCCESS: statuses.filter((a) => a === 'SUCCESS').length,
});

const getRowsLengths = <T extends AllowedSlaTypes>(data: SlasResponses<T>['data']) => (data ? data?.items.length : 0);
const defaultStatuses: Record<'FAILED' | 'WARN' | 'SUCCESS', number> = { FAILED: 0, WARN: 0, SUCCESS: 0 };

export const useGetSlas = <T extends AllowedSlaTypes>(
	result: { id: string; status: TestResult['status']; qualityStatus: TestResult['qualityStatus'] },
	slaType: T,
	elementType: ('SCENARIO' | 'TRANSACTION' | 'PAGE' | 'ACTION' | 'REQUEST')[] = []
): SlasResponses<T> => {
	const [data, setData] = useState<SlasResponses<T>['data']>();
	const [currentPage, setCurrentPage] = useState(0);
	const maxPageSize = 200;

	const {
		isLoading,
		isFetching,
		error,
		data: fetchedData,
	} = useGetV4ResultsByResultIdSlasQuery(
		{ resultId: result.id, slaType, pageSize: maxPageSize, pageNumber: currentPage, elementType },
		{ skip: getRowsLengths<T>(data) === data?.total }
	);

	useEffect(() => setData(undefined), [result.id]);

	useEffect(() => {
		if (fetchedData) {
			const { items, total, pageNumber, pageSize } = fetchedData;
			if (pageNumber === currentPage) {
				const fetchNextPage = getRowsLengths(data) + items.length < total;
				setData((previous) => ({
					items: [...(previous?.items ?? []), ...(items as RealSlaType<T>[])],
					total,
					pageNumber,
					pageSize,
					statuses: defaultStatuses,
				}));
				if (fetchNextPage) {
					setCurrentPage((previous) => previous + 1);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedData]);
	return {
		isLoading,
		isFetching: isFetching || !data || data.items.length < data.total,
		error,
		data: data && { ...data, statuses: splitByStatus(data.items.map(({ status }) => status)) },
	};
};
