import { ReactNode, useCallback, useEffect, useRef, useState, forwardRef, Ref, useImperativeHandle } from 'react';
import Highcharts, { SeriesLineOptions, TooltipFormatterContextObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsXRange from 'highcharts/modules/xrange';
import HighchartsDraggable from 'highcharts/modules/draggable-points';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { getCommonChartOptions } from './chart-config';
import { CustomChartTooltip } from './custom-chart-tooltip';

HighchartsAccessibility(Highcharts);
HighchartsXRange(Highcharts);
HighchartsDraggable(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

Highcharts.Pointer.prototype.reset = () => undefined; // Prevent reset tooltip when out of the graph

type XAxisType = 'TIMESERIES' | 'PERCENTILES' | 'CATEGORY';

type SeriesBase = {
	title: string;
	visible: boolean;
	color: string;
	legendItemClick?: () => unknown;
};

type ValidSeries = {
	isError: false;
	points: [number | string, number | null][];
	targetValue?: number;
	axis: {
		x: {
			type: XAxisType;
		};
		y: {
			displayLegend: boolean;
			unit: string;
		};
	};
	tooltip?: {
		headerLabel?: string;
		footerLabel?: string;
	};
	isStep?: boolean;
};

type InvalidSeries = {
	isError: true;
	error: string;
};

type Series = SeriesBase & (ValidSeries | InvalidSeries);

type ChartGlobalConfiguration = {
	axis: {
		y: {
			maximumDisplayedCount: number | 'unlimited';
		};
	};
	noDataLabel?: {
		i18n: string;
	};
	tooltip: {
		shared: boolean;
	};
};

type ChartProps = {
	series: Series[];
	configuration: ChartGlobalConfiguration;
	tooltipComponent?: (formatterContext: TooltipFormatterContextObject) => ReactNode;
};

const disabledLegendItemClickCallback = () => false;

const containsPercentilesSeries = (seriesArray: Series[]): boolean =>
	seriesArray.some((series) => !series.isError && series.axis.x.type === 'PERCENTILES');
const containsCategorySeries = (seriesArray: Series[]): boolean =>
	seriesArray.some((series) => !series.isError && series.axis.x.type === 'CATEGORY');

const buildTooltipHeaderFormat = (series: ValidSeries): string => {
	if (series.axis.x.type === 'PERCENTILES') {
		return '{point.key} % - ';
	}
	if (series.axis.x.type === 'CATEGORY') {
		return `{point.key}<br />${series.tooltip?.headerLabel}<br /><br />`;
	}
	return '{point.key} - ';
};

const getXAxisOptions = (
	seriesArray: Series[],
	theme: Theme
): Highcharts.XAxisOptions | Highcharts.XAxisOptions[] | undefined => {
	const { xAxis } = getCommonChartOptions(theme);
	if (containsPercentilesSeries(seriesArray)) {
		const labels = xAxis && !Array.isArray(xAxis) ? xAxis.labels : {};
		return {
			...xAxis,
			type: 'linear',
			labels: {
				...labels,
				format: '{text} %',
			},
		};
	}
	if (containsCategorySeries(seriesArray)) {
		return {
			...xAxis,
			type: 'category',
			crosshair: false,
		};
	}
	return xAxis;
};

const getMaxSeriesValue = (series: ValidSeries) =>
	Math.max(...series.points.map((point) => point[1]).filter((value): value is number => value !== null));

const getYAxisOptions = (
	seriesArray: Series[],
	configuration: ChartGlobalConfiguration,
	theme: Theme
): Highcharts.YAxisOptions[] => {
	let seriesWithVisibleYAxis = seriesArray.filter(({ visible }) => visible);
	if (configuration.axis.y.maximumDisplayedCount !== 'unlimited') {
		seriesWithVisibleYAxis = seriesWithVisibleYAxis.slice(0, configuration.axis.y.maximumDisplayedCount);
	}
	return seriesArray.map((series, index) => ({
		title: {
			text: getYAxisTitleText(series),
		},
		color: series.color,
		allowDecimals: true,
		showEmpty: true,
		visible: seriesWithVisibleYAxis.includes(series),
		opposite: index % 2 !== 0,
		startOnTick: true,
		endOnTick: true,
		gridLineWidth: 1,
		gridLineDashStyle: 'Dash',
		gridLineColor: theme.palette.divider,
		min: 0,
		max: series.isError ? undefined : Math.max(series.targetValue ?? 0, getMaxSeriesValue(series)),
		labels: {
			format: '{value:.1f}',
			style: {
				color: series.color,
			},
		},
		plotLines:
			!series.isError && series.targetValue
				? [
						{
							color: series.color,
							dashStyle: 'Dash',
							value: series.targetValue,
							zIndex: 1,
						},
				  ]
				: undefined,
	}));
};

const getYAxisTitleText = (series: Series) => {
	if (series.isError) {
		return null;
	}
	if (!series.axis.y.displayLegend) {
		return null;
	}
	return series.axis.y.unit;
};

const getSeriesOptionsType = (seriesArray: Series[]): Highcharts.SeriesOptionsType[] =>
	seriesArray.map((series) => toSeriesOptionsType(series, seriesArray));

const toSeriesOptionsType = (series: Series, seriesArray: Series[]): Highcharts.SeriesOptionsType => {
	let data: SeriesLineOptions['data'];
	let step: SeriesLineOptions['step'];
	let footerFormat: NonNullable<SeriesLineOptions['tooltip']>['footerFormat'];
	let valueSuffix: NonNullable<SeriesLineOptions['tooltip']>['valueSuffix'];
	if (!series.isError) {
		data = series.points;
		step = series.isStep ? 'left' : undefined;
		footerFormat = series.tooltip?.footerLabel;
		valueSuffix = series.axis.y.unit.trim().length > 0 ? ' ' + series.axis.y.unit : '';
	}
	return {
		data,
		name: series.title,
		type: 'line',
		step: step,
		visible: series.visible,
		yAxis: seriesArray.indexOf(series),
		color: series.color,
		marker: {
			enabled: false,
		},
		tooltip: {
			headerFormat: series.isError ? undefined : buildTooltipHeaderFormat(series),
			footerFormat: footerFormat,
			valueSuffix: valueSuffix,
			valueDecimals: 3,
		},
		stickyTracking: true,
		events: {
			legendItemClick: series.legendItemClick ?? disabledLegendItemClickCallback,
		},
	};
};

type ChartRef = {
	downloadPng: (fileName: string, title: string) => void;
};

/**
 * @param ref pass a ref to the Chart to get acces to method allowing to export the chart as PN or other formats in the future.
 * You can take a look at `TestTrendsGraphSection` for usage example.
 */
const Chart = forwardRef((props: ChartProps, ref: Ref<ChartRef>) => {
	const { t } = useTranslation(['common']);
	const theme = useTheme();
	const currentChart = useRef<HighchartsReact.RefObject>(null);
	const xAxisOptions = getXAxisOptions(props.series, theme);
	const commonChartOptions = getCommonChartOptions(theme);
	const [yAxisOptions, setYAxisOptions] = useState(getYAxisOptions(props.series, props.configuration, theme));
	const [seriesOptionsType, setSeriesOptionsType] = useState(getSeriesOptionsType(props.series));
	const [series, setSeries] = useState(props.series);

	useImperativeHandle(
		ref,
		() => ({
			downloadPng: (fileName, title) =>
				currentChart.current?.chart.exportChartLocal(
					{
						filename: fileName,
					},
					{
						title: {
							text: title,
						},
						legend: {
							itemStyle: {
								fontFamily: 'Arial',
							},
						},
						chart: {
							backgroundColor: 'rgba(255, 255, 255, 255)',
							style: {
								fontFamily: 'Arial',
							},
						},
					}
				),
		}),
		[]
	);

	const doUpdateChart = useCallback(
		(seriesList: Series[], configuration: ChartGlobalConfiguration) => {
			setSeries(seriesList);
			setYAxisOptions(getYAxisOptions(seriesList, configuration, theme));
			setSeriesOptionsType(getSeriesOptionsType(seriesList));
		},
		[theme]
	);

	useEffect(() => {
		doUpdateChart(props.series, props.configuration);
	}, [doUpdateChart, props.configuration, props.series]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const clearTooltipAndMarker = (mouseLeaveEvent: any) => {
		// Hide tooltips and clear selected point in the graph
		const chart = currentChart?.current?.chart;
		if (chart) {
			chart.tooltip.hide();
			chart.xAxis[0].hideCrosshair();
			const event = chart.pointer.normalize(mouseLeaveEvent);
			if (event) {
				for (const series of chart.series) {
					series.setState('');
					const point = series.searchPoint(event, true);
					if (point && series.visible) {
						point.setState('');
						point.onMouseOut();
					}
				}
			}
		}
	};

	useEffect(() => {
		const handleLegendMouseEvent = (event: MouseEvent): void => {
			if (event.target instanceof Element) {
				const legend = event.target.closest('.highcharts-legend');
				if (legend) {
					clearTooltipAndMarker(event);
				}
			}
		};
		document.addEventListener('mouseover', handleLegendMouseEvent);
		return () => {
			document.removeEventListener('mouseover', handleLegendMouseEvent);
		};
	}, []);

	const options: Highcharts.Options = {
		...commonChartOptions,
		chart: {
			...commonChartOptions.chart,
			spacingBottom: 0,
		},
		title: undefined,
		xAxis: xAxisOptions,
		yAxis: yAxisOptions,
		series: seriesOptionsType,
		legend: {
			...commonChartOptions.legend,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			useHTML: true,
			labelFormatter: function () {
				const thisSeries = series[this.index];
				const label = thisSeries.title;
				if (thisSeries.visible && thisSeries.isError) {
					return `<span style="color: ${theme.palette.error.main}">${label}</span>`;
				}
				return label;
			},
			margin: 0,
		},
		plotOptions: {
			line: {
				legendSymbol: 'rectangle',
			},
		},
		tooltip: {
			...commonChartOptions.tooltip,
			outside: true,
			shared: props.configuration.tooltip.shared,
			followPointer: true,
		},
		exporting: {
			fallbackToExportServer: false,
			sourceWidth: 1800,
			sourceHeight: 1000,
			scale: 1,
			buttons: {
				contextButton: {
					enabled: false,
				},
			},
		},
	};

	if (series.length === 0) {
		return (
			<Typography align={'center'} variant={'body2'}>
				{t(props.configuration.noDataLabel?.i18n ?? 'chart.noData')}
			</Typography>
		);
	}

	return (
		<Box onMouseLeave={clearTooltipAndMarker} paddingX={1} sx={{ width: '100%', height: '100%' }}>
			<HighchartsReact
				highcharts={Highcharts}
				ref={currentChart}
				options={options}
				containerProps={{ style: { height: '100%' } }}
			/>
			{props.tooltipComponent && (
				<CustomChartTooltip chart={currentChart.current?.chart ?? null}>
					{(formatterContext) => props.tooltipComponent?.(formatterContext)}
				</CustomChartTooltip>
			)}
		</Box>
	);
});

export { Chart };
export type { ChartProps, Series, ChartRef };
