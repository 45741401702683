import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import { darken, lighten, useColorScheme } from '@mui/material';
import fetchingError from '../../../../assets/home/Error500&503.svg';
import { useGetV4ResultsQuery, useGetV4TestsQuery } from '@neoload/api';
import { useCurrentWorkspace } from '@neoload/hooks';
import { RUNNING_STATUSES } from '@neoload/utils';

type TestsKpiTileProps = {
	pollingIntervalDurationInMs: number;
};

export const TestsKpiTile = ({ pollingIntervalDurationInMs }: TestsKpiTileProps) => {
	const { mode } = useColorScheme();
	const [{ id: workspaceId }, isCurrentWorkspaceSelected] = useCurrentWorkspace();
	const { data: runningResults, error: runningResultsError } = useGetV4ResultsQuery(
		{
			workspaceId,
			status: RUNNING_STATUSES,
		},
		{
			skip: !isCurrentWorkspaceSelected,
			pollingInterval: pollingIntervalDurationInMs,
		}
	);
	const { data: tests, error: testsError } = useGetV4TestsQuery(
		{
			workspaceId,
		},
		{
			skip: !isCurrentWorkspaceSelected,
			pollingInterval: pollingIntervalDurationInMs,
		}
	);
	const { t } = useTranslation(['home']);
	const isLoaded = runningResults && tests;
	return (
		<Box
			height={'100%'}
			width={'100%'}
			paddingX={(theme) => theme.spacing(2)}
			paddingBottom={(theme) => theme.spacing(2)}
			paddingTop={(theme) => theme.spacing(2)}
		>
			{runningResultsError || testsError ? (
				<Stack
					direction={'column'}
					height={'100%'}
					width={'100%'}
					alignItems={'center'}
					justifyContent={'space-between'}
					gap={(theme) => theme.spacing(2)}
				>
					<Box
						component='img'
						src={fetchingError}
						alt={'Error 503 image'}
						sx={{
							maxHeight: '5em',
						}}
					/>
					<Typography variant={'h6'} fontWeight={400}>
						{t('grid.tile.kpi.error.title')}
					</Typography>
					<Typography variant={'body1'}>{t('grid.tile.kpi.error.description')}</Typography>
					<Button variant='outlined' color='primary' href='/'>
						{t('grid.tile.kpi.error.button')}
					</Button>
				</Stack>
			) : (
				<Stack direction={'row'} height={'100%'} width={'100%'} justifyContent={'space-between'} gap={2}>
					<Stack
						alignItems={'center'}
						justifyContent={'center'}
						width={'50%'}
						borderRadius={'4px'}
						gap={1}
						sx={(theme) => ({
							backgroundColor:
								mode === 'light' ? lighten(theme.palette.info.main, 0.9) : darken(theme.palette.info.main, 0.9),
						})}
					>
						<Typography title={t('grid.tile.kpi.running')} variant={'h6'} fontWeight={400}>
							{t('grid.tile.kpi.running')}
						</Typography>
						<Typography variant={'h3'} fontWeight={400} title={t('grid.tile.kpi.runningValueTitle')}>
							{isLoaded && runningResults ? runningResults.total : <Skeleton width={30} />}
						</Typography>
					</Stack>
					<Stack
						alignItems={'center'}
						justifyContent={'center'}
						width={'50%'}
						borderRadius={'4px'}
						gap={(theme) => theme.spacing(1)}
						sx={{ backgroundColor: (theme) => theme.palette.background.default }}
					>
						<Typography title={t('grid.tile.kpi.total')} variant={'h6'} fontWeight={400} alignItems={'center'}>
							{t('grid.tile.kpi.total')}
						</Typography>
						<Typography variant={'h3'} fontWeight={400} title={t('grid.tile.kpi.totalValueTitle')}>
							{isLoaded && tests ? tests.total : <Skeleton width={30} />}
						</Typography>
					</Stack>
				</Stack>
			)}
		</Box>
	);
};
