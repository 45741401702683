import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { ScreenshotViewer } from './screenshot-viewer';
import { Spinner } from '../../../../../common/spinner';
import { useGetV4ResultsByResultIdEventsContentsAndContentIdQuery } from '@neoload/api';
import { isNotFoundError } from '@neoload/utils';

export type EventTabScreenshotProps = {
	screenshotId: string;
	resultId: string;
};

const EventTabScreenshot = ({ screenshotId, resultId }: EventTabScreenshotProps) => {
	const {
		data: eventContent,
		isLoading,
		isFetching,
		error,
	} = useGetV4ResultsByResultIdEventsContentsAndContentIdQuery({
		contentId: encodeURIComponent(screenshotId),
		resultId,
	});
	const { t } = useTranslation(['result']);

	if (isLoading || isFetching) {
		return <Spinner />;
	}

	if (isNotFoundError(error)) {
		return <Typography variant='body2'>{t('events.details.screenshotNotSaved')}</Typography>;
	}
	if (!eventContent?.stringContent) {
		return (
			<Typography variant='body2' color='error'>
				{t('events.details.screenshotFetchError')}
			</Typography>
		);
	}

	return <ScreenshotViewer screenShot={eventContent.stringContent} />;
};
export { EventTabScreenshot };
