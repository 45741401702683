import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

type SplitPaneProps = {
	children: [ReactNode, ReactNode];
	// proportion as a value from 0 to 1 where 1 is full size
	proportions: [number, number];
};

export const SplitPane: React.FC<SplitPaneProps> = ({ children, proportions }) => {
	const [isResizing, setIsResizing] = useState(false);
	const [initialPosition, setInitialPosition] = useState(0);
	const [height, setHeight] = useState(0);
	const ref = useRef<HTMLDivElement>(null);
	const [paneSizes, setPaneSizes] = useState([0, 0]);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (ref.current !== null) {
			setHeight(ref.current.clientHeight);

			if (!initialized && height > 0) {
				const nextSizes = [...paneSizes];
				nextSizes[0] = height * proportions[0];
				nextSizes[1] = height * proportions[1];
				setPaneSizes(nextSizes);
				setInitialized(true);
			}
		}
	}, [initialized, height, paneSizes, proportions]);

	useEffect(() => {
		const handleMouseMove = (event: MouseEvent) => {
			if (isResizing) {
				const delta = event.clientY - initialPosition;
				const nextSizes = [...paneSizes];

				nextSizes[0] = Math.max(100, nextSizes[0] + delta);
				nextSizes[1] = Math.max(100, nextSizes[1] - delta);

				setPaneSizes(nextSizes);
				setInitialPosition(event.clientY);
			}
		};

		const handleMouseUp = () => {
			setIsResizing(false);
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		};

		if (isResizing) {
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		}

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		};
	}, [isResizing, initialPosition, paneSizes]);

	const handleMouseDown = (event: { clientY: React.SetStateAction<number> }) => {
		setIsResizing(true);
		setInitialPosition(event.clientY);
	};

	const panes = React.Children.toArray(children);

	return (
		<Stack sx={{ height: '100%' }} ref={ref}>
			<Box sx={{ overflowY: 'auto', height: `${paneSizes[0]}px`, userSelect: 'none' }}>{panes[0]}</Box>
			<Box
				sx={{
					marginX: 2,
					height: '1px',
					cursor: 'row-resize',
					bgcolor: (theme) => theme.palette.divider,
					position: 'relative',
				}}
				onMouseDown={handleMouseDown}
			>
				<Box
					sx={{
						zIndex: 1,
						position: 'absolute',
						top: '-1',
						bottom: '-1',
						left: 0,
						right: 0,
					}}
				/>
			</Box>
			<Box sx={{ overflowY: 'auto', height: `${paneSizes[1]}px`, userSelect: 'none' }}>{panes[1]}</Box>
		</Stack>
	);
};
