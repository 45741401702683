import { MouseEventHandler } from 'react';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import { ChipProps } from '@mui/material/Chip';
import { RunningChip } from './running-chip';
import { PlannedChip } from './planned-chip';
import { CancelledChip } from './cancelled-chip';
import { FailedChip } from './failed-chip';
import { PassedChip } from './passed-chip';
import { UnknownChip } from './unknown-chip';
import { ResultStatusData } from './result-status-data';
import { InitializingChip } from './initializing-chip';
import { isRunning, isStarting } from '@neoload/utils';

type RunChipProps = {
	resultStatusData: ResultStatusData;
	onClick?: MouseEventHandler<HTMLDivElement>;
	onRightClick?: MouseEventHandler<HTMLDivElement>;
	withLabel?: boolean;
	percentage?: number;
	size?: ChipProps['size'];
};

type RunStatus = 'passed' | 'failed' | 'unknown' | 'planned' | 'running' | 'cancelled' | 'none' | 'initializing';

const toRunStatus = (resultStatusData: ResultStatusData): RunStatus => {
	if (isRunning(resultStatusData)) {
		return 'running';
	}
	if (isStarting(resultStatusData)) {
		return 'initializing';
	}
	if (resultStatusData.terminationReason === 'CANCELLED') {
		return 'cancelled';
	}
	switch (resultStatusData.qualityStatus) {
		case 'PASSED': {
			return 'passed';
		}
		case 'FAILED': {
			return 'failed';
		}
		case 'COMPUTING':
		case 'UNKNOWN': {
			return 'unknown';
		}
		default: {
			return 'none';
		}
	}
};

const RunChip = (props: RunChipProps) => {
	const status = toRunStatus(props.resultStatusData);
	switch (status) {
		case 'initializing': {
			return <InitializingChip {...props} />;
		}
		case 'running': {
			return <RunningChip {...props} />;
		}
		case 'failed': {
			return <FailedChip {...props} />;
		}
		case 'passed': {
			return <PassedChip {...props} />;
		}
		case 'planned': {
			return <PlannedChip {...props} />;
		}
		case 'cancelled': {
			return <CancelledChip {...props} />;
		}
		case 'unknown': {
			return <UnknownChip {...props} />;
		}
		default: {
			return <ChevronRightOutlined sx={{ color: 'text.disabled' }} />;
		}
	}
};

export { RunChip };
