import { hasNotStarted, isAlive } from './running-utils';
import { TestResult } from '@neoload/api';

export const isEditable = (result: Pick<TestResult, 'qualityStatus' | 'status' | 'terminationReason'>) => {
	if (hasNotStarted(result)) {
		return false;
	}
	if (isAlive(result)) {
		return false;
	}
	switch (result.qualityStatus) {
		case 'COMPUTING':
		case 'FAILED':
		case 'PASSED':
		case 'UNKNOWN': {
			return true;
		}
		default: {
			return false;
		}
	}
};
