import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';

/**
 * Redirect user to login when a HTTP 401 error occurs
 */
// eslint-disable-next-line unicorn/consistent-function-scoping
export const rtkQueryUnauthorizedHandler: Middleware = () => (next) => (action) => {
	if (
		isRejectedWithValue(action) &&
		typeof action.payload === 'object' &&
		action.payload &&
		'status' in action.payload &&
		action.payload.status === 401
	) {
		const { pathname, search, hash } = window.location;
		if (pathname !== '/login') {
			if (Object.prototype.hasOwnProperty.call(window, 'aptrinsic')) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(window as any).aptrinsic('reset');
			}
			const url = `/login?return=${pathname + encodeURIComponent(search + hash)}`;
			window.location.replace(url);
		}
	}
	return next(action);
};
